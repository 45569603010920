import Avatar from '@mui/material/Avatar';
import { Text } from '@shared/components';
import { JobSummaryFragment } from '@shared/generated/graphql';
import {
  getUserColorGroup,
  getUserColour,
  makeElementClassNameFactory,
  makeRootClassName,
} from '@shared/utils';
import { ReactNode } from 'react';
import { FeedItemUser } from '../../types';
import { FeedItemTimestamp } from '../feed-item/FeedItem';

const ROOT = makeRootClassName('CreatedByWrapper');
const el = makeElementClassNameFactory(ROOT);

const getJobEntityDisplayText = (jobSummary: JobSummaryFragment) => {
  const quotesLength = jobSummary.quotes?.length;
  const ordersLength = jobSummary.orders?.length;

  if (quotesLength) {
    return `${quotesLength === 1 ? 'a' : quotesLength} quote${
      quotesLength === 1 ? '' : 's'
    }`;
  }

  if (ordersLength) {
    return `${ordersLength === 1 ? 'an' : ordersLength} order${
      ordersLength === 1 ? '' : 's'
    }`;
  }
};

export const getAvatarParams = (createdBy: FeedItemUser) => {
  const colorGroup = getUserColorGroup(createdBy.id);
  const bgColor = getUserColour(colorGroup);
  const visual =
    createdBy.firstName && createdBy.lastName
      ? createdBy.firstName.substring(0, 1) + createdBy.lastName.substring(0, 1)
      : createdBy.email?.substring(0, 1) || 'U';

  return { bgColor, visual };
};

export const CreatedByWrapper = ({
  createdByDisplay,
  createdBy,
  createdAt,
  children,
  jobSummary,
}: {
  createdByDisplay?: string;
  createdBy?: FeedItemUser;
  createdAt?: string;
  jobSummary: JobSummaryFragment;
  children: ReactNode;
}) => {
  const avatarParams = createdBy ? getAvatarParams(createdBy) : null;

  return (
    <div className={el`created-by-wrapper-root`}>
      {avatarParams && (
        <Avatar
          sx={{
            width: 24,
            height: 24,
            fontSize: '12px',
            bgcolor: avatarParams.bgColor,
          }}
        >
          {avatarParams.visual}
        </Avatar>
      )}
      <div className={el`created-content`}>
        {createdByDisplay ? (
          <div className={el`header`}>
            <Text isHeavy className={el`created-by`} type="body-xs">
              {createdByDisplay} created {getJobEntityDisplayText(jobSummary)}
            </Text>
            <FeedItemTimestamp timestamp={createdAt} />
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
};
