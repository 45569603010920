import { ApolloError } from '@apollo/client';
import {
  QuoteBidStatus,
  QuoteSummaryFragment,
  useGetQuoteSummariesQuery,
} from '@shared/generated/graphql';

export interface Pagination {
  first?: number;
  last?: number;
  after?: string | null;
  before?: string | null;
}

type UseGetQuotesOptions = {
  fetchAll: boolean;
  organizationIds?: string[];
  originatorIds?: string[];
  userIds?: string[];
  statuses?: QuoteBidStatus[];
  dateFilter?: {
    start?: string;
    end?: string;
  };
  searchTerm?: string;
  pollIntervalMs?: number;
  conversationId?: string;
  sharedInbox?: string;
};

type UseGetQuotesState = {
  quotes: QuoteSummaryFragment[];
  isLoading?: boolean;
  error?: ApolloError;
  startCursor?: string | null;
  endCursor?: string | null;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  totalCount: number;
};

type UseGetQuotesActions = {
  // add any actions the hook provides
  refetch: () => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
};

export type UseGetQuotesValue = UseGetQuotesState & UseGetQuotesActions;

const useGetQuoteSummaries = (
  props?: UseGetQuotesOptions & Pagination
): UseGetQuotesValue => {
  const {
    data,
    error,
    loading: isLoading,
    refetch,
    startPolling,
    stopPolling,
  } = useGetQuoteSummariesQuery({
    variables: {
      input: {
        organizationIds: props?.organizationIds,
        originatorIds: props?.originatorIds,
        dateFilter: props?.dateFilter,
        userIds: props?.userIds,
        statuses: props?.statuses,
        searchTerm: props?.searchTerm,
        outlookFilter: {
          conversationId: props?.conversationId,
          sharedInbox: props?.sharedInbox,
        },
      },
      first: props?.first,
      last: props?.last,
      after: props?.after,
      before: props?.before,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: props?.pollIntervalMs,
    skip: !props?.fetchAll && !props?.conversationId,
  });

  const quotes = data?.quotes.edges.map((edge) => edge.node) ?? [];

  return {
    ...data?.quotes.pageInfo,
    totalCount: data?.quotes.totalCount ?? 0,
    quotes,
    error,
    isLoading,
    refetch,
    startPolling,
    stopPolling,
  };
};

export default useGetQuoteSummaries;
