import { Text } from '@shared/components';
import { AuthorizeMicrosoftInstructions } from '@shared/components/authorizeInstructions/AuthorizeMicrosoftInstructions';
import { PiWarningCircleDuotone } from 'react-icons/pi';

export const WEB_INTEGRATION_CONIFIGURATION_URL = `${
  import.meta.env.VITE_WEB_BASE_URL
}/integrations`;

export const AuthorizeUnderlyingInboxNotice = () => {
  return (
    <div className="p-4 flex flex-col">
      <div className="flex flex-row gap-5">
        <PiWarningCircleDuotone size={24} className="text-yellow-500" />
        <Text type="body-md" isHeavy className="text-gray-600">
          Vooma needs access to the Outlook account that you use through Front
        </Text>
      </div>
      <AuthorizeMicrosoftInstructions
        webIntegrationsUrl={WEB_INTEGRATION_CONIFIGURATION_URL}
      />
    </div>
  );
};
