import { CopyButton } from '@shared/components/copy-button';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { LinkToRateProps } from './types';

export function CopyGenericRateId(p: LinkToRateProps) {
  const { track } = useAnalytics();

  const rateId = p.metadata?.rateId;
  if (!rateId) return <div />;

  return (
    <CopyButton
      textToCopy={rateId}
      label={`Copy quote ID`}
      variant="secondary"
      size="small"
      className="my-1"
      onCopy={() => {
        track('Copy rate ID Clicked', {
          quoteId: p.quote.id,
          organizationId: p.quote.organization.id,
        });
      }}
    />
  );
}
