import { Button, RHFTextField as TextField, Text } from '@shared/components';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import {
  WizardStepContainer,
  WizardStepProps,
} from '@shared/components/wizard';
import { Originator } from '@shared/plugin/types/originator';
import { useForm } from 'react-hook-form';
import { el } from '../OriginatorOnboardingWizard';
import { OriginatorOnboardingType } from '../types';

interface OriginatorOnboardingGetStartedProps {
  originator?: Originator;
  onboardingType: OriginatorOnboardingType;
}
export type GetStartedData = { originatorName: string };
const GET_STARTED_DEFAULTS = { originatorName: '' };
export function OriginatorOnboardingGetStarted(
  p: WizardStepProps<GetStartedData> & OriginatorOnboardingGetStartedProps
) {
  const { control, handleSubmit, getValues, reset } = useForm<GetStartedData>({
    defaultValues: p.defaultValues ?? GET_STARTED_DEFAULTS,
  });
  useLoadInitialValues(reset, p.defaultValues as GetStartedData);

  const hasDefaultName = Boolean(p.defaultValues?.originatorName);
  const displayName = getValues('originatorName') || 'this customer';
  const isFirstOrder =
    p.onboardingType === OriginatorOnboardingType.FIRST_ORDER;
  const isNewOriginator =
    p.onboardingType === OriginatorOnboardingType.NEW_ORIGINATOR;

  const getTitleText = () => {
    switch (p.onboardingType) {
      case OriginatorOnboardingType.EXISTING_ORIGINATOR:
        return `Configure the details for ${displayName}.`;
      case OriginatorOnboardingType.FIRST_ORDER:
        return `This is the first order we've received for ${displayName}. Let's get their order
        details set up.`;
      case OriginatorOnboardingType.NEW_ORIGINATOR:
        return `This is a new customer. Let's get their order details set up.`;
    }
  };

  return (
    <WizardStepContainer
      handleSubmit={handleSubmit}
      {...p}
      className={el`wizard-step-container`}
    >
      <div className="flex flex-col gap-4">
        <div className={el`header`}>
          <Text type="custom" isHeavy className={el`title`}>
            {getTitleText()}
          </Text>
          <Text type="body-sm" className={el`subtitle`}>
            {`We’ll ask a few questions so Vooma can learn how to best build their
        orders.`}
          </Text>
        </div>

        <div className={el`body`}>
          {isNewOriginator && (
            <TextField
              label="Can you confirm their name?"
              name="originatorName"
              control={control}
              placeholder={
                p.defaultValues?.originatorName || 'Enter customer name'
              }
              rules={{ required: 'Customer name is required' }}
            />
          )}
          {isNewOriginator && hasDefaultName ? (
            <Button
              onPress={() => p.onClose?.()}
              variant="tertiary"
              size="small"
            >
              This customer is already setup
            </Button>
          ) : null}
          <Button
            onPress={() => handleSubmit(p.toNextStep)()}
            size="medium"
            variant="primary"
          >
            Get started
          </Button>
          {isFirstOrder || !hasDefaultName ? (
            <Button
              onPress={() => p.onClose?.()}
              variant="tertiary"
              size="small"
            >{`I'll do this later`}</Button>
          ) : null}
        </div>
      </div>
    </WizardStepContainer>
  );
}
