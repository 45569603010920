import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  formatAddress,
  formatAddressLineOne,
  formatAddressLineTwo,
} from 'clerk_common/stringification/address';
import { Address } from 'clerk_common/types/address';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Button } from '../button';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { TextField } from '../react-hook-form';
import { SearchInput, SearchInputOption } from '../search-input/SearchInput';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineAddressField');
const el = makeElementClassNameFactory(ROOT);

interface InlineAddressFieldProps<T> {
  name: Path<T>;
  required: boolean;
  options: SearchInputOption[];
  placeholder?: string;
  loadingSearchResults: boolean;
  save: (p: Path<T>) => void;
  validateAddress: (address: Address) => boolean;
  handleSearch: (query?: string) => void;
  handleSelect: (id: string) => void;
  allowCustomAddress?: boolean;
}

export const InlineAddressField = <T extends FieldValues>(
  p: InlineAddressFieldProps<T>
) => {
  const [isEditing, setIsEditing] = useState(false);
  const { watch, setValue } = useFormContext<T>();

  const addressPath = `${p.name}.address` as Path<T>;

  const addressValue: Address = watch(addressPath);
  const addressQuery = formatAddress(addressValue);

  const nameValue: string = watch(`${p.name}.name` as Path<T>);

  const initialValue = useMemo(() => {
    return addressValue;
  }, [isEditing]);

  const onCancel = () => {
    setValue(addressPath, initialValue as PathValue<T, Path<T>>);
    p.handleSearch('');
    setIsEditing(false);
  };

  return (
    <div className={clsx(ROOT)}>
      <div className={clsx(el`place-wrapper`, isEditing && 'EDITING')}>
        <div
          className={clsx(el`address-wrapper`, isEditing && 'EDITING')}
          onClick={() => {
            if (!isEditing) {
              setIsEditing(true);
            }
          }}
        >
          {isEditing ? (
            <div className="w-full">
              <SearchInput
                debounce
                handleSearch={p.handleSearch}
                handleSelect={p.handleSelect}
                options={p.options}
                selectedOption={{
                  label: addressQuery,
                  id: 'selected-address-id',
                  details: '',
                }}
                hideSelected
                placeholder={addressQuery || p.placeholder}
                onClose={onCancel}
                autofocus
                initializeSearchWithValue
                loading={p.loadingSearchResults}
                isTransparent
                optionsClassName={el`search-options`}
              />
            </div>
          ) : (
            <DisplayAddress
              address={addressValue}
              locationName={nameValue}
              validateAddress={p.validateAddress}
              name={p.name}
              allowCustomAddress={p.allowCustomAddress}
            />
          )}
        </div>
        {!isEditing && <FlowIcon fieldPath={addressPath} type="ADDRESS" />}
      </div>
    </div>
  );
};

type DisplayAddressProps<T extends FieldValues> = {
  address: Address;
  locationName: string;
  validateAddress: (address: Address) => boolean;
  name: Path<T>;
  allowCustomAddress?: boolean;
};
function DisplayAddress<T extends FieldValues>({
  address,
  locationName,
  validateAddress,
  name,
  allowCustomAddress,
}: DisplayAddressProps<T>) {
  const [customizeAddress, setCustomizeAddress] = useState(false);
  const addressLineOne = formatAddressLineOne(address);
  const addressLineTwo = formatAddressLineTwo(address);
  const isAddressValid = validateAddress(address);

  const mainAddressLine = [locationName, addressLineTwo]
    .filter(Boolean)
    .join(' · ');

  return (
    <div className="flex h-full w-full flex-row items-center justify-between">
      {customizeAddress ? (
        <EditAddress path={name} close={() => setCustomizeAddress(false)} />
      ) : (
        <div>
          <Text
            className={clsx(
              el`address-line-two`,
              isAddressValid ? 'VALID' : 'INVALID'
            )}
            isHeavy={isAddressValid}
            type="body-sm"
          >
            {mainAddressLine || 'Enter city or zipcode'}
          </Text>
          <Text
            className={clsx(el`address-line-one`, !addressLineOne && 'EMPTY')}
            type="body-xs"
          >
            {addressLineOne || 'Address details'}
          </Text>
        </div>
      )}
      {allowCustomAddress && !customizeAddress && (
        <Button
          className={el`edit-button`}
          onPress={() => setCustomizeAddress((prev) => !prev)}
          icon={<MdEdit />}
          isGhost
          size="xs"
          tooltip={'Type address manually'}
        />
      )}
    </div>
  );
}

type EditAddressProps<T> = {
  path: Path<T>;
  close: () => void;
};
function EditAddress<T extends FieldValues>({
  path,
  close,
}: EditAddressProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <div onClick={(e) => e.stopPropagation()} className="w-full">
      <TextField
        control={control}
        label="Name"
        name={`${path}.name` as Path<T>}
        size="medium"
        className="w-full"
      />
      <TextField
        control={control}
        label="Address Line One"
        name={`${path}.address.addressOne` as Path<T>}
        size="medium"
        className="w-full"
      />
      <TextField
        control={control}
        label="Address Line Two"
        name={`${path}.address.addressTwo` as Path<T>}
        size="medium"
      />
      <div className="flex w-full flex-row justify-between gap-2">
        <TextField
          control={control}
          label="City"
          name={`${path}.address.city` as Path<T>}
          size="medium"
        />
        <TextField
          control={control}
          label="State"
          name={`${path}.address.state` as Path<T>}
          size="medium"
        />
      </div>
      <div className="flex w-full flex-row justify-between gap-2">
        <TextField
          control={control}
          label="Postal Code"
          name={`${path}.address.zip` as Path<T>}
          size="medium"
        />
        <TextField
          control={control}
          label="Country"
          name={`${path}.address.country` as Path<T>}
          size="medium"
        />
      </div>
      <Button onPress={close} variant="secondary" className="mt-5 w-full">
        Done
      </Button>
    </div>
  );
}
