import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { EditableJSONField } from '../../types';
import { SimpleArray } from '../simple-array/SimpleArray';
import { Tag } from './Tag';

const ROOT = makeRootClassName('TagArray');
export const el = makeElementClassNameFactory(ROOT);

export type TagElement = {
  name: EditableJSONField;
};

export type TagArrayData = {
  _value: TagElement[];
  _added?: TagElement[];
  _idxs?: string[];
};

type TagArrayProps = {
  data: TagArrayData;
  prefix?: string;
};

export function TagArray({ data, prefix }: TagArrayProps) {
  return (
    <SimpleArray
      data={data}
      ElementComponent={Tag}
      createNewElement={createTagElement}
      description="Tag"
      prefix={prefix}
    />
  );
}

function createTagElement(): TagElement {
  return {
    name: {
      _value: '',
      _display: {
        name: '',
      },
      _corrected: '',
      _logProbs: [],
    },
  };
}
