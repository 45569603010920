import { Button, Text } from '@shared/components';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import { ORGANIZATION_SETTINGS_ROUTE } from 'clerk_common/constants/urls/routes';
import { PiWarningCircleDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

const WEB_BASE_URL = import.meta.env.VITE_WEB_BASE_URL;

export const AuthorizeFrontNotice = () => {
  const navigate = useNavigate();
  const openFrontTokenPage = () =>
    window.open(
      `https://app.frontapp.com/settings/developers/tokens`,
      '_blank'
    );

  const openVoomaIntegrationsPage = () =>
    window.open(
      `${WEB_BASE_URL}${ORGANIZATION_SETTINGS_ROUTE}?tab=${DrawerTabValue.INTEGRATIONS}`
    );

  return (
    <div className="p-4 flex flex-col gap-5">
      <div className="flex flex-row gap-5">
        <PiWarningCircleDuotone size={24} className="text-yellow-500" />
        <Text type="body-md" isHeavy className="text-gray-600">
          Vooma needs access to your Front data
        </Text>
      </div>
      <Text type="body-md" className="text-gray-600">
        This must be configured by an organization admin
      </Text>
      <Text type="body-md" className="text-gray-600">
        First, you will need to create an API token for your Front account
      </Text>
      <Button variant="primary" className="w-full" onPress={openFrontTokenPage}>
        Create Front API Token
      </Button>
      <Text type="body-md" className="text-gray-600">
        When you have created the API token, enter it on the integrations page
        in the Vooma web app
      </Text>
      <Button
        variant="primary"
        className="w-full"
        onPress={openVoomaIntegrationsPage}
      >
        Manage Vooma Integrations
      </Button>
      <Text type="body-md" className="text-gray-600">
        When you've completed these steps, click "Refresh" below
      </Text>
      <Button
        variant="primary"
        className="w-full"
        onPress={() => navigate('/')}
      >
        Refresh
      </Button>
    </div>
  );
};
