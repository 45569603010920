import { Tooltip } from '@shared/components/tooltip';
import { useLogosContext } from '@shared/contexts/pluginContexts/LogosURLsContext';
import { RatingMethod } from '@shared/generated/graphql';

export const RateLogo = ({ rateMethod }: { rateMethod?: RatingMethod }) => {
  const logos = useLogosContext();
  if (!rateMethod) return null;

  const gsTooltip = 'Rate sourced from Greenscreens.ai';
  const datTooltip = 'Rate sourced from DAT';
  const sunsetTooltip = 'Rate sourced from Sunset';
  const tabiTooltip = 'Rate sourced from Tabi';
  const wernerTooltip = 'Rate sourced from Werner';
  const arcbestTooltip = 'Rate sourced from ArcBest';
  const echoTooltip = 'Rate sourced from Echo';

  switch (rateMethod) {
    case RatingMethod.DAT:
      return <Logo src={logos.DAT} tooltip={datTooltip} width={48} />;
    case RatingMethod.GREENSCREENS:
      return <Logo src={logos.GREENSCREENS} tooltip={gsTooltip} />;
    case RatingMethod.SUNSET:
      return <Logo src={logos.SUNSET} tooltip={sunsetTooltip} />;
    case RatingMethod.TABI:
      return <Logo src={logos.TABI} tooltip={tabiTooltip} />;
    case RatingMethod.WERNER:
      return <Logo src={logos.WERNER} tooltip={wernerTooltip} />;
    case RatingMethod.ARCBEST_TL_QUOTING:
      return <Logo src={logos.ARCBEST} tooltip={arcbestTooltip} />;
    case RatingMethod.ECHO:
      return <Logo src={logos.ECHO} tooltip={echoTooltip} />;
    default:
      return null;
  }
};

type LogoProps = {
  src: string;
  tooltip: string;
  width?: number;
};
function Logo({ src, tooltip, width = 32 }: LogoProps) {
  return (
    <Tooltip content={tooltip} isInstant>
      <div className="rounded-md border p-[2px]">
        <img width={width} src={src} alt={tooltip} />
      </div>
    </Tooltip>
  );
}
