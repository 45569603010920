import { Button } from '@shared/components/button';
import { Separator } from '@shared/components/separator';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { RateComponentType } from 'clerk_common/types/pricingStrategy';
import { motion } from 'framer-motion';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MdClear } from 'react-icons/md';
import { v4 } from 'uuid';
import { RateLineItem } from '../rate-line-item/RateLineItem';
import {
  MAX_RATE_LINE_ITEMS,
  MoneyFormRateLineItem,
  MoneyFormValues,
} from '../types';

const ROOT = makeRootClassName('RateLineItems');
const el = makeElementClassNameFactory(ROOT);

type RateLineItemsProps = {
  quoteId: string;
  expanded: boolean;
  compact?: boolean;
  onSubmit: (data: MoneyFormValues, createNewRate?: boolean) => Promise<void>;
  setExpanded: (expanded: boolean) => void;
};

export const RateLineItems = ({
  quoteId,
  setExpanded,
  expanded,
  compact,
  onSubmit,
}: RateLineItemsProps) => {
  const { control, getValues } = useFormContext<MoneyFormValues>();

  const { track } = useAnalytics();

  const toggleExpanded = () => {
    track(`${expanded ? 'Collapsed' : 'Expanded'} Rate Line Items Table`, {
      quoteId,
    });
    setExpanded(!expanded);
  };

  const {
    fields: lineItemFields,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const canAddItems = lineItemFields.length < MAX_RATE_LINE_ITEMS;
  const canRemoveItems = lineItemFields.length > 1;

  const submitForm = () => {
    onSubmit(getValues() as MoneyFormValues);
  };

  const addLineItem = () => {
    const componentId = v4();
    const item: MoneyFormRateLineItem = {
      description: '',
      buyRate: '',
      sellRate: '',
      componentId,
      type: RateComponentType.OTHER,
      index: lineItemFields.length,
    };
    appendLineItem(item);
    track('Added Rate Line Item', { quoteId });
  };

  const handleRemoveLineItem = (idx: number) => {
    removeLineItem(idx);
    track('Removed Rate Line Item', { quoteId });

    submitForm();
  };

  if (!lineItemFields.length) return null;

  return (
    <div className={ROOT}>
      <Button onPress={toggleExpanded} variant="tertiary">
        {expanded ? 'Hide line items' : 'See line items'}
      </Button>
      <div className={el`line-items-container`}>
        <motion.div
          initial={{ height: 0, marginTop: 0, marginBottom: 0 }}
          animate={{
            height: expanded ? 'auto' : 0,
            marginTop: expanded ? 8 : 0,
            marginBottom: expanded ? 8 : 0,
          }}
          transition={{ duration: 0.2 }}
          className={el`line-items`}
        >
          <Separator className={el`line-items-separator`} />
          {expanded ? (
            <div className={el`line-items-table`}>
              {lineItemFields.map(({ id, type }, idx) => {
                return (
                  <div
                    key={id}
                    className={
                      compact ? el`compact-line-item-row` : el`line-item-row`
                    }
                  >
                    <RateLineItem
                      idx={idx}
                      type={type}
                      expanded={expanded}
                      submitForm={submitForm}
                    />
                    {compact ? (
                      canRemoveItems && (
                        <Button
                          variant="tertiary"
                          size="xs"
                          onPress={() => handleRemoveLineItem(idx)}
                        >
                          Remove
                        </Button>
                      )
                    ) : (
                      <Button
                        onPress={() => handleRemoveLineItem(idx)}
                        variant="secondary"
                        size="xs"
                        isDisabled={!canRemoveItems}
                        icon={<MdClear size={14} />}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}

          <Separator className={el`line-items-separator`} />
          {expanded ? (
            <div className={el`add-item-container`}>
              <Button
                variant="tertiary"
                onPress={addLineItem}
                isDisabled={!canAddItems}
              >
                + Add line item
              </Button>
            </div>
          ) : null}
        </motion.div>
      </div>
    </div>
  );
};
