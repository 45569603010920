import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { createCheck, createMinus } from '@shared/assets/icons';
import { Icon, Text } from '@shared/components';
import { useOptionalRef } from '@shared/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement } from 'react';
import { TextType } from '../text';

export interface CheckboxProps extends RadixCheckboxProps, StyleProps {
  /**
   * The visual style of the checkbox
   * @default 'default'
   */
  variant?: 'default' | 'light';

  /**
   * Whether or not this checkbox is indeterminate
   * @default false
   */
  isIndeterminate?: boolean;

  /**
   * An optional label to display next to the checkbox.
   */
  label?: string;
  labelType?: TextType;
  labelclassname?: string;
}

// config
// ------

const ROOT = makeRootClassName('Checkbox');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  variant: 'default',
  isIndeterminate: false,
} as const;

const CHECK_ICON = createCheck;
const MINUS_ICON = createMinus;

// component
// ---------

function CheckboxComponent(
  props: CheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  // handle indeterminate state
  // ---------------------------

  const indeterminateProps = p.isIndeterminate ? { checked: true } : {};

  // behaviour
  // ---------

  const { hoverProps, isHovered } = useHover({
    isDisabled: p.disabled,
  });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(hoverProps, focusProps);

  const { disabled, isIndeterminate, ...restProps } = p;

  // rendering
  // ---------

  function renderIndicator() {
    if ((isIndeterminate && disabled) || isIndeterminate) {
      return (
        <Icon content={MINUS_ICON} size="custom" className={el`indicator`} />
      );
    }

    return (
      <Icon content={CHECK_ICON} size="custom" className={el`indicator`} />
    );
  }

  function renderCheckbox() {
    return (
      <RadixCheckbox.Root
        {...behaviorProps}
        {...restProps}
        {...indeterminateProps}
        ref={domRef}
        disabled={p.disabled}
        className={clsx(
          `${ROOT} variant-${p.variant}`,
          {
            'is-hovered': isHovered,
            'is-disabled': p.disabled,
            'is-focus-visible': isFocusVisible,
            'is-indeterminate': p.isIndeterminate,
            'has-label': !!p.label,
          },
          p.className
        )}
      >
        <RadixCheckbox.Indicator>{renderIndicator()}</RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    );
  }

  return (
    <>
      {p.label ? (
        <label className={el`label-wrapper`}>
          {renderCheckbox()}
          <Text
            as="span"
            className={clsx(
              el`label-text`,
              `variant-${p.variant}`,
              p.labelclassname
            )}
            type={p.labelType}
          >
            {p.label}
          </Text>
        </label>
      ) : (
        renderCheckbox()
      )}
    </>
  );
}

/**
 * A control that allows the user to toggle between checked and not checked.
 */
export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  CheckboxComponent
);

export default Checkbox;
