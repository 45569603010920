import {
  CircularProgress,
  SlideOver,
} from '@shared/components/plugin-components';
import { PlaceDetailsSection } from '@shared/features/place-details-section';
import { usePlaceQuery } from '@shared/generated/graphql';
import { placeFromFragment } from '@shared/graphql/fromFragments/place';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Place } from '@shared/types/place';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useEffect } from 'react';

const POLLING_INTERVAL = 10 * 1000;

const ROOT = makeRootClassName('PlaceSidePanel');
export const el = makeElementClassNameFactory(ROOT);

interface PlaceSidePanelProps {
  place: Place;
  loading: boolean;
}

const PlaceSidePanel = ({ place }: PlaceSidePanelProps) => {
  return (
    <div className={el`place-container`}>
      <PlaceDetailsSection place={place} />
    </div>
  );
};

interface PlaceSidePanelContainerProps {
  placeId: string;
  showSidepanel: boolean;
  setShowSidepanel: (show: boolean) => void;
}

const PlaceSidePanelContainer = ({
  placeId,
  showSidepanel,
  setShowSidepanel,
}: PlaceSidePanelContainerProps) => {
  const { page } = useAnalytics();
  const { data, loading, stopPolling, startPolling } = usePlaceQuery({
    variables: {
      id: placeId,
    },
    pollInterval: POLLING_INTERVAL,
    skip: !placeId,
  });

  useEffect(() => {
    if (showSidepanel) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [showSidepanel]);

  const place = data?.placeById ? placeFromFragment(data?.placeById) : null;

  useEffect(() => {
    if (place?.id) {
      page('Place', { placeId: place?.id });
    }
  }, [place?.id]);

  return (
    <SlideOver
      title={''}
      show={showSidepanel}
      onClose={() => setShowSidepanel(false)}
    >
      {loading && !place && (
        <div className="w-full h-1/3 flex items-center justify-center">
          <CircularProgress size="md" />
        </div>
      )}
      {!loading && !place && <div className="p-2">Could not find place</div>}
      {place && <PlaceSidePanel place={place} loading={loading} />}
    </SlideOver>
  );
};

export default PlaceSidePanelContainer;
