import { Button } from '@shared/components/button';
import { InteractableComponent } from '@shared/components/interactable-component';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { Trash } from 'react-feather';
import { PiPlus } from 'react-icons/pi';
import { getTextProps } from '../../formating';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableJSONField, EditableJSONObject } from '../../types';
import { useArrayBlock } from '../../useArrayBlock';
import { LineItem } from './LineItem';

const ROOT = makeRootClassName('LineItemArray');
export const el = makeElementClassNameFactory(ROOT);

export type LineItemElement = {
  amount: EditableJSONField;
  description: EditableJSONField;
  // NOTE(max): This is named with the "lineItem" prefix due to how component
  // matching works in Select.tsx based on prefix. This is not ideal. Once
  // Select.tsx moves to matching on a template "_type" value instead,
  // this could be renamed to simplify.
  lineItemType?: EditableJSONField;
};

export type LineItemArrayData = {
  _value: EditableJSONObject[];
  _added?: EditableJSONObject[];
  _idxs?: string[];
  _display: {
    name: string;
  };
};

type LineItemArrayElementDisplayOptions = {
  descriptionFieldName?: string;
  amountFieldName?: string;
  typeFieldName?: string;
};

type LineItemArrayProps = {
  data: LineItemArrayData;
  prefix?: string;
};

export function LineItemArray({ data, prefix }: LineItemArrayProps) {
  const { save } = useInternalFormContext();
  const { elementsToDisplay, addElement, removeElement } = useArrayBlock({
    data,
    prefix,
    save,
  });

  const hasExistingLineItems = data._value.length > 0;
  const newItemDisplayOptions = {
    descriptionFieldName: hasExistingLineItems
      ? data._value[0].description._display?.name
      : undefined,
    amountFieldName: hasExistingLineItems
      ? data._value[0].amount._display?.name
      : undefined,
    typeFieldName: hasExistingLineItems
      ? data._value[0].lineItemType?._display?.name
      : undefined,
  };

  const elementDescription = 'Line Item';
  if (elementsToDisplay.length === 0) {
    return (
      <div className={el`empty-list`}>
        <Text type="body-xs" isHeavy>
          No Line Items
        </Text>
        <div className={el`add-element-button`}>
          <Button
            onPress={() => {
              addElement(createNewElement(newItemDisplayOptions));
            }}
            variant="tertiary"
            size="small"
            icon={<PiPlus />}
          >
            {`Add ${elementDescription}`}
          </Button>
        </div>
      </div>
    );
  }

  const textProps = getTextProps();
  return (
    <div className={ROOT}>
      <div className={el`line-item-list`}>
        {elementsToDisplay.map((elem, index) => (
          <div key={`${elem.provenance}.${elem.idx}`}>
            <Text className={el`nested-object-title`} {...textProps}>
              {`${data._display?.name || 'Line Item'} #${index + 1}`}
            </Text>
            <div className={el`element-row`}>
              <LineItem
                data={elem.element as LineItemElement}
                prefix={`${prefix}${elem.provenance}.${elem.idx}.`}
              />
              <InteractableComponent
                className={el`element-row-button`}
                onPress={() => {
                  removeElement(elem.provenance, elem.idx);
                }}
              >
                <Tooltip content={`Delete ${elementDescription}`}>
                  <Trash size={12} />
                </Tooltip>
              </InteractableComponent>
            </div>
          </div>
        ))}
      </div>
      <div className={el`add-element-button`}>
        <Button
          onPress={() => {
            addElement(createNewElement(newItemDisplayOptions));
          }}
          variant="tertiary"
          size="small"
          icon={<PiPlus />}
        >
          {`Add ${elementDescription}`}
        </Button>
      </div>
    </div>
  );
}

function createNewElement(
  options: LineItemArrayElementDisplayOptions
): LineItemElement {
  // TODO(mike): In theory, this could revive a deleted element.
  const defaultFields = {
    _value: '',
    _sanitized: '',
    _entity: '',
    _transformed: '',
    _display: {
      name: '',
    },
    _logProbs: [],
  };

  return {
    description: {
      ...defaultFields,
      _corrected: '',
      _display: {
        name: options.descriptionFieldName || 'Description',
      },
    },
    amount: {
      ...defaultFields,
      _corrected: '',
      _display: {
        name: options.amountFieldName || 'Amount',
      },
    },
    ...{
      lineItemType: {
        ...defaultFields,
        _corrected: '',
        _display: {
          name: options.typeFieldName || 'Type',
        },
      },
    },
  };
}
