import { Place } from '@shared/types/place';
import { Mode } from '@shared/types/side-panel';
import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { PlaceDetailsDisplay } from './components/place-details-display';
import { PlaceDetailsForm } from './components/place-details-form';

export type PlaceDetailsSectionProps = StyleProps & {
  place: Place;
};

const ROOT = makeRootClassName('PlaceDetailsSection');

const DEFAULT_PROPS = {} as const;

function PlaceDetailsSection(props: PlaceDetailsSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [mode, setMode] = useState(Mode.DISPLAY);

  return (
    <div className={clsx(ROOT, p.className)}>
      {Mode.DISPLAY === mode ? (
        <PlaceDetailsDisplay
          onEdit={() => setMode(Mode.EDIT)}
          place={p.place}
        />
      ) : (
        <PlaceDetailsForm
          onDiscard={() => setMode(Mode.DISPLAY)}
          placeId={p.place.id}
        />
      )}
    </div>
  );
}

export default PlaceDetailsSection;
