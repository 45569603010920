import { FieldValues, UseFormReset } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * A utility hook to use in forms that load initial values
 * async from a backend. Uses the reset action from useForm.
 *
 * react-hook-form caches initial values on first render, so
 * we need to reset the form with the new initial values if
 * they come in after the first render.
 */
export default function useLoadInitialValues<T extends FieldValues>(
  reset: UseFormReset<T>,
  initialValues: T,
  transform?: (initialValues: T) => T
): void {
  useDeepCompareEffect(() => {
    if (initialValues) {
      reset(transform ? transform(initialValues) : initialValues);
    }
  }, [reset, initialValues]);
}
