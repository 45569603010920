import { startCase } from 'lodash';

export const DEFAULT_REQUIRED_FIELDS = [
  'tenderType',
  'orderReferenceNumber',
  'orderReferenceNumbers.<n>.referenceNumber',
  'equipment.equipmentType',
  'weight.weight',
  'weight.units',
  'pickup.location.address',
  'pickup.location.address.addressOne',
  'pickup.location.address.city',
  'pickup.location.address.zip',
  'pickup.location.address.state',
  'pickup.date',
  'stops.<n>.location.address',
  'stops.<n>.location.address.addressOne',
  'stops.<n>.location.address.city',
  'stops.<n>.location.address.state',
  'stops.<n>.location.address.zip',
  'stops.<n>.date',
] as const;

export function printMissingRequiredFields(
  fields: string[],
  joinWithChar?: string
) {
  const lines = fields.map((f) => {
    const indexed = replaceIndices(f);
    const parts = indexed.split('.');
    const cased = parts.map((p) => correctCase(p));
    return cased.join(' > ');
  });
  return joinWithChar
    ? lines.join(`${joinWithChar} `)
    : lines.map((l) => `* ${l}`).join('\n');
}

function replaceIndices(s: string) {
  const result = s.replace(/\.\d+/g, (match: string) => {
    return ` (#${parseInt(match.slice(1)) + 1})`;
  });
  return result.replace(/\.<n>/g, ' (All)');
}

function correctCase(s: string) {
  const words = s.split(' ');
  const cased = words.map((w) => {
    if (isInBrackets(w)) return w;
    return startCase(w);
  });
  return cased.join(' ');
}

function isInBrackets(s: string) {
  return s.startsWith('(') && s.endsWith(')');
}

export type RequiredOneOfOption = string[];
export type RequiredField = string | RequiredOneOfOption[];
