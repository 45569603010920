import { isNil } from 'lodash';
import { STANDARD_REFERENCE_TYPES } from '../../../../enums';
import { placeholderToOptions } from '../placeholders';
import {
  ExplicitReferenceSettings,
  GenericReferenceSettings,
  ReferenceNumbersElement,
  ReferenceSettings,
  StopConfigurationType,
  StopWithRefs,
  TemplateReferenceSettings,
  TemplateWithRefs,
} from './types';

export function extractReferenceSettings(
  template: TemplateWithRefs
): TemplateReferenceSettings {
  const stopConfiguration = inferStopConfiguration(template);
  const pickupReferences = extractPickupReferences(template, stopConfiguration);
  const deliveryReferences = extractDeliveryReferences(
    template,
    stopConfiguration
  );
  const stopReferences = extractStopReferences(template, stopConfiguration);
  return {
    orderReferences: toReferenceSettings(
      template._value.orderReferenceNumbers?._value ?? []
    ),
    stopConfiguration,
    pickupReferences,
    deliveryReferences,
    stopReferences,
  };
}

export function extractStopReferences(
  template: TemplateWithRefs,
  stopConfiguration: StopConfigurationType
): ReferenceSettings | undefined {
  switch (stopConfiguration) {
    case StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY:
      return;
    case StopConfigurationType.TYPED_STOP_ARRAY: {
      const stops = template._value.stops?._value;
      if (stops?.length !== 1) {
        throw new Error('Invalid stop configuration');
      }
      return toReferenceSettings(getReference(stops[0]));
    }
    case StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT:
      return;
    default:
      throw new Error('Unknown stop configuration');
  }
}

export function extractPickupReferences(
  template: TemplateWithRefs,
  stopConfiguration: StopConfigurationType
): ReferenceSettings | undefined {
  const pickupSectionReferences =
    template._value.pickup?._value.stopReferenceNumbers?._value;
  switch (stopConfiguration) {
    case StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY:
      if (isNil(pickupSectionReferences)) {
        throw new Error('Pickup references are missing');
      }
      return toReferenceSettings(pickupSectionReferences ?? []);
    case StopConfigurationType.TYPED_STOP_ARRAY:
      return;
    case StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT: {
      const pickup = firstStopOfType(template, 'PICKUP');
      return toReferenceSettings(getReference(pickup));
    }
    default:
      throw new Error('Unknown stop configuration');
  }
}

function hasStopOfType(
  template: TemplateWithRefs,
  stopType: 'PICKUP' | 'DELIVERY'
): boolean {
  return template._value.stops?._value.some(
    (s) => s.stopType?._ai?.placeholder === stopType
  );
}

function firstStopOfType(
  template: TemplateWithRefs,
  stopType?: 'PICKUP' | 'DELIVERY'
) {
  const stopsArray = template._value.stops?._value;
  if (isNil(stopsArray) || stopsArray.length === 0) {
    throw new Error('Stops are missing from template');
  }
  if (isNil(stopType)) {
    return stopsArray[0];
  }
  const firstStop = template._value.stops._value.find(
    (s) => s.stopType?._ai?.placeholder === stopType
  );
  if (isNil(firstStop)) {
    throw new Error(`No stop of type ${stopType} found in template`);
  }
  return firstStop;
}

function getReference(stop: StopWithRefs): ReferenceNumbersElement[] {
  return stop.stopReferenceNumbers?._value ?? [];
}

export function extractDeliveryReferences(
  template: TemplateWithRefs,
  stopConfiguration: StopConfigurationType
): ReferenceSettings | undefined {
  switch (stopConfiguration) {
    case StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY:
      return toReferenceSettings(getReference(firstStopOfType(template)));
    case StopConfigurationType.TYPED_STOP_ARRAY:
      return;
    case StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT: {
      const delivery = firstStopOfType(template, 'DELIVERY');
      return toReferenceSettings(getReference(delivery));
    }
    default:
      throw new Error('Unknown stop configuration');
  }
}

export function inferStopConfiguration(
  template: TemplateWithRefs
): StopConfigurationType {
  if (!isNil(template._value.pickup)) {
    return StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY;
  }
  if (
    hasStopOfType(template, 'PICKUP') &&
    hasStopOfType(template, 'DELIVERY')
  ) {
    return StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT;
  }
  if (template._value.stops._value.length > 1) {
    throw new Error('Unsupported stop configuration');
  }
  return StopConfigurationType.TYPED_STOP_ARRAY;
}

function toReferenceSettings(
  reference: ReferenceNumbersElement[]
): ReferenceSettings {
  return {
    explicitReferences: reference
      .filter(isExplicitReference)
      .map(explicitReferenceToSettings),
    genericReferences: reference
      .filter((ref) => !isExplicitReference(ref))
      .map(genericReferenceToSettings),
  };
}

export function explicitReferenceToSettings(
  reference: ReferenceNumbersElement
): ExplicitReferenceSettings {
  return {
    appearsAs: reference.referenceNumber._ai?.name,
    hardCodeAs: reference.referenceType?._ai?.placeholder ?? '',
  };
}

export function isExplicitReference(
  reference: ReferenceNumbersElement
): boolean {
  return reference.referenceType?._ai?.default?.type === 'hard';
}

export function genericReferenceToSettings(
  template: ReferenceNumbersElement
): GenericReferenceSettings {
  const allowedReferenceTypes = placeholderToOptions(
    template.referenceType?._ai?.placeholder,
    STANDARD_REFERENCE_TYPES
  );
  return {
    allowedReferenceTypes,
    template,
  };
}
