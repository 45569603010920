import { Button } from '@shared/components/button';
import { IntegrationType } from '@shared/types/order';
import { Quote } from '@shared/types/quote';
import { MdOutlineOpenInNew } from 'react-icons/md';

const filterAuthorizableRatingIntegrations = (quote: Quote) => {
  const integrations = quote.organization.integrationConfigs?.integrations;
  return integrations?.filter(
    (i) =>
      i.orderType === 'QUOTE' && i.integrationType !== IntegrationType.SUNSET
  );
};

export const RatingIntegrationsNotice = ({
  webBaseUrl,
  quote,
}: {
  webBaseUrl: string;
  quote: Quote;
}) => {
  const integrations = filterAuthorizableRatingIntegrations(quote);

  if (!integrations?.length) return null;

  return (
    <div className="flex flex-col items-center -mt-3 mb-1">
      <a href={`${webBaseUrl}/integrations`} target="_blank" rel="noreferrer">
        <Button
          size="xs"
          icon={<MdOutlineOpenInNew className="text-brand-500" />}
          tooltip="Manage integrations in web app"
          variant="tertiary"
          iconPosition="right"
        >
          Manage rating integrations
        </Button>
      </a>
    </div>
  );
};
