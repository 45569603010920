import { AutocompleteBase, AutocompleteBaseProps } from '@shared/components';
import { OptionData } from '@shared/components/autocomplete/AutocompleteBase';
import { FieldWrapper } from '@shared/components/react-hook-form/shared';
import { ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export type ReactHookFormAutocompleteBaseProps<
  TOption extends OptionData,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = AutocompleteBaseProps<TOption, true> &
  UseControllerProps<TFieldValues, TName>;

function ReactHookFormAutocomplete<
  TOption extends OptionData,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: ReactHookFormAutocompleteBaseProps<TOption, TFieldValues, TName>
): ReactElement {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    options,
    multiple,
    freeSolo,
    ...rest
  } = props;

  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const newVal = multiple ? [...(value ?? [])] : value;

  return (
    <FieldWrapper error={error}>
      <AutocompleteBase
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        onChange={(_, newValue) => {
          // did it this way because passing onChange directly resulted on
          // getting the latest item in the array
          onChange(newValue);
        }}
        ref={ref}
        value={newVal || null}
        options={options}
        multiple={multiple}
        freeSolo={freeSolo}
        validationState={error ? 'invalid' : 'valid'}
      />
    </FieldWrapper>
  );
}

export default ReactHookFormAutocomplete;
