import { useContext } from 'react';

import { MeContext } from '../MeContext';

export const useMeContext = () => {
  const context = useContext(MeContext);

  if (!context) {
    throw new Error('MeContext must be used within a MeContextProvider');
  }
  return context;
};
