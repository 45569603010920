import { ConversationStatus } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import {
  PiPhoneCallDuotone,
  PiPhoneDisconnectDuotone,
  PiPhoneOutgoingDuotone,
} from 'react-icons/pi';

const ROOT = makeRootClassName('ConversationIcon');
const el = makeElementClassNameFactory(ROOT);

export const ConversationIcon = ({
  status,
  size,
}: {
  status: ConversationStatus;
  size: number;
}) => {
  const className = clsx(el`conversation-icon`, {
    ACTIVE: status === ConversationStatus.ACTIVE,
  });
  switch (status) {
    case ConversationStatus.PENDING:
      return <PiPhoneOutgoingDuotone size={size} className={className} />;
    case ConversationStatus.ACTIVE:
      return <PiPhoneCallDuotone size={size} className={className} />;
    case ConversationStatus.COMPLETED:
      return <PiPhoneDisconnectDuotone size={size} className={className} />;
    default:
      return null;
  }
};
