import { Button } from '@shared/components/button';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { MdOutlineOpenInNew } from 'react-icons/md';

const ROOT = makeRootClassName('LinkToRateSource');
const el = makeElementClassNameFactory(ROOT);

type LinkToRateSourceProps = {
  url?: string;
};

export function LinkToRateSource(p: LinkToRateSourceProps) {
  if (!p.url) return null;
  return (
    <div className={el`group-url`}>
      <Button
        size="xs"
        onPress={() => window.open(p.url, '_blank')}
        icon={<MdOutlineOpenInNew className="text-brand-500" />}
        tooltip="Open rate source in new tab"
        variant="secondary"
        iconPosition="right"
        className={el`group-url-link`}
      >
        View rate source
      </Button>
    </div>
  );
}
