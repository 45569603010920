import { RateComponentType } from 'clerk_common';

export const MAX_RATE_LINE_ITEMS = 10;

export type LineItemType = 'buy' | 'sell';

export type MoneyFormRateLineItem = {
  description: string;
  buyRate: string;
  sellRate: string;
  componentId: string;
  type: RateComponentType;
  index: number;
};

export type MoneyFormValues = {
  buyRate: string;
  marginPercent: string;
  marginDollars: string;
  sellRate: string;
  lineItems: MoneyFormRateLineItem[];
};
