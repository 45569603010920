import { useHover } from '@react-aria/interactions';
import {
  Button,
  InteractableComponent,
  PopoverWithTooltip,
  Tooltip,
} from '@shared/components';
import {
  DefaultType,
  useFlowsViewContext,
} from '@shared/contexts/FlowsViewContext';
import { TrackProperties } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FieldTransformationInfo } from '@shared/utils/flows/transformationStatus';
import { KnownType } from 'clerk_common';
import { formatAddress } from 'clerk_common/stringification/address';
import { Address } from 'clerk_common/types/address';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { useState } from 'react';
import { PiLightningBold, PiLightningFill } from 'react-icons/pi';

const ROOT = makeRootClassName('FlowIcon');
const el = makeElementClassNameFactory(ROOT);

export type FlowIconProps = {
  fieldPath: string;
  type?: KnownType;
};

type ExistingFlowIconProps = FlowIconProps & {
  onPress: () => void;
};

const ExecutedFlowIcon = (p: ExistingFlowIconProps) => {
  return (
    <div className={clsx(ROOT)}>
      <Tooltip content="Vooma populated this field using a Flow" isInstant>
        <div className={el`icon-wrapper`}>
          <InteractableComponent onPress={p.onPress}>
            <PiLightningFill className={el`icon`} size="14px" />
          </InteractableComponent>
        </div>
      </Tooltip>
    </div>
  );
};

const CreatedFlowIcon = (p: ExistingFlowIconProps) => {
  return (
    <div className={clsx(ROOT)}>
      <Tooltip
        content="Vooma will populate this field using a Flow in the future"
        isInstant
      >
        <div className={el`icon-wrapper`}>
          <InteractableComponent onPress={p.onPress}>
            <PiLightningFill className={el`icon`} size="14px" />
          </InteractableComponent>
        </div>
      </Tooltip>
    </div>
  );
};

type CreateFlowPopoverContentProps = CreateFlowIconProps & {
  track: (event: string) => void;
  dismiss: () => void;
  createDefault: (type: DefaultType) => void;
};

const getTargetValueDescription = (targetValue: string | Address) => {
  if (typeof targetValue === 'string') {
    return targetValue;
  } else {
    return formatAddress(targetValue);
  }
};

const CreateFlowPopoverContent = (p: CreateFlowPopoverContentProps) => {
  const targetDescription = getTargetValueDescription(
    p.transformationInfo.targetValue ?? ''
  );

  return (
    <div className={el`popover-content`}>
      <div className={el`popover-header`}>
        <div className="flex items-center gap-1">
          <PiLightningBold size="12px" />
          <p>Create Flow</p>
        </div>
        <Button
          variant="dark"
          size="xs"
          onPress={() => {
            p.dismiss();
          }}
        >
          {'\u2715'}
        </Button>
      </div>
      <div className={el`popover-details`}>
        <div className={el`popover-description`}>
          {
            // TODO(jacob): Smarter detection of what the desired default actually is.
            // For example, setting a date field to 2024-04-01 probably means "default
            // to today's date", not "default to 2024-04-01".
            `Default this field to ${targetDescription}?`
          }
        </div>
        <div className={el`popover-actions`}>
          <Button
            variant="secondary"
            size="xs"
            onPress={() => {
              p.track('Create Hard Default');
              p.createDefault('HARD');
            }}
          >
            Always
          </Button>
          <Button
            variant="secondary"
            size="xs"
            onPress={() => {
              p.track('Create Soft Default');
              p.createDefault('SOFT');
            }}
          >
            If missing
          </Button>
        </div>
      </div>
    </div>
  );
};

type CreateFlowIconProps = FlowIconProps & {
  transformationInfo: FieldTransformationInfo;
  track: (event: string) => void;
  createDefault: (type: DefaultType) => void;
};

const CreateFlowIcon = (p: CreateFlowIconProps) => {
  const { hoverProps, isHovered } = useHover({});
  const [popoverOpen, setPopoverOpen] = useState(false);

  const popoverContent = (
    <CreateFlowPopoverContent
      fieldPath={p.fieldPath}
      transformationInfo={p.transformationInfo}
      createDefault={(type: DefaultType) => {
        p.createDefault(type);
        setPopoverOpen(false);
      }}
      dismiss={() => {
        setPopoverOpen(false);
      }}
      track={p.track}
    />
  );

  return (
    <div className={clsx(ROOT)}>
      <PopoverWithTooltip
        popoverClassName={el`popover`}
        roundedLarge={true}
        popoverSide="top"
        popoverAlign="center"
        open={popoverOpen}
        onOpenChange={setPopoverOpen}
        popoverContent={popoverContent}
        tooltipContent="Click to create a flow for this field"
        tooltipSide="top"
        isInstant
      >
        <div className={el`icon-wrapper`} {...hoverProps}>
          <InteractableComponent
            onPress={() => {
              p.track('Click Create Flow');
              setPopoverOpen(!popoverOpen);
            }}
          >
            <PiLightningBold
              className={clsx(
                {
                  'is-hovered': isHovered,
                },
                el`create-icon`
              )}
              size="14px"
            />
          </InteractableComponent>
        </div>
      </PopoverWithTooltip>
    </div>
  );
};

export const FlowIcon = (p: FlowIconProps) => {
  const { track } = useAnalytics();

  const {
    getFieldTransformationInfo,
    setShow,
    addDefault,
    originatorId,
    organizationId,
  } = useFlowsViewContext();

  const transformationInfo = getFieldTransformationInfo(p.fieldPath);
  if (isNil(transformationInfo)) return null;

  const trackProperties: TrackProperties = {
    originatorId,
    organizationId,
  };

  const createTrack = (event: string) => {
    track(event, trackProperties);
  };

  const onPress = () => {
    createTrack('Click Flow Icon');
    setShow(true);
  };

  if (transformationInfo?.status === 'TRANSFORMED') {
    return <ExecutedFlowIcon {...p} onPress={onPress} />;
  } else if (transformationInfo?.status === 'CREATED') {
    return <CreatedFlowIcon {...p} onPress={onPress} />;
  } else if (transformationInfo?.status === 'CAN_CREATE') {
    if (isNil(originatorId)) {
      // NOTE(jacob): We don't want users to create organization-level
      // flows if there's no originator on the quote/order.
      return null;
    }
    const targetValue = transformationInfo.targetValue;
    if (isNil(targetValue)) {
      console.warn(
        'Expected targetValue to be defined for CAN_CREATE transformation'
      );
      return null;
    }
    return (
      <CreateFlowIcon
        fieldPath={p.fieldPath}
        transformationInfo={transformationInfo}
        createDefault={(defaultType: DefaultType) => {
          addDefault(p.fieldPath, targetValue, defaultType, p.type);
        }}
        track={createTrack}
      />
    );
  }

  return null;
};
