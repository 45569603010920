import { BasicUserFragment } from '@shared/generated/graphql';
import { isNil } from 'lodash';

export function basicUserFromFragment(fragment?: BasicUserFragment | null) {
  if (isNil(fragment)) return;
  return {
    id: fragment.id,
    email: fragment.email ?? undefined,
    firstName: fragment.firstName ?? undefined,
    lastName: fragment.lastName ?? undefined,
  };
}
