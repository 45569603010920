import { useFrontContext } from '@/contexts/useFrontPluginContext';
import { useCreateFrontReplyAllMutation } from '@/generated/graphql';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';

type UseReplyAllState = {
  loadingDraftReplyAll: boolean;
};

type UseReplyAllActions = {
  draftReplyAll: (itemId: string, content?: string) => Promise<void>;
};

export const useReplyAll = (): UseReplyAllState & UseReplyAllActions => {
  const { defaultOrgId } = useMeContext();
  const { conversationId, openThread, frontUser } = useFrontContext();
  const { sendToast } = useToast();
  const [draftReplyAllMutation, { loading: loadingDraftReplyAll }] =
    useCreateFrontReplyAllMutation();
  // const { track } = useAnalytics();

  const toastNoOrgId = () => {
    sendToast('Failed to draft message', {
      description: 'Organization ID not found.',
      variant: 'error',
    });
  };

  const toastFailedDraft = () => {
    sendToast('Failed to compose message', {
      description: 'Something went wrong drafting your message.',
      variant: 'error',
    });
  };

  const toastSuccessDraft = () => {
    sendToast('Success!', {
      description: 'Your message was drafted.',
      variant: 'success',
    });
  };

  const openConversation = (threadId: string) => {
    if (!openThread) return;
    openThread(threadId);
  };

  const draftReplyAll = async (itemId: string, content?: string) => {
    if (!defaultOrgId) return toastNoOrgId();

    const isItemInFocus = itemId === conversationId;
    const input = {
      organizationId: defaultOrgId,
      frontUserEmail: frontUser.email,
      conversationId: itemId,
      content,
    };
    const res = await draftReplyAllMutation({ variables: { input } });

    const draftMessageId = res?.data?.createFrontReplyAll?.messageId;
    if (!res.data?.createFrontReplyAll?.success) {
      toastFailedDraft();
    } else if (draftMessageId && isItemInFocus) {
      toastSuccessDraft();
    } else if (draftMessageId) {
      openConversation(itemId);
    }
  };

  return { loadingDraftReplyAll, draftReplyAll };
};
