import {
  ExternalMessageFragment,
  GetConversationExternalMessagesQuery,
} from '@shared/generated/graphql';
import {
  ConversationContribution,
  ConversationContributionRole,
} from '@shared/types/conversation';

export const conversationContributionFromExternalMessageFragment = (
  fragment: ExternalMessageFragment
): ConversationContribution => {
  return {
    id: fragment.id,
    createdAt: new Date(fragment.createdAt),
    role: fragment.content?.role ?? ConversationContributionRole.USER,
    transcript: fragment.content?.transcript ?? '',
  };
};

export const toConversationContributions = (
  query?: GetConversationExternalMessagesQuery
): ConversationContribution[] => {
  return (
    query?.conversationExternalMessages?.edges.map((e) =>
      conversationContributionFromExternalMessageFragment(e.node)
    ) ?? []
  );
};
