import { Text } from '@shared/components';
import { Option, Select } from '@shared/components/react-hook-form';
import { StyleProps } from '@shared/utils';
import {
  DRAYAGE_SUBTYPES,
  HAZMAT_CLASSES,
  HAZMAT_CLASS_QUALIFIERS,
  HAZMAT_PACKING_GROUPS,
  STANDARD_ACCESSORIALS,
  STANDARD_DIMENSION_UNITS,
  STANDARD_EQUIPMENT_LENGTHS,
  STANDARD_EQUIPMENT_TYPES,
  STANDARD_FREIGHT_MODES,
  STANDARD_LINE_ITEMS,
  STANDARD_MILEAGE_UNITS,
  STANDARD_PAYMENT_TERMS,
  STANDARD_REFERENCE_TYPES,
  STANDARD_SHIPPING_UNITS,
  STANDARD_TRANSACTION_PURPOSES,
  STANDARD_WEIGHT_UNITS,
  STOP_SCHEDULING_TYPES,
  STOP_TYPES,
} from 'clerk_common/enums';
import { formatOption } from 'clerk_common/stringification/options';
import clsx from 'clsx';
import { toLower, toUpper } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { Path } from 'react-hook-form';
import { el } from '../JSONDefinedForm';
import { EditableJSONField, FORM_STATE_FIELD } from '../types';

// TODO(max): Switch to using "_type" in template instead for select type.
export const SELECT_TYPES = [
  'tenderType',
  'equipmentType',
  'equipment._value.length._value.value',
  'weight._value.unit',
  'temperature._value.unit',
  'handlingUnits',
  'pieceUnits',
  'referenceType',
  'freightMode._value.mode',
  'freightMode._value.subType',
  'lineItemType',
  'accessorialDescription',
  'length._value.units',
  'width._value.units',
  'height._value.units',
  'mileage._value.units',
  'stopType',
  'paymentTerms',
  'packingGroup',
  'hazardClass',
  'qualifier',
  'schedulingType',
] as const;
type SelectTypeTuple = typeof SELECT_TYPES;
export type SelectType = SelectTypeTuple[number];

function formatAccessorial(option: string) {
  switch (option) {
    case 'GPS':
      return 'GPS';
    default:
      return formatOption(option);
  }
}

function formatSchedulingType(option: string) {
  switch (option) {
    case 'FIRST_COME_FIRST_SERVED':
      return 'FCFS (first come, first served)';
    default:
      return formatOption(option);
  }
}

function formatFreightMode(option: string) {
  switch (option) {
    case 'LTL':
      return 'LTL';
    default:
      return formatOption(option);
  }
}

function getOptions(selectType: SelectType) {
  switch (selectType) {
    case 'tenderType':
      return ['', ...STANDARD_TRANSACTION_PURPOSES];
    case 'equipmentType':
      return ['', ...STANDARD_EQUIPMENT_TYPES];
    case 'weight._value.unit':
      return ['', ...STANDARD_WEIGHT_UNITS];
    case 'referenceType':
      return ['', ...STANDARD_REFERENCE_TYPES];
    case 'temperature._value.unit':
      return ['', 'F', 'C'];
    case 'handlingUnits':
      return ['', ...STANDARD_SHIPPING_UNITS];
    case 'pieceUnits':
      return ['', ...STANDARD_SHIPPING_UNITS];
    case 'freightMode._value.mode':
      return ['', ...STANDARD_FREIGHT_MODES];
    // NOTE(max): Right now we only support the subType field for drayage
    // orders. In the future we may need to change the select
    // options based on the mode selected.
    case 'freightMode._value.subType':
      return ['', ...DRAYAGE_SUBTYPES];
    case 'lineItemType':
      return ['', ...STANDARD_LINE_ITEMS];
    case 'accessorialDescription':
      return ['', ...[...STANDARD_ACCESSORIALS].sort()];
    case 'length._value.units':
    case 'width._value.units':
    case 'height._value.units':
      return ['', ...STANDARD_DIMENSION_UNITS];
    case 'mileage._value.units':
      return ['', ...STANDARD_MILEAGE_UNITS];
    case 'stopType':
      return ['', ...STOP_TYPES];
    case 'paymentTerms':
      return ['', ...STANDARD_PAYMENT_TERMS];
    case 'packingGroup':
      return ['', ...HAZMAT_PACKING_GROUPS];
    case 'hazardClass':
      return ['', ...HAZMAT_CLASSES];
    case 'qualifier':
      return ['', ...HAZMAT_CLASS_QUALIFIERS];
    case 'equipment._value.length._value.value':
      return ['', ...STANDARD_EQUIPMENT_LENGTHS.map((l) => l.toString())];
    case 'schedulingType':
      return ['', ...STOP_SCHEDULING_TYPES];
    default:
      return [''];
  }
}

function getFormatOptionFn(selectType: SelectType) {
  switch (selectType) {
    case 'tenderType':
      return formatOption;
    case 'equipmentType':
      return formatOption;
    case 'weight._value.unit':
    case 'length._value.units':
    case 'width._value.units':
    case 'height._value.units':
    case 'mileage._value.units':
      return toLower;
    case 'accessorialDescription':
      return formatAccessorial;
    case 'packingGroup':
      return toUpper;
    case 'schedulingType':
      return formatSchedulingType;
    case 'freightMode._value.mode':
      return formatFreightMode;
    default:
      return formatOption;
  }
}

type SelectFieldProps = StyleProps & {
  fieldValue: string;
  data: EditableJSONField;
  prefix?: string;
  label?: ReactNode;
  hideLabel?: boolean;
  isRequired?: boolean;
  confidenceBucket: 'high' | 'low';
  onChange: (e: string) => void;
  selectType: SelectType;
  wrapperChildren?: ReactElement | ReactElement[];
  wrapperChildrenPlacement?: 'inside' | 'outside';
};

export function SelectField({
  fieldValue,
  data,
  prefix,
  label,
  hideLabel,
  isRequired,
  confidenceBucket,
  onChange,
  selectType,
  className,
  wrapperChildren,
  wrapperChildrenPlacement,
}: SelectFieldProps) {
  const options = getOptions(selectType);
  const display = getFormatOptionFn(selectType);

  return (
    <Select
      className={clsx(className, el`field`)}
      inputClassName={clsx(el`input`, `confidence-${confidenceBucket}`)}
      label={hideLabel ? undefined : label}
      aria-label={label as string}
      onChange={onChange}
      defaultValue={data[FORM_STATE_FIELD]}
      isRequired={isRequired}
      rules={{
        required: isRequired,
      }}
      size="small"
      value={fieldValue}
      name={`${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>}
      renderValue={(val) => {
        return <Text type="body-xs">{display(val)}</Text>;
      }}
      wrapperChildren={wrapperChildren}
      wrapperChildrenPlacement={wrapperChildrenPlacement}
    >
      {options.map((o) => (
        <Option key={o} value={o}>
          {display(o)}
        </Option>
      ))}
    </Select>
  );
}
