import { JobType } from '@shared/generated/graphql';
import clsx from 'clsx';
import { LuMail } from 'react-icons/lu';
import { PiCurrencyDollarBold, PiTrayBold } from 'react-icons/pi';

type JobIconProps = {
  jobType: JobType;
};
export function JobIcon({ jobType }: JobIconProps) {
  switch (jobType) {
    case JobType.ORDERS:
      return <PiTrayBold size={14} />;
    case JobType.QUOTES:
      return <PiCurrencyDollarBold size={14} />;
    default:
      return <LuMail size={14} />;
  }
}

export function JobIconWithPadding({ jobType }: JobIconProps) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-md p-1',
        'bg-gray-100 text-gray-600'
      )}
    >
      {<JobIcon jobType={jobType} />}
    </div>
  );
}
