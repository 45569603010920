import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { createContext, useContext } from 'react';
import { EditableUnit } from '../types';

type InternalFormContextType = {
  loading: boolean;
  showDebugInfo: boolean;
  requiredFields: string[];
  inTrainingMode: boolean;
  templateData?: MaybeCompletedFreightOrderTemplate;
  createCustomBlock: (data: EditableUnit, prefix: string) => JSX.Element | null;
  onFieldEdit?: () => Promise<void>;
  save: () => void;
  handleUpdateTemplate?: (
    data: MaybeCompletedFreightOrderTemplate
  ) => Promise<void>;
  handleUpdateData?: (data: EditableUnit) => Promise<void>;
  extractedData: EditableUnit;
  pendingAddElementPrefix: string | null;
  setPendingAddElementPrefix: (prefix: string | null) => void;
};

export const InternalFormContext = createContext(
  null as InternalFormContextType | null
);

export function useInternalFormContext() {
  const context = useContext(InternalFormContext);
  if (!context) {
    throw new Error(
      'useInternalFormContext must be used within a InternalFormContext.Provider'
    );
  }
  return context;
}
