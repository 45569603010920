import { useContext } from 'react';
import { PlaceSidePanelContext } from '../PlaceSidePanelContext';

export const usePlaceSidePanelContext = () => {
  const context = useContext(PlaceSidePanelContext);

  if (!context) {
    throw new Error(
      'PlaceSidePanelContext must be used within a PlaceSidePanelContextProvider'
    );
  }
  return context;
};
