import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { ReactElement, useMemo, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import { InteractableComponent } from '../interactable-component';
import { Text } from '../text';

export enum AssigneeOptionVariant {
  SUCCESS,
  DANGER,
  DEFAULT,
  WARNING,
  INFO,
}

type AssigneeOption = {
  label: string;
  value: string;
  variant?: AssigneeOptionVariant;
};

export type DropdownChipProps = StyleProps & {
  value?: string;
  options: AssigneeOption[];
  isDisabled?: boolean;
  handleSelect: (value: string) => void;
  isAssigned?: boolean;
  defaultLabel?: string;
};

const ROOT = makeRootClassName('DropdownChip');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isDisabled: false,
  isAssigned: false,
  status: 'default',
} as const;

function DropdownChip(props: DropdownChipProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = useMemo(() => {
    return p.options.find((option) => option.value === p.value);
  }, [p.value, p.options]);
  const defaultLabel = p.defaultLabel || 'Select option...';

  return (
    <RadixDropdown.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <RadixDropdown.Trigger className="focus:outline-none">
        <InteractableComponent
          className={clsx(
            ROOT,
            p.className,
            `variant-${
              AssigneeOptionVariant[
                selectedOption?.variant ?? AssigneeOptionVariant.DEFAULT
              ]
            }`,
            {
              'is-assigned': p.isAssigned,
              'is-disabled': p.isDisabled,
            }
          )}
          onPress={() => setIsOpen(!isOpen)}
        >
          <>
            <Text className={el`chip-text`} type="body-xs">
              {selectedOption?.label ?? defaultLabel}
            </Text>
          </>
        </InteractableComponent>
      </RadixDropdown.Trigger>
      <RadixDropdown.Content align="end" className={el`menu no-scrollbar`}>
        {p.options?.map((option) => (
          <RadixDropdown.Item
            key={option.value}
            className={
              'outline-none border-none focus:outline-none hover:outline-none'
            }
            onClick={() => {
              p.handleSelect?.(option.value);
            }}
          >
            <div className={el`dropdown-menu-item`}>
              <Text type="body-sm" className={'p-[8px] m-2'}>
                {option.label}
              </Text>
            </div>
          </RadixDropdown.Item>
        ))}
      </RadixDropdown.Content>
    </RadixDropdown.Root>
  );
}

export default DropdownChip;
