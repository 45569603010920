import {
  determineBestValueField,
  hasBeenCorrected,
} from '@shared/components/json-defined-form/fields/fieldLogic';
import { FormTemplateChildConfigs } from '@shared/components/json-defined-form/stateManagement/form';
import { EditableJSONField } from '@shared/components/json-defined-form/types';
import { WorkflowNode } from '@shared/hooks/useManageFlows';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { mutateByFieldType } from 'clerk_common/templates/mutate/mutate';
import { reduceFullyQualifiedPath } from 'clerk_common/templates/path';
import { FormValueChild } from 'clerk_common/templates/types';
import { Address } from 'clerk_common/types/address';
import { isNil } from 'lodash';
import { ALL_DEFAULT_TF_NAMES } from './definitions';

export type TransformationStatus = 'TRANSFORMED' | 'CREATED' | 'CAN_CREATE';

export type FieldTransformationInfo = {
  status: TransformationStatus;
  transformationId?: string;
  targetValue?: string | Address;
};

export type FieldTransformations = Record<string, FieldTransformationInfo>;

export const getTransformedFields = async (
  completedTemplate: CompletedFreightOrderTemplate,
  defaultedFields: string[]
): Promise<FieldTransformations> => {
  const transformedFields: FieldTransformations = {};

  defaultedFields.forEach((defaultPath) => {
    transformedFields[defaultPath] = {
      status: 'CREATED',
    };
  });

  const mutation = async (child: FormTemplateChildConfigs, path?: string) => {
    const bestValueField = determineBestValueField(child as EditableJSONField);
    if (isNil(path)) {
      return child;
    }

    const reducedPath = reduceFullyQualifiedPath(
      path,
      completedTemplate as FormValueChild
    );
    if (isNil(reducedPath)) {
      console.warn(`${path} did not exist in the template`);
      return child;
    }

    // TODO(jacob): If the user has corrected a field that was transformed, we
    // should prompt them to update the transformation.
    const eligibleForCreation =
      hasBeenCorrected(child) && !defaultedFields.includes(reducedPath);

    if (bestValueField === '_transformed') {
      const mostRecentTransformation =
        child._provenance?.transformations?.at(-1);
      if (isNil(mostRecentTransformation)) {
        console.warn(
          `${path} is marked as transformed but has no transformation provenance`
        );
        return child;
      }

      transformedFields[reducedPath] = {
        status: 'TRANSFORMED',
        transformationId: mostRecentTransformation,
      };
    } else if (eligibleForCreation) {
      transformedFields[reducedPath] = {
        status: 'CAN_CREATE',
        targetValue: child._corrected,
      };
    }

    return child;
  };

  await mutateByFieldType(completedTemplate as FormValueChild, {
    field: mutation,
  });
  return transformedFields;
};

// TODO(jacob): Super hacky. Right now this extracts the 'path' param from all
// transformations named 'Hardcode field' or 'Set field if empty'.
export const extractDefaults = (node: WorkflowNode): string[] => {
  const defaults = node.transformations.filter((tf) => {
    return Object.values(ALL_DEFAULT_TF_NAMES).includes(
      tf.transformationConfig.transformationDefinition.definition.name
    );
  });

  return defaults.map(
    (tf) =>
      tf.transformationConfig.params.path ??
      tf.transformationConfig.params.prefix
  );
};
