import {
  AddButton,
  RemoveButton,
} from '@shared/components/json-defined-form/arrayComponents/buttons';
import { TextField } from '@shared/components/react-hook-form';
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';

type TextFieldArrayProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
> = {
  arrayLabel: string;
  elementLabel: string;
  control: Control<TFieldValues>;
  name: TName;
  isDisabled?: boolean;
  hideLabel?: boolean;
  validate?: (value: string) => boolean | string | undefined;
};

function TextFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>({
  arrayLabel,
  elementLabel,
  control,
  name,
  isDisabled,
  hideLabel,
  validate,
}: TextFieldArrayProps<TFieldValues, TName>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <ul className="flex flex-col gap-[4px]">
      {fields.map((item, index) => (
        <li key={item.id} className="flex flex-row gap-[12px] items-end">
          <TextField
            label={!hideLabel && index === 0 ? arrayLabel : ''}
            aria-label={elementLabel}
            name={`${name}.${index}.value` as FieldPath<TFieldValues>}
            control={control}
            rules={
              validate
                ? {
                    validate,
                  }
                : undefined
            }
            size="small"
            inputWrapperClassName="pr-[8px]"
            wrapperChildrenPlacement="outside"
            isDisabled={isDisabled}
            wrapperChildren={
              <div className="mr-[8px]">
                <RemoveButton
                  removeElement={() => remove(index)}
                  elementDescription={elementLabel}
                  isDisabled={isDisabled}
                />
              </div>
            }
          />
        </li>
      ))}
      <AddButton
        isDisabled={isDisabled}
        elementDescription={elementLabel}
        addElement={() =>
          append({ value: '' } as FieldArray<TFieldValues, TName>)
        }
      />
    </ul>
  );
}

export default TextFieldArray;
