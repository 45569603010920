import { UserFeatureName } from 'clerk_common/types';

export const getUserFeatureDisplayName = (
  featureName: UserFeatureName
): string => {
  switch (featureName) {
    case 'EmailScanning':
      return 'Email monitoring';
    default:
      return featureName;
  }
};
