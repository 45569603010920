import { OrderEvent, ProcessingStatus } from '@shared/generated/graphql';
import { orderEventAdaptor } from '@shared/graphql/fromFragments/order';
import { processingStatusFromEvents } from '@shared/graphql/processingStatus';
import { formatOption } from 'clerk_common/stringification/options';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { ReactElement } from 'react';
import { GoCheckCircleFill, GoXCircleFill } from 'react-icons/go';
import { IoEllipsisHorizontalCircleSharp } from 'react-icons/io5';
import { PiCircleBold } from 'react-icons/pi';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import { Text } from '../text';

export type OrderStatusChipProps = StyleProps & {
  orderEvents: OrderEvent[];
};

const ROOT = makeRootClassName('OrderStatusChip');
const el = makeElementClassNameFactory(ROOT);

function OrderStatusChip(p: OrderStatusChipProps): ReactElement {
  const processingStatus = processingStatusFromEvents(
    p.orderEvents,
    orderEventAdaptor
  );
  const hasBeenSubmitted = p.orderEvents.some((e) => e.type === 'SUBMITTED');
  const status = determineStatus(processingStatus, hasBeenSubmitted);
  const icon = getOrderStatusChipIcon(status);
  return (
    <div className={clsx(ROOT, p.className, `variant-${status}`)}>
      {icon && (
        <div className={clsx(el`chip-icon`, `variant-${status}`)}>{icon}</div>
      )}
      <Text className={el`chip-text`} type="body-xs">
        {capitalize(formatOption(status))}
      </Text>
    </div>
  );
}

type VisualizedStatus =
  | 'PENDING'
  | 'PROCESSING'
  | 'READY_FOR_REVIEW'
  | 'SUBMITTED'
  | 'FAILED'
  | 'TIMED_OUT'
  | 'UNKNOWN';

const getOrderStatusChipIcon = (status: VisualizedStatus) => {
  switch (status) {
    case 'SUBMITTED':
      return <GoCheckCircleFill />;
    case 'FAILED':
    case 'TIMED_OUT':
      return <GoXCircleFill />;
    case 'PROCESSING':
      return <IoEllipsisHorizontalCircleSharp />;
    case 'PENDING':
      return <PiCircleBold />;
    default:
      return null;
  }
};

function determineStatus(
  status: ProcessingStatus,
  hasBeenSubmitted: boolean
): VisualizedStatus {
  switch (status) {
    case ProcessingStatus.NEW:
      return 'PENDING';
    case ProcessingStatus.PROCESSING:
      return 'PROCESSING';
    case ProcessingStatus.COMPLETED:
      if (hasBeenSubmitted) return 'SUBMITTED';
      return 'READY_FOR_REVIEW';
    case ProcessingStatus.FAILED:
      return 'FAILED';
    default:
      return 'UNKNOWN';
  }
}

export default OrderStatusChip;
