import { Checkbox, Text } from '@shared/components';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import {
  WizardFooter,
  WizardStepContainer,
  WizardStepProps,
} from '@shared/components/wizard';
import { StandardReferenceType } from 'clerk_common/enums';
import { formatOption } from 'clerk_common/stringification/options';
import {
  extractReferenceSettings,
  saveReferenceSettingsBackToTemplate,
} from 'clerk_common/templates/freight_order/configuration';
import {
  ReferenceSettings,
  StopConfigurationType,
} from 'clerk_common/templates/freight_order/configuration/referenceNumbers/types';
import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { isNil } from 'lodash';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { el } from '../OriginatorOnboardingWizard';
import {
  ReferenceNumber,
  ReferenceNumbers,
} from './ReferenceNumberFormSection';

type ReferencesStepProps = {
  orderReferences: ReferenceNumber[];
  stopConfiguration: StopConfigurationType;
  pickupReferences: ReferenceNumber[];
  deliveryReferences: ReferenceNumber[];
  stopReferences: ReferenceNumber[];
};
const REFERENCES_DEFAULTS = {
  orderReferences: [],
  pickupReferences: [],
  deliveryReferences: [],
  stopReferences: [],
};
export function ReferencesStep(p: WizardStepProps<ReferencesStepProps>) {
  const methods = useForm<ReferencesStepProps>({
    defaultValues: p.defaultValues ?? REFERENCES_DEFAULTS,
  });
  useLoadInitialValues(methods.reset, p.defaultValues as ReferencesStepProps);

  const stopConfiguration = methods.watch('stopConfiguration');

  return (
    <WizardStepContainer
      handleSubmit={methods.handleSubmit}
      {...p}
      className={el`wizard-step-container`}
    >
      <div className={el`step-content`}>
        <div className={el`header`}>
          <Text type="custom" isHeavy className={el`title`}>
            Reference Numbers
          </Text>
          <Text type="body-sm" className={el`subtitle`}>
            Please tell Vooma what type of reference numbers to look for in the
            data your customer sends.
          </Text>
          <Text type="body-sm" className={el`subtitle`}>
            {`You can also tell Vooma how the reference typically appears in
            customer data. For example, you may need a Bill of Lading number
            that typically appears as "BOL".`}
          </Text>
        </div>
        <FormProvider {...methods}>
          <OrderReferenceConfigurationForm />
          <StopReferenceConfigurationForm
            stopConfiguration={stopConfiguration}
          />
        </FormProvider>
      </div>
      <WizardFooter
        handleSubmit={methods.handleSubmit}
        getValues={methods.getValues}
        {...p}
        className={el`footer`}
      />
    </WizardStepContainer>
  );
}

function OrderReferenceConfigurationForm() {
  const { control, watch, setValue } = useFormContext<ReferencesStepProps>();
  const orderRefs = watch('orderReferences');
  return (
    <>
      <Text isHeavy type="body-sm" className={el`subtitle`}>
        Order Reference Numbers
      </Text>
      <ReferenceNumbers
        refFieldName="orderReferences"
        refArray={orderRefs}
        setValue={setValue}
        control={control}
        watch={watch}
      />
    </>
  );
}

type StopReferenceConfigurationFormProps = {
  stopConfiguration: StopConfigurationType;
};
function StopReferenceConfigurationForm(
  p: StopReferenceConfigurationFormProps
) {
  switch (p.stopConfiguration) {
    case StopConfigurationType.TYPED_STOP_ARRAY:
    case StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT:
      return <TypedStopArrayReferenceConfigurationForm {...p} />;
    case StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY:
      return <SinglePickupReferenceConfigurationForm />;
    default:
      throw new Error('Unknown stop configuration type');
  }
}

type TypedStopArrayReferenceConfigurationFormProps =
  StopReferenceConfigurationFormProps;
function TypedStopArrayReferenceConfigurationForm(
  p: TypedStopArrayReferenceConfigurationFormProps
) {
  const { control, watch, setValue } = useFormContext<ReferencesStepProps>();
  const stopRefs = watch('stopReferences');
  const pickupRefs = watch('pickupReferences');
  const deliveryRefs = watch('deliveryReferences');

  const useStopRefs =
    p.stopConfiguration === StopConfigurationType.TYPED_STOP_ARRAY;

  const modifyRefConfiguration = (useStopReferences: boolean) => {
    if (useStopReferences) {
      setValue('stopReferences', pickupRefs);
    } else {
      setValue('pickupReferences', stopRefs);
      setValue('deliveryReferences', stopRefs);
    }
  };

  const setUseStopRefs = (useStopReferences: boolean) => {
    const stopCfg = useStopReferences
      ? StopConfigurationType.TYPED_STOP_ARRAY
      : StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT;
    setValue('stopConfiguration', stopCfg);
    modifyRefConfiguration(useStopReferences);
  };

  return (
    <>
      <Text isHeavy type="body-sm" className={el`subtitle`}>
        Pickup Reference Numbers
      </Text>
      <ReferenceNumbers
        refFieldName={useStopRefs ? 'stopReferences' : 'pickupReferences'}
        refArray={useStopRefs ? stopRefs : pickupRefs}
        setValue={setValue}
        control={control}
        watch={watch}
      />
      <Text isHeavy type="body-sm" className={el`subtitle`}>
        Delivery Reference Numbers
      </Text>
      <Checkbox
        labelType="body-sm"
        labelclassname={el`subtitle`}
        label="Same as pickup references"
        checked={p.stopConfiguration === StopConfigurationType.TYPED_STOP_ARRAY}
        onCheckedChange={(e: boolean) => setUseStopRefs(e)}
      />
      {!useStopRefs && (
        <ReferenceNumbers
          refFieldName={useStopRefs ? 'stopReferences' : 'deliveryReferences'}
          refArray={useStopRefs ? stopRefs : deliveryRefs}
          setValue={setValue}
          control={control}
          watch={watch}
        />
      )}
    </>
  );
}

function SeparatePickupDeliveryReferenceForm() {
  const { control, watch, setValue } = useFormContext<ReferencesStepProps>();
  const pickupRefs = watch('pickupReferences');
  const deliveryRefs = watch('deliveryReferences');
  return (
    <>
      <Text isHeavy type="body-sm" className={el`subtitle`}>
        Pickup Reference Numbers
      </Text>
      <ReferenceNumbers
        refFieldName="pickupReferences"
        refArray={pickupRefs}
        setValue={setValue}
        control={control}
        watch={watch}
      />
      <Text isHeavy type="body-sm" className={el`subtitle`}>
        Delivery Reference Numbers
      </Text>
      <ReferenceNumbers
        refFieldName="deliveryReferences"
        refArray={deliveryRefs}
        setValue={setValue}
        control={control}
        watch={watch}
      />
    </>
  );
}

function SinglePickupReferenceConfigurationForm() {
  return <SeparatePickupDeliveryReferenceForm />;
}

export function toReferenceDefaultValues(
  templateData: MaybeCompletedFreightOrderTemplate | null
) {
  if (isNil(templateData)) return undefined;
  const settings = extractReferenceSettings(templateData);
  return {
    orderReferences: toRefForm(settings.orderReferences),
    stopConfiguration: settings.stopConfiguration,
    pickupReferences: settings.pickupReferences
      ? toRefForm(settings.pickupReferences)
      : [],
    deliveryReferences: settings.deliveryReferences
      ? toRefForm(settings.deliveryReferences)
      : [],
    stopReferences: settings.stopReferences
      ? toRefForm(settings.stopReferences)
      : [],
  };
}

function toRefForm(data: ReferenceSettings): ReferenceNumber[] {
  return data.explicitReferences.map((ref) => {
    const type = ref.hardCodeAs as StandardReferenceType;
    return {
      name: ref.appearsAs ?? '',
      type: {
        label: formatOption(type),
        value: type,
      },
    };
  });
}

export function fromReferenceValues(
  templateData: MaybeCompletedFreightOrderTemplate | null,
  data: ReferencesStepProps
): MaybeCompletedFreightOrderTemplate | null {
  if (isNil(templateData)) return null;
  const orderReferences = fromRefForm(
    data.orderReferences.filter(isNotBlankReference)
  );
  const pickupReferences = fromRefForm(
    data.pickupReferences.filter(isNotBlankReference)
  );
  const deliveryReferences = fromRefForm(
    data.deliveryReferences.filter(isNotBlankReference)
  );
  const stopReferences = fromRefForm(
    data.stopReferences.filter(isNotBlankReference)
  );
  return saveReferenceSettingsBackToTemplate(templateData, {
    orderReferences,
    stopConfiguration: data.stopConfiguration,
    pickupReferences,
    deliveryReferences,
    stopReferences,
  });
}

function isNotBlankReference(ref: ReferenceNumber) {
  return ref.type !== undefined;
}

function fromRefForm(data: ReferenceNumber[]): ReferenceSettings {
  return {
    explicitReferences: data.map((ref) => ({
      ...(ref.name && { appearsAs: ref.name }),
      hardCodeAs: ref.type.value,
    })),
    genericReferences: [],
  };
}
