import { useToast } from '@shared/components/toast';
import {
  OrderDocument,
  useReprocessOrderMutation,
} from '@shared/generated/graphql';

type ReprocessOrderActions = {
  reprocess: () => void;
  promptToReprocess: () => Promise<void>;
};

type ReprocessOrderState = {
  loading: boolean;
};

export const useReprocessOrder = (
  orderId?: string
): ReprocessOrderActions & ReprocessOrderState => {
  const [reprocessOrder, { loading: loadingReprocess }] =
    useReprocessOrderMutation();
  const { sendToast } = useToast();

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const reprocess = async () => {
    if (!orderId) return;
    await reprocessOrder({
      variables: { input: { id: orderId } },
      refetchQueries: [OrderDocument],
    })
      .then(() => {
        sendToast('Reprocessing triggered', {
          variant: 'success',
          isDismissible: true,
        });
      })
      .catch((e) => sendErrorToast(e));
  };

  const promptToReprocess = async () => {
    if (!orderId) return;

    sendToast('Originator updated', {
      description: 'Reprocess this order with the new customer?',
      autoHideDuration: 7000,
      isDismissible: true,
      actions: [
        {
          label: `Reprocess order`,
          onPress: () => reprocess(),
        },
      ],
    });
  };

  return {
    reprocess,
    promptToReprocess,
    loading: loadingReprocess,
  };
};
