import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { EditableJSONField } from '../../types';
import { SimpleArray } from '../simple-array/SimpleArray';
import { ReferenceNumber } from './ReferenceNumber';

const ROOT = makeRootClassName('ReferenceNumberArray');
export const el = makeElementClassNameFactory(ROOT);

export type ReferenceNumberElement = {
  referenceNumber: EditableJSONField;
  referenceType?: EditableJSONField;
};

export type ReferenceNumberArrayData = {
  _value: ReferenceNumberElement[];
  _added?: ReferenceNumberElement[];
  _idxs?: string[];
};

type ReferenceNumberArrayProps = {
  data: ReferenceNumberArrayData;
  prefix?: string;
};

export function ReferenceNumberArray({
  data,
  prefix,
}: ReferenceNumberArrayProps) {
  return (
    <SimpleArray
      data={data}
      ElementComponent={ReferenceNumber}
      createNewElement={createReferenceNumberElement}
      description="Reference Number"
      prefix={prefix}
    />
  );
}

function createReferenceNumberElement(): ReferenceNumberElement {
  // TODO(mike): In theory, this could revive a deleted element.
  const defaultFields = {
    _value: '',
    _display: {
      name: '',
    },
    _logProbs: [],
  };

  return {
    referenceNumber: {
      ...defaultFields,
      _corrected: '',
    },
    referenceType: {
      ...defaultFields,
      _corrected: '',
    },
  };
}
