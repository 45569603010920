import { MaybeCompletedTemplateRoot } from '../types';

export function extractOneCommodity(
  commodities: MaybeCompletedTemplateRoot['commodities']
): MaybeCompletedTemplateRoot['commodityDetails'] {
  if (!commodities || commodities._value.length === 0) {
    throw new Error('No commodities found');
  }
  return { _value: commodities._value[0] };
}

export function toComplexCommodityBlock(
  simpleCommodity: MaybeCompletedTemplateRoot['commodity']
): MaybeCompletedTemplateRoot['commodityDetails'] {
  if (!simpleCommodity) throw new Error('No commodity found');
  // TODO(mike): Remove type assertion by making other fields optional.
  return {
    _value: {
      description: simpleCommodity,
    },
  } as MaybeCompletedTemplateRoot['commodityDetails'];
}
