import { Text } from '@shared/components/text';
import moment from 'moment';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// TODO(parlato): Should be shared from clerk_common
export type DATLoadTruckRatio = {
  // YYYY-MM-DD
  date: string;
  loads: number;
  trucks: number;
  ratio: string;
};

const TooltipRow = ({ label, value }: { label: string; value: string }) => (
  <div className="flex w-full min-w-[120px] flex-row justify-between">
    <Text type="body-xs" isHeavy>
      {label}
    </Text>
    <Text type="body-xs">{value}</Text>
  </div>
);

const CustomTooltip = (props: any) => {
  if (!props.active) return null;

  const data = props.payload[0].payload as DATLoadTruckRatio;

  return (
    <div className="bg-white py-1 px-3 rounded-md gap-1 shadow-md">
      <TooltipRow label="Ratio" value={data.ratio} />
      <TooltipRow label="Loads" value={data.loads.toString()} />
      <TooltipRow label="Trucks" value={data.trucks.toString()} />
      <TooltipRow label="Date" value={data.date} />
    </div>
  );
};

type DATLoadTruckRatioChartProps = {
  data: DATLoadTruckRatio[];
};

export const DATLoadTruckRatioChart = ({
  data,
}: DATLoadTruckRatioChartProps) => {
  const dataWithDateLabels = data.map((d) => ({
    ...d,
    dateLabel: moment(d.date).format('ddd'),
  }));
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={dataWithDateLabels}>
        <XAxis dataKey="dateLabel" tickSize={4} fontSize={10} />
        <YAxis tickSize={4} fontSize={10} orientation="left" width={28} />
        <Tooltip position={{ y: -80 }} content={<CustomTooltip />} />
        <Line type="monotone" dataKey="ratio" stroke="#F97316" />
      </LineChart>
    </ResponsiveContainer>
  );
};
