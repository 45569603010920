import { makeRootClassName } from '@shared/utils';
import { FormField } from '../../fields/FormField';
import { EditableJSONField, EditableUnit } from '../../types';
import { ConfigureCommodity } from '../commodity-array/ConfigureCommodity';

const ROOT = makeRootClassName('CommodityField');

type CommodityProps = {
  data: EditableUnit;
  prefix: string;
};

export const Commodity = ({ data, prefix }: CommodityProps) => {
  return (
    <div className={ROOT}>
      <FormField data={data as EditableJSONField} prefix={prefix} />
      <ConfigureCommodity isMultiCommodity={false} />
    </div>
  );
};
