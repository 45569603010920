import { RatingMethod } from '@shared/generated/graphql';
import { CustomerRatesRatingWidget } from './CustomerRatesRatingWidget/CustomerRatesRatingWidget';
import { LegacyRatingWidget } from './LegacyRatingWidget';
import { RatingWidgetProps } from './types';

export function RatingWidget(p: RatingWidgetProps) {
  switch (p.ratingMethod) {
    case RatingMethod.ARCBEST_TL_QUOTING:
      return null;
    case RatingMethod.WERNER:
      return <CustomerRatesRatingWidget {...p} />;
    default:
      // TODO(mike): Remove the legacy widget in a future refactor.
      return <LegacyRatingWidget {...p} />;
  }
}
