import { VoomaApiClient } from '@shared/generated/graphql';
import { createContext } from 'react';

export type AnalyticsContextType = {
  apiClient: VoomaApiClient;
};

export const AnalyticsContext = createContext<AnalyticsContextType>({
  apiClient: VoomaApiClient.WEB,
});

export const AnalyticsContextProvider = ({
  children,
  apiClient,
}: {
  children: JSX.Element;
  apiClient: VoomaApiClient;
}) => {
  return (
    <AnalyticsContext.Provider
      value={{
        apiClient,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
