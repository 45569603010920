import { JobStatus } from '@shared/types/job';
import { Order } from '@shared/types/order';
import { multipleAreSuspected } from '@shared/utils/multipleAreSuspected';

export function shouldShowReplicatePrompt(
  order: Pick<Order, 'isOnlyOrderInJob' | 'parentJobStatus' | 'extractedData'>
) {
  const isOnlyOrderInJob = order.isOnlyOrderInJob ?? false;
  const jobIsProcessing = order.parentJobStatus !== JobStatus.COMPLETED;
  const extracted = order.extractedData ?? JSON.parse('{}');
  const multipleSuspected = multipleAreSuspected(extracted);
  return isOnlyOrderInJob && multipleSuspected && !jobIsProcessing;
}
