import { Button, Text } from '@shared/components';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';

const cursorToNumber = (cursor?: string | null) => {
  if (!cursor) return 0;

  return (
    (parseInt(atob(cursor || '').replace('arrayconnection:', '')) ?? 0) + 1
  );
};

interface PaginationProps {
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  totalCount: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  startCursor?: string | null;
  endCursor?: string | null;
}

const Pagination = (p: PaginationProps) => {
  const start = cursorToNumber(p.startCursor);
  const end = cursorToNumber(p.endCursor);

  return (
    <div className="flex flex-row justify-between items-center m-2">
      <Button
        onPress={p.onPreviousPage}
        variant="secondary"
        size="xs"
        isDisabled={!p.hasPreviousPage}
        icon={<GoArrowLeft />}
        tooltip="Previous page"
      >
        Prev
      </Button>
      <Text type="body-xs" className="text-gray-500">
        {p.totalCount
          ? `${start}-${end} of ${p.totalCount} quotes`
          : 'No results.'}
      </Text>
      <Button
        onPress={p.onNextPage}
        variant="secondary"
        size="xs"
        isDisabled={!p.hasNextPage}
        icon={<GoArrowRight />}
        iconPosition="right"
        tooltip="Next page"
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;
