import { format } from 'date-fns';

export function extractDateString(date?: Date): string {
  if (!date) return '';
  try {
    return format(date, ' LLL d');
  } catch (e) {
    return '';
  }
}

export function extractDateWithDayOfWeek(date?: Date): string {
  if (!date) return '';
  try {
    return format(date, 'EEE, LLL d');
  } catch (e) {
    return '';
  }
}

export function extractTimeString(date?: Date, twentyFourHour = false): string {
  if (!date) return '';
  if (twentyFourHour) return format(date, 'HH:mm');
  return format(date, 'h:mm a');
}

export function formatTimeString(
  time?: string,
  twentyFourHour = false
): string {
  if (!time) return '';

  const [hours, minutes] = time.split(':').map(Number);

  const date = new Date();

  date.setHours(hours, minutes, 0, 0);

  return extractTimeString(date, twentyFourHour);
}
