import { format } from 'date-fns';
const dateTimeFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
});

export const formatDateTime = (datetime: number | Date | string): string =>
  dateTimeFormatter.format(new Date(datetime));

export const formatDate = (
  date: string,
  outputFormat = 'PP',
  localize = false
): string => {
  if (localize) return format(getLocalizedDate(date), outputFormat);
  return format(new Date(date), outputFormat);
};

export const getLocalizedDate = (dateString: string): Date => {
  const date = new Date(dateString + 'T00:00:00Z');
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const localDate = new Date(date.getTime() + timezoneOffset);
  return localDate;
};

export function filtersToDateRange(start: string[], end: string[]) {
  const ti = start.length
    ? new Date(decodeURIComponent(start[0])).toISOString()
    : undefined;
  const tf = end.length
    ? new Date(decodeURIComponent(end[0])).toISOString()
    : undefined;
  return { start: ti, end: tf };
}

export const toDateFilter = (d: string[]) => {
  if (!d || !d.length) {
    return undefined;
  }
  return decodeURIComponent(d[0]);
};
