import {
  LaneFilter,
  ShipmentHistorySummaryFragment,
  useGetShipmentsQuery,
} from '@shared/generated/graphql';
import { FeatureFlagName } from 'clerk_common/types';
import { useOrganizationFeatureFlag } from './useOrganizationFeatureFlag';

type ShipmentFilters = {
  equipmentTypes?: string[];
  laneFilter?: LaneFilter;
};

export const useGetShipments = (shipmentFilters?: ShipmentFilters) => {
  const { data, loading, error } = useGetShipmentsQuery({
    variables: {
      input: {
        equipmentTypes: shipmentFilters?.equipmentTypes,
        laneFilter: shipmentFilters?.laneFilter,
      },
    },
  });

  const isShipmentHistoryEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.SHIPMENT_HISTORY,
  });

  if (!isShipmentHistoryEnabled) {
    return {
      shipments: [],
      loading: false,
      error: null,
    };
  }

  const shipments =
    data?.shipments.edges.map((edge) => edge.node) ??
    ([] as ShipmentHistorySummaryFragment[]);

  return {
    shipments,
    loading,
    error,
  };
};
