import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactChild, ReactElement } from 'react';
import { BaseTimelineEvent } from '../base-timeline-event';
import { StopPointProps } from '../stop-point/StopPoint';

export type ActionTimelineEventProps = StyleProps &
  Pick<StopPointProps, 'type'> & {
    eventMarker?: ReactChild;
    children: ReactElement | ReactElement[] | string;
  };

const ROOT = makeRootClassName('ActionTimelineEvent');

const DEFAULT_PROPS = {} as const;

function ActionTimelineEvent(props: ActionTimelineEventProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <BaseTimelineEvent type={p.type} eventMarker={p.eventMarker}>
        {p.children}
      </BaseTimelineEvent>
    </div>
  );
}

export default ActionTimelineEvent;
