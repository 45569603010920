import { Text } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FaCircleExclamation } from 'react-icons/fa6';

const ROOT = makeRootClassName('QuoteDetails');
export const el = makeElementClassNameFactory(ROOT);

export const InformationNeeded = () => {
  return (
    <div className={el`information-needed-container`}>
      <div className={el`information-needed-header`}>
        <FaCircleExclamation className="text-red-500" size={14} />
        <Text isHeavy type="body-sm" className="text-gray-700">
          Information needed
        </Text>
      </div>
      <Text type="body-xs" className={el`missing-fields`}>
        The Vooma AI needs help pulling the required info. Update the
        highlighted fields to get a rate.
      </Text>
    </div>
  );
};
