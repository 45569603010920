import { PlaceDetailsFormValues } from '@shared/features/place-details-section/types';
import {
  GetPlacesDocument,
  PlaceDocument,
  PlaceTag,
  usePlaceFormDataQuery,
  useUpdatePlaceDetailsMutation,
} from '@shared/generated/graphql';
import { getStateLabelFromAbbreviation } from 'clerk_common/geography';
import { capitalize, isNil } from 'lodash';
import { useState } from 'react';

type usePlaceDetailsOptions = {
  placeId: string;
};

type usePlaceDetailsState = {
  isLoading: boolean;
  isUpdating: boolean;
  initialFormData: PlaceDetailsFormValues;
  onUpdate: (data: PlaceDetailsFormValues) => Promise<void>;
};

type usePlaceDetailsActions = {
  // add any actions the hook provides
};

export type usePlaceDetailsValue = usePlaceDetailsState &
  usePlaceDetailsActions;

const usePlaceDetails = ({
  placeId,
}: usePlaceDetailsOptions): usePlaceDetailsValue => {
  const { data, loading } = usePlaceFormDataQuery({
    variables: {
      placeId,
    },
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [updatePlace] = useUpdatePlaceDetailsMutation();

  const state = data?.placeById?.address?.state;
  const initialFormData: PlaceDetailsFormValues = {
    originator: {
      id: data?.placeById?.originator?.id,
      label: data?.placeById?.originator?.name ?? '',
    },
    name: data?.placeById?.name || '',
    address: {
      addressOne: data?.placeById?.address?.addressOne || '',
      addressTwo: data?.placeById?.address?.addressTwo || '',
      city: data?.placeById?.address?.city || '',
      state: {
        label: !isNil(state) ? getStateLabelFromAbbreviation(state) || '' : '',
        value: !isNil(state) ? state : '',
      },
      zip: data?.placeById?.address?.zip || '',
      country: data?.placeById?.address?.country || '',
    },
    locationCode: data?.placeById?.locationCode || '',
    tags:
      data?.placeById?.tags?.map((t: PlaceTag) => ({
        label: capitalize(t),
        value: t,
      })) || [],
    aliases: data?.placeById?.aliases?.map((a) => ({ value: a })) || [],
  };

  const handleUpdatePlace = async (data: PlaceDetailsFormValues) => {
    setIsUpdating(true);

    const cleaned = {};

    await updatePlace({
      variables: {
        input: {
          id: placeId,
          originatorId: data.originator?.id,
          name: data.name,
          address: {
            addressOne: data.address.addressOne,
            addressTwo: data.address.addressTwo,
            city: data.address.city,
            state: data.address.state.value,
            zip: data.address.zip,
            country: data.address.country,
          },
          locationCode: data.locationCode,
          tags: data.tags.map((t) => t.value),
          aliases: data.aliases.map((a) => a.value),
          ...cleaned,
        },
      },
      refetchQueries: [PlaceDocument, GetPlacesDocument],
    });
    setIsUpdating(false);
  };

  return {
    isLoading: loading,
    isUpdating,
    initialFormData,
    onUpdate: handleUpdatePlace,
  };
};

export default usePlaceDetails;
