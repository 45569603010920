import { useToast } from '@shared/components/toast';
import {
  DEFAULT_TOAST_WITH_ACTION_DURATION,
  ToastOptions,
} from '@shared/components/toast/useToast';
import { DeepLinkLocation } from '@shared/constants/identifiers';
import {
  OrderDocument,
  SubmitOrderError,
  useSubmitOrderMutation,
} from '@shared/generated/graphql';
import errorMessages from 'clerk_common/errors/errorMessages.json';
import { isNil } from 'lodash';
import { ReactElement } from 'react';
import { Link } from '..';
import { useAnalytics } from './useAnalytics';

type UseSubmitOrderActions = {
  submitOrder: (orderId: string) => void;
};

export function useSubmitOrder(): UseSubmitOrderActions {
  const [submitOrder] = useSubmitOrderMutation();
  const { track } = useAnalytics();
  const { sendToast } = useToast();

  const submitOrderWrapper = async (orderId: string) => {
    const response = await submitOrder({
      variables: { input: { id: orderId } },
      refetchQueries: ['GetOrders', OrderDocument],
      awaitRefetchQueries: true,
    }).catch((e) => {
      if (e.message.includes('not live')) {
        sendToast('Integration is not yet live', { isDismissible: true });
        return;
      }
      if (e.message.includes('Geocoding error')) {
        sendToast('Address validation failed', {
          variant: 'error',
          description: errorMessages['errorSupportPostamble'],
          isDismissible: true,
        });
        return;
      }
      // Catch-all case for all other errors.
      sendToast('Failed to submit order to TMS', {
        variant: 'error',
        description: e.message,
        isDismissible: true,
      });
      console.error(e);
      return;
    });

    if (!response?.data?.submitOrder.success) return;

    track('Submitted Order', { orderId });

    const orderLink: string | undefined =
      response?.data?.submitOrder.order?.externalDeepLink ?? undefined;
    const description = !isNil(orderLink) ? (
      <Link
        target="_blank"
        href={orderLink}
        className="underline text-subtle text-body-sm"
        onPress={() =>
          track('Deep Link Clicked', {
            orderId,
            name: DeepLinkLocation.SUBMITTED_ORDER_TOAST,
          })
        }
      >
        View order in TMS
      </Link>
    ) : undefined;
    if (!isNil(description)) {
      track('Deep Link Viewed', {
        orderId,
        name: DeepLinkLocation.SUBMITTED_ORDER_TOAST,
      });
    }
    sendToast('Order submitted to TMS', {
      variant: 'success',
      isDismissible: true,
      children: description,
      autoHideDuration: DEFAULT_TOAST_WITH_ACTION_DURATION,
    });

    if (!isNil(response.data.submitOrder.errors)) {
      handleSubmitOrderErrors(
        response.data.submitOrder.errors,
        sendToast,
        description
      );
    }
  };

  return { submitOrder: submitOrderWrapper };
}

const handleSubmitOrderErrors = (
  errors: SubmitOrderError[],
  sendToast: (message: string, options: ToastOptions) => void,
  deepLink?: ReactElement
) => {
  if (errors.includes(SubmitOrderError.DOCUMENT_UPLOAD_FAILED)) {
    sendToast('Document upload failure', {
      variant: 'error',
      isDismissible: true,
      description: 'Vooma failed to upload one or more of the order documents.',
      children: deepLink,
    });
  }
};
