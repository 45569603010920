import { Text } from '@shared/components';
import { TextFieldArray } from '@shared/components/react-hook-form';
import ReactHookFormCheckbox from '@shared/components/react-hook-form/ReactHookFormCheckbox';
import { StyleProps } from '@shared/utils';
import { getUserFeatureDisplayName } from '@shared/utils/userFeatures/getUserFeatureDisplayName';
import { ReactElement } from 'react';
import { Control } from 'react-hook-form';
import { EmailScanningFeatureFormValues } from './utils/featuresForm';

export type EmailScanningFeatureFormProps = StyleProps & {
  el: (strings: TemplateStringsArray) => string;
  control: Control<EmailScanningFeatureFormValues, object>;
  disableAdminControl?: boolean;
};

function EmailScanningFeatureForm({
  el,
  control,
  disableAdminControl,
}: EmailScanningFeatureFormProps): ReactElement {
  return (
    <div className={el`email-classification`}>
      <div className={el`feature-container`}>
        <ReactHookFormCheckbox
          label={getUserFeatureDisplayName('EmailScanning')}
          control={control}
          name={'enabled'}
          labelType="body-sm"
          disabled={disableAdminControl}
        />
        <Text type="body-xs" className={el`feature-description`}>
          With email monitoring enabled, Vooma will build quotes or orders
          automatically, as soon as an email reaches your inbox.
        </Text>
        <TextFieldArray
          control={control}
          name={'allowList'}
          arrayLabel="Allowed email addresses"
          elementLabel="allowed email"
        />
        {disableAdminControl ? null : (
          <TextFieldArray
            control={control}
            name={'blockList'}
            arrayLabel="Blocked email addresses"
            elementLabel="blocked email"
            isDisabled
          />
        )}
      </div>
    </div>
  );
}

export default EmailScanningFeatureForm;
