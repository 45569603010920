import { MaybeCompletedFreightOrderTemplate } from '../../types';
import { hasCommoditySection } from './hasCommoditySection';

type CommodityFieldOption = {
  id: string;
  label: string;
};

type CommodityFieldOptionWithPresence = CommodityFieldOption & {
  hasOption: boolean;
};

const COMMODITY_FIELD_OPTIONS = [
  {
    id: 'handlingUnits',
    label: 'Handling units',
  },
  {
    id: 'pieceUnits',
    label: 'Piece units',
  },
  {
    id: 'freightClass',
    label: 'Freight class',
  },
  {
    id: 'nmfcCode',
    label: 'NMFC code',
  },
  {
    id: 'itemReference',
    label: 'Item reference',
  },
];

export const hasField = (
  option: CommodityFieldOption,
  template: MaybeCompletedFreightOrderTemplate
) => {
  return hasCommoditySection(option.id, template);
};

const getCommodityFieldOptions = (
  includes: boolean,
  template?: MaybeCompletedFreightOrderTemplate
): CommodityFieldOption[] => {
  const fields: CommodityFieldOption[] = [];

  if (!template) return fields;

  for (const option of COMMODITY_FIELD_OPTIONS) {
    if (includes === hasField(option, template)) {
      fields.push(option);
    }
  }

  return fields;
};

export function getAddOrRemoveCommodityFieldOptions(
  template: MaybeCompletedFreightOrderTemplate
): CommodityFieldOptionWithPresence[] {
  return COMMODITY_FIELD_OPTIONS.map((option) => ({
    ...option,
    hasOption: hasField(option, template),
  }));
}

export function getAddCommodityFieldOptions(
  template?: MaybeCompletedFreightOrderTemplate
): CommodityFieldOption[] {
  return getCommodityFieldOptions(false, template);
}

export function getRemoveCommodityFieldOptions(
  template?: MaybeCompletedFreightOrderTemplate
): CommodityFieldOption[] {
  return getCommodityFieldOptions(true, template);
}
