import clsx from 'clsx';

interface ItemsCountProps {
  count: number;
  className?: string;
}

export function ItemsCount({ count, className }: ItemsCountProps) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full bg-gray-400 min-w-4 h-4 text-white',
        className
      )}
    >
      {count}
    </div>
  );
}
