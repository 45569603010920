import { Button, CopyButton } from '@shared/components';
import { isNil } from 'lodash';
import { MdOutlineOpenInNew } from 'react-icons/md';

export interface ExternalLinkProps {
  externalLink: string | null;
  externalId: string | null;
  handleExternalLinkPress: () => void;
  handleExternalIdPress: () => void;
}
export function ExternalResource({
  externalId,
  externalLink,
  handleExternalLinkPress,
  handleExternalIdPress,
}: ExternalLinkProps) {
  if (!externalId && !externalLink) {
    return null;
  }

  return !isNil(externalLink) ? (
    <Button
      variant={'tertiary'}
      onPress={() => {
        window.open(externalLink as string, '_blank', 'noopener,noreferrer');
        handleExternalLinkPress();
      }}
      icon={<MdOutlineOpenInNew className="text-brand-500" />}
      iconPosition="right"
    >
      View submitted order in TMS
    </Button>
  ) : !isNil(externalId) ? (
    <div className="flex justify-center">
      <CopyButton
        textToCopy={externalId}
        label={`TMS ID: ${externalId}`}
        variant="tertiary"
        size="medium"
        className="my-1"
        onCopy={handleExternalIdPress}
      />
    </div>
  ) : (
    <></>
  );
}
