import { Layout } from '@/Layout';
import '@/styles/all.scss';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { init } from '@fullstory/browser';
import { ErrorBoundary } from '@sentry/react';
import { GlobalErrorBoundary } from '@shared/components/GlobalErrorBoundary';
import PrivateRoute from '@shared/components/PrivateRoute/PrivateRoute';
import { FULLSTORY_ORG } from '@shared/constants';
import { AnalyticsContextProvider } from '@shared/contexts/AnalyticsContext';
import { ApolloWrapper } from '@shared/contexts/ApolloWrapper';
import { AuthContextProvider } from '@shared/contexts/AuthContext';
import { useMe } from '@shared/contexts/hooks/useMe';
import { MeContextProvider } from '@shared/contexts/MeContext';
import { StandardLogosContextProvider } from '@shared/contexts/pluginContexts/StandardLogosContextProvider';
import { StandardIntercomSupportContextProvider } from '@shared/contexts/StandardIntercomSupportContext';
import { AutomaticNavigator } from '@shared/features/automatic-navigator/AutomaticNavigator';
import QuoteAuditTrail from '@shared/features/quote-trail/QuoteAuditTrail';
import { VoomaApiClient } from '@shared/generated/graphql';
import { useInterval } from '@shared/hooks/useInterval';
import { FlowsViewProvider } from '@shared/plugin/contexts/FlowsViewProvider';
import { PlaceSidePanelContextProvider } from '@shared/plugin/contexts/PlaceSidePanelContext';
import { ToastProvider } from '@shared/plugin/contexts/ToastProvider';
import { AccountSettings } from '@shared/plugin/pages/AccountSettings';
import { Home } from '@shared/plugin/pages/Home';
import { OrderSidePanel } from '@shared/plugin/pages/OrderSidePanel';
import { OriginatorOnboarding } from '@shared/plugin/pages/OriginatorOnboarding';
import { Quotes } from '@shared/plugin/pages/Quotes';
import { QuoteSidePanel } from '@shared/plugin/pages/QuoteSidePanel';
import {
  checkClientVersion,
  DEFAULT_VERSION_CHECK_INTERVAL,
} from '@shared/utils/version';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import { ORDERS_ROUTE, QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FrontAPIContextProvider } from './contexts/FrontAPIContextProvider';
import { FrontAuthorizedIntegrationProvider } from './contexts/FrontIntegrationsAuthContextProvider';
import { FrontThreadContextProvider } from './contexts/FrontThreadContextProvider';
import { Login } from './features/Login';

const WEB_BASE_URL = import.meta.env.VITE_WEB_BASE_URL;
const LOGIN_URL = `${import.meta.env.VITE_APP_BASE_URL}/login`;
const APOLLO_URI = `${import.meta.env.VITE_API_BASE_URL}/api`;

function App() {
  React.useEffect(() => {
    init({ orgId: FULLSTORY_ORG, recordOnlyThisIFrame: true });
  }, []);
  useInterval(() => {
    checkClientVersion();
  }, DEFAULT_VERSION_CHECK_INTERVAL);
  const location = useLocation();

  return (
    <div className="App">
      <AnalyticsContextProvider apiClient={VoomaApiClient.FRONT}>
        <ErrorBoundary fallback={GlobalErrorBoundary}>
          <AnimatePresence>
            <ToastProvider>
              <Auth0ContextProvider>
                <ApolloWrapper appName="front" httpLinkUri={APOLLO_URI}>
                  <MeContextProvider useMe={useMe}>
                    <StandardIntercomSupportContextProvider>
                      <FrontAuthorizedIntegrationProvider>
                        <FrontAPIContextProvider>
                          <StandardLogosContextProvider>
                            <FrontThreadContextProvider>
                              <PlaceSidePanelContextProvider>
                                <FlowsViewProvider>
                                  <AutomaticNavigator>
                                    <Routes
                                      location={location}
                                      key={location.pathname}
                                    >
                                      <Route
                                        path="/login-prompt"
                                        element={<Login />}
                                      />
                                      {/* Private routes */}
                                      <Route
                                        path="/"
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout
                                              webBaseUrl={WEB_BASE_URL}
                                              hasMenu
                                            >
                                              <Home />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path="/settings"
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <AccountSettings />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={QUOTES_ROUTE}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <Quotes
                                                webBaseUrl={WEB_BASE_URL}
                                              />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${QUOTES_ROUTE}/:quoteId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <QuoteSidePanel
                                              webBaseUrl={WEB_BASE_URL}
                                            />
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${QUOTES_ROUTE}/:quoteId/${DrawerTabValue.AUDIT_TRAIL}`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <QuoteAuditTrail />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${ORDERS_ROUTE}/:orderId/originator-onboarding/:originatorId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <Layout webBaseUrl={WEB_BASE_URL}>
                                              <OriginatorOnboarding />
                                            </Layout>
                                          </PrivateRoute>
                                        }
                                      />
                                      <Route
                                        path={`${ORDERS_ROUTE}/:orderId`}
                                        element={
                                          <PrivateRoute redirectTo="/login-prompt">
                                            <OrderSidePanel
                                              webBaseUrl={WEB_BASE_URL}
                                            />
                                          </PrivateRoute>
                                        }
                                      />
                                    </Routes>
                                  </AutomaticNavigator>
                                </FlowsViewProvider>
                              </PlaceSidePanelContextProvider>
                            </FrontThreadContextProvider>
                          </StandardLogosContextProvider>
                        </FrontAPIContextProvider>
                      </FrontAuthorizedIntegrationProvider>
                    </StandardIntercomSupportContextProvider>
                  </MeContextProvider>
                </ApolloWrapper>
              </Auth0ContextProvider>
            </ToastProvider>
          </AnimatePresence>
        </ErrorBoundary>
      </AnalyticsContextProvider>
    </div>
  );
}

type Auth0ContextProviderProps = {
  children: JSX.Element;
};
function Auth0ContextProvider({ children }: Auth0ContextProviderProps) {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      <AuthProviderAdapter>{children}</AuthProviderAdapter>
    </Auth0Provider>
  );
}

function AuthProviderAdapter({ children }: { children: JSX.Element }) {
  const { logout } = useAuth0();

  const authLogout = async () => {
    await logout({
      logoutParams: { returnTo: import.meta.env.VITE_APP_BASE_URL },
    });
  };

  return (
    <AuthContextProvider loginUrl={LOGIN_URL} authLogout={authLogout}>
      {children}
    </AuthContextProvider>
  );
}

export default App;
