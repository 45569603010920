import { Separator } from '@shared/components';
import {
  FlowTriggerType,
  GetWorkflowsQuery,
  OrderEventType,
  QuoteEventType,
} from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { TransformationCard } from '../transformation-card/TransformationCard';

export type Workflow = GetWorkflowsQuery['workflows']['edges'][0]['node'];

const ROOT = makeRootClassName('WorkflowDetails');
const el = makeElementClassNameFactory(ROOT);

const getOrderEventTriggerText = (config: { eventType: OrderEventType }) => {
  switch (config.eventType) {
    case OrderEventType.AI_INFERENCE_COMPLETED:
      return 'a new order is created';
    case OrderEventType.SUBMITTED:
      return 'an order is submitted';
    default:
      return `${config.eventType} occurs`;
  }
};

const getQuoteEventTriggerText = (config: { eventType: QuoteEventType }) => {
  switch (config.eventType) {
    case QuoteEventType.AI_INFERENCE_COMPLETED:
      return 'a new quote is created';
    case QuoteEventType.MARKED_AS_NEW:
    case QuoteEventType.MARKED_AS_QUOTED:
    case QuoteEventType.MARKED_AS_WON:
    case QuoteEventType.MARKED_AS_LOST:
      return `a quote is ${config.eventType.toLowerCase().replace(/_/g, ' ')}`;
    default:
      return `${config.eventType} occurs`;
  }
};

const getTriggerText = (trigger: Workflow['trigger']): string => {
  const baseText = 'Triggered when';
  switch (trigger.type) {
    case FlowTriggerType.ORDER_EVENT:
      return `${baseText} ${getOrderEventTriggerText(trigger.config)}`;
    case FlowTriggerType.QUOTE_EVENT:
      return `${baseText} ${getQuoteEventTriggerText(trigger.config)}`;
    case FlowTriggerType.CONFIRMED_QUOTE_CREATED:
      return 'a new quote is created';
    default:
      return 'Unknown trigger';
  }
};

export type WorkflowDetailsProps = {
  workflow: Workflow;
};

export const WorkflowDetails = (p: WorkflowDetailsProps) => {
  return (
    <div className={clsx(ROOT)}>
      <div className={el`trigger`}>
        <p>{getTriggerText(p.workflow.trigger)}</p>
      </div>
      <div className={el`transformations`}>
        {p.workflow.transformations.map((transformation) => (
          <div className="w-full" key={transformation.id}>
            <Separator />
            <TransformationCard
              workflowId={p.workflow.id}
              transformation={transformation}
              key={transformation.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
