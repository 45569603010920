import { RatingMethod } from '@shared/generated/graphql';

export type HasMethod = {
  ratingMethod: RatingMethod;
};

export const groupRatesByMethod = <T extends HasMethod>(
  rates: T[]
): Record<RatingMethod, T[]> => {
  return rates.reduce(
    (acc, rate) => {
      if (!acc[rate.ratingMethod]) {
        acc[rate.ratingMethod] = [];
      }
      acc[rate.ratingMethod].push(rate);
      return acc;
    },
    {} as Record<RatingMethod, T[]>
  );
};
