import {
  AutocompleteBase,
  InteractableComponent,
  Text,
} from '@shared/components';
import { CreateCustomerLocation } from '@shared/constants/identifiers';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Order } from '@shared/types/order';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { isNil } from 'lodash';
import { ReactElement } from 'react';
import { OriginatorSearch } from '../originator-search/OriginatorSearch';

const ROOT = makeRootClassName('OriginatorDetails');
export const el = makeElementClassNameFactory(ROOT);

type OriginatorOption = {
  id: string;
  label: string;
  value?: string;
};

type OriginatorDetailsProps = StyleProps & {
  originator: Order['originator'];
  transmitId?: string;
  updateOriginator: (id: string) => void;
  updateTransmitId?: (id: string) => void;
  loading: boolean;
  originatorOptions: OriginatorOption[];
  customerDetailsCTA?: string;
  navigateToOriginatorDetails?: () => void;
  initiateOriginatorOnboarding?: (
    originatorName: string
  ) => Promise<string | undefined>;
  handleSearch: (query?: string) => void;
};
export function OriginatorDetails({
  originator,
  transmitId,
  updateTransmitId,
  updateOriginator,
  loading,
  originatorOptions,
  customerDetailsCTA,
  navigateToOriginatorDetails,
  initiateOriginatorOnboarding,
  handleSearch,
}: OriginatorDetailsProps): ReactElement {
  const { track } = useAnalytics();

  const transmitIdOptions = originator.transmitIds.map((id) => ({
    id,
    label: id,
  }));

  const createOriginator = async (name: string) => {
    if (isNil(initiateOriginatorOnboarding)) return undefined;
    const originatorId = await initiateOriginatorOnboarding(name);
    track('Clicked Create Customer', {
      originatorName: name,
      originatorId,
      name: CreateCustomerLocation.ORDER_ORIGINATOR_DROPDOWN,
    });
    return originatorId;
  };

  return (
    <div className={el`originator-details`}>
      <Text type="body-md" isHeavy className={el`originator-details-title`}>
        Customer Details
      </Text>
      <div className={el`originator-field`}>
        <div className="flex flex-grow flex-row w-full gap-2">
          <OriginatorSearch
            originator={originator}
            updateOriginator={updateOriginator}
            loading={loading}
            originatorOptions={originatorOptions}
            handleSearch={handleSearch}
            createOriginator={createOriginator}
            originatorRequired={true}
          />
        </div>
        {originator.id && navigateToOriginatorDetails && (
          <OpenOriginatorDetailsButton
            navigateToOriginatorDetails={navigateToOriginatorDetails}
            customerDetailsCTA={customerDetailsCTA}
          />
        )}
      </div>
      {updateTransmitId && originator.transmitIds.length > 1 && (
        <AutocompleteBase
          label="Customer Code"
          defaultValue={transmitId}
          className={el`transmit-id-field`}
          size="small"
          onChange={(_, value) => {
            if (!value) return;
            updateTransmitId((value as { id: string }).id);
          }}
          inputProps={{
            required: true,
          }}
          options={transmitIdOptions}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          isDisabled={loading}
          value={transmitId}
          blurOnSelect
        />
      )}
    </div>
  );
}

type OpenOriginatorDetailsButtonProps = {
  navigateToOriginatorDetails: () => void;
  customerDetailsCTA?: string;
};
function OpenOriginatorDetailsButton({
  navigateToOriginatorDetails,
  customerDetailsCTA,
}: OpenOriginatorDetailsButtonProps) {
  return (
    <InteractableComponent onPress={navigateToOriginatorDetails}>
      <Text
        type="body-xs"
        isHeavy
        className="flex w-full p-[4px] justify-end text-indigo-400 cursor-pointer"
      >
        {customerDetailsCTA || 'Open customer details'}
      </Text>
    </InteractableComponent>
  );
}
