import { useOptionalRef } from '@shared/hooks';
import {
  makeRootClassName,
  StyleProps,
  TooltipComponentProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import { OptionalTooltip } from '../tooltip/Tooltip';

// prop types

export type IconData = string | ReactNode;

export type IconProps = StyleProps &
  Omit<TooltipComponentProps, 'isTooltipInstant'> & {
    /**
     * The icon's size.
     * @default "medium"
     */
    size?: 'xs' | 'medium' | 'small' | 'custom';

    /** The content to render. Can be an SVG path
     * string or the svg content as react elements. */
    content: IconData;

    /**
     * Fine grain control of the icon's svg viewbox.
     * USE WITH CAUTION: You don't need to use these unless you are
     * rendering a non-square svg icon, but sometimes it's unavoidable.
     * @default 20
     */
    viewBoxWidth?: number;
    viewBoxHeight?: number;
  };

// config

const ROOT = makeRootClassName('Icon');

const DEFAULT_PROPS = {
  size: 'medium',
  viewBoxWidth: 20,
  viewBoxHeight: 20,
  tooltipSide: 'top' as TooltipComponentProps['tooltipSide'],
};

// main

function IconComponent(
  props: IconProps,
  ref: ForwardedRef<SVGSVGElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  return (
    <OptionalTooltip
      side={p.tooltipSide}
      content={p.tooltipContent}
      open={p.isTooltipOpen}
      isInstant
    >
      {typeof p.content === 'string' ? (
        // SVG path
        <svg
          ref={domRef}
          className={clsx(`${ROOT} size-${p.size}`, p.className)}
          viewBox={`0 0 ${p.viewBoxWidth} ${p.viewBoxHeight}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={p.content} fillRule="evenodd" clipRule="evenodd" />
        </svg>
      ) : (
        // react elements
        <div className={clsx(`${ROOT} size-${p.size}`, p.className)}>
          {p.content}
        </div>
      )}
    </OptionalTooltip>
  );
}

/**
 * A symbol that represents something, like an action or notice.
 */
const Icon = forwardRef<SVGSVGElement, IconProps>(IconComponent);

export default Icon;
