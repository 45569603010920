import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { StopType } from 'clerk_common/types/stop';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';

export type StopPointProps = StyleProps & {
  type?: StopType | null;
};

const ROOT = makeRootClassName('StopPoint');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export default function StopPoint(props: StopPointProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const stopType = p.type ?? 'NONE';

  return (
    <div className={clsx(ROOT)}>
      {stopType === 'DELIVERY' ? (
        <div>
          <HiOutlineLocationMarker
            className={clsx(el`final-point`)}
            size={16}
          />
        </div>
      ) : (
        <div className={el`point-container`}>
          <div className={clsx(el`point`, `type-${stopType}`)} />
        </div>
      )}
    </div>
  );
}
