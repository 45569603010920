import { EmailScanningConfig, UserFeature, UserFeatures } from '../types';

export const EMAIL_MONITORING_SOURCES = [
  'OUTLOOK_EMAIL_MONITORING',
  'OUTLOOK_EMAIL_MONITORING_SHORT_CIRCUIT',
  'GMAIL_EMAIL_MONITORING',
];

// NOTE(parlato): https://emailregex.com/index.html
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_DOMAIN_REGEX =
  /^(?!-)(?!.*-\.)[a-zA-Z0-9]+([a-zA-Z0-9-]*[a-zA-Z0-9]+)?(\.[a-zA-Z]{2,})+$/;

const ALLOW_LIST_SPECIFICITY = {
  WILDCARD_MATCH: 0,
  DOMAIN_MATCH: 1,
  EMAIL_MATCH: 2,
};

const BLOCK_LIST_SPECIFICITY = {
  DOMAIN_MATCH: 1,
  EMAIL_MATCH: 2,
};

export const getAllowListSpecificity = ({
  email,
  domain,
  allowList,
}: {
  email?: string;
  domain?: string;
  allowList: string[];
}): number => {
  if (email && caseInsensitiveIncludes(allowList, email))
    return ALLOW_LIST_SPECIFICITY.EMAIL_MATCH;

  if (domain && caseInsensitiveIncludes(allowList, domain))
    return ALLOW_LIST_SPECIFICITY.DOMAIN_MATCH;

  if (allowList.includes('*')) return ALLOW_LIST_SPECIFICITY.WILDCARD_MATCH;

  return -1;
};

export const getBlockListSpecificity = ({
  email,
  domain,
  blockList,
}: {
  email?: string;
  domain?: string;
  blockList: string[];
}): number => {
  if (email && caseInsensitiveIncludes(blockList, email))
    return BLOCK_LIST_SPECIFICITY.EMAIL_MATCH;

  if (domain && caseInsensitiveIncludes(blockList, domain))
    return BLOCK_LIST_SPECIFICITY.DOMAIN_MATCH;

  return -1;
};

export const checkEmailScanningItemAllowed = ({
  value,
  allowList,
  blockList,
}: {
  value?: string;
  allowList: string[];
  blockList: string[];
}): boolean => {
  if (!value) return false;

  let email: string | undefined;
  let domain: string | undefined;
  const parts = value.split('@');
  if (parts.length == 2) {
    email = value;
    domain = parts[1];
  }

  if (parts.length == 1) domain = parts[0];

  if (!checkIsValidEmailDomain(domain)) return false;

  const allowListSpecificity = getAllowListSpecificity({
    email,
    domain,
    allowList,
  });
  const blockListSpecificity = getBlockListSpecificity({
    email,
    domain,
    blockList,
  });

  if (blockListSpecificity >= allowListSpecificity) return false;

  if (allowListSpecificity > -1) return true;

  return false;
};

export const checkIsValidEmail = (email?: string) => {
  if (!email?.trim()) return false;

  return EMAIL_REGEX.test(email);
};

export const checkIsValidEmailDomain = (domain?: string) => {
  if (!domain?.trim()) return false;

  return EMAIL_DOMAIN_REGEX.test(domain);
};

export const getUserEmailScanningFeature = (
  organizationId: string,
  userFeatures?: UserFeatures
): UserFeature | undefined => {
  if (!userFeatures) return undefined;

  return userFeatures.features.find(
    (feature) =>
      feature.name === 'EmailScanning' &&
      feature.organizationId === organizationId
  );
};

const hasEmailScanningFeatureEnabled = (
  organizationId: string,
  userFeatures?: UserFeatures
): boolean => {
  const feature = getUserEmailScanningFeature(organizationId, userFeatures);
  return feature?.enabled || false;
};

const caseInsensitiveIncludes = (arr: string[], value: string) => {
  return arr.some((e) => e.toLowerCase() === value.toLowerCase());
};

const isEmailAllowListed = (
  config: EmailScanningConfig,
  senderEmail: string
) => {
  return caseInsensitiveIncludes(config.allowList, senderEmail);
};

const isEmailBlockListed = (
  config: EmailScanningConfig,
  senderEmail: string
) => {
  return caseInsensitiveIncludes(config.blockList, senderEmail);
};

export const shouldPromptToAllowScanning = (
  senderEmail: string,
  jobSource: string,
  organizationId: string,
  userFeatures?: UserFeatures
): boolean => {
  if (EMAIL_MONITORING_SOURCES.includes(jobSource)) return false;

  const config = userFeatures?.features?.find(
    (feature) => feature.name === 'EmailScanning'
  )?.config;
  if (!config || !hasEmailScanningFeatureEnabled(organizationId, userFeatures))
    return true;

  const isSenderAllowListed = isEmailAllowListed(config, senderEmail);
  const isSenderBlockListed = isEmailBlockListed(config, senderEmail);

  // NOTE(parlato): I could see a desire to still prompt if the email is block
  // listed and the feature is enabled since the user is explicitly creating
  // a quote/order - the action implies the block is a mistake
  if (isSenderBlockListed || isSenderAllowListed) return false;

  return true;
};
