import { RatingMethod } from '@shared/generated/graphql';
import { Dictionary } from 'lodash';
import { createContext, useContext } from 'react';

type RateErrorsContextType = {
  rateErrors: Dictionary<string>;
  setRateError: (ratingMethod: RatingMethod, error: string) => void;
  clearRateErrors: () => void;
};

export const RateErrorsContext = createContext<RateErrorsContextType>({
  rateErrors: {},
  setRateError: () => null,
  clearRateErrors: () => null,
});

export function useRateErrorsContext() {
  const context = useContext(RateErrorsContext);
  if (!context) {
    throw new Error(
      'useRateErrorsContext must be used within a RateErrorsContext.Provider'
    );
  }
  return context;
}
