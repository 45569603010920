import { Text } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import { PiWarning } from 'react-icons/pi';

interface RatesUnavailableWarningProps {
  loading: boolean;
  el: (strings: TemplateStringsArray) => string;
}
export const RatesUnavailableWarning = (p: RatesUnavailableWarningProps) => {
  return p.loading ? (
    <div className={p.el`loading-container`}>
      <CircularProgress size="md" />
    </div>
  ) : (
    <div className={p.el`rates-unavailable-container`}>
      <PiWarning size={18} className="text-gray-500" />
      <Text isHeavy type="body-sm" className="text-gray-700">
        Rates not available
      </Text>
      <div>
        <Text type="body-xs" className="text-gray-500 italic">
          {`You'll need to update the highlighted fields to get a rate.`}
        </Text>
      </div>
    </div>
  );
};
