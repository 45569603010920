import * as RadixSeparator from '@radix-ui/react-separator';
import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type SeparatorProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('Separator');

const DEFAULT_PROPS = {} as const;

export function Separator(props: SeparatorProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return <RadixSeparator.Root decorative className={clsx(ROOT, p.className)} />;
}
export default Separator;
