import { MessageActionButton, QuoteDraftResponse } from '@shared/components';
import {
  ResponseDraftContext,
  ResponseDraftContextProvider,
} from '@shared/contexts/ResponseDraftContext';
import {
  JobSummaryFragment,
  JobType,
  ProcessingStatus,
  QuoteSummaryFragment,
} from '@shared/generated/graphql';
import { useActionRegistryContext } from '@shared/plugin/contexts/ActionRegistryContext';
import { QuoteRow } from '@shared/plugin/pages/Quotes/components/QuoteRow';
import { shouldShowReplicatePrompt } from '@shared/plugin/utils/shouldShowReplicatePrompt';
import { JobStatus } from '@shared/types/job';
import { sumCustomerRate } from 'clerk_common/types';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { JobCardActions } from './job-card-actions/JobCardActions';
import { JobCardHeader } from './JobCardHeader';
import { OrderRow } from './OrderRow';

function getPossibleOnlyChild(job: JobSummaryFragment) {
  const numChildren = job.orders.length + job.quotes.length;
  if (numChildren === 1) {
    if (job.status === ProcessingStatus.PROCESSING) return null;
    if (job.orders.length === 1) {
      const o = job.orders[0];
      const showReplicatePrompt = shouldShowReplicatePrompt({
        parentJobStatus: JobStatus.COMPLETED,
        isOnlyOrderInJob: true,
        extractedData: JSON.parse(o.extractedData ?? '{}'),
      });
      return <OrderRow order={o} showReplicatePrompt={showReplicatePrompt} />;
    }
    if (job.quotes.length === 1) {
      return <QuoteRow quote={job.quotes[0]} />;
    }
  }
  return null;
}

function asTimestamp(str: string) {
  return new Date(str).getTime();
}

type HasCreatedAt = { createdAt: string };
function sortArr<T extends HasCreatedAt>(arr: T[]) {
  return [...arr].sort(
    (a, b) => asTimestamp(a.createdAt) - asTimestamp(b.createdAt)
  );
}

export type ChildSelection = {
  selectableIds: string[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  showSelection: boolean;
  setShowSelection: (jobId: string | undefined) => void;
};

const BatchReplySection = () => {
  const {
    draftReply,
    draftReplyInProgress,
    composeMessage,
    composeMessageInProgress,
  } = useContext(ResponseDraftContext);

  return (
    <div>
      <QuoteDraftResponse />
      <MessageActionButton
        draftReply={draftReply}
        draftReplyInProgress={draftReplyInProgress}
        composeMessage={composeMessage}
        composeMessageInProgress={composeMessageInProgress}
      />
    </div>
  );
};

interface JobCardProps {
  job: JobSummaryFragment;
  initialJobType: JobType;
}

const JOB_CARD_EXPAND_DURATION_SECONDS = 0.3;

export const JobCard = ({ job, initialJobType }: JobCardProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpanded = () => setIsExpanded((prev) => !prev);
  const onlyChild = getPossibleOnlyChild(job);

  const sortedOrders = sortArr(job.orders);
  const sortedQuotes = sortArr(job.quotes);

  const selectableIds = collectSelectable(job.quotes);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { batchReplyJobId, setBatchReplyJobId } = useActionRegistryContext();
  const originatorId = job.originator?.id;

  useEffect(() => {
    if (batchReplyJobId === job.id && !isExpanded) {
      setIsExpanded(true);
    }
  }, [batchReplyJobId, job.id]);

  useEffect(() => {
    if (!isExpanded) {
      setBatchReplyJobId(undefined);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (batchReplyJobId === job.id) {
      setSelectedIds(selectableIds);
      setIsExpanded(true);
    }
  }, [batchReplyJobId]);

  const childSelectionState: ChildSelection = {
    selectableIds,
    selectedIds,
    setSelectedIds,
    showSelection: batchReplyJobId === job.id,
    setShowSelection: setBatchReplyJobId,
  };

  const selectedQuotesIds = getSelectedQuotes(job.quotes, selectedIds).map(
    (q) => q.id
  );
  const itemId = job.emailMetadata?.itemId as string | undefined;

  return (
    <>
      {onlyChild ? (
        <div
          className={clsx(
            'my-1 overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm'
          )}
        >
          {onlyChild}
        </div>
      ) : (
        <div
          className={clsx(
            'my-1 flex-shrink-0 overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm'
          )}
        >
          <JobCardHeader
            job={job}
            toggleExpanded={toggleExpanded}
            initialJobType={initialJobType}
          />
          <motion.div
            animate={{ height: isExpanded ? 'auto' : 0 }}
            transition={{ duration: JOB_CARD_EXPAND_DURATION_SECONDS }}
            className="flex flex-col"
          >
            {sortedOrders.map((order) => (
              <OrderRow
                order={order}
                key={order.id}
                asJobChild
                siblingSelectionState={childSelectionState}
              />
            ))}
            {sortedQuotes.map((quote) => (
              <QuoteRow
                quote={quote}
                key={quote.id}
                asJobChild
                siblingSelectionState={childSelectionState}
              />
            ))}
          </motion.div>
          {batchReplyJobId && selectedQuotesIds.length > 0 && itemId && (
            <ResponseDraftContextProvider
              quoteIds={selectedQuotesIds}
              itemId={itemId}
              respondToEmail={job.emailMetadata?.originatorAddress}
              originatorId={originatorId}
            >
              <div className="bg-white p-2">
                <BatchReplySection />
              </div>
            </ResponseDraftContextProvider>
          )}
        </div>
      )}
      <JobCardActions job={job} />
    </>
  );
};

function collectSelectable(quotes: QuoteSummaryFragment[]) {
  return quotes
    .filter((q) => canBeIncludedInBatchQuoteReply(q))
    .map((q) => q.id);
}

function canBeIncludedInBatchQuoteReply(quote: QuoteSummaryFragment) {
  const rate = quote.customerRate ?? undefined;
  const sellRate = sumCustomerRate(rate);
  if (isNil(sellRate)) return false;
  return true;
}

function getSelectedQuotes(
  quotes: QuoteSummaryFragment[],
  selectedIds: string[]
) {
  return sortArr(quotes.filter((q) => selectedIds.includes(q.id)));
}
