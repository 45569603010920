import { isNil } from 'lodash';
import { EditableJSONField } from '../../types';
import { DimensionsData } from '../dimensions/Dimensions';
import {
  createHazmatFieldsElement,
  HazmatFieldsData,
} from '../hazmat-fields/HazmatFields';
import { SimpleArray } from '../simple-array/SimpleArray';
import {
  createWeightFieldsElement,
  WeightFieldsData,
} from '../weight-fields/WeightFields';
import { Commodity } from './Commodity';
import { ConfigureCommodity } from './ConfigureCommodity';

export type CommodityElement = {
  description?: EditableJSONField;
  handlingQuantity?: EditableJSONField;
  handlingUnits?: EditableJSONField;
  pieceQuantity?: EditableJSONField;
  pieceUnits?: EditableJSONField;
  freightClass?: EditableJSONField;
  nmfcCode?: EditableJSONField;
  hazmat?: HazmatFieldsData;
  itemReference?: EditableJSONField;
  weight?: WeightFieldsData;
  dimensions?: DimensionsData;
};

export type CommodityArrayData = {
  _value: CommodityElement[];
  _added?: CommodityElement[];
  _idxs?: string[];
};

type CommodityArrayProps = {
  data: CommodityArrayData;
  prefix?: string;
};
export function CommodityArray({ data, prefix }: CommodityArrayProps) {
  return (
    <>
      <SimpleArray
        data={data}
        ElementComponent={Commodity}
        createNewElement={() => createCommodityElement(data)}
        description="Commodity"
        prefix={prefix}
      />
      <ConfigureCommodity isMultiCommodity={true} />
    </>
  );
}

function createCommodityElement(data: CommodityArrayData): CommodityElement {
  const defaultFields = {
    _value: '',
    _logProbs: [],
  };
  const hasExtractedCommodities = data._value.length > 0;
  // NOTE(max): We include weight if there were no commodities because
  // weight is a fairly common per-commodity field, whereas hazmat is rare so
  // we only include it if it was in the original commodities.
  const includeWeight =
    !hasExtractedCommodities ||
    (hasExtractedCommodities && !isNil(data._value[0]?.weight));
  const includeHazmat =
    hasExtractedCommodities && !isNil(data._value[0]?.hazmat);
  const includeDimensions =
    hasExtractedCommodities && !isNil(data._value[0]?.dimensions);

  const hazmatStructureSample = data._value[0]?.hazmat;

  // TODO(parlato): We should clean this up and generalize so that we
  // can add an item of arbitrary type to an array that matches the
  // existing items' schema
  const includeHandlingQuantity =
    !hasExtractedCommodities || !isNil(data._value[0]?.handlingQuantity);
  const includeHandlingUnits =
    !hasExtractedCommodities || !isNil(data._value[0]?.handlingUnits);
  const includePieceQuantity =
    !hasExtractedCommodities || !isNil(data._value[0]?.pieceQuantity);
  const includePieceUnits =
    !hasExtractedCommodities || !isNil(data._value[0]?.pieceUnits);
  const includeFreightClass =
    !hasExtractedCommodities || !isNil(data._value[0]?.freightClass);
  const includeNmfcCode =
    !hasExtractedCommodities || !isNil(data._value[0]?.nmfcCode);
  const includeItemReference =
    !hasExtractedCommodities || !isNil(data._value[0]?.itemReference);

  const createDimensionElement = (name: string) => {
    return {
      _value: {
        value: {
          ...defaultFields,
          _display: {
            name,
          },
        },
        units: {
          ...defaultFields,
          _display: {
            name: 'Units',
          },
        },
      },
    };
  };

  return {
    description: {
      ...defaultFields,
      _corrected: '',
      _display: {
        name: 'Description',
      },
    },
    ...(includeHandlingQuantity
      ? {
          handlingQuantity: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Handling Quantity',
            },
          },
        }
      : {}),
    ...(includeHandlingUnits
      ? {
          handlingUnits: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Handling Units',
            },
          },
        }
      : {}),
    ...(includePieceQuantity
      ? {
          pieceQuantity: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Piece Quantity',
            },
          },
        }
      : {}),
    ...(includePieceUnits
      ? {
          pieceUnits: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Piece Units',
            },
          },
        }
      : {}),
    ...(includeFreightClass
      ? {
          freightClass: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Freight Class',
            },
          },
        }
      : {}),
    ...(includeNmfcCode
      ? {
          nmfcCode: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'NMFC Code',
            },
          },
        }
      : {}),
    ...(includeItemReference
      ? {
          itemReference: {
            ...defaultFields,
            _corrected: '',
            _display: {
              name: 'Item Reference',
            },
          },
        }
      : {}),
    ...(includeWeight
      ? {
          weight: {
            _value: createWeightFieldsElement(),
          },
        }
      : {}),
    ...(includeHazmat && !isNil(hazmatStructureSample)
      ? {
          hazmat: {
            _value: createHazmatFieldsElement(hazmatStructureSample),
            _display: {
              name: 'Hazmat',
            },
          },
        }
      : {}),
    ...(includeDimensions
      ? {
          dimensions: {
            _value: {
              length: createDimensionElement('Length'),
              width: createDimensionElement('Width'),
              height: createDimensionElement('Height'),
            },
          },
        }
      : {}),
  };
}
