export function pushUnique<T>(arr: T[], el: T): T[] {
  if (arr.includes(el)) return arr;
  return [...arr, el];
}

type OptionsToPlaceholderArgs = {
  options?: string[];
  includeUnknown?: boolean;
};
export function optionsToPlaceholder(args: OptionsToPlaceholderArgs): string {
  if (!args.options) return '';
  if (args.options.length === 0) return '';
  if (args.options.length === 1) return args.options[0];
  const opts = args.includeUnknown
    ? [...args.options, 'UNKNOWN']
    : args.options;
  return `<${opts?.join(' or ')}>`;
}

export function placeholderToOptions<OptionT>(
  placeholder?: string,
  validOptions?: readonly OptionT[]
): OptionT[] {
  if (!placeholder) return [];
  if (placeholder === '') return [];
  if (!placeholder.includes(' or ')) {
    return [placeholder as OptionT].filter(
      (opt) => validOptions?.includes(opt) ?? true
    );
  }
  const opts = placeholder
    .replace('<', '')
    .replace('>', '')
    .split(' or ')
    .map((option) => option.trim()) as OptionT[];
  return opts.filter((opt) => validOptions?.includes(opt) ?? true);
}
