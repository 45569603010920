import { Button } from '@shared/components/button';
import { Link } from '@shared/components/link';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { FeatureFlagName } from 'clerk_common/types';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { maybeGetDATRateUrl } from '../../utils/getGroupedRatesUrl';
import { LinkToRateProps } from './types';

export function LinkToDATRate(p: LinkToRateProps) {
  const isDATRateViewAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_RATE_VIEW_AUTOMATION,
  });
  const isDATLoadSearchAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_LOAD_SEARCH_AUTOMATION,
  });
  const datUrl =
    isDATRateViewAutomationEnabled || isDATLoadSearchAutomationEnabled
      ? maybeGetDATRateUrl(
          p.quote,
          p.ratingMethod,
          isDATRateViewAutomationEnabled,
          isDATLoadSearchAutomationEnabled
        )
      : undefined;
  const rateUrl = datUrl || p.metadata?.url;
  if (!rateUrl) return <div />;

  return (
    <Link size="xs" href={rateUrl} target="_blank" className="link">
      <div>
        <Button
          size="xs"
          icon={<MdOutlineOpenInNew className="text-brand-500" />}
          tooltip={p.optionalLabel || 'View rate source'}
          variant="tertiary"
          iconPosition="right"
        >
          {p.optionalLabel || 'View rate source'}
        </Button>
      </div>
    </Link>
  );
}
