import { PlaceSidePanel } from '@shared/plugin/pages/PlaceSidePanel';
import { createContext, useState } from 'react';

export type PlaceSidePanelContextType = {
  show: boolean;
  setShow: (show: boolean) => void;
  placeId: string;
  setPlaceId: (placeId: string) => void;
  viewPlace: (placeId: string) => void;
};

export const PlaceSidePanelContext = createContext<PlaceSidePanelContextType>({
  show: false,
  setShow: () => null,
  placeId: '',
  setPlaceId: () => null,
  viewPlace: () => null,
});

export const PlaceSidePanelContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [show, setShow] = useState(false);
  const [placeId, setPlaceId] = useState<string>('');

  const viewPlace = (placeId: string) => {
    setPlaceId(placeId);
    setShow(true);
  };

  return (
    <PlaceSidePanelContext.Provider
      value={{ show, setShow, placeId, setPlaceId, viewPlace }}
    >
      {children}
      <PlaceSidePanel
        placeId={placeId}
        showSidepanel={show}
        setShowSidepanel={setShow}
      />
    </PlaceSidePanelContext.Provider>
  );
};
