import { Text } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { Button, ButtonProps } from '../button';
import Checkbox from '../checkbox/Checkbox';
import Popover from '../popover/Popover';

const ROOT = makeRootClassName('PopoverMultiSelect');
const el = makeElementClassNameFactory(ROOT);

type OptionData = {
  id: string;
  label: string;
  selected: boolean;
};

type PopoverMultiSelectProps = {
  options: OptionData[];
  onOptionChange: (id: string, newValue: boolean) => void;
  buttonLabel: string;
  buttonProps?: ButtonProps;
  onOpen?: () => void;
};

export function PopoverMultiSelect(p: PopoverMultiSelectProps): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <div className={ROOT}>
      <Popover
        roundedLarge
        open={open}
        onOpenChange={(val) => {
          setOpen(val);
          if (val && p.onOpen) {
            p.onOpen();
          }
        }}
        content={
          <div className={el`container`}>
            {p.options.map((option) => (
              <CheckboxRow
                key={option.id}
                label={option.label}
                value={option.selected}
                onCheckedChange={(value) => {
                  p.onOptionChange(option.id, value);
                  setOpen(false);
                }}
              />
            ))}
          </div>
        }
      >
        <Button {...p.buttonProps}>{p.buttonLabel}</Button>
      </Popover>
    </div>
  );
}

type CheckboxRowProps = {
  label: string;
  value: boolean;
  onCheckedChange: (value: boolean) => void;
};

function CheckboxRow(props: CheckboxRowProps) {
  const p = { ...props };
  const { label, value } = p;
  const isDisabled = false;

  return (
    <div className={clsx(el`checkbox-row`, { 'is-checked': value })}>
      <Checkbox
        disabled={isDisabled}
        checked={value}
        onCheckedChange={p.onCheckedChange}
      />
      <Text>{label}</Text>
    </div>
  );
}
