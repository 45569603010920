import {
  ClassificationStatus,
  ExternalMessageType,
} from '@shared/generated/graphql';
import { ExternalMessageClassifications } from 'clerk_common';
import { FreightEntityType } from 'clerk_common/enums';

export const CONFIDENCE_THRESHOLD = 0.5;

export const FREIGHT_ENTITY_TYPE_LABELS = {
  LOAD_LOCATION_INQUIRY: 'Load location inquiry',
  SPOT_QUOTE_ACCEPTANCE: 'Quote acceptance',
  SPOT_QUOTE_REQUEST: 'Quote request',
  SPOT_QUOTE_REJECTION: 'Quote rejection',
  SPOT_QUOTE_UPDATE: 'Quote update',
  SPOT_QUOTE_NEGOTIATION: 'Quote negotiation',
} as Record<FreightEntityType, string>;

export type ExternalMessageFragment = {
  _typename: string;
  id: string;
  type: ExternalMessageType;
  externalIdentifier: string;
  status: ClassificationStatus;
  emailMetadata?: any | null;
  classifications?: any | null;
  user?: {
    id: string;
  };
};

export type MessageClassificationMetadata = {
  receivedDateTime?: string;
  sender?: string;
};

export type RelatedExternalMessage = {
  id: string;
  type: ExternalMessageType;
  externalIdentifier: string;
  status: ClassificationStatus;
  emailMetadata?: MessageClassificationMetadata;
  classifications?: ExternalMessageClassifications;
  userId?: string;
};
