import { ConstrainedKeysOf } from 'clerk_common';
import { isNil } from 'lodash';
import { FormField } from './fields/FormField';
import { isArrayPrefix } from './specializedBlocks/isArrayPrefix';
import { EditableJSONField, EditableJSONObject } from './types';

export const buildFieldIfPresent = <ParentT extends EditableJSONObject>(
  data: ParentT,
  fieldName: ConstrainedKeysOf<ParentT, EditableJSONField>,
  prefix: string
) => {
  const field = data[fieldName] as EditableJSONField;
  if (isNil(field)) {
    return null;
  }

  const newPrefix = isArrayPrefix(prefix)
    ? `${prefix}${fieldName as string}.`
    : `${prefix}_value.${fieldName as string}.`;
  return <FormField data={field} prefix={newPrefix} />;
};
