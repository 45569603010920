import { Option, Select, SelectProps } from '@shared/components';
import { FieldWrapper } from '@shared/components/react-hook-form/shared';
import { ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

// don't need any changes
export { Option };

/**
 * We extend the regular Select<TOption> props with UseControllerProps.
 * To make this truly generic (reusable in any react-hook-form, regarless of
 * the type of its FieldValues), we use generics to infer the type of the
 * FieldValues from the value passed into control prop.
 *
 * @see https://github.com/react-hook-form/react-hook-form/discussions/7851#discussioncomment-2219298
 */
export type ReactHookFormSelectProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SelectProps<TOption> &
  UseControllerProps<TFieldValues, TName> & {
    wrapperChildren?: ReactElement | ReactElement[];
    wrapperChildrenPlacement?: 'inside' | 'outside';
  };

function ReactHookFormSelect<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ReactHookFormSelectProps<TOption, TFieldValues, TName>): ReactElement {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    children,
    ...rest
  } = props;

  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FieldWrapper error={error}>
      <Select
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        name={fieldName}
        validationState={error ? 'invalid' : undefined}
        value={value}
        onChange={(val) => {
          onChange(val);
          if (props.onChange) {
            props.onChange(val);
          }
        }}
        onBlur={onBlur}
        ref={ref}
        wrapperChildren={props.wrapperChildren}
        wrapperChildrenPlacement={props.wrapperChildrenPlacement}
      >
        {children}
      </Select>
    </FieldWrapper>
  );
}

export default ReactHookFormSelect;
