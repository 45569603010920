import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  HazmatTemplate,
  MetadataWrapped,
} from 'clerk_common/templates/freight_order/types';
import { isNil } from 'lodash';
import { buildFieldIfPresent } from '../../buildFieldIfPresent';
import { EditableJSONField } from '../../types';
import {
  createHazmatClassElement,
  HazmatClass,
} from '../hazmat-class/HazmatClass';
import { SimpleArray } from '../simple-array/SimpleArray';
import {
  createWeightFieldsElement,
  WeightFields,
  WeightFieldsData,
} from '../weight-fields/WeightFields';

const ROOT = makeRootClassName('HazmatFields');
export const el = makeElementClassNameFactory(ROOT);

type HazmatContact = {
  name: EditableJSONField;
  phone: EditableJSONField;
  reference?: EditableJSONField;
};

type HazmatData = {
  contact: {
    _value: HazmatContact;
  };
  weight: WeightFieldsData;
  unNumber?: EditableJSONField;
  packingGroup?: EditableJSONField;
  classifications: {
    _value: {
      hazardClass: EditableJSONField;
      qualifier: EditableJSONField;
    }[];
  };
};

export type HazmatFieldsData = {
  _value: HazmatData;
  _display: {
    name: string;
  };
};

type HazmatFieldsProps = {
  data: HazmatFieldsData;
  prefix?: string;
};

export function HazmatFields({ data, prefix }: HazmatFieldsProps) {
  const hazmatValue = data._value;

  const text = data._display?.name && (
    <Text type="body-sm" isHeavy className="text-gray-700">
      {data._display.name}
    </Text>
  );

  const unNumberField = buildFieldIfPresent(
    hazmatValue,
    'unNumber',
    prefix ?? ''
  );
  const packingGroupField = buildFieldIfPresent(
    hazmatValue,
    'packingGroup',
    prefix ?? ''
  );
  const weightField = (
    <WeightFields
      data={hazmatValue.weight}
      prefix={`${prefix}_value.weight.`}
    />
  );

  const contactPrefix = `${prefix}_value.contact._value.`;
  const contactNameField = buildFieldIfPresent(
    hazmatValue.contact._value,
    'name',
    contactPrefix
  );
  const contactPhoneField = buildFieldIfPresent(
    hazmatValue.contact._value,
    'phone',
    contactPrefix
  );
  const contactReferenceField = buildFieldIfPresent(
    hazmatValue.contact._value,
    'reference',
    contactPrefix
  );

  const classificationFields = (
    <SimpleArray
      data={hazmatValue.classifications}
      ElementComponent={HazmatClass}
      createNewElement={createHazmatClassElement}
      description="Hazmat Class"
      prefix={`${prefix}_value.classifications.`}
    />
  );

  return (
    <div className={ROOT}>
      {text}
      {(unNumberField || packingGroupField) && (
        <div className={el`oneline`}>
          {unNumberField}
          {packingGroupField}
        </div>
      )}
      {weightField}
      {contactNameField}
      {contactPhoneField}
      {contactReferenceField}
      {classificationFields}
    </div>
  );
}

export function createHazmatFieldsElement(
  hazmatStructureSample: MetadataWrapped<HazmatTemplate<string>>
): HazmatData {
  const defaultFields = {
    _value: '',
    _corrected: '',
    _logProbs: [],
  };

  const includeContactReference = !isNil(
    hazmatStructureSample._value.contact?._value.reference
  );
  const contactNameDisplayName =
    hazmatStructureSample._value.contact?._value.name?._display?.name;
  const contactPhoneDisplayName =
    hazmatStructureSample._value.contact?._value.phone?._display?.name;
  const contactReferenceDisplayName =
    hazmatStructureSample._value.contact?._value.reference?._display?.name;

  return {
    contact: {
      _value: {
        name: {
          ...defaultFields,
          _display: {
            name: contactNameDisplayName ?? 'Contact Name',
          },
        },
        phone: {
          ...defaultFields,
          _display: {
            name: contactPhoneDisplayName ?? 'Contact Phone',
          },
        },
        ...(includeContactReference
          ? {
              reference: {
                ...defaultFields,
                _display: {
                  name: contactReferenceDisplayName ?? 'Contact Reference',
                },
              },
            }
          : {}),
      },
    },
    weight: {
      _value: createWeightFieldsElement(),
    },
    classifications: {
      _value: [],
    },
  };
}
