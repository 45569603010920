import { Button, Text } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FaCircleExclamation } from 'react-icons/fa6';

const ROOT = makeRootClassName('MultiOrderBanner');
export const el = makeElementClassNameFactory(ROOT);

interface MultiOrderBannerProps {
  buildTheRest: () => void;
}
export const MultiOrderBanner = (p: MultiOrderBannerProps) => {
  return (
    <div className={el`multiorder-banner-container`}>
      <div className={el`multiorder-banner-header`}>
        <FaCircleExclamation className="text-indigo-500" size={14} />
        <Text isHeavy type="body-sm" className="text-gray-700">
          Multiple orders detected
        </Text>
      </div>
      <Text type="body-xs" className={el`multiorder-banner-subtext`}>
        Vooma has built the first one. Would you like to build more, using this
        one as an example?
      </Text>
      <Button
        tooltip="Build more orders from the same input data"
        onPress={p.buildTheRest}
        variant="secondary"
        size="xs"
      >
        Build more
      </Button>
    </div>
  );
};
