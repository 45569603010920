import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { extractDateWithDayOfWeek } from '@shared/utils/stringification/timestamp';
import clsx from 'clsx';
import { useState } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { Calendar } from '../calendar';
import { Popover } from '../popover';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineDateField');
const el = makeElementClassNameFactory(ROOT);

interface InlineDateFieldProps<T> {
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
}

export const InlineDateField = <T extends FieldValues>(
  p: InlineDateFieldProps<T>
) => {
  const { watch, setValue } = useFormContext<T>();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const value: Date = watch(p.name);
  const dateString = value ? extractDateWithDayOfWeek(value) : '';

  const onChange = (date: Date) => {
    setValue(p.name, date as PathValue<T, Path<T>>);
    setDatePickerOpen(false);
    p.save(p.name);
  };

  return (
    <Popover
      className={clsx(ROOT)}
      side="bottom"
      align="start"
      open={datePickerOpen}
      onOpenChange={setDatePickerOpen}
      content={
        <div className={el`calendar-container`}>
          <Calendar
            value={value}
            onChange={onChange}
            className={el`calendar`}
            locale="en-US"
          />
          {/* <div className={el`close`}>
            <Button
              variant="secondary"
              tooltip="Cancel"
              size="xs"
              icon={<MdClear />}
              onPress={() => setDatePickerOpen(false)}
            />
          </div> */}
        </div>
      }
    >
      <div className={clsx(ROOT)}>
        <div
          className={clsx(
            el`date-wrapper`,
            p.required && 'REQUIRED',
            dateString ? 'VALID' : 'INVALID'
          )}
        >
          <Text type="body-xs">{dateString || 'Add date'}</Text>
        </div>
      </div>
    </Popover>
  );
};
