import { Button, Modal, Text } from '@shared/components';
import { InlineAutocomplete } from '@shared/components/inline-autocomplete/InlineAutocomplete';
import { InlineQuantifiedField } from '@shared/components/inline-quantified-field/InlineQuantifiedField';
import { InlineTextArea } from '@shared/components/inline-text-area/InlineTextArea';
import { InlineTextField } from '@shared/components/inline-text-field/InlineTextField';

import { RouteForm } from '@shared/components/route-form';
import { useFlowsViewContext } from '@shared/contexts/FlowsViewContext';
import { QuoteFormAdaptorContext } from '@shared/contexts/QuoteFormAdaptorContext';
import {
  GetQuoteResponseMessageDocument,
  JobType,
  QuoteDocument,
  useUpdateQuoteMutation,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Quote } from '@shared/types/quote';
import { StyleProps } from '@shared/utils';
import { getEquipmentTypeOptions } from '@shared/utils/forms/equipment';
import {
  getIsRequiredField,
  mapExtractedDataToQuoteFormValues,
  mutateFieldByPath,
  removeArrayElement,
} from '@shared/utils/forms/formAdaptor';
import { QuoteFormValues } from '@shared/utils/forms/types';
import { getWeightUoMOptions } from '@shared/utils/forms/weight';
import {
  getRateTotalDistanceDisplay,
  getRouteDuration,
} from '@shared/utils/rates/rates';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { StopType } from 'clerk_common/types/stop';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, Path, useForm } from 'react-hook-form';
import { SeeMore } from '../../components/see-more/SeeMore';

type QuoteDetailsDisplayProps = StyleProps & {
  quote: Quote;
};

export function QuoteDetailsDisplay(p: QuoteDetailsDisplayProps): ReactElement {
  const formValues = mapExtractedDataToQuoteFormValues(p.quote.extractedData);
  const methods = useForm<QuoteFormValues>({
    defaultValues: formValues,
  });
  const [updateQuote, { loading: updateIsLoading }] = useUpdateQuoteMutation();
  const { track } = useAnalytics();

  const { setOrganizationId, setOriginatorId, setExtractedData, setJobType } =
    useFlowsViewContext();
  useEffect(() => {
    setOrganizationId(p.quote.organization.id);
    setOriginatorId(p.quote.originator?.id);
    setExtractedData(p.quote.extractedData);
  }, [p.quote.organization.id, p.quote.originator?.id, p.quote.extractedData]);

  setJobType(JobType.QUOTES);

  useEffect(() => {
    methods.reset(mapExtractedDataToQuoteFormValues(p.quote.extractedData));
  }, [p.quote.id]);

  const totalDistanceDisplay = getRateTotalDistanceDisplay(
    p.quote?.carrierRate
  );
  const estimatedDuration = getRouteDuration(p.quote?.carrierRate);

  const integrationConfig =
    p.quote.organization.integrationConfigs?.integrations.find(
      (c) => c.orderType === 'QUOTE'
    );

  const updateExtractedData = async (
    newData: CompletedFreightOrderTemplate
  ) => {
    await updateQuote({
      variables: {
        input: {
          id: p.quote?.id,
          extractedData: newData,
        },
      },
      refetchQueries: [
        { query: QuoteDocument, variables: { id: p.quote?.id } },
        GetQuoteResponseMessageDocument,
      ],
    });
    track('Updated Quote', { quoteId: p.quote?.id });
    methods.reset(mapExtractedDataToQuoteFormValues(newData));
  };

  const save = async (
    nameOrNames: Path<QuoteFormValues> | Path<QuoteFormValues>[]
  ) => {
    const data = p.quote.extractedData;
    if (!data) return;

    const names = Array.isArray(nameOrNames) ? nameOrNames : [nameOrNames];
    let extractedData: CompletedFreightOrderTemplate = data;

    for (const name of names) {
      const fieldValue = methods.getValues(name);

      if (typeof fieldValue === 'object' && 'addressOne' in fieldValue) {
        extractedData = mutateFieldByPath(extractedData, name, fieldValue);
      } else if (typeof fieldValue === 'object') {
        const value = format(fieldValue as Date, 'yyyy-MM-dd');
        extractedData = mutateFieldByPath(extractedData, name, value);
      } else if (typeof fieldValue === 'string') {
        extractedData = mutateFieldByPath(extractedData, name, fieldValue);
      }
    }

    const dataIsDifferent = !isEqual(data, extractedData);
    dataIsDifferent && (await updateExtractedData(extractedData));
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      setModalIsOpen(false);
    }
  };

  const [removeStopIdx, setRemoveStopIdx] = useState<number | null>(null);

  const promptRemoveStop = (type: StopType, idx: number) => {
    if (type !== 'DELIVERY') {
      console.warn('Removing non-delivery stops is not supported');
      return;
    }

    setRemoveStopIdx(idx);
    setModalIsOpen(true);
  };

  const removeStop = async () => {
    setModalIsOpen(false);
    if (removeStopIdx === null) return;

    const data = p.quote.extractedData;
    if (!data) return;

    const extractedData = removeArrayElement(data, 'stops', removeStopIdx);
    await updateExtractedData(extractedData);
  };

  const weightName: Path<QuoteFormValues> = 'weight.weight';
  const weightUnits: Path<QuoteFormValues> = 'weight.units';
  const isWeightRequired = getIsRequiredField(weightName, integrationConfig);
  const equipmentTypeName: Path<QuoteFormValues> = 'equipmentType';
  const isEquipmentTypeRequired = getIsRequiredField(
    equipmentTypeName,
    integrationConfig
  );
  const commodityName: Path<QuoteFormValues> = 'commodity';
  const isCommodityRequired = getIsRequiredField(
    commodityName,
    integrationConfig
  );
  const equipmentTypeOptions = getEquipmentTypeOptions();
  const commodityOptions =
    p.quote?.organization?.configurableOptions?.commodities;
  const weightUoMOptions = getWeightUoMOptions();

  return (
    <div>
      <FormProvider {...methods}>
        <QuoteFormAdaptorContext.Provider
          value={{
            save,
            loading: updateIsLoading,
            integrationConfig: integrationConfig,
          }}
        >
          <div className="px-4 pb-[0px] pt-3">
            <RouteForm
              stops={formValues.stops}
              pickups={[formValues.pickup]}
              removeStop={promptRemoveStop}
            />
            {totalDistanceDisplay ? (
              <div className="flex justify-end px-2">
                <Text className="text-gray-500" type="body-xs">
                  {totalDistanceDisplay}
                  {estimatedDuration ? ` (${estimatedDuration} hours)` : ''}
                </Text>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-[2px] p-[16px] pt-[8px]">
            <InlineAutocomplete<QuoteFormValues>
              label="Equipment"
              name={equipmentTypeName}
              required={isEquipmentTypeRequired}
              save={save}
              options={equipmentTypeOptions}
            />
            {commodityOptions ? (
              <InlineAutocomplete<QuoteFormValues>
                label="Commodity"
                name={commodityName}
                required={isCommodityRequired}
                save={save}
                options={commodityOptions}
              />
            ) : (
              <InlineTextField<QuoteFormValues>
                label="Commodity"
                name={commodityName}
                required={isCommodityRequired}
                save={save}
              />
            )}
            <InlineQuantifiedField<QuoteFormValues>
              label="Weight"
              save={save}
              valueName={weightName}
              uomName={weightUnits}
              required={isWeightRequired}
              uomOptions={weightUoMOptions}
            />
            <MoreDetails save={save} />
          </div>
        </QuoteFormAdaptorContext.Provider>
      </FormProvider>
      <Modal.Root open={modalIsOpen}>
        <Modal.Content className="p-3" onKeyDown={handleKeyDown}>
          <div className="">
            <Text type="body-sm">
              Are you sure you want to remove this stop?
            </Text>
            <div className="flex justify-end gap-3">
              <Button
                className=""
                onPress={() => setModalIsOpen(false)}
                size="xs"
              >
                Cancel
              </Button>
              <Button
                className=""
                onPress={removeStop}
                size="xs"
                variant="danger"
              >
                Remove
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

type MoreDetailsProps = {
  save: (name: Path<QuoteFormValues>) => void;
};
function MoreDetails(props: MoreDetailsProps) {
  const { save } = props;
  const notesName: Path<QuoteFormValues> = 'notes';
  const isNotesRequired = getIsRequiredField(notesName);

  return (
    <SeeMore className="flex w-full">
      <InlineTextArea<QuoteFormValues>
        label="Notes"
        name={notesName}
        required={isNotesRequired}
        save={save}
      />
    </SeeMore>
  );
}
