import { format } from 'date-fns';

export function formatElapsedTime(
  conversationStartTime: Date,
  contributionTime: Date
): string {
  const elapsedMs =
    contributionTime.getTime() - conversationStartTime.getTime();
  return format(elapsedMs, 'm:ss');
}
