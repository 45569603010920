import { Button, Text } from '@shared/components';
import {
  ConversationIcon,
  ConversationTranscript,
} from '@shared/features/conversation-transcript';
import { describeConversation } from '@shared/features/conversation-transcript/describeConversation';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useState } from 'react';
import { ConversationFeedItem } from '../../types';
import { FeedItemTimestamp } from '../feed-item/FeedItem';

const ROOT = makeRootClassName('ConversationFeedItemPreview');
const el = makeElementClassNameFactory(ROOT);

type ConversationFeedItemPreviewProps = {
  item: ConversationFeedItem;
};

export const ConversationFeedItemPreview = ({
  item,
}: ConversationFeedItemPreviewProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={el`conversation-root`}>
      <div className={el`conversation-header`}>
        <div className="flex flex-row items-center gap-2">
          <ConversationIcon size={24} status={item.data.conversation.status} />
          <Text type="body-sm" className="text-gray-700">
            {describeConversation(
              item.data.conversation.status,
              item.data.conversation.type
            )}
          </Text>
        </div>
        <FeedItemTimestamp timestamp={item.createdAt} />
      </div>
      <div className={el`conversation-body`}>
        <Text type="body-xs" className="text-gray-500">
          Goal: {item.data.conversation.purposeDescription}
        </Text>
        <Button
          variant="tertiary"
          size="xs"
          onPress={() => setIsExpanded((s) => !s)}
        >
          {`${isExpanded ? 'Hide' : 'View'} details`}
        </Button>
      </div>
      {isExpanded && (
        <div className={el`conversation-details`}>
          <ConversationTranscript conversation={item.data.conversation} />
        </div>
      )}
    </div>
  );
};
