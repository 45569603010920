import { useOptionalRef } from '@shared/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';

export type CircularProgressProps = StyleProps & {
  /**
   * The size of the progress indicator
   * @default 'medium'
   */
  size?: 'medium' | 'small' | 'xs' | '2xs';

  /**
   * Whether the indicator represents an indeterminate or
   * determinate length of time.
   * @default 'indeterminate'
   */
  variant?: 'indeterminate' | 'determinate';

  /**
   * Whether the color of the loader should be inverted to white.
   * @default false
   */
  isInverted?: boolean;

  /**
   * The amount of progress. Used for determinate spinners.
   * Should be a number between 0 and 100
   */
  progress?: number;
};

// config

const ROOT = makeRootClassName('CircularProgress');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'medium',
  variant: 'indeterminate',
  isInverted: false,
} as const;

function CircularProgressComponent(
  props: CircularProgressProps,
  ref: ForwardedRef<SVGSVGElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  // for determinate spinners
  const isDeterminate = p.variant === 'determinate';
  const [offset, setOffset] = useState(100);
  const offsetProps = isDeterminate ? { strokeDashoffset: offset } : {};

  useEffect(() => {
    if (isDeterminate && p.progress) {
      const amount = 100 - p.progress / 2;
      setOffset(amount);
    }
  }, [isDeterminate, p.progress]);

  return (
    <svg
      ref={domRef}
      className={clsx(
        ROOT,
        `size-${p.size} variant-${p.variant}`,
        { 'is-inverted': p.isInverted },
        p.className
      )}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={el`path`}
        cx="50%"
        cy="50%"
        r="40%"
        fill="none"
        strokeWidth="10%"
        {...offsetProps}
      />
    </svg>
  );
}

/**
 * A circular progress indicator that can represent an indeterminate
 * or determinate loading time.
 */
const CircularProgress = forwardRef<SVGSVGElement, CircularProgressProps>(
  CircularProgressComponent
);

export default CircularProgress;
