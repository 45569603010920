import { Option, Select, Text, TextField } from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { getUserFromMe } from '@shared/contexts/MeContext';
import { useAuthorizedIntegrationContext } from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { UserFeaturesDisplay } from '@shared/features/user-features-display';
import UserFeaturesFormContainer from '@shared/features/user-features-form/UserFeaturesForm';
import { getInitialEmailScanningFormData } from '@shared/features/user-features-form/utils/featuresForm';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { uniq } from 'lodash';
import { useState } from 'react';

const ROOT = makeRootClassName('AccountSettings');
export const el = makeElementClassNameFactory(ROOT);

const NOT_SHARED_DISPLAY_TEXT = 'Not a shared inbox';

interface SharedInboxProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SharedInboxProps) => {
  return (
    <div className={el`section`}>
      <Text className={el`section-title`} type="body-md" isHeavy>
        {title}
      </Text>
      {children}
    </div>
  );
};

const AccountRow = ({ email, type }: { email: string; type: string }) => {
  return (
    <div className="flex flex-row text-gray-700 gap-1 w-full cursor-not-allowed">
      <TextField
        className="w-full"
        size="small"
        label={type}
        value={email}
        isDisabled
      />
    </div>
  );
};

const AccountSettings = () => {
  const { sharedInbox } = useEmailThreadContext();
  const { owner: sharedInboxOwner, candidates, selectCandidate } = sharedInbox;
  const { authorized, authorizedEmail, integrationName } =
    useAuthorizedIntegrationContext();
  const [isEditingFeatures, setEditingFeatures] = useState(false);
  const { me, defaultOrgId } = useMeContext();

  const authItems = [
    <AccountRow
      key="account-row-vooma"
      email={me?.email ?? 'Unknown'}
      type="Vooma"
    />,
  ];

  if (authorized) {
    authItems.push(
      <AccountRow
        key="account-row-integration"
        email={authorizedEmail ?? 'Unknown'}
        type={integrationName}
      />
    );
  }

  const inboxOwnerOptions = uniq([
    '',
    ...(sharedInboxOwner ? [sharedInboxOwner] : []),
    ...candidates,
  ]);

  const user = getUserFromMe(me);
  const initialEmailScanningData = getInitialEmailScanningFormData(
    defaultOrgId ?? undefined,
    user
  );
  const showFeatures = user && defaultOrgId && initialEmailScanningData.enabled;

  return (
    <div className={ROOT}>
      <Text className={el`page-title`} type="body-md" isHeavy>
        Account settings
      </Text>

      <Section title={'Shared inbox'}>
        <Select
          placeholder="Select shared inbox..."
          className={el`select-shared-inbox`}
          value={sharedInboxOwner}
          renderValue={(val) => val ?? NOT_SHARED_DISPLAY_TEXT}
          onChange={(val) => selectCandidate(val)}
        >
          {inboxOwnerOptions.map((email) => (
            <Option key={email ?? 'not-shared'} value={email}>
              {email || NOT_SHARED_DISPLAY_TEXT}
            </Option>
          ))}
        </Select>
        <Text type="body-xs" className={el`shared-inbox-details`}>
          {`If you are accessing messages in a shared inbox, select it from the
          list above. Alternatively, select '${NOT_SHARED_DISPLAY_TEXT}' if you
          are using an individual inbox or merely accessing messages from a
          distribution list of which you are a member.`}
        </Text>
      </Section>

      {showFeatures && (
        <>
          {isEditingFeatures ? (
            <UserFeaturesFormContainer
              onDiscard={() => setEditingFeatures(false)}
              user={user}
              organizationId={defaultOrgId}
              className="p-2 w-full bg-gray-50"
              disableAdminControl
            />
          ) : (
            <UserFeaturesDisplay
              onEdit={() => setEditingFeatures(true)}
              user={user}
              className="p-2 w-full bg-gray-50"
            />
          )}
        </>
      )}

      <Section title={'User'}>
        <div className="flex flex-col mt-2 gap-2 w-full">{authItems}</div>
      </Section>
    </div>
  );
};

export default AccountSettings;
