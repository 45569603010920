import { isNil, merge, omit } from 'lodash';
import { optionsToPlaceholder } from '../placeholders';
import {
  ExplicitReferenceSettings,
  GenericReferenceSettings,
  ReferenceNumbersElement,
  ReferenceSettings,
  StopConfigurationType,
  StopWithRefs,
  TemplateReferenceSettings,
  TemplateWithRefs,
} from './types';

export function settingsToExplicitReference(
  explicitReferenceSettings: ExplicitReferenceSettings
): ReferenceNumbersElement {
  return {
    referenceNumber: {
      _ai: {
        name: explicitReferenceSettings.appearsAs,
        placeholder: `<IDENTIFIER>`,
      },
    },
    referenceType: {
      _ai: {
        placeholder: explicitReferenceSettings.hardCodeAs,
        default: {
          type: 'hard',
          value: explicitReferenceSettings.hardCodeAs,
        },
      },
    },
  };
}

export function settingsToGenericReference(
  settings: GenericReferenceSettings
): ReferenceNumbersElement {
  const refTypePlaceholder = optionsToPlaceholder({
    options: settings.allowedReferenceTypes,
  });
  if (!refTypePlaceholder) return settings.template;
  return merge({}, settings.template, {
    referenceType: {
      _ai: {
        placeholder: refTypePlaceholder,
      },
    },
  });
}

function settingsToRefs(
  settings: ReferenceSettings
): ReferenceNumbersElement[] {
  return [
    ...settings.explicitReferences.map(settingsToExplicitReference),
    ...settings.genericReferences.map((s) => settingsToGenericReference(s)),
  ];
}

function saveOrderRefsToTemplate<T extends TemplateWithRefs>(
  template: T,
  settings: ReferenceSettings
): T {
  const refs = settingsToRefs(settings);
  const withoutOldRefs = omit(
    template,
    '_value.orderReferenceNumbers._value'
  ) as T;
  return merge({}, withoutOldRefs, {
    _value: { orderReferenceNumbers: { _value: refs } },
  });
}

function savePickupRefSettingsToTemplate<T extends TemplateWithRefs>(
  template: T,
  settings: ReferenceSettings
): T {
  const refs = settingsToRefs(settings);
  const withoutOldRefs = omit(
    template,
    '_value.pickup._value.stopReferenceNumbers._value'
  ) as T;
  return merge({}, withoutOldRefs, {
    _value: { pickup: { _value: { stopReferenceNumbers: { _value: refs } } } },
  });
}

type StopAndIndex = {
  stop: StopWithRefs;
  index: number;
};
function getOnlyStop(template: TemplateWithRefs): StopAndIndex {
  if (template._value.stops._value.length !== 1) {
    throw new Error('Expected only one stop');
  }
  return {
    stop: template._value.stops._value[0],
    index: 0,
  };
}

function getStopThatMatchesType(
  template: TemplateWithRefs,
  stopType: 'PICKUP' | 'DELIVERY'
): StopAndIndex {
  if (template._value.stops._value.length < 1) {
    throw new Error('Expected at least one stop');
  }
  const stopIdx = template._value.stops._value.findIndex(
    (s) => s.stopType?._ai?.placeholder?.includes(stopType)
  );
  if (stopIdx === -1) throw new Error(`No ${stopType} stop found`);
  return {
    stop: template._value.stops._value[stopIdx],
    index: stopIdx,
  };
}

function saveDeliveryRefSettingsToTemplate<T extends TemplateWithRefs>(
  template: T,
  settings: ReferenceSettings,
  stopTypePlaceholder?: string
): T {
  const refs = settingsToRefs(settings);
  const { stop } = getOnlyStop(template);
  const stopWithoutOldRefs = omit(
    stop,
    'stopReferenceNumbers._value',
    'stopType'
  );
  const newStops = [
    merge({}, stopWithoutOldRefs, {
      ...(stopTypePlaceholder && {
        stopType: { _ai: { placeholder: stopTypePlaceholder } },
      }),
      stopReferenceNumbers: { _value: refs },
    }),
  ];
  const withOldStops = omit(template, '_value.stops._value') as T;
  return merge({}, withOldStops, { _value: { stops: { _value: newStops } } });
}

function savePickupAndStopRefsToTemplate<T extends TemplateWithRefs>(
  template: T,
  pickSettings?: ReferenceSettings,
  dropSettings?: ReferenceSettings
): T {
  if (isNil(pickSettings)) throw new Error('Missing pickup ref settings');
  if (isNil(dropSettings)) throw new Error('Missing delivery ref settings');
  const ret = savePickupRefSettingsToTemplate(template, pickSettings);
  return saveDeliveryRefSettingsToTemplate(ret, dropSettings);
}

function saveStopRefsToTemplate<T extends TemplateWithRefs>(
  template: T,
  settings?: ReferenceSettings
): T {
  if (isNil(settings)) throw new Error('Missing stop ref settings');
  if (template._value.stops._value.length > 1) {
    template._value.stops._value = template._value.stops._value.slice(1);
  }
  return saveDeliveryRefSettingsToTemplate(
    template,
    settings,
    '<PICKUP or DELIVERY>'
  );
}

function savePickupAndDeliveryStopRefsToTemplate<T extends TemplateWithRefs>(
  template: T,
  pickSettings?: ReferenceSettings,
  dropSettings?: ReferenceSettings
): T {
  if (isNil(pickSettings)) throw new Error('Missing pickup ref settings');
  if (isNil(dropSettings)) throw new Error('Missing delivery ref settings');
  const pick = getStopThatMatchesType(template, 'PICKUP');
  const pickWithoutOldRefs = omit(pick.stop, 'stopReferenceNumbers._value');
  const newPick = merge({}, pickWithoutOldRefs, {
    stopType: { _ai: { placeholder: 'PICKUP' } },
    stopReferenceNumbers: { _value: settingsToRefs(pickSettings) },
  });
  const drop = getStopThatMatchesType(template, 'DELIVERY');
  const dropWithoutOldRefs = omit(drop.stop, 'stopReferenceNumbers._value');
  const newDrop = merge({}, dropWithoutOldRefs, {
    stopType: { _ai: { placeholder: 'DELIVERY' } },
    stopReferenceNumbers: { _value: settingsToRefs(dropSettings) },
  });
  const newTemplate = merge({}, template);
  newTemplate._value.stops._value[pick.index] = newPick;
  if (pick.index === drop.index) {
    newTemplate._value.stops._value.push(newDrop);
  } else {
    newTemplate._value.stops._value[drop.index] = newDrop;
  }
  return newTemplate;
}

export function saveReferenceSettingsBackToTemplate<T extends TemplateWithRefs>(
  template: T,
  settings: TemplateReferenceSettings
): T {
  const ret = saveOrderRefsToTemplate(template, settings.orderReferences);
  switch (settings.stopConfiguration) {
    case StopConfigurationType.SINGLE_PICKUP_MULTI_DELIVERY:
      return savePickupAndStopRefsToTemplate(
        ret,
        settings.pickupReferences,
        settings.deliveryReferences
      );
    case StopConfigurationType.TYPED_STOP_ARRAY:
      return saveStopRefsToTemplate(ret, settings.stopReferences);
    case StopConfigurationType.TYPED_STOP_ARRAY_EXPLICIT:
      return savePickupAndDeliveryStopRefsToTemplate(
        ret,
        settings.pickupReferences,
        settings.deliveryReferences
      );
    default:
      throw new Error(
        `Invalid stop configuration: ${settings.stopConfiguration}`
      );
  }
}
