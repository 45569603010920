import { Button, CircularProgress } from '@shared/components';
import { EditableUnit } from '@shared/components/json-defined-form/types';
import { useToast } from '@shared/components/toast';
import { DeepLinkLocation } from '@shared/constants/identifiers';
import { ExternalResource } from '@shared/features/externalResource/ExternalResource';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useSubmitOrder } from '@shared/hooks/useSubmitOrder';
import { Order } from '@shared/types/order';
import { useState } from 'react';

export type SubmitOrderProps = {
  order: Order;
  updateOrder: (data: EditableUnit) => Promise<void>;
  updateIsLoading: boolean;
  beforeSubmit?: () => Promise<boolean>;
};

function SubmitOrder(props: SubmitOrderProps) {
  const { sendToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { submitOrder } = useSubmitOrder();
  const { track } = useAnalytics();

  const submit = async () => {
    if (!props.order) return;
    if (props.beforeSubmit) {
      const val = await props.beforeSubmit();
      if (!val) {
        sendToast('Required fields are missing', {
          variant: 'error',
          isDismissible: true,
        });
        return;
      }
    }
    setIsSubmitting(true);
    await submitOrder(props.order.id);
    setIsSubmitting(false);
  };

  const { order } = props;

  const hasBeenSubmitted = order.hasBeenSubmitted;
  const disable = !order || isSubmitting;
  return (
    <div className="flex flex-col gap-2 justify-center sticky bottom-0 bg-white p-4 px-14 border-t">
      <Button
        isDisabled={disable}
        variant={hasBeenSubmitted ? 'secondary' : 'primary'}
        className="w-full pb-2"
        onPress={() => {
          submit();
        }}
        icon={
          isSubmitting ? (
            <svg>
              <CircularProgress isInverted={!hasBeenSubmitted} />
            </svg>
          ) : undefined
        }
        iconPosition="right"
      >
        {hasBeenSubmitted ? 'Resend to TMS' : 'Send to TMS'}
      </Button>
      <ExternalResource
        externalId={order.externalId ?? null}
        externalLink={order.externalDeepLink ?? null}
        handleExternalLinkPress={() => {
          track('Deep Link Clicked', {
            orderId: order.id,
            name: DeepLinkLocation.ORDER_REVIEW_FORM,
          });
        }}
        handleExternalIdPress={() => {
          track('Copy TMS Id Clicked', {
            orderId: order?.id,
          });
        }}
      />
    </div>
  );
}

export default SubmitOrder;
