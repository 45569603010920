import {
  ApplicationMessageList,
  ApplicationTeammate,
  ApplicationTeammateId,
  contextUpdates,
  SingleConversationContext,
} from '@frontapp/plugin-sdk';
import { WebViewContext } from '@frontapp/plugin-sdk/dist/webViewSdkTypes';
import { useEffect, useState } from 'react';

type FrontContextType =
  | 'singleConversation'
  | 'noConversation'
  | 'multipleConversations';
export type FrontContextT = {
  type?: FrontContextType;
  frontUser: ApplicationTeammate;
  conversationId: string;
  itemParticipants: string[];
  listMessages: () => Promise<ApplicationMessageList>;
  openThread?: (conversationId: string) => void;
};

export function useFrontContext() {
  const [context, setContext] = useState<FrontContextT>(buildEmptyContext());

  useEffect(() => {
    const subscription = contextUpdates.subscribe((frontContext) => {
      setContext(toFrontContext(frontContext));
    });
    return () => subscription.unsubscribe();
  }, []);

  return context;
}

function toSingleConversationContext(
  context: SingleConversationContext
): FrontContextT {
  const openThread = (conversationId: string) => {
    const url = `https://app.frontapp.com/open/${conversationId}`;
    context.openUrl(url);
  };

  return {
    type: 'singleConversation',
    frontUser: context.teammate,
    conversationId: context.conversation.id,
    itemParticipants: [],
    listMessages: context.listMessages,
    openThread,
  };
}

async function listEmptyMessages(): Promise<ApplicationMessageList> {
  const empty: ApplicationMessageList = {
    results: [],
    nextPageToken: undefined,
  };
  return empty;
}

function buildEmptyContext(type?: FrontContextType): FrontContextT {
  return {
    type,
    frontUser: {
      id: '' as ApplicationTeammateId,
      email: '',
      username: '',
      name: '',
    },
    conversationId: '',
    itemParticipants: [],
    listMessages: listEmptyMessages,
  };
}

function toFrontContext(context: WebViewContext): FrontContextT {
  // TODO(mike): Handle multiple conversations if necessary.
  switch (context.type) {
    case 'singleConversation':
      return toSingleConversationContext(context as SingleConversationContext);
    case 'noConversation':
    case 'multipleConversations':
    default:
      return buildEmptyContext(context.type);
  }
}
