import { StandardReferenceType } from '../../../../enums';
import { MaybeCompletedTemplateChildConfigs } from '../../../types';
import { ReferenceTemplate, WithValuesAndPropsRecursive } from '../../types';

type StopWithRefsStructure<ChildType> = {
  stopType?: ChildType;
  stopReferenceNumbers?: ReferenceTemplate<ChildType>[];
};

type TemplateWithRefsStructure<ChildType> = {
  orderReferenceNumbers?: ReferenceTemplate<ChildType>[];
  pickup?: StopWithRefsStructure<ChildType>;
  stops: StopWithRefsStructure<ChildType>[];
};

export type StopWithRefs = WithValuesAndPropsRecursive<
  StopWithRefsStructure<MaybeCompletedTemplateChildConfigs>,
  MaybeCompletedTemplateChildConfigs
>;

export type TemplateWithRefs = {
  _value: WithValuesAndPropsRecursive<
    TemplateWithRefsStructure<MaybeCompletedTemplateChildConfigs>,
    MaybeCompletedTemplateChildConfigs
  >;
};

export type ReferenceNumbersElementStructure<ChildType> =
  WithValuesAndPropsRecursive<ReferenceTemplate<ChildType>, ChildType>;

export type ReferenceNumbersElement =
  ReferenceNumbersElementStructure<MaybeCompletedTemplateChildConfigs>;

export type ExplicitReferenceSettings = {
  appearsAs?: string;
  hardCodeAs: string;
};

export type GenericReferenceSettings = {
  allowedReferenceTypes: StandardReferenceType[];
  template: ReferenceNumbersElement;
};

export type ReferenceSettings = {
  explicitReferences: ExplicitReferenceSettings[];
  genericReferences: GenericReferenceSettings[];
};

export enum StopConfigurationType {
  SINGLE_PICKUP_MULTI_DELIVERY = 'SINGLE_PICKUP_MULTI_DELIVERY',
  TYPED_STOP_ARRAY = 'TYPED_STOP_ARRAY',
  TYPED_STOP_ARRAY_EXPLICIT = 'TYPED_STOP_ARRAY_EXPLICIT',
}

export type TemplateReferenceSettings = {
  orderReferences: ReferenceSettings;
  stopConfiguration: StopConfigurationType;
  pickupReferences?: ReferenceSettings;
  deliveryReferences?: ReferenceSettings;
  stopReferences?: ReferenceSettings;
};
