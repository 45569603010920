import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { getObjectValue } from 'clerk_common/templates/getValue';
import {
  FieldMutationSync,
  mutateByFieldTypeSync,
} from 'clerk_common/templates/mutate/mutateSync';
import {
  CompletedTemplateChildConfigs,
  FormValueChild,
} from 'clerk_common/templates/types';
import { isEqual, isNil } from 'lodash';
import { EditableUnit } from '../types';

export type FormTemplateChildConfigs = CompletedTemplateChildConfigs & {
  // NOTE(max): Technically _form can actually be an AddressComponents
  // here, but we don't care about that for our purposes because
  // it doesn't affect the code in any important way here.
  _form?: string;
};

const shouldCorrectField = (child: FormTemplateChildConfigs): boolean => {
  if (!('_form' in child)) return false;
  if (isNil(child['_form'])) return false;

  const bestValue = getObjectValue(child);
  if (isEqual(child['_form'], bestValue)) return false;
  return true;
};

export const convertFormFieldToCorrected: FieldMutationSync = (
  child: FormTemplateChildConfigs
): FormTemplateChildConfigs => {
  if ('_form' in child) {
    if (shouldCorrectField(child)) {
      child['_corrected'] = child['_form'];
    }
    delete child['_form'];
  }
  return child;
};

export const renameFormToCorrected = (
  completedTemplate: CompletedFreightOrderTemplate
) => {
  return mutateByFieldTypeSync(completedTemplate as FormValueChild, {
    field: convertFormFieldToCorrected,
  }) as CompletedFreightOrderTemplate;
};

export const initFormStateWithBestValue: FieldMutationSync = (
  child: FormTemplateChildConfigs
): FormTemplateChildConfigs => {
  const bestValue = getObjectValue(child);
  child['_form'] = bestValue;
  return child;
};

export const initializeFormState = (data: EditableUnit) => {
  return mutateByFieldTypeSync(data as FormValueChild, {
    field: initFormStateWithBestValue,
  }) as EditableUnit;
};
