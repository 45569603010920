import { ApolloError } from '@apollo/client';
import { useMe } from '@shared/contexts/hooks/useMe';
import { useCreateQuoteNoteMutation } from '@shared/generated/graphql';
import { useState } from 'react';
import { MentionInput } from '../mention-input/MentionInput';
import { useToast } from '../toast';

interface Props {
  quoteId: string;
}

function NoteInput({ quoteId }: Props) {
  const { sendToast } = useToast();

  function onError(_e: ApolloError) {
    sendToast('Failed to add a note', { variant: 'error' });
  }
  function onCompleted() {
    setNewNote('');
  }

  const [createQuoteEvent, { loading }] = useCreateQuoteNoteMutation({
    onError,
    onCompleted,
    refetchQueries: ['QuoteEvents'],
  });

  const [newNote, setNewNote] = useState('');

  const { me } = useMe();

  function handleSubmit() {
    // NOTE(martin): we disable the button and check onEnter if me is undefined
    // but TS doesn't know that so we check here again
    if (me) {
      void createQuoteEvent({
        variables: {
          input: {
            quoteId,
            note: newNote,
            userId: me.id,
          },
        },
      });
    }
  }
  return (
    <>
      <MentionInput
        value={newNote}
        onChange={setNewNote}
        submit={{
          loading,
          isDisabled: !me?.id || !newNote,
          onSubmit: handleSubmit,
        }}
      />
    </>
  );
}

export default NoteInput;
