import { useQuoteEventsQuery } from '@shared/generated/graphql';
import { quoteEventFromFragment } from '@shared/graphql/fromFragments/quoteEvent';

interface Props {
  quoteId: string;
  pollInterval: number;
}

function useQuoteEvents({ quoteId, pollInterval }: Props) {
  const { data, loading } = useQuoteEventsQuery({
    variables: {
      id: quoteId ?? '',
    },
    pollInterval: pollInterval,
    skip: !quoteId,
  });

  const extractedData = data?.quoteById.quoteEvents
    ? quoteEventFromFragment(data)
    : null;

  return {
    ...extractedData,
    quoteExists: !!data?.quoteById,
    loading,
  };
}

export default useQuoteEvents;
