import { JSONDefinedForm } from '@shared/components';
import {
  initializeFormState,
  renameFormToCorrected,
} from '@shared/components/json-defined-form/stateManagement/form';
import {
  EditableJSON,
  EditableUnit,
} from '@shared/components/json-defined-form/types';
import { LocationEntity } from '@shared/components/json-defined-specialized-blocks';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import { useToast } from '@shared/components/toast';
import { useFlowsViewContext } from '@shared/contexts/FlowsViewContext';
import { OriginatorContext } from '@shared/contexts/OriginatorContext';
import {
  JobType,
  OrderDocument,
  OrderUpdateFailureReason,
  useUpdateOrderMutation,
  useUpdateTemplateMutation,
} from '@shared/generated/graphql';
import useOrderTemplate from '@shared/graphql/hooks/templates/useOrderTemplate';
import { usePlaceSidePanelContext } from '@shared/plugin/contexts/hooks/usePlaceSidePanelContext';
import { Order } from '@shared/types/order';
import { StyleProps } from '@shared/utils';
import { addDisplaySettings } from '@shared/utils/addDisplaySettings';
import { getRequiredFields } from '@shared/utils/forms/requiredFields';
import { getTemplateJson } from '@shared/utils/getTemplateJson';
import errorMessages from 'clerk_common/errors/errorMessages.json';
import {
  CompletedFreightOrderTemplate,
  MaybeCompletedFreightOrderTemplate,
  ORDER_REVIEW_FIELD_ORDERING_PRIORITIES,
} from 'clerk_common/templates/freight_order/types';
import { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SubmitOrder from './SubmitOrder';

export function CreateCustomSpecializedBlock(
  data: EditableUnit,
  prefix: string
) {
  const { viewPlace } = usePlaceSidePanelContext();
  const openLocation = (id: string) => {
    console.log('openLocation', id);
    viewPlace(id);
  };
  switch (data._type) {
    case 'LOCATION':
      return (
        <LocationEntity
          data={data as EditableJSON}
          prefix={prefix}
          openLocation={openLocation}
        />
      );
    default:
      return null;
  }
}

export type OrderReviewFormProps = StyleProps & {
  order: Order;
  data: EditableUnit;
  isLoading: boolean;
  isInTrainingMode: boolean;
  updateOrder: (data: EditableUnit) => Promise<void>;
  updateIsLoading: boolean;
  templateData: MaybeCompletedFreightOrderTemplate | null;
  handleUpdateTemplate: (
    data: MaybeCompletedFreightOrderTemplate
  ) => Promise<void>;
};

function OrderReviewForm(props: OrderReviewFormProps) {
  const p = { ...props };

  const { setOrganizationId, setOriginatorId, setExtractedData, setJobType } =
    useFlowsViewContext();
  useEffect(() => {
    setOrganizationId(p.order.organization.id);
    setOriginatorId(p.order.originator?.id);
    setExtractedData(p.data as CompletedFreightOrderTemplate);
  }, [p.order.organization.id, p.order.originator?.id, p.order.extractedData]);

  setJobType(JobType.ORDERS);

  const requiredFields = getRequiredFields(
    p.order.organization.integrationConfigs,
    p.order.originator.configOverrides
  );

  return (
    <OriginatorContext.Provider value={p.order.originator}>
      <JSONDefinedForm
        data={p.data}
        requiredFields={requiredFields}
        showDebugInfo={false}
        onSave={p.updateOrder}
        saveIsLoading={p.updateIsLoading}
        isInTrainingMode={p.isInTrainingMode}
        createCustomBlock={CreateCustomSpecializedBlock}
        orderingPriorities={ORDER_REVIEW_FIELD_ORDERING_PRIORITIES}
        handleUpdateTemplate={p.handleUpdateTemplate}
        templateData={p.templateData ?? undefined}
      />
    </OriginatorContext.Provider>
  );
}

export type OrderReviewFormContainerProps = StyleProps & {
  order: Order;
  isLoading: boolean;
};

export function OrderReviewFormContainer(
  p: OrderReviewFormContainerProps
): ReactElement {
  const extractedData = p.order?.extractedData ?? JSON.parse('{}');
  const data = addDisplaySettings(extractedData);
  const { sendToast } = useToast();
  const { template } = useOrderTemplate({
    originatorId: p.order?.originator.id,
  });
  const [updateTemplate] = useUpdateTemplateMutation();

  const convertFailureReason = (
    failureReason: OrderUpdateFailureReason
  ): string => {
    switch (failureReason) {
      case OrderUpdateFailureReason.DATA_CORRUPTED:
        return 'The form data is corrupt';
      default:
        return 'An unknown error occurred';
    }
  };

  const sendErrorToast = (reasons: OrderUpdateFailureReason[]) => {
    sendToast('Unable to update order', {
      description:
        reasons.map(convertFailureReason).join('\n') +
        `\n\n${errorMessages['errorSupportPostamble']}`,
      variant: 'error',
      isDismissible: true,
    });
  };

  const handleUpdateTemplate = async (
    data: MaybeCompletedFreightOrderTemplate
  ) => {
    if (!template?.id) return;
    await updateTemplate({
      variables: {
        input: {
          id: template.id,
          specializedTemplate: JSON.stringify(data, null, 2),
        },
      },
    }).catch((e) => sendErrorToast(e));
  };

  const methods = useForm<EditableUnit>({
    defaultValues: data,
  });
  useLoadInitialValues(methods.reset, data);

  const [updateOrderMutation, { loading: updateIsLoading }] =
    useUpdateOrderMutation();

  const updateOrder = async (data: EditableUnit) => {
    const dataWithCorrectedFields = renameFormToCorrected(
      data as CompletedFreightOrderTemplate
    );
    console.log('data', dataWithCorrectedFields);
    const result = await updateOrderMutation({
      variables: {
        input: {
          id: p.order?.id,
          extractedData: JSON.stringify(dataWithCorrectedFields),
        },
      },
      refetchQueries: [
        { query: OrderDocument, variables: { id: p.order?.id } },
      ],
    });

    const failureReasons = result.data?.updateOrder?.errors ?? [];
    if (failureReasons.length > 0) {
      sendErrorToast(failureReasons);
    }
  };

  useLoadInitialValues(methods.reset, data, initializeFormState);

  useEffect(() => {
    // Highlight missing fields when the user opens an order.
    void updateAndValidateForm();
  }, [p.order.id]);

  const updateAndValidateForm = async () => {
    const data = methods.getValues();
    await updateOrder(data);
    let result = false;
    await methods.trigger().then((isValid) => {
      result = isValid;
    });
    return result;
  };

  const templateData = getTemplateJson(template);

  return (
    <FormProvider {...methods}>
      <OrderReviewForm
        order={p.order}
        data={data}
        isLoading={p.isLoading}
        isInTrainingMode={false}
        updateIsLoading={updateIsLoading}
        updateOrder={updateOrder}
        handleUpdateTemplate={handleUpdateTemplate}
        templateData={templateData}
      />
      <SubmitOrder
        order={p.order}
        updateIsLoading={updateIsLoading}
        updateOrder={updateOrder}
        beforeSubmit={updateAndValidateForm}
      />
    </FormProvider>
  );
}

export default OrderReviewFormContainer;
