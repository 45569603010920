import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { ReactNode } from 'react';
import FieldErrorMessage, { FieldErrorMessageProps } from './FieldErrorMessage';

export const ROOT = makeRootClassName('ReactHookForm');
export const el = makeElementClassNameFactory(ROOT);

type FieldWrapperProps = FieldErrorMessageProps & {
  children: ReactNode;
  fieldWrapperClassName?: string;
};

/**
 * A shared utility component to wrap react-hook-form connected fields with
 * an error message.
 */
export const FieldWrapper = (props: FieldWrapperProps) => {
  return (
    <div className={clsx(el`field-wrapper`, props.fieldWrapperClassName)}>
      {props.children}
      <FieldErrorMessage error={props.error} />
    </div>
  );
};
