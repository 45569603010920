import { VOOMA_USER_EMAIL } from '@shared/constants';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import {
  QuoteEventType,
  useGetFeedQuoteEventsQuery,
} from '@shared/generated/graphql';
import { roundToNearestMinutes } from 'date-fns';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { FeedItemType, FeedQuoteEvent, QuoteEventsFeedItem } from '../types';

const groupQuoteEvents = (events: FeedQuoteEvent[]) =>
  groupBy(events, (item) => {
    const dateTime = new Date(item.createdAt);
    const roundedTime = roundToNearestMinutes(dateTime).toISOString();
    return `${item.type}-${roundedTime}`;
  });

const shouldIncludeEvent = (event: FeedQuoteEvent) =>
  event.type.startsWith('MARKED_AS') ||
  event.type === QuoteEventType.AUTO_REPLY_EVALUATOR_RESPONSE;

export const useFeedQuoteEvents = () => {
  const { threadJobs } = useEmailThreadContext();
  const jobIds = threadJobs.jobs.map((job) => job.id);

  const { data } = useGetFeedQuoteEventsQuery({
    variables: { input: { ids: jobIds } },
    pollInterval: 10 * 1000,
  });

  const events: QuoteEventsFeedItem[] = useMemo(() => {
    const quotes = data?.jobs.edges.flatMap((job) => job?.node?.quotes);
    const quoteEvents = quotes
      ?.flatMap((quote) => quote?.quoteEvents)
      .filter(shouldIncludeEvent);

    const feedQuoteEvents = quoteEvents
      ?.filter((qe) => {
        return qe.user?.email === VOOMA_USER_EMAIL;
      })
      .map((qe) => {
        return {
          createdAt: qe.createdAt,
          type: qe.type,
          quoteId: qe.quoteId,
          metadata: qe.metadata,
        } as FeedQuoteEvent;
      });

    if (!feedQuoteEvents) {
      return [];
    }

    const groupedEvents = groupQuoteEvents(feedQuoteEvents);

    return Object.values(groupedEvents).map((events) => {
      return {
        type: FeedItemType.QUOTE_EVENTS,
        createdAt: events[0].createdAt,
        createdByDisplay: 'Vooma',
        data: {
          quoteEvents: events,
        },
      };
    });
  }, [data]);

  return {
    events,
  };
};
