import { useEffect, useRef } from 'react';

type FeedScrollContainerProps = {
  children: React.ReactNode;
  className?: string;
  length: number;
};

/**
 * NOTE(parlato): Container used for feeds that should scroll to
 * bottom when new items are added.
 */
const FeedScrollContainer = ({
  children,
  className,
  length,
}: FeedScrollContainerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [length]);

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
};

export default FeedScrollContainer;
