import { Button } from '@shared/components';
import { initializeFormState } from '@shared/components/json-defined-form/stateManagement/form';
import { EditableUnit } from '@shared/components/json-defined-form/types';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import { QuoteDetailsDisplay } from '@shared/features/quote-details/QuoteDetailsDisplay';
import { Quote } from '@shared/types/quote';
import { StyleProps } from '@shared/utils';
import { addDisplaySettings } from '@shared/utils/addDisplaySettings';
import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { QuoteReviewDrawerComponent } from './QuoteReviewDrawerComponent';

export const DEFAULT_PROPS = {} as const;

export type QuoteReviewFormProps = StyleProps & {
  quote: Quote;
  isLoading: boolean;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export function QuoteReviewForm(props: QuoteReviewFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const extractedData = p.quote?.extractedData ?? JSON.parse('{}');
  const data = addDisplaySettings(extractedData);

  const methods = useForm<EditableUnit>({
    defaultValues: data,
  });

  useLoadInitialValues(methods.reset, data, initializeFormState);

  if (p.isEditing) {
    return (
      <FormProvider {...methods}>
        <QuoteReviewDrawerComponent
          setNotEditing={() => p.setIsEditing(false)}
          quote={p.quote}
          data={data}
          isLoading={p.isLoading}
          isInTrainingMode={false}
        />
      </FormProvider>
    );
  } else {
    return (
      <div className="relative">
        <QuoteDetailsDisplay quote={p.quote} />
        <div className="absolute top-0 flex w-full justify-end pr-2 pt-2">
          <Button
            className="z-[5]"
            onPress={() => p.setIsEditing(true)}
            isGhost
            icon={<MdEdit className="text-gray-700" />}
            size="xs"
          />
        </div>
      </div>
    );
  }
}

export default QuoteReviewForm;
