import { Text, Tooltip } from '@shared/components';
import { JobCard } from '@shared/plugin/pages/Home/components/JobCard';
import {
  formatFeedItemTime,
  formatFullDatetime,
} from '@shared/plugin/utils/datetime';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { FeedItemType, FeedItem as TFeedItem } from '../../types';
import { ConversationFeedItemPreview } from '../conversation/ConversationFeedItemPreview';
import { CreatedByWrapper } from '../created-by-wrapper/CreatedByWrapper';
import { MessageClassificationItem } from '../message-classification/MessageClassification';
import { QuoteEventItem } from '../quote-event/QuoteEvent';
import { SuggestedActionsItem } from '../suggested-actions/SuggestedActions';

type FeedItemProps = {
  item: TFeedItem;
};

const ROOT = makeRootClassName('FeedItem');
const el = makeElementClassNameFactory(ROOT);

export const FeedItemTimestamp = ({ timestamp }: { timestamp?: string }) => {
  if (!timestamp) {
    return null;
  }
  const receivedAt = new Date(timestamp);

  const formattedTimestamp = formatFeedItemTime(receivedAt);

  // TODO(parlato): hacky, do better
  if (formattedTimestamp === 'Just now') return null;

  return (
    <Tooltip content={formatFullDatetime(receivedAt)}>
      <div className={el`feed-item-timestamp`}>
        <Text type="custom">{formattedTimestamp}</Text>
      </div>
    </Tooltip>
  );
};

export const FeedItem = ({ item }: FeedItemProps) => {
  const renderItem = () => {
    switch (item.type) {
      case FeedItemType.SUGGESTED_ACTIONS:
        return <SuggestedActionsItem item={item} />;
      case FeedItemType.ACTION:
        return <div>Action unimplemented</div>;
      case FeedItemType.COMMENT:
        return <div>Comment unimplemented</div>;
      case FeedItemType.QUOTE_EVENTS:
        return <QuoteEventItem item={item} />;
      case FeedItemType.MESSAGE_CLASSIFICATION:
        return <MessageClassificationItem item={item} />;
      case FeedItemType.JOB_CARD:
        return (
          <CreatedByWrapper
            createdByDisplay={item.createdByDisplay}
            createdBy={item.createdBy}
            createdAt={item.createdAt}
            jobSummary={item.data.job}
          >
            <JobCard
              job={item.data.job}
              initialJobType={item.data.initialJobType}
            />
          </CreatedByWrapper>
        );
      case FeedItemType.CONVERSATION:
        return <ConversationFeedItemPreview item={item} />;
      case FeedItemType.EMPTY_STATE:
        return (
          <div className={el`empty-state`}>
            <Text type="body-sm">
              There is no activity yet.
              <br />
              Select an action for Vooma to get started.
            </Text>
          </div>
        );
      default:
        throw new Error(`Unknown feed item type: ${(item as any).type}`);
    }
  };

  return <div>{renderItem()}</div>;
};
