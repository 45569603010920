import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

import { Text } from '@shared/components';
import { QuoteEventType } from '@shared/generated/graphql';
import { uniq } from 'lodash';
import { PiSparkle } from 'react-icons/pi';
import { FeedQuoteEvent, QuoteEventsFeedItem } from '../../types';
import { FeedItemTimestamp } from '../feed-item/FeedItem';

const ROOT = makeRootClassName('QuoteEvents');
const el = makeElementClassNameFactory(ROOT);

const getActivityLabel = (item: QuoteEventsFeedItem) => {
  const event = item.data.quoteEvents[0];
  const type = event.type;
  const createdByDisplay = item.createdByDisplay;
  const count = uniq(
    item.data.quoteEvents.map((event) => event.quoteId)
  ).length;

  if (count === 0) return;

  const markAsString = `marked ${count == 1 ? 'a' : count} quote${
    count <= 1 ? '' : 's'
  }`;

  switch (type) {
    case QuoteEventType.MARKED_AS_LOST:
      return `${createdByDisplay} ${markAsString} lost`;
    case QuoteEventType.MARKED_AS_WON:
      return `${createdByDisplay} ${markAsString} won`;
    case QuoteEventType.MARKED_AS_QUOTED:
      return `${createdByDisplay} ${markAsString} quoted`;
    case QuoteEventType.AUTO_REPLY_EVALUATOR_RESPONSE:
      return `Vooma decided ${
        event.metadata?.decision ? 'to auto reply' : 'not to auto reply'
      }`;
    default:
      return undefined;
  }
};

type QuoteEventsFeedItemProps = {
  item: QuoteEventsFeedItem;
};
export const QuoteEventItem = ({ item }: QuoteEventsFeedItemProps) => {
  const renderQuoteEventItem = (events: FeedQuoteEvent[]) => {
    const activityLabel = getActivityLabel(item);

    if (!activityLabel) {
      return null;
    }

    return (
      <div>
        <div className={el`quote-event-details`}>
          {activityLabel ? (
            <div className={el`quote-event`}>
              <PiSparkle size={14} className={el`quote-event-icon`} />
              <Text type="body-xs">{activityLabel}</Text>
            </div>
          ) : null}
          <FeedItemTimestamp timestamp={events[0].createdAt} />
        </div>
      </div>
    );
  };

  return (
    <div className={el`quote-event-root`}>
      {renderQuoteEventItem(item.data.quoteEvents)}
    </div>
  );
};
