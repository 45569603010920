import {
  GetQuoteRateFormDocument,
  QuoteDocument,
  useUpdateQuoteMutation,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';

type UseSetSellRateIdProps = {
  quote: Quote;
};

export function useSetSellRateId(p: UseSetSellRateIdProps) {
  const [updateQuote, { loading }] = useUpdateQuoteMutation();

  const setSellRateId = async (
    customerRateId: string,
    carrierRateId: string | null
  ) => {
    const input = { id: p.quote.id, customerRateId, carrierRateId };
    const refetchQueries = [
      { query: QuoteDocument, variables: { id: p.quote.id } },
      GetQuoteRateFormDocument,
    ];
    await updateQuote({ variables: { input }, refetchQueries });
  };

  return { setSellRateId, loading };
}
