import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const TOKEN_KEY = 'vooma-auth0-token';

export type AuthContextType = {
  token?: string;
  isAuthenticated: boolean;
  logout: () => void;
  setToken: (token?: string) => void;
  loading: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  token: undefined,
  isAuthenticated: false,
  logout: () => null,
  setToken: () => null,
  loading: true,
});

export type OpenLoginDialogProps = {
  handleSuccessfulAuth: (data: { token: string }) => void;
  loginUrl: string;
};

type AuthContextProviderProps = {
  children: JSX.Element;
  loginUrl: string;
  authLogout?: () => void;
};

export const AuthContextProvider = ({
  children,
  authLogout,
}: AuthContextProviderProps) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [ready, setReady] = useState(true);

  const navigate = useNavigate();

  const handleSetToken = (token?: string) => {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    }
    setIsAuthenticated(Boolean(token));
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    setIsAuthenticated(false);
    authLogout?.();
    setToken(undefined);
    navigate('/login-prompt');
  };

  useEffect(() => {
    // Set token from local storage on initial load
    const localStorageToken = localStorage.getItem(TOKEN_KEY);
    if (localStorageToken) {
      handleSetToken(localStorageToken);
    }
    setReady(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logout,
        isAuthenticated,
        token,
        setToken: handleSetToken,
        loading: ready,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
