import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

const ROOT = makeRootClassName('WeightFields');
export const el = makeElementClassNameFactory(ROOT);

type WeightData = {
  weight: EditableJSONField;
  units: EditableJSONField;
};

export type WeightFieldsData = {
  _value: WeightData;
};

type WeightFieldsProps = {
  data: WeightFieldsData;
  prefix?: string;
};

export function WeightFields({ data, prefix }: WeightFieldsProps) {
  const weightData = getObjectValue(data).weight;
  const weightUnitsData = getObjectValue(data).units;
  if (!weightData || !weightUnitsData) {
    console.warn(
      'Block is designated as a weight block but does not have weight or units data.'
    );
    return null;
  }
  return (
    <div className={ROOT}>
      <FormField data={weightData} prefix={`${prefix}_value.weight.`} />
      <FormField data={weightUnitsData} prefix={`${prefix}_value.units.`} />
    </div>
  );
}

export function createWeightFieldsElement(): WeightData {
  const defaultFields = {
    _value: '',
    _logProbs: [],
  };

  return {
    weight: {
      ...defaultFields,
      _corrected: '',
      _display: {
        name: 'Weight',
      },
    },
    units: {
      ...defaultFields,
      _corrected: '',
      _display: {
        name: 'Units',
      },
    },
  };
}
