import { CircularProgress } from '@shared/components/plugin-components';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import useQuoteEvents from './hooks/useQuoteEvents';

import NoteInput from '@shared/components/note-input/NoteInput';
import { useToast } from '@shared/components/toast';
import AuditTrailEvent from '@shared/components/trail/AuditTrailEvent';
import AuditTrailLaneSnippet from '@shared/components/trail/AuditTrailLaneSnippet';
import { IntercomSupportContext } from '@shared/contexts/IntercomSupportContext';
import { useContext, useEffect, useRef } from 'react';

const POLLING_INTERVAL = 2 * 1000;

interface Props {
  quoteIdArg?: string;
}

function QuoteAuditTrail({ quoteIdArg }: Props) {
  const { displayLauncher } = useContext(IntercomSupportContext);
  const { sendToast } = useToast();
  const { quoteId: quoteIdParam } = useParams();

  useEffect(() => {
    displayLauncher(false);
    return () => displayLauncher(true);
  }, []);

  const bottomScrollTarget = useRef<HTMLDivElement | null>(null);

  const eventsCount = useRef<number>(0);

  const quoteId = quoteIdArg || quoteIdParam;

  const {
    quoteEvents,
    route,
    originatorName,
    pickupDate,
    loading: loadingQuote,
    quoteExists,
  } = useQuoteEvents({
    quoteId: quoteId ?? '',
    pollInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    if (quoteIdParam === 'undefined' || (!loadingQuote && !quoteExists)) {
      sendToast("This email links to an entity that doesn't exist", {
        variant: 'info',
      });
    }
  }, [quoteIdParam, quoteExists, loadingQuote]);

  useEffect(() => {
    if (quoteEvents?.length && eventsCount.current < quoteEvents?.length) {
      bottomScrollTarget.current?.scrollIntoView(true);
      eventsCount.current = quoteEvents?.length || 0;
    }
  }, [quoteEvents]);

  if (loadingQuote || !quoteId) {
    return (
      <div className="flex h-1/3 w-full items-center justify-center">
        <CircularProgress size="md" />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col justify-between bg-white px-5">
      <div className="overflow-scroll overflow-x-hidden">
        {route && originatorName && (
          <AuditTrailLaneSnippet
            route={route}
            originatorName={originatorName}
            pickupDate={pickupDate || ''}
          />
        )}
        {quoteEvents &&
          quoteEvents.map((event, idx) => (
            <div key={`quote-event-item-${idx}`} className="py-1">
              <AuditTrailEvent
                time={format(event.timestamp, 'HH:mm, MMM d')}
                message={event.value || ''}
                creator={event.creator}
                type={event.type}
              />
            </div>
          ))}
        <div ref={bottomScrollTarget}></div>
      </div>
      <div className="sticky bottom-0 w-full bg-white pb-2 pt-2">
        <NoteInput quoteId={quoteId} />
      </div>
    </div>
  );
}

export default QuoteAuditTrail;
