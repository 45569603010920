import { Text } from '@shared/components';
import { useFeedContext } from '@shared/plugin/contexts/FeedContext';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { enumToString } from 'clerk_common/enums';
import clsx from 'clsx';
import { useMemo } from 'react';
import { FeedFilterOption, FeedItemType } from '../../types';

const ROOT = makeRootClassName('FeedFilter');
const el = makeElementClassNameFactory(ROOT);

export const FeedFilter = () => {
  const {
    feed: { items },
    filter,
  } = useFeedContext();

  const { selectedOption, setSelectedOption, filterOptions } = filter;

  const quoteCount = useMemo(() => {
    return items.reduce((acc, item) => {
      if (item.type === FeedItemType.JOB_CARD) {
        return acc + item.data.job.quotes.length;
      }
      return acc;
    }, 0);
  }, [items]);

  const orderCount = useMemo(() => {
    return items.reduce((acc, item) => {
      if (item.type === FeedItemType.JOB_CARD) {
        return acc + item.data.job.orders.length;
      }
      return acc;
    }, 0);
  }, [items]);

  const renderCount = (option: FeedFilterOption) => {
    switch (option) {
      case FeedFilterOption.QUOTES:
        return (
          <Text type="body-xs" className={el`count`}>
            {quoteCount}
          </Text>
        );
      case FeedFilterOption.ORDERS:
        return (
          <Text type="body-xs" className={el`count`}>
            {orderCount}
          </Text>
        );
      case FeedFilterOption.ACTIVITY:
      default:
        return null;
    }
  };

  const getIsDisabled = (option: FeedFilterOption) => {
    switch (option) {
      case FeedFilterOption.QUOTES:
        return quoteCount === 0;
      case FeedFilterOption.ORDERS:
        return orderCount === 0;
      default:
        return false;
    }
  };

  return (
    <div className={el`feed-filter-root`}>
      {filterOptions.map((option) => {
        const isSelected = selectedOption === option;
        const isDisabled = getIsDisabled(option);
        return (
          <div
            key={option}
            className={clsx(
              el`option`,
              isSelected && 'selected',
              isDisabled && 'disabled',
              option === FeedFilterOption.ACTIVITY && 'activity'
            )}
            onClick={isDisabled ? undefined : () => setSelectedOption(option)}
          >
            <Text type="body-xs">{enumToString(option)}</Text>
            {renderCount(option)}
          </div>
        );
      })}
    </div>
  );
};
