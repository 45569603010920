import { AnalyticsContext } from '@shared/contexts/AnalyticsContext';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  NavigatorFields,
  PageProperties,
  TrackProperties,
  usePageMutation,
  useTrackMutation,
} from '@shared/generated/graphql';
import { useCurrentClientVersion } from '@shared/utils/version';
import { useContext } from 'react';

export type AnalyticsActions = {
  track: (event: string, properties?: TrackProperties) => void;
  page: (name: string, properties?: PageProperties) => void;
};

export type AnalyticsState = {
  loadingTrack: boolean;
  loadingPage: boolean;
};

const getNavigatorFields = (): NavigatorFields | undefined => {
  if (typeof window === 'undefined') return;

  return {
    appName: window.navigator?.appName,
    appVersion: window.navigator?.appVersion,
    language: window.navigator?.language,
    platform: window.navigator?.platform,
    userAgent: window.navigator?.userAgent,
    vendor: window.navigator?.vendor,
  };
};

export const useAnalytics = (): AnalyticsActions & AnalyticsState => {
  const { defaultOrgName, defaultOrgId } = useMeContext();
  const { apiClient } = useContext(AnalyticsContext);
  const [track, { loading: loadingTrack }] = useTrackMutation();
  const [page, { loading: loadingPage }] = usePageMutation();
  const clientVersion = useCurrentClientVersion();

  const navigator = getNavigatorFields();

  const handleTrack = (event: string, properties?: TrackProperties) =>
    track({
      variables: {
        input: {
          event,
          properties: {
            organizationId: defaultOrgId ?? undefined,
            organizationName: defaultOrgName ?? undefined,
            ...properties,
          },
          context: {
            apiClient,
            clientVersion,
            navigator,
          },
        },
      },
    });

  const handlePage = (name: string, properties?: PageProperties) => {
    const location = window.location;
    page({
      variables: {
        input: {
          name,
          properties: {
            url: location.pathname + location.search,
            organizationId: defaultOrgId ?? undefined,
            organizationName: defaultOrgName ?? undefined,
            ...properties,
          },
          context: {
            apiClient,
            clientVersion,
            navigator,
          },
        },
      },
    });
  };

  return {
    page: handlePage,
    track: handleTrack,
    loadingPage,
    loadingTrack,
  };
};
