import {
  CreateTransformationConfigMutation,
  FlowTrigger,
  GetTransformationDefinitionsQuery,
  GetWorkflowsDocument,
  GetWorkflowsQuery,
  useCreateTransformationConfigMutation,
  useCreateTransformationDefinitionMutation,
  useCreateWorkflowMutation,
  useGetTransformationDefinitionsQuery,
  useGetWorkflowsQuery,
  useUpdateWorkflowMutation,
} from '@shared/generated/graphql';
import { ScalarMap, TransformationDef } from 'clerk_common';

export type WorkflowNode = GetWorkflowsQuery['workflows']['edges'][0]['node'];
export type TransformationConfigNode =
  CreateTransformationConfigMutation['createTransformationConfig']['transformationConfig'];
export type TransformationDefinitionNode =
  GetTransformationDefinitionsQuery['transformationDefinitions']['edges'][0]['node'];

export type UseManageFlowsResp = {
  orgWorkflows: WorkflowNode[];
  origWorkflows: WorkflowNode[];
  definitions: TransformationDefinitionNode[];
  createWorkflow: (trigger: FlowTrigger) => Promise<WorkflowNode | undefined>;
  updateWorkflow: (id: string, transformationConfigIds: string[]) => void;
  createTransformationConfig: (
    transformationDefinitionId: string,
    params: ScalarMap
  ) => Promise<TransformationConfigNode | undefined>;
  createTransformationDefinition: (
    definition: TransformationDef
  ) => Promise<TransformationDefinitionNode | undefined>;
};

export function useManageFlows(
  organizationId?: string,
  originatorId?: string
): UseManageFlowsResp {
  const orgWorkflowResp = useGetWorkflowsQuery({
    variables: {
      input: {
        organizationId: organizationId,
      },
    },
    pollInterval: 10000,
  });
  const orgWorkflows =
    orgWorkflowResp.data?.workflows.edges.map((e) => e.node) ?? [];

  const origWorkflowResp = useGetWorkflowsQuery({
    variables: {
      input: {
        originatorId: originatorId,
        organizationId: organizationId,
      },
    },
    pollInterval: 10000,
  });
  const origWorkflows =
    origWorkflowResp.data?.workflows.edges.map((e) => e.node) ?? [];

  const [createWorkflowMut] = useCreateWorkflowMutation();
  const createWorkflow = async (trigger: FlowTrigger) => {
    if (!organizationId) {
      console.error('No organizationId provided');
      return;
    }

    const res = await createWorkflowMut({
      variables: {
        input: {
          originatorId: originatorId ?? null,
          organizationId: organizationId,
          trigger,
          transformationConfigIds: [],
        },
      },
      refetchQueries: [GetWorkflowsDocument],
    });

    return res.data?.createWorkflow.workflow ?? undefined;
  };

  const [updateWorkflowMut] = useUpdateWorkflowMutation();
  const updateWorkflow = (id: string, transformationConfigIds: string[]) => {
    updateWorkflowMut({
      variables: {
        input: {
          id,
          transformationConfigIds,
        },
      },
      refetchQueries: [GetWorkflowsDocument],
    });
  };

  const [createTransformationConfigMut] =
    useCreateTransformationConfigMutation();
  const createTransformationConfig = async (
    transformationDefinitionId: string,
    params: ScalarMap
  ): Promise<TransformationConfigNode | undefined> => {
    if (!organizationId) {
      console.error('No organizationId provided');
      return;
    }

    const tConfig = await createTransformationConfigMut({
      variables: {
        input: {
          originatorId: originatorId,
          organizationId: organizationId,
          transformationDefinitionId,
          params,
        },
      },
    });

    return (
      tConfig.data?.createTransformationConfig.transformationConfig ?? undefined
    );
  };

  const [createTransformationDefMut] =
    useCreateTransformationDefinitionMutation();
  const createTransformationDefinition = async (
    definition: TransformationDef
  ): Promise<TransformationDefinitionNode | undefined> => {
    if (!organizationId) {
      console.error('No organizationId provided');
      return;
    }

    const res = await createTransformationDefMut({
      variables: {
        input: {
          organizationId: organizationId,
          originatorId: originatorId,
          definition,
        },
      },
    });

    return (
      res.data?.createTransformationDefinition.transformationDefinition ??
      undefined
    );
  };

  const { data: definitionsData } = useGetTransformationDefinitionsQuery({
    variables: {
      input: {
        originatorIds: originatorId ? [originatorId, null] : [],
        organizationIds: [organizationId ?? null, null],
      },
    },
  });
  const definitions =
    definitionsData?.transformationDefinitions.edges.map((e) => e.node) ?? [];

  return {
    orgWorkflows,
    origWorkflows,
    definitions,
    createWorkflow,
    updateWorkflow,
    createTransformationConfig,
    createTransformationDefinition,
  };
}
