import { createContext, useContext } from 'react';

type API = {
  url: string;
  headers: { [key: string]: string };
};
export type APIContextType = {
  apiResolver: (type?: ApiReqType) => API;
};

export const APIContext = createContext<APIContextType>(buildEmptyContext());

export enum ApiReqType {
  PLACES = 'PLACES',
}
export function useAPIContext(type?: ApiReqType) {
  const context = useContext(APIContext);

  if (!context) {
    throw new Error('APIContext must be used within a APIContextProvider');
  }
  return context.apiResolver(type);
}

function buildEmptyContext(): APIContextType {
  return {
    apiResolver: () => ({ url: '', headers: {} }),
  };
}
