import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { RateError } from '../RateError/RateError';

const ROOT = makeRootClassName('WidgetBody');
const el = makeElementClassNameFactory(ROOT);

type WidgetBodyProps = {
  children: React.ReactNode;
  integrationName: string;
  rateError?: string;
};

export function WidgetBody({
  children,
  integrationName,
  rateError,
}: WidgetBodyProps) {
  return (
    <div className={el`details`}>
      {rateError && (
        <div className={el`rate-error-container`}>
          <RateError
            errorMessage={rateError}
            integrationName={integrationName}
            fetchedEntity="rates"
          />
        </div>
      )}
      {children}
    </div>
  );
}
