import {
  MessageQuoteAssociation,
  useMessageQuoteAssociationsQuery,
} from '@shared/generated/graphql';

const MESSAGE_QUOTE_ASSOCIATION_POLLING_FREQUENCY_MS = 5 * 1000;

export const useMessageQuoteAssociations = (messageId: string) => {
  const { data } = useMessageQuoteAssociationsQuery({
    variables: {
      input: {
        messageIds: [messageId],
      },
    },
    pollInterval: MESSAGE_QUOTE_ASSOCIATION_POLLING_FREQUENCY_MS,
  });

  if (!data?.messageQuoteAssociations?.nodes?.length) return [];
  return data.messageQuoteAssociations.nodes as MessageQuoteAssociation[];
};
