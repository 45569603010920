import { Button, FormDisplayTableRow } from '@shared/components';
import { Text } from '@shared/components/text';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { User } from '@shared/types/user';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { getUserFeatureDisplayName } from '@shared/utils/userFeatures/getUserFeatureDisplayName';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';

export type UserFeaturesDisplayProps = StyleProps & {
  onEdit: () => void;
  user?: User;
  isCompact?: boolean;
};

const ROOT = makeRootClassName('UserFeaturesDisplay');
const el = makeElementClassNameFactory(ROOT);

function UserFeaturesDisplay(props: UserFeaturesDisplayProps): ReactElement {
  const { defaultOrgId } = useMeContext();
  const userFeatures = props.user?.features?.features || [];
  const featureNames = userFeatures
    .filter((f) => f.enabled && defaultOrgId === f.organizationId)
    .map((f) => getUserFeatureDisplayName(f.name))
    .join(', ');
  return (
    <div className={clsx(ROOT, props.className)}>
      <div className={el`content`}>
        <div className={el`user-features`}>
          <Text type="body-md" isHeavy>
            Features
          </Text>
          <Button
            icon={<AiOutlineEdit size={20} />}
            size="small"
            isGhost
            onPress={() => props.onEdit()}
          />
        </div>
        <FormDisplayTableRow
          displayText="Enabled features"
          value={featureNames || 'None'}
        />
      </div>
    </div>
  );
}

export default UserFeaturesDisplay;
