// NOTE(jacob): This is a direct copy from the upstream at
// https://github.com/gregberge/react-merge-refs. I've copied it here
// to get around a weird Jest issue that I don't want to spend time on.
// This dependency is extremely small and seems quite stable, so I don't
// think we'll add any extra maintenance overhead by copying it here.

import type * as React from 'react';

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
