import { parseIdx } from 'clerk_common/templates/getValue';
import { useFormContext } from 'react-hook-form';
import { EditableJSONObject, EditableUnit } from './types';

export type ArrayBlockData = {
  _value: EditableJSONObject[];
  _added?: EditableJSONObject[];
  _idxs?: string[];
};

type UseArrayBlockProps = {
  data: ArrayBlockData;
  prefix?: string;
  save: () => void;
};

export function useArrayBlock({ data, prefix, save }: UseArrayBlockProps) {
  const { setValue } = useFormContext<ArrayBlockData>();

  const setIdxs = (idxs: string[]) => {
    setValue(`${prefix}_idxs` as '_idxs', idxs);
    save();
  };

  const getIdxs = (): string[] => {
    return data._idxs ?? getAllIdxs(data);
  };

  const addIdx = (idx: string) => {
    const idxs = getIdxs();
    if (idxs.includes(idx)) return;
    setIdxs([...idxs, idx]);
  };

  const removeElement = (provenance: string, idx: number) => {
    const idxToRemove = `${provenance}.${idx}`;
    const idxs = getIdxs();
    if (!idxs.includes(idxToRemove)) return;
    setIdxs(idxs.filter((i) => i !== idxToRemove));
  };

  const addElement = (element: EditableJSONObject) => {
    const added = data._added ?? [];
    const idx = added.length;
    setValue(`${prefix}_added` as '_added', [...added, element]);
    addIdx(`_added.${idx}`);
    save();
  };

  const createElement = () => createEmptyElement(data);

  const elementsToDisplay = getElementsToDisplay(data);
  return {
    elementsToDisplay,
    addElement,
    removeElement,
    createElement,
  };
}

type ArrayBlockElement = {
  element: EditableJSONObject;
  idx: number;
  provenance: '_value' | '_added';
};

function getElementsByIdx(
  original: ArrayBlockElement[],
  added: ArrayBlockElement[],
  idxs: string[]
): ArrayBlockElement[] {
  const elements: ArrayBlockElement[] = [];
  idxs.forEach((i) => {
    const { provenance, idx } = parseIdx(i);
    if (provenance === '_value') {
      elements.push(original[idx]);
    } else if (provenance === '_added') {
      elements.push(added[idx]);
    }
  });
  return elements;
}

function getElementsToDisplay(data: ArrayBlockData): ArrayBlockElement[] {
  const original = getOriginalElements(data);
  const addedElements = getAddedElements(data);
  if (data._idxs === undefined) {
    return [...original, ...addedElements];
  }
  return getElementsByIdx(original, addedElements, data._idxs);
}

function getOriginalElements(data: ArrayBlockData): ArrayBlockElement[] {
  return data._value.map((e, idx) => ({
    element: e,
    idx,
    provenance: '_value' as const,
  }));
}

function getAddedElements(data: ArrayBlockData): ArrayBlockElement[] {
  return (
    data._added?.map((e, idx) => ({
      element: e,
      idx,
      provenance: '_added' as const,
    })) ?? []
  );
}

function getAllElements(data: ArrayBlockData): ArrayBlockElement[] {
  return [...getOriginalElements(data), ...getAddedElements(data)];
}

function getAllIdxs(data: ArrayBlockData): string[] {
  return getAllElements(data).map((e) => `${e.provenance}.${e.idx}`);
}

function createEmptyElement(data: ArrayBlockData) {
  if (data._value.length == 0) {
    alert('Cannot create empty element without knowing the structure');
  }
  const copy = JSON.parse(JSON.stringify(data._value[0]));
  const ret = clearAllValuesInObject(copy);
  return ret;
}

function clearAllValuesInObject(obj: EditableJSONObject): EditableJSONObject {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, clearAllValues(value)];
    })
  );
}
export function clearAllValues(obj: EditableUnit): EditableUnit {
  if (typeof obj._value === 'string') {
    return {
      _display: obj._display,
      _type: obj._type,
      _value: '',
      _corrected: '',
      _logProbs: [],
      _sanitized: '',
      _transformed: '',
      _entity: '',
    };
  }
  if (Array.isArray(obj._value)) {
    return {
      _display: obj._display,
      _type: obj._type,
      _value: obj._value.map((v) => clearAllValuesInObject(v)),
    };
  }
  return {
    _display: obj._display,
    _type: obj._type,
    _value: clearAllValuesInObject(obj._value as EditableJSONObject),
  };
}
