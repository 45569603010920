import { ExternalMessage } from '@shared/generated/graphql';
import { ExternalMessageClassification } from 'clerk_common';
import { isValidType } from './hasProbableClass';

export const findMaxConfidenceClass = (
  message: Pick<ExternalMessage, 'classifications'>
): ExternalMessageClassification | null => {
  let idx = -1;
  let maxConfidence = -1;
  message.classifications.classes.forEach(
    (c: ExternalMessageClassification, i: number) => {
      if (isValidType(c) && c?.confidence && c?.confidence > maxConfidence) {
        idx = i;
        maxConfidence = c.confidence;
      }
    }
  );

  if (idx === -1) return null;

  return message.classifications.classes[idx];
};
