import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  Icon,
  InteractableComponent,
  TextFieldProps,
} from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { TimeDisplayStyle } from '@shared/generated/graphql';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { KeyboardEvent, ReactElement } from 'react';
import { X } from 'react-feather';

export type TimeSelectProps = StyleProps & {
  name: string;
  value?: string;
  onChange?: (newTime: string) => void;
  onBlur?: () => void;
  step?: number;
  onKeyDown?: (e: KeyboardEvent) => void;
} & Pick<
    TextFieldProps,
    | 'isRequired'
    | 'isDisabled'
    | 'label'
    | 'validationState'
    | 'wrapperChildren'
    | 'wrapperChildrenPlacement'
  >;

// TODO(jacob): There might be a cleaner way to override the MUI styles,
// but need to keep moving.
const MUI_STYLE_OVERRIDES = {
  '& .MuiInputBase-input': {
    padding: '8px',
  },
  '& .MuiInputBase-root': {
    'font-size': '0.75rem',
    padding: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    visibility: 'hidden',
  },
};

const ROOT = makeRootClassName('TimeSelect');
const el = makeElementClassNameFactory(ROOT);

export function TimeSelect(p: TimeSelectProps): ReactElement {
  const { me } = useMeContext();

  const handleInputChange = (val: Moment | null) => {
    const strVal = val?.format('HH:mm');
    p.onChange?.(strVal ?? '');
  };

  const onClear = () => {
    handleInputChange(null);
    p.onBlur?.();
  };

  const momentValue = moment(p.value, 'HH:mm');
  const minutesStep = (p.step ?? 60) / 60;

  const timeDisplayStyle =
    me?.settings?.timeDisplayStyle ?? TimeDisplayStyle.TIME_12H;

  const validationClasses = {
    'is-required': p.isRequired,
    'is-valid': p.validationState === 'valid',
    'is-invalid': p.validationState === 'invalid',
  };

  return (
    <div className={clsx(ROOT, validationClasses)}>
      {p.label && (
        <label className={el`label`}>
          {p.isRequired && <div className={el`label-required-indicator`} />}
          <span className={el`label-span`}>{p.label}</span>
        </label>
      )}
      <div className={el`wrapper`}>
        <div className={el`time-picker`}>
          {
            // TODO(jacob): Should we lift the LocalizationProvider to a higher
            // component?
          }
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{
              fieldHoursPlaceholder: () => '--',
              fieldMinutesPlaceholder: () => '--',
              fieldMeridiemPlaceholder: () => '--',
            }}
          >
            <TimeField
              name={p.name}
              ampm={timeDisplayStyle === TimeDisplayStyle.TIME_12H}
              value={momentValue}
              onChange={handleInputChange}
              className="w-full"
              minutesStep={minutesStep}
              sx={MUI_STYLE_OVERRIDES}
              onKeyDown={p.onKeyDown}
              inputProps={{
                onBlur: p.onBlur,
              }}
            />
          </LocalizationProvider>
          <InteractableComponent
            className={el`clear-button`}
            onPress={onClear}
            tabIndex={-1}
          >
            <Icon size="custom" className={el`clear-icon`} content={<X />} />
          </InteractableComponent>
          {p.wrapperChildrenPlacement === 'inside' && p.wrapperChildren}
        </div>
        {p.wrapperChildrenPlacement === 'outside' && p.wrapperChildren}
      </div>
    </div>
  );
}
export default TimeSelect;
