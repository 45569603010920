import { useInterval } from '@shared/hooks/useInterval';
import { useState } from 'react';

export const VERSION_FILE = '/version.txt';
const VERSION_LOCAL_STORAGE_KEY = 'clientVersion';
export const DEFAULT_VERSION_CHECK_INTERVAL = 1000 * 10; // 10 seconds

export const getServerClientVersion = async () => {
  /* eslint no-restricted-syntax: 0 */
  const res = await fetch(VERSION_FILE);
  if (!res.ok) return;
  return res.text();
};

export const checkClientVersion = async () => {
  const clientVersion = await getServerClientVersion();

  if (!clientVersion) {
    console.log('Missing version file...');
    return;
  }
  const currentClientVersion = localStorage.getItem(VERSION_LOCAL_STORAGE_KEY);
  if (clientVersion !== currentClientVersion) {
    window.location.reload();
    localStorage.setItem(VERSION_LOCAL_STORAGE_KEY, clientVersion);
  }
};

export const useCurrentClientVersion = () => {
  const [clientVersion, setClientVersion] = useState<string | undefined>();

  useInterval(() => {
    const storedClientVersion = localStorage.getItem(VERSION_LOCAL_STORAGE_KEY);
    setClientVersion(storedClientVersion ?? undefined);
  }, DEFAULT_VERSION_CHECK_INTERVAL);

  return clientVersion;
};
