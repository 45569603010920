import { AssigneeOptionVariant } from '@shared/components/dropdown-chip/DropdownChip';
import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { ReactElement } from 'react';

export type TextChipProps = StyleProps & {
  text: string;
  variant?: AssigneeOptionVariant;
};

const ROOT = makeRootClassName('TextChip');

const DEFAULT_PROPS = {} as const;

function TextChip(props: TextChipProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div
      className={clsx(
        `${ROOT}`,
        p.className,
        `variant-${AssigneeOptionVariant[p.variant || 0]}`
      )}
    >
      {capitalize(p.text)}
    </div>
  );
}

export default TextChip;
