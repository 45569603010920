import { JobSummaryFragment } from '@shared/generated/graphql';
import { useFeedContext } from '@shared/plugin/contexts/FeedContext';
import { SuggestedActionsItem } from '@shared/plugin/features/Feed/components/suggested-actions/SuggestedActions';
import { FeedItemType } from '@shared/plugin/features/Feed/types';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

type JobCardActionsProps = {
  job: JobSummaryFragment;
};

const ROOT = makeRootClassName('JobCardActions');
const el = makeElementClassNameFactory(ROOT);

export const JobCardActions = ({ job }: JobCardActionsProps) => {
  const {
    feed: { getJobSuggestedActions },
  } = useFeedContext();

  const suggestedActions = getJobSuggestedActions(job);

  return (
    <div className={el`job-card-actions-root`}>
      <SuggestedActionsItem
        item={{
          type: FeedItemType.SUGGESTED_ACTIONS,
          data: { actions: suggestedActions },
        }}
        isCompact
      />
    </div>
  );
};
