import { Text } from '@shared/components';
import { StyleProps } from '@shared/utils';
import { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';
import { el } from './shared';

export type FieldErrorMessageProps = StyleProps & {
  /**
   * The field error useController. If undefined, the component
   * will still take visual space but will not render any error message.
   */
  error: FieldError | undefined;
};

function FieldErrorMessage(props: FieldErrorMessageProps): ReactElement {
  return (
    <Text type="body-sm" className={el`field-error-message`}>
      {props.error?.message ?? ''}
    </Text>
  );
}

export default FieldErrorMessage;
