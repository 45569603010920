import { Button } from '@shared/components/button';
import { InteractableComponent } from '@shared/components/interactable-component';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { Trash } from 'react-feather';
import { PiPlus } from 'react-icons/pi';
import {
  isParentOfRequiredField,
  isRequiredField,
} from '../../fields/fieldLogic';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableJSONObject } from '../../types';
import { useArrayBlock } from '../../useArrayBlock';

const ROOT = makeRootClassName('SimpleArray');
export const el = makeElementClassNameFactory(ROOT);

export function makeDisplayData(idx: number, title: string) {
  if (idx === 0) {
    return {
      _display: {
        name: title,
      },
    };
  }
  return {
    _display: {
      name: ' ',
    },
  };
}

export type SimpleArrayData<ElementT extends EditableJSONObject> = {
  _value: ElementT[];
  _added?: ElementT[];
  _idxs?: string[];
};

export interface ElementComponentProps<ElementT extends EditableJSONObject> {
  idx: number;
  data: ElementT;
  prefix?: string;
  hideLabel?: boolean;
  showIndexOnHover?: boolean;
}

export type SimpleArrayProps<ElementT extends EditableJSONObject> = {
  ElementComponent: React.ComponentType<ElementComponentProps<ElementT>>;
  data: SimpleArrayData<ElementT>;
  description: string;
  prefix?: string;
  createNewElement: () => ElementT;
};

export function SimpleArray<ElementT extends EditableJSONObject>({
  data,
  ElementComponent,
  createNewElement,
  description,
  prefix,
}: SimpleArrayProps<ElementT>) {
  const { save } = useInternalFormContext();
  const {
    requiredFields,
    showDebugInfo,
    pendingAddElementPrefix,
    setPendingAddElementPrefix,
  } = useInternalFormContext();
  const { elementsToDisplay, addElement, removeElement } = useArrayBlock({
    data,
    prefix,
    save,
  });

  const isRequired =
    isRequiredField(requiredFields, prefix) ||
    isParentOfRequiredField(requiredFields, prefix);

  useEffect(() => {
    if (pendingAddElementPrefix && prefix === pendingAddElementPrefix) {
      setPendingAddElementPrefix(null);
      addElement(createNewElement());
    }
  }, [prefix, pendingAddElementPrefix]);

  if (elementsToDisplay.length === 0) {
    return (
      <div
        className={clsx(el`empty-list`, {
          'is-required': isRequired,
        })}
      >
        <div className="flex flex-row gap-1 items-center">
          {isRequired && <div className={el`label-required-indicator`} />}
          <Text type="body-xs" isHeavy>
            No {pluralize(description)}
          </Text>
        </div>
        <div className={el`add-element-button`}>
          <Button
            onPress={() => {
              addElement(createNewElement());
            }}
            variant="tertiary"
            size="small"
            icon={<PiPlus />}
          >
            {`Add ${description}`}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={ROOT}>
      <div className={el`ref-list`}>
        {elementsToDisplay.map((elem, index) => (
          <div
            key={`${elem.provenance}.${elem.idx}`}
            className={el`element-row`}
          >
            <ElementComponent
              idx={index}
              data={elem.element as ElementT}
              prefix={`${prefix}${elem.provenance}.${elem.idx}.`}
              hideLabel={index !== 0}
              showIndexOnHover={showDebugInfo && elementsToDisplay.length > 6}
            />
            <InteractableComponent
              className={el`element-row-button`}
              onPress={() => {
                removeElement(elem.provenance, elem.idx);
              }}
            >
              <Tooltip content={`Delete ${description}`}>
                <Trash size={12} />
              </Tooltip>
            </InteractableComponent>
          </div>
        ))}
      </div>
      <div className={el`add-element-button`}>
        <Button
          onPress={() => {
            addElement(createNewElement());
          }}
          variant="tertiary"
          size="small"
          icon={<PiPlus />}
        >
          {`Add ${description}`}
        </Button>
      </div>
    </div>
  );
}
