import { useToast } from '@shared/components/toast';
import {
  useOnboardNewOriginatorMutation,
  useUpdateOrderMutation,
} from '@shared/generated/graphql';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { OriginatorOnboardingType } from '../components/OriginatorOnboardingWizard/types';
import { getOnboardingRoute } from '../OriginatorOnboarding';

export const useInitiateOriginatorOnboarding = ({
  orderId,
  organizationId,
}: {
  orderId: string;
  organizationId: string;
}) => {
  const { sendToast } = useToast();
  const navigate = useNavigate();
  const [onboardNewOriginator, { loading: loadingOnboardOriginator }] =
    useOnboardNewOriginatorMutation();
  const [updateOrder] = useUpdateOrderMutation();

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const initiateOriginatorOnboarding = async (name: string) => {
    const res = await onboardNewOriginator({
      variables: {
        input: {
          name,
          organizationId,
        },
      },
      refetchQueries: ['GetOriginators'],
    }).catch((e) => sendErrorToast(e));
    const originator =
      res?.data?.onboardNewOriginator?.customerFacingOriginator;

    if (isNil(originator)) {
      return undefined;
    }

    await updateOrder({
      variables: {
        input: { id: orderId, originatorId: originator.id },
      },
    }).catch((e) => sendErrorToast(e));

    navigate(
      getOnboardingRoute(
        OriginatorOnboardingType.NEW_ORIGINATOR,
        orderId,
        originator.id
      )
    );
    return originator.id;
  };

  return {
    initiateOriginatorOnboarding,
    loading: loadingOnboardOriginator,
  };
};
