import { isNil, lowerCase } from 'lodash';
import { formatDataTableCellAddress } from '../../../stringification/address';
import { formatDate, getLocalizedDate } from '../../../stringification/dates';
import { formatNumber } from '../../../stringification/numbers';
import { formatOption } from '../../../stringification/options';
import { CompletedTemplateChildConfigs } from '../../../templates/types';
import { Address } from '../../../types/address';
import { StopType } from '../../../types/stop';
import { getArrayValue, getObjectValue, getValue } from '../../getValue';
import {
  CompletedAddressChild,
  CompletedFreightOrderTemplate,
  StopTemplate,
  WeightTemplate,
  WithMetadata,
} from '../types';

export type Location = {
  address: CompletedAddressChild;
  name: CompletedTemplateChildConfigs;
  locationCode?: CompletedTemplateChildConfigs | undefined;
};

export const getDateFromStop = (
  stop: WithMetadata<StopTemplate<CompletedTemplateChildConfigs>>,
  formatToken: string = 'M/d/yy'
): string | undefined => {
  if (!stop.date) return;

  const date = getValue(stop.date);
  if (Array.isArray(date)) return;

  if (!date.trim()) return;

  return formatDate(date, formatToken, true);
};

export const getTimeFromStop = (
  stop: WithMetadata<StopTemplate<CompletedTemplateChildConfigs>>
): string | undefined => {
  if (!stop.time) return;

  const time = getValue(stop.time);
  if (Array.isArray(time)) return;

  if (!time.trim()) return;

  return time;
};

export const getPickupFromFreightOrder = (
  data: CompletedFreightOrderTemplate
): WithMetadata<StopTemplate<CompletedTemplateChildConfigs>> | undefined => {
  const dataValue = data._value;
  const pickupStop = !isNil(dataValue.pickup)
    ? getObjectValue(dataValue.pickup)
    : dataValue.stops._value.find(
        (s) => !isNil(s.stopType) && getObjectValue(s.stopType) === 'PICKUP'
      );
  if (isNil(pickupStop)) return;
  return pickupStop;
};

export const getDeliveryFromFreightOrder = (
  data: CompletedFreightOrderTemplate
): WithMetadata<StopTemplate<CompletedTemplateChildConfigs>> | undefined => {
  const dataValue = data._value;
  const stops = getArrayValue(dataValue.stops);

  // NB: Assuming a single stop per quote
  const delivery = stops[stops.length - 1];

  if (Array.isArray(delivery)) return;

  return delivery;
};

export const getAddressFromLocation = (
  location: Location
): Address | string | undefined => {
  if (Array.isArray(location)) return;
  const address = getValue(location.address);
  if (Array.isArray(address)) return;
  if (typeof address === 'string') return address;
  return address;
};

export const formatPickupCityState = (
  data?: CompletedFreightOrderTemplate
): string | undefined => {
  if (!data) return;
  const pickup = getPickupFromFreightOrder(data);
  if (isNil(pickup)) return;
  const location = getValue(pickup.location) as Location;
  const address = getAddressFromLocation(location);
  return formatDataTableCellAddress(address);
};

export const formatDeliveryCityState = (
  data?: CompletedFreightOrderTemplate
): string | undefined => {
  if (!data) return;
  const delivery = getDeliveryFromFreightOrder(data);
  if (!delivery) return;
  const location = getObjectValue(delivery.location) as Location;
  const address = getAddressFromLocation(location);
  return formatDataTableCellAddress(address);
};

export const formatPickupDate = (
  data?: CompletedFreightOrderTemplate,
  formatToken?: string
): string | undefined => {
  if (!data) return;
  const pickup = getPickupFromFreightOrder(data);
  if (isNil(pickup)) return;
  return getDateFromStop(pickup, formatToken);
};

export const formatDeliveryDate = (
  data?: CompletedFreightOrderTemplate
): string | undefined => {
  if (!data) return;
  const delivery = getDeliveryFromFreightOrder(data);
  if (!delivery) return;
  return getDateFromStop(delivery);
};

const formatValues = (values: string | string[]): string | undefined => {
  if (!values) return;
  if (Array.isArray(values)) {
    return values.map((e) => formatOption(e)).join(', ');
  }
  return formatOption(values);
};

export const getEquipmentTypesFromFreightOrder = (
  data?: CompletedFreightOrderTemplate
): string[] => {
  const equipmentType = data?._value?.equipmentType;
  if (!equipmentType) return [];
  const value = getValue(equipmentType);
  if (typeof value === 'string') return [value];
  return value;
};

export const getAndFormatEquipmentType = (
  data?: CompletedFreightOrderTemplate
): string | undefined => {
  const equipmentTypes = getEquipmentTypesFromFreightOrder(data);
  return equipmentTypes.map(formatOption).join(', ') || undefined;
};

export const getCommodityFromFreightOrder = (
  data?: CompletedFreightOrderTemplate
): string | string[] | undefined => {
  const commodity = data?._value?.commodity;
  if (!commodity) return;
  return formatValues(getValue(commodity));
};

export const getWeightFromFreightOrder = (
  data?: CompletedFreightOrderTemplate
): string | undefined => {
  const weight = data?._value?.weight;
  if (!weight) return;
  const weightSection = getValue(
    weight
  ) as WeightTemplate<CompletedTemplateChildConfigs>;
  const weightValue = getValue(weightSection.weight) as string;
  const weightUnits = getValue(weightSection.units) as string;
  if (!weightValue) return;
  return `${formatNumber(parseFloat(weightValue), 7)} ${lowerCase(
    weightUnits
  )}`;
};

export function getStopsFromFreightOrder(data: CompletedFreightOrderTemplate) {
  const hardcodedPickup = data?._value?.pickup?._value;
  const deliveries = getArrayValue(data?._value?.stops);
  const stops = !isNil(hardcodedPickup)
    ? [hardcodedPickup, ...deliveries]
    : deliveries;
  return stops.map((stop, idx, arr) => getStopData(stop, idx, arr.length));
}

function getStopData(
  stop: WithMetadata<StopTemplate<CompletedTemplateChildConfigs>>,
  idx: number,
  length: number
) {
  const date = stop.date
    ? getLocalizedDate(getValue(stop.date) as string)
    : undefined;
  const time = stop.time ? (getValue(stop.time) as string) : undefined;
  const location = getValue(stop.location) as Location;
  const address = extractAddress(location);
  const type = determineStopType(idx, length);
  return {
    address,
    date,
    time,
    type,
  };
}

function determineStopType(idx: number, length: number) {
  if (idx === 0) {
    return 'PICKUP' as StopType;
  } else if (idx === length - 1) {
    return 'DELIVERY' as StopType;
  } else {
    return 'NONE' as StopType;
  }
}

function extractAddress(location: Location) {
  return getValue(location.address) as Address;
}
