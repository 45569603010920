import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiWarningCircleFill,
} from 'react-icons/pi';

const ROOT = makeRootClassName('WidgetHeader');
const el = makeElementClassNameFactory(ROOT);

type WidgetHeaderProps = {
  title: string;
  logo: React.ReactNode;
  expanded: boolean;
  rateError?: string;
  ratePreview?: React.ReactNode;
};

export function WidgetHeader({
  title,
  logo,
  expanded,
  rateError,
  ratePreview,
}: WidgetHeaderProps) {
  return (
    <div className={clsx(el`summary`, expanded && 'expanded')}>
      <div className={el`rate-header`}>
        <div className={el`rate-logo`}>
          {logo}
          {rateError && (
            <Tooltip isInstant content={rateError}>
              <div className={el`rate-error-badge`}>
                <PiWarningCircleFill
                  className={el`rate-error-icon`}
                  size={16}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <Text isHeavy type="body-sm">
          {title}
        </Text>
      </div>
      <div className={el`preview-and-caret`}>
        {ratePreview}
        <div>{expanded ? <PiCaretUpFill /> : <PiCaretDownFill />}</div>
      </div>
    </div>
  );
}
