import { JobType } from '@shared/generated/graphql';
import { FieldTransformationInfo } from '@shared/utils/flows/transformationStatus';
import { KnownType } from 'clerk_common';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { Address } from 'clerk_common/types/address';
import { createContext, useContext } from 'react';

export type DefaultType = 'HARD' | 'SOFT';

type FlowsViewContextType = {
  show: boolean;
  setShow: (show: boolean) => void;
  originatorId?: string;
  setOriginatorId: (originatorId?: string) => void;
  organizationId?: string;
  setOrganizationId: (organizationId: string) => void;
  jobType?: JobType;
  setJobType: (jobType: JobType) => void;
  extractedData?: CompletedFreightOrderTemplate;
  setExtractedData: (extractedData?: CompletedFreightOrderTemplate) => void;
  getFieldTransformationInfo: (
    fieldPath: string
  ) => FieldTransformationInfo | undefined;
  addDefault: (
    fieldPath: string,
    value: string | Address,
    defaultType: DefaultType,
    fieldType?: KnownType
  ) => void;
  removeWorkflowTransformation: (workflowId: string, tfId: string) => void;
};

export const FlowsViewContext = createContext<FlowsViewContextType>({
  show: false,
  setShow: () => null,
  originatorId: '',
  setOriginatorId: () => null,
  organizationId: '',
  setOrganizationId: () => null,
  jobType: undefined,
  setJobType: () => null,
  extractedData: undefined,
  setExtractedData: () => null,
  getFieldTransformationInfo: () => undefined,
  addDefault: () => null,
  removeWorkflowTransformation: () => null,
});

export function useFlowsViewContext() {
  const context = useContext(FlowsViewContext);
  if (!context) {
    throw new Error(
      'useFlowsViewContext must be used within a FlowsViewContext.Provider'
    );
  }
  return context;
}
