import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { OrderingPriorities } from 'clerk_common/templates/types';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InternalFormContext } from './internalFormContext/InternalFormContext';
import { JSONFormFields } from './JSONFormFields';
import { initializeFormState } from './stateManagement/form';
import { EditableJSONField, EditableUnit } from './types';

const ROOT = makeRootClassName('JSONDefinedForm');
export const el = makeElementClassNameFactory(ROOT);

export type JSONDefinedFormProps = {
  showDebugInfo?: boolean;
  className?: string;
  data: EditableUnit;
  onSave: (data: EditableUnit) => Promise<void>;
  saveIsLoading?: boolean;
  requiredFields: string[];
  orderingPriorities?: OrderingPriorities;
  isInTrainingMode?: boolean;
  templateData?: MaybeCompletedFreightOrderTemplate;
  handleUpdateTemplate?: (
    data: MaybeCompletedFreightOrderTemplate
  ) => Promise<void>;

  /* The JSONDefinedForm has built in renderers for certain types of data
   * that do not have deep dependencies on the rest of the application. If
   * you need to depend on something app-specific, like a query or a hook,
   * you can register it using this prop.
   */
  createCustomBlock?: (
    data: EditableUnit,
    prefix: string
  ) => JSX.Element | null;

  onFieldEdit?: () => Promise<void>;
};

const DEFAULT_PROPS = {} as const;

export function JSONDefinedForm(props: JSONDefinedFormProps) {
  const p = { ...DEFAULT_PROPS, ...props };
  const [data, setData] = useState({} as EditableUnit);
  const { getValues } = useFormContext<EditableJSONField>();

  // NOTE(parlato): This flag is used to signal the need to add an element
  // to eventual specialized array components in the event a simple block is
  // converted to an array block
  const [pendingAddElementPrefix, setPendingAddElementPrefix] = useState<
    string | null
  >(null);

  // NOTE(max): This useEffect is needed to handle the await for
  // the initializeFormState function since React components cannot.
  // handle this in the main function body.
  useEffect(() => {
    const initializeData = async () => {
      setData(initializeFormState(p.data));
    };

    initializeData();
  }, [p.data]);

  const save = async () => {
    const newData = getValues();
    await p.onSave(newData);
  };

  const handleUpdateData = async (newData: EditableUnit) => {
    await p.onSave(newData);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <InternalFormContext.Provider
        value={{
          loading: p.saveIsLoading ?? false,
          inTrainingMode: p.isInTrainingMode ?? false, // TODO(mike): Remove this.
          showDebugInfo: p.showDebugInfo ?? false,
          requiredFields: p.requiredFields,
          save,
          createCustomBlock: p.createCustomBlock ?? (() => null),
          onFieldEdit: p.onFieldEdit,
          templateData: p.templateData,
          handleUpdateTemplate: p.handleUpdateTemplate,
          extractedData: data,
          handleUpdateData,
          pendingAddElementPrefix,
          setPendingAddElementPrefix,
          // TODO(mike): Ordering priorities can probably also go here.
        }}
      >
        <JSONFormFields
          data={data}
          prefix={''}
          orderingPriorities={p.orderingPriorities}
        />
        <div className={el`footer`}></div>
      </InternalFormContext.Provider>
    </div>
  );
}
