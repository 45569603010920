import { Text } from '@shared/components/text';
import { RateMetadata } from '@shared/types/quote';
import { getPerMileComponent } from '@shared/utils/rates/rates';
import {
  usdFormatter,
  usdFormatterNoCents,
} from 'clerk_common/stringification/numbers';
import { RateComponentType } from 'clerk_common/types/pricingStrategy';
import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RateLineItems } from './rate-line-items/RateLineItems';
import { MoneyFormRateLineItem, MoneyFormValues } from './types';

interface RateDetailsProps {
  el: (strings: TemplateStringsArray) => string;
  metadata?: RateMetadata;
  name: 'sellRate' | 'buyRate';
  expanded: boolean;
  quoteId: string;
  compact?: boolean;
  onSubmit: (data: MoneyFormValues, createNewRate?: boolean) => Promise<void>;
  setExpanded: (expanded: boolean) => void;
}

const chooseRate = (field?: MoneyFormRateLineItem) => {
  const rate = field?.sellRate;

  if (!rate || parseFloat(rate) === 0) {
    return field?.buyRate;
  }

  return rate;
};

const getSellRateSubtext = (
  sellRate: string,
  fuelSurchargeField?: MoneyFormRateLineItem
): string | undefined => {
  const fuelSurchargeSellRate = chooseRate(fuelSurchargeField);
  const sellRateNum = parseFloat(sellRate);
  if (!fuelSurchargeSellRate || isNaN(sellRateNum) || sellRateNum === 0) {
    return;
  }

  const fuelSurchargeNum = parseFloat(fuelSurchargeSellRate);

  if (fuelSurchargeNum === 0) return;

  const sellRateLessFuel = sellRateNum - fuelSurchargeNum;
  return `${usdFormatterNoCents.format(
    sellRateLessFuel
  )} + ${usdFormatterNoCents.format(fuelSurchargeNum)} FSC`;
};

export const RateDetails = ({
  metadata,
  name,
  quoteId,
  expanded,
  compact,
  el,
  setExpanded,
  onSubmit,
}: RateDetailsProps) => {
  const mileageRate = getPerMileComponent(metadata);
  const { control, watch } = useFormContext<MoneyFormValues>();

  const { fields: lineItemFields } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const sellRate = watch('sellRate');
  const buyRate = watch('buyRate');
  const lineItems = watch('lineItems');

  const rateSubtext = useMemo(() => {
    if (name === 'buyRate' && mileageRate) {
      return `${usdFormatter.format(
        mileageRate.value
      )}/${mileageRate.units.toLowerCase()}`;
    }

    if (name === 'sellRate') {
      const fuelSurchargeField = lineItems.find(
        (li) => li.type === RateComponentType.FUEL_SURCHARGE
      );
      return getSellRateSubtext(sellRate, fuelSurchargeField);
    }
  }, [name, mileageRate, ...lineItemFields.map((li) => li), sellRate, buyRate]);

  return (
    <div className={'flex flex-col'}>
      {rateSubtext ? (
        <div className={el`mileage-info`}>
          <Text type="custom" className="text-[10px] text-gray-500">
            {rateSubtext}
          </Text>
        </div>
      ) : (
        <div />
      )}
      {name === 'sellRate' ? (
        <RateLineItems
          quoteId={quoteId}
          expanded={expanded}
          setExpanded={setExpanded}
          onSubmit={onSubmit}
          compact={compact}
        />
      ) : null}
    </div>
  );
};
