import type { TooltipProps as RadixTooltipProps } from '@radix-ui/react-tooltip';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { Text } from '..';

export type TooltipProps = StyleProps &
  RadixTooltipProps & {
    /**
     * The side the tooltip should show on
     * @default 'top'
     */
    side?: 'top' | 'right' | 'bottom' | 'left';

    /**
     * Whether to show the tooltip instantly, without a delay.
     * @default false
     */
    isInstant?: boolean;

    /** The target for the tooltip. */
    children: ReactNode;

    /** The content of the tooltip. */
    content?: string;
  };

const ROOT = makeRootClassName('Tooltip');

const DELAY = 600;

const DEFAULT_PROPS = {
  side: 'top',
  isInstant: false,
} as const;

function Tooltip(props: TooltipProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={p.isInstant ? 0 : DELAY}>
        <TooltipPrimitive.Trigger asChild>
          {p.children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          side={p.side}
          align="center"
          sideOffset={4}
          className={clsx(ROOT, p.className)}
        >
          <Text type="body-xs">{p.content}</Text>
          {/* <TooltipPrimitive.Arrow width={6}
        height={4} className={el`arrow`} /> */}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

/** Allows one to wrap a component in a tooltip and only
 * show the tooltip if the content prop is set. */
export function OptionalTooltip(props: TooltipProps): ReactElement {
  if (props.content) return <Tooltip {...props} />;
  return <>{props.children}</>;
}

export default Tooltip;
