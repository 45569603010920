import { Text } from '@shared/components';
import { Product } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { PiLightningBold } from 'react-icons/pi';
import { Workflow, WorkflowDetails } from '../workflow-details/WorkflowDetails';

const ROOT = makeRootClassName('WorkflowSequence');
const el = makeElementClassNameFactory(ROOT);

export const productListToText = (products: Product[]) => {
  return products
    .filter((product) => product !== Product.EMAIL_SCANNING)
    .map((product) => product.toLowerCase())
    .join(' and ');
};

type WorkflowSequenceHeaderProps = {
  originator: Workflow['originator'];
  products: Product[];
};

const WorkflowSequenceHeader = (p: WorkflowSequenceHeaderProps) => {
  const productStr = productListToText(p.products);
  const isOrganizationWorkflow = isNil(p.originator);
  const details = isOrganizationWorkflow
    ? {
        flowType: 'Organization',
        name: 'your organization',
      }
    : {
        flowType: 'Customer',
        name: p.originator?.name,
      };

  return (
    <div className={el`header`}>
      <div className="flex items-center justify-start gap-1">
        <PiLightningBold className={el`icon`} />
        <Text isHeavy type="body-sm">{`${details.flowType} Flows`}</Text>
      </div>
      <p className="text-sm text-gray-600">
        Applied to all {`${productStr}`} created for {details.name}.
      </p>
    </div>
  );
};

export type WorkflowSequenceProps = {
  originator: Workflow['originator'];
  workflows: Workflow[];
  products: Product[];
};

export const WorkflowSequence = (p: WorkflowSequenceProps) => {
  const allTransformations = p.workflows.flatMap(
    (workflow) => workflow.transformations
  );
  if (allTransformations.length === 0) {
    return null;
  }

  const nonEmptyWorkflows = p.workflows.filter(
    (workflow) => workflow.transformations.length > 0
  );

  return (
    <div className={clsx(ROOT)}>
      <WorkflowSequenceHeader originator={p.originator} products={p.products} />
      {nonEmptyWorkflows.map((workflow) => (
        <div className="flex flex-col items-center w-full" key={workflow.id}>
          <div className={el`vertical-divider`} />
          <WorkflowDetails workflow={workflow} key={workflow.id} />
        </div>
      ))}
    </div>
  );
};
