import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

type CreateApolloClientArgs = {
  token?: string;
  appName: string;
  httpLinkUri: string;
};
export const createApolloClient = (args: CreateApolloClientArgs) => {
  const httpLink = createHttpLink({
    uri: args.httpLinkUri,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-Auth0-Authorization': args.token,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    name: args.appName,
  });
};
