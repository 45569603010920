import { usePlaceQuery } from '@shared/generated/graphql';
import { placeFromFragment } from '@shared/graphql/fromFragments/place';
import { Place } from '@shared/types/place';

type UsePlaceOptions = {
  id: string;
};

type UsePlaceState = {
  place: Place | null;
  isLoading: boolean;
};

type UsePlaceActions = {
  // add any actions the hook provides
};

export type UsePlaceValue = UsePlaceState & UsePlaceActions;

const usePlace = (props: UsePlaceOptions): UsePlaceValue => {
  const { id } = props;
  const { data, loading } = usePlaceQuery({
    variables: {
      id,
    },
  });

  const place = data?.placeById ? placeFromFragment(data.placeById) : null;

  return {
    place,
    isLoading: loading,
  };
};

export default usePlace;
