import { useAuth0 } from '@auth0/auth0-react';
import { Button, Text } from '@shared/components';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { loginWithPopup, getAccessTokenSilently } = useAuth0();
  const { setToken } = useAuthContext();
  const navigate = useNavigate();

  const handleLoginSuccess = (token: string) => {
    setToken(token);
    navigate(-1);
  };

  const handleLogin = async () => {
    await loginWithPopup();
    const token = await getAccessTokenSilently();
    handleLoginSuccess(token);
  };

  return (
    <div className="flex flex-col justify-start h-screen m-8 gap-6">
      <img className="self-center" width="128" src="logo.png" />
      <Text type="body-sm" className="text-gray-700 text-center">
        Automate quoting and order entry with the Vooma add-in for Outlook.
      </Text>
      <div className="flex flex-col gap-3">
        <Button
          size="medium"
          variant="primary"
          className="w-full mt-2"
          onPress={handleLogin}
        >
          Log in
        </Button>
      </div>
    </div>
  );
};

export default Login;
