import { ApolloProvider } from '@apollo/client';
import { useAuthContext } from '@shared/contexts/useAuthContext';

import { createApolloClient } from '../utils/apollo';

type ApolloWrapperProps = {
  appName: string;
  httpLinkUri: string;
  children: JSX.Element;
};
export const ApolloWrapper = ({
  appName,
  httpLinkUri,
  children,
}: ApolloWrapperProps) => {
  const { token } = useAuthContext();

  const client = createApolloClient({ token, appName, httpLinkUri });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
