import { ExpandablePanel } from '@shared/components/expandable-panel';
import { useRateErrorsContext } from '@shared/components/money-form/contexts/RateErrorContext';
import { RatingMethod } from '@shared/generated/graphql';
import { CarrierRate, CustomerRate } from '@shared/types/quote';
import { makeRootClassName } from '@shared/utils';
import {
  isManualAdjustment,
  ratingMethodToLabel,
} from '@shared/utils/rates/rates';
import { sumCustomerRate } from 'clerk_common/types';
import { isNil } from 'lodash';
import { useState } from 'react';
import { RateDetail } from '../../rate-detail/RateDetail';
import { RateLogo } from '../../RateLogo';
import { WidgetBody } from '../components/WidgetBody/WidgetBody';
import { WidgetHeader } from '../components/WidgetHeader/WidgetHeader';
import { useSetSellRateId } from '../hooks/useSetSellRate';
import { RatingWidgetProps } from '../types';

const IS_DEFAULT_EXPANDED = true;
const ROOT = makeRootClassName('CustomerRatesRatingWidget');

export function CustomerRatesRatingWidget(p: RatingWidgetProps) {
  const [expanded] = useState(IS_DEFAULT_EXPANDED);
  const { rateErrors } = useRateErrorsContext();

  const { setSellRateId, loading: loadingSetRate } = useSetSellRateId({
    quote: p.quote,
  });

  const unadjustedRates = p.customerRates.map((cr) => filterManualAdjust(cr));
  const ratingMethodLabel = ratingMethodToLabel(p.ratingMethod);
  const errors = rateErrors[p.ratingMethod];
  const rm = p.ratingMethod;
  const carrierRateId = getCarrierRateIdForMethod(p.quote.carrierRates, rm);

  const header = (
    <WidgetHeader
      title={ratingMethodLabel}
      logo={<RateLogo rateMethod={p.ratingMethod} />}
      expanded={expanded}
      rateError={rateErrors[p.ratingMethod]}
    />
  );

  return (
    <div className={ROOT}>
      <ExpandablePanel summary={header} defaultExpanded={expanded}>
        <WidgetBody integrationName={ratingMethodLabel} rateError={errors}>
          {unadjustedRates.map((rate) => {
            return (
              <RateDetail
                onPress={() => setSellRateId(rate.id, carrierRateId)}
                isDisabled={loadingSetRate}
                key={rate.id}
                label={rate.metadata!.label!}
                rate={sumCustomerRate(rate) || 0}
                ratingMethod={p.ratingMethod}
                metadata={rate.metadata}
              />
            );
          })}
        </WidgetBody>
      </ExpandablePanel>
    </div>
  );
}

function filterManualAdjust(cr: CustomerRate): CustomerRate {
  return {
    ...cr,
    rate: {
      rateComponents: cr.rate?.rateComponents.filter(
        (rc) => !isManualAdjustment(rc)
      ),
    },
  } as CustomerRate;
}

function getCarrierRateIdForMethod(
  carrierRates?: CarrierRate[],
  ratingMethod?: RatingMethod
): string | null {
  if (isNil(carrierRates) || isNil(ratingMethod)) return null;
  const result = carrierRates.find((cr) => cr.ratingMethod === ratingMethod);
  if (isNil(result)) return null;
  return result.id;
}
