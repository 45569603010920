import { FormField } from '../../fields/FormField';
import { makeDisplayData } from '../simple-array/SimpleArray';
import { AccessorialElement, el } from './AccessorialArray';

type AccessorialProps = {
  idx: number;
  data: AccessorialElement;
  prefix?: string;
  hideLabel?: boolean;
};

export function Accessorial({
  idx,
  data,
  prefix,
  hideLabel,
}: AccessorialProps) {
  return (
    <div className={el`accessorial-element`}>
      {data.accessorialDescription !== undefined && (
        <FormField
          data={{
            ...data.accessorialDescription,
            ...makeDisplayData(idx, 'Accessorial Services'),
          }}
          prefix={`${prefix}accessorialDescription.`}
          hideLabel={hideLabel}
        />
      )}
    </div>
  );
}
