import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { useGetConversationsQuery } from '@shared/generated/graphql';
import { conversationFromFragment } from '@shared/graphql/fromFragments/conversation';
import { useMemo } from 'react';
import { ConversationFeedItem, FeedItemType } from '../types';

export const useFeedConversations = () => {
  const { threadJobs } = useEmailThreadContext();
  const entityIds = threadJobs.jobs.flatMap((j) => [
    ...j.quotes.map((q) => q.id),
    ...j.orders.map((o) => o.id),
  ]);

  const { data } = useGetConversationsQuery({
    variables: { input: { entityIds } },
    pollInterval: 10 * 1000,
  });

  const conversations: ConversationFeedItem[] = useMemo(() => {
    return (
      data?.conversations.edges.map((edge) => {
        const conversation = conversationFromFragment(edge.node);
        return {
          type: FeedItemType.CONVERSATION,
          createdAt: conversation.createdAt.toISOString(),
          data: { conversation },
        };
      }) ?? []
    );
  }, [data]);

  return {
    conversations,
  };
};
