import { Button } from '@shared/components/button';
import { InteractableComponent } from '@shared/components/interactable-component';
import { Tooltip } from '@shared/components/tooltip';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { Trash } from 'react-feather';
import { PiPlus } from 'react-icons/pi';

const ROOT = makeRootClassName('ArrayComponents');
export const el = makeElementClassNameFactory(ROOT);

type AddButtonProps = {
  elementDescription: string;
  addElement?: () => void;
  isDisabled?: boolean;
};

export function AddButton({
  elementDescription,
  addElement,
  isDisabled,
}: AddButtonProps) {
  if (addElement)
    return (
      <div
        className={clsx(el`add-element-button`, isDisabled && 'is-disabled')}
      >
        <Button
          isDisabled={isDisabled}
          onPress={() => {
            addElement();
          }}
          variant="tertiary"
          size="small"
          icon={<PiPlus />}
        >
          {`Add ${elementDescription}`}
        </Button>
      </div>
    );
  return null;
}

type RemoveButtonProps = {
  elementDescription: string;
  removeElement: () => void;
  isDisabled?: boolean;
} & StyleProps;

export function RemoveButton({
  elementDescription,
  removeElement,
  className,
  isDisabled,
}: RemoveButtonProps) {
  return (
    <InteractableComponent
      className={clsx(className, el`remove-element-button`)}
      onPress={removeElement}
      isDisabled={isDisabled}
    >
      <Tooltip content={`Delete ${elementDescription}`}>
        <Trash size={16} />
      </Tooltip>
    </InteractableComponent>
  );
}
