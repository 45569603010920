import usePlaces from '@shared/graphql/hooks/places/usePlaces';
import { Place } from '@shared/types/place';
import { useState } from 'react';

type UsePlaceOptionsProps = {
  organizationId: string;
  originatorIds: string[];
};

type UsePlaceOptionsValue = {
  places: Place[];
  isLoading: boolean;
  setSearchTerm: (searchTerm: string) => void;
};

const usePlaceOptions = ({
  organizationId,
  originatorIds,
}: UsePlaceOptionsProps): UsePlaceOptionsValue => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { places, isLoading } = usePlaces({
    organizationId,
    originatorIds,
    searchTerm,
  });

  return {
    places,
    isLoading,
    setSearchTerm,
  };
};

export default usePlaceOptions;
