type TextElement = {
  elementType: 'text';
  text: string;
};

export type TaggableEntityType = 'user';

type MentionElement = {
  elementType: 'mention';
  id: string;
  entityType: TaggableEntityType;
  display?: string;
};

export type TextOrMentionElement = TextElement | MentionElement;

export function parseToSequence(text: string): TextOrMentionElement[] {
  const regex = /(<@[^>]+>)/g;
  const parts = text.split(regex);
  const elements: TextOrMentionElement[] = [];
  parts.forEach((part) => {
    if (part.startsWith('<@')) {
      const { id, entityType, display } = parseMention(part);
      elements.push({ elementType: 'mention', id, entityType, display });
    } else if (part && !part.startsWith(':<')) {
      elements.push({ elementType: 'text', text: part });
    }
  });
  return elements;
}

export function parseMention(mention: string): MentionElement {
  const stripped = mention.replace(/<@|>/g, '');
  const [id, entityType, display] = stripped.split(':');
  return {
    elementType: 'mention',
    id,
    entityType: entityType as TaggableEntityType,
    display,
  };
}

export function toUniqueIds(elements: TextOrMentionElement[]): string[] {
  const mentions = elements.filter(
    (e) => e.elementType === 'mention'
  ) as MentionElement[];
  const ids = mentions.map((m) => m.id);
  return Array.from(new Set(ids));
}

export function collectUsersMentioned(text: string): string[] {
  const sequence = parseToSequence(text);
  return toUniqueIds(sequence);
}

export function removeMentions(textWithMentions: string): string {
  const elements = parseToSequence(textWithMentions);
  return elements
    .map((e) => (e.elementType === 'mention' ? `@${e.display}` : e.text))
    .join('');
}
