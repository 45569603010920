// MarketConditionsContext just needs to provide the results of useMarketConditionsMutation
// to its consumers, so it doesn't need to do much else.

import { MarketConditionsPayload } from '@shared/generated/graphql';
import { createContext, useContext } from 'react';

interface MarketConditionsContextValue {
  fetchMarketConditionsMutation: () => Promise<void>;
  forceFetchMarketConditionsMutation: () => Promise<void>;
  marketConditions?: MarketConditionsPayload[];
  loading: boolean;
}

export const MarketConditionsContext =
  createContext<MarketConditionsContextValue>(
    {} as MarketConditionsContextValue
  );

export function useMarketConditionsContext() {
  const context = useContext(MarketConditionsContext);
  if (!context) {
    throw new Error(
      'useMarketConditionsContext must be used within a MarketConditionsProvider'
    );
  }
  return context;
}
