import { Menu } from '@/features/Menu';
import { Button } from '@shared/components';
import { GoArrowLeft } from 'react-icons/go';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  webBaseUrl: string;
}

export const Layout = ({ hasMenu, children, webBaseUrl }: LayoutProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col text-body-md overflow-hidden h-screen">
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="flex flex-row justify-between p-2 text-gray-700 bg-white items-center z-10">
          {hasMenu ? (
            <Menu />
          ) : (
            <Button
              onPress={() => navigate(-1)}
              variant="secondary"
              size="xs"
              icon={<GoArrowLeft size={12} />}
            >
              Back
            </Button>
          )}

          <a href={webBaseUrl} target="_blank" rel="noreferrer">
            <Button
              size="xs"
              icon={<MdOutlineOpenInNew className="text-brand-500" />}
              tooltip="Open Vooma web app"
              variant="secondary"
              iconPosition="right"
            >
              Vooma
            </Button>
          </a>
        </div>
        <div className="flex flex-col flex-1 bg-gray-50 overflow-y-hidden">
          {children}
        </div>
      </div>
    </div>
  );
};
