import { ApolloError } from '@apollo/client';
import {
  PopoverWithTooltip,
  Separator,
  Text,
  RHFTextField as TextField,
} from '@shared/components';
import { RateLineItem, RatingMethod } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import { Quote } from '@shared/types/quote';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { intersperse } from '@shared/utils/arrays/intersperse';
import {
  computeMarginPercent,
  manuallyAdjustBuyAndSell,
  manuallyAdjustSingleType,
  sumFilteredRateComponents,
  toMoneyFormValues,
  toRateComponents,
} from '@shared/utils/rates/rates';
import { FeatureFlagName } from 'clerk_common/types/featureFlags';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { PiInfoDuotone } from 'react-icons/pi';
import { CollectRatesButton } from '../components/CollectRatesButton';
import { LinkToDATRate } from './link-to-rate/LinkToDATRate';
import { LinkToRate } from './link-to-rate/LinkToRate';
import { RateDetails } from './RateDetails';
import { RateLogo } from './RateLogo';
import { RatingWidgets } from './rating-widgets/RatingWidgets';
import { MoneyFormValues } from './types';

// NOTE(parlato): Estimate of maximum possible margin, if there is a buy rate
const MAX_MARGIN_PERCENT = 99.99999999;

type MoneyFormFieldsProps = StyleProps & {
  quote: Quote;
  isSubmitting?: boolean;
  onSubmit: (data: MoneyFormValues, createNewRate?: boolean) => Promise<void>;
  error?: ApolloError;
  initialRates: {
    buyRate: string;
    marginPercent?: string;
    marginDollars?: string;
    sellRate?: string;
    lineItems: RateLineItem[];
  };
  compact?: boolean;
  hideGetRates?: boolean;
  sellRateExplanation?: string;
  refetchRateForm: () => Promise<any>;
};

const ROOT = makeRootClassName('MoneyFormFields');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_FORM_VALUES = {
  buyRate: '123',
  lineItems: [],
};

const FORM_ID = 'money-form';

export function MoneyFormFields(p: MoneyFormFieldsProps) {
  const defaultValues = {
    ...DEFAULT_FORM_VALUES,
    ...toMoneyFormValues(p.initialRates),
  };
  const methods = useForm<MoneyFormValues>({
    defaultValues,
  });
  const isDATRateViewAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_RATE_VIEW_AUTOMATION,
  });
  const isDATLoadSearchAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_LOAD_SEARCH_AUTOMATION,
  });

  const { control, watch, setValue, getValues, reset } = methods;
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const createOnFocusChange = (field: string) => (isFocused: boolean) => {
    setFocusedField(isFocused ? field : null);
  };
  const { track } = useAnalytics();
  const [lineItemsExpanded, setLineItemsExpanded] = useState(false);
  const isFormDisabled = p.isSubmitting || lineItemsExpanded;
  const [resetFlag, setResetFlag] = useState(false);

  // BEGIN_HACK(parlato): I don't like this, but without it we have a pretty
  // significant bug where the form doesn't pull in new values after
  // selecting "Get Rates"
  useEffect(() => {
    p.refetchRateForm().then(() => {
      setResetFlag(true);
    });
  }, [p.quote.customerRate?.id, p.quote.carrierRate?.id]);

  useEffect(() => {
    if (resetFlag) {
      reset({
        ...DEFAULT_FORM_VALUES,
        ...toMoneyFormValues(p.initialRates),
      });
      setResetFlag(false);
    }
  }, [p.initialRates, resetFlag]);
  // END_HACK(parlato)

  const onBlur = (
    field: 'Margin Percent' | 'Margin Dollar' | 'Sell Rate' | 'Buy Rate'
  ) => {
    track(`Changed Rate ${field}`, { quoteId: p.quote.id });
    p.onSubmit(getValues());
  };

  const enableForm = () => {
    setLineItemsExpanded(false);
  };

  // Set sell rate when buy rate or margin changes
  const buyRate = watch('buyRate');
  const marginPercent = watch('marginPercent');
  const marginDollars = watch('marginDollars');
  const sellRate = watch('sellRate');
  const lineItems = useWatch({
    control,
    name: 'lineItems',
  });

  const newestCarrierRate = p.quote.carrierRate;
  const newestCustomerRate = p.quote.customerRate;

  const marginIsValid =
    !marginPercent || parseFloat(marginPercent) <= MAX_MARGIN_PERCENT;

  const setSell = (val: number) => {
    setValue('sellRate', val.toFixed(2));
  };

  const setBuy = (val: number, createNewRate: boolean) => {
    setFocusedField('buyRate');
    setValue('buyRate', val.toFixed(2));
    p.onSubmit(getValues(), createNewRate);
    setFocusedField(null);
  };

  useEffect(() => {
    if (isFormDisabled) return;

    switch (focusedField) {
      case 'buyRate':
        onBuyRateChange();
        return;
      case 'marginPercent':
        onMarginPercentChange();
        return;
      case 'marginDollars':
        onMarginDollarsChange();
        return;
      case 'sellRate':
        onSellRateChange();
        return;
    }
  }, [buyRate, marginPercent, marginDollars, sellRate]);

  useEffect(() => {
    if (!isFormDisabled) return;

    const sellRateComponents = toRateComponents(lineItems, 'sell');
    const buyRateComponents = toRateComponents(lineItems, 'buy');
    const sellRateNum = sumFilteredRateComponents(sellRateComponents);
    const buyRateNum = sumFilteredRateComponents(buyRateComponents);

    const buyRateChanged = buyRate !== buyRateNum.toFixed(2);
    const sellRateChanged = sellRate !== sellRateNum.toFixed(2);
    if (buyRateChanged) {
      setValue('buyRate', buyRateNum.toFixed(2));
    }

    if (sellRateChanged) {
      setValue('sellRate', sellRateNum.toFixed(2));
    }

    if (!(buyRateChanged || sellRateChanged)) return;

    track(`Changed Buy Rate Line Items`, {
      quoteId: p.quote.id,
      rate: { lineItems },
    });

    if (buyRateNum && sellRateNum) {
      const marginDollars = sellRateNum - buyRateNum;
      const marginPercent = computeMarginPercent(buyRateNum, sellRateNum);
      setValue(
        'marginPercent',
        marginPercent === undefined ? '' : marginPercent.toFixed(2)
      );
      setValue('marginDollars', marginDollars.toFixed(2));
    } else if (buyRateNum) {
      setValue('marginDollars', '0.00');
      setValue('marginPercent', '0.00');
    }
  }, [lineItems]);

  const manuallyAdjustLineItemsSellRate = (sellRate: number) => {
    const newLineItems = manuallyAdjustSingleType(sellRate, lineItems, 'sell');
    setValue('lineItems', newLineItems);
  };

  const manuallyAdjustLineItems = (buyRate: number, sellRate: number) => {
    const newLineItems = manuallyAdjustBuyAndSell({
      buyRate,
      sellRate,
      lineItems,
    });
    setValue('lineItems', newLineItems);
  };

  const onBuyRateChange = () => {
    if (!buyRate) {
      setValue('marginPercent', '');
      setValue('marginDollars', '');

      const sellRateNum = parseFloat(sellRate || '0');
      manuallyAdjustLineItems(0, sellRateNum);

      return;
    }

    const buyRateNum = parseFloat(buyRate);
    let sellRateNum = buyRateNum;
    if (buyRate && marginPercent) {
      const marginNum = parseFloat(marginPercent);
      const sellRate = buyRateNum / (1.0 - marginNum / 100);
      const marginDollars = sellRate - buyRateNum;
      sellRateNum = sellRate;
      setValue('marginDollars', marginDollars.toFixed(2));
    } else if (buyRate) {
      setValue('marginDollars', '0.00');
      setValue('marginPercent', '0.00');
    }
    setSell(sellRateNum);
    manuallyAdjustLineItems(buyRateNum, sellRateNum);
  };

  const onSellRateChange = () => {
    if (buyRate && sellRate) {
      const buyRateNum = parseFloat(buyRate);
      const sellRateNum = parseFloat(sellRate);
      const marginPercent = computeMarginPercent(
        buyRateNum,
        sellRateNum
      ) as number; // NOTE(parlato): Ok to cast because buyRate and sellRate are guaranteed above
      const marginDollars = sellRateNum - buyRateNum;

      setValue(
        'marginPercent',
        marginPercent === undefined ? '' : marginPercent.toFixed(2)
      );
      setValue('marginDollars', marginDollars.toFixed(2));
    }
    if (sellRate) {
      const sellRateNum = parseFloat(sellRate);
      manuallyAdjustLineItemsSellRate(sellRateNum);
    }
  };

  const onMarginPercentChange = () => {
    if (buyRate && marginPercent) {
      const buyRateNum = parseFloat(buyRate);
      const marginNum = parseFloat(marginPercent);
      const sellRate = buyRateNum / (1.0 - marginNum / 100);
      const marginDollars = sellRate - buyRateNum;
      setSell(sellRate);
      setValue('marginDollars', marginDollars.toFixed(2));
      manuallyAdjustLineItemsSellRate(sellRate);
    }
  };

  const onMarginDollarsChange = () => {
    if (buyRate && marginDollars) {
      const buyRateNum = parseFloat(buyRate);
      const marginNum = parseFloat(marginDollars);
      const sellRate = buyRateNum + marginNum;
      const marginPercent = computeMarginPercent(
        buyRateNum,
        sellRate
      ) as number;

      if (isNil(marginPercent)) return;

      setSell(sellRate);
      setValue('marginPercent', marginPercent.toFixed(2));
      manuallyAdjustLineItemsSellRate(sellRate);
    }
  };

  const getLabel = (label: string) => {
    return (
      <div className={el`field-label`}>
        <Text type="body-sm">{label}</Text>
        {p.sellRateExplanation && label === 'Sell rate' && (
          <PopoverWithTooltip
            popoverContent={
              <div className={el`sell-rate-explanation`}>
                <Text type="body-sm" isHeavy>
                  Sell rate calculated with pricing strategy
                </Text>
                <Text type="body-xs">{p.sellRateExplanation}</Text>
              </div>
            }
            tooltipContent="Pricing strategy explanation"
          >
            <PiInfoDuotone className={el`info-icon`} />
          </PopoverWithTooltip>
        )}
      </div>
    );
  };

  const marginInputsComponent = (
    <div className={clsx('my-1 flex w-full flex-col')}>
      <div className={el`money-field-and-label`}>
        {getLabel('Margin')}
        <div
          className="flex w-[158px] flex-row"
          onClick={() => isFormDisabled && enableForm()}
        >
          {/* TODO(Martin): Remove this listener and div when react-aria's focus in shadow-dom is merged */}
          <div onClick={() => setFocusedField('marginDollars')}>
            <TextField
              onBlur={() => onBlur('Margin Dollar')}
              label={''}
              aria-label="Margin $"
              size="medium"
              type={'number'}
              name="marginDollars"
              control={control}
              isDisabled={isFormDisabled}
              onFocusChange={createOnFocusChange('marginDollars')}
              inputWrapperClassName={clsx(
                el`margin-dollars-input-wrapper`,
                el`money-form-text-field`
              )}
              fieldWrapperClassName={el`money-form-field-wrapper`}
              prepend={
                <Text type="body-xs" className={el`margin-dollars-prepend`}>
                  $
                </Text>
              }
            />
          </div>
          {/* TODO(Martin): Remove this listener and div when react-aria's focus in shadow-dom is merged */}
          <div onClick={() => setFocusedField('marginPercent')}>
            <TextField
              onBlur={() => onBlur('Margin Percent')}
              label={''}
              aria-label="Margin %"
              size="medium"
              type={'number'}
              name="marginPercent"
              control={control}
              isDisabled={isFormDisabled}
              onFocusChange={createOnFocusChange('marginPercent')}
              inputWrapperClassName={clsx(
                el`margin-percent-input-wrapper`,
                el`money-form-text-field`,
                marginIsValid ? 'valid' : 'invalid'
              )}
              append={
                <Text type="body-xs" className={el`margin-percent-append`}>
                  %
                </Text>
              }
              fieldWrapperClassName={el`money-form-field-wrapper`}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const buyRateComponent = (
    <div className={el`buy-rate-container`}>
      <div className={el`money-field-and-label`}>
        {getLabel('Buy rate')}
        <div
          onClick={() => {
            /* TODO(Martin): Remove this call when react-aria's focus in shadow-dom is merged */
            setFocusedField('buyRate');
            return isFormDisabled && enableForm();
          }}
        >
          <TextField
            onBlur={() => onBlur('Buy Rate')}
            size="medium"
            type="number"
            name="buyRate"
            control={control}
            isDisabled={isFormDisabled}
            onFocusChange={createOnFocusChange('buyRate')}
            inputWrapperClassName={clsx(el`money-form-text-field`)}
            prepend={
              <Text type="body-xs" className={el`margin-dollars-prepend`}>
                $
              </Text>
            }
            fieldWrapperClassName={el`money-form-field-wrapper`}
          />
        </div>
      </div>
      <RateDetails
        el={el}
        name="buyRate"
        metadata={newestCarrierRate?.metadata}
        expanded={lineItemsExpanded}
        setExpanded={setLineItemsExpanded}
        onSubmit={p.onSubmit}
        quoteId={p.quote.id}
        compact={p.compact}
      />
    </div>
  );

  const sellRateComponent = (
    <div>
      <div className={el`money-field-and-label`}>
        {getLabel('Sell rate')}
        <div
          onClick={() => {
            /* TODO(Martin): Remove this call when react-aria's focus in shadow-dom is merged */
            setFocusedField('sellRate');
            isFormDisabled && enableForm();
          }}
        >
          <TextField
            onBlur={() => onBlur('Sell Rate')}
            size="medium"
            type="number"
            name="sellRate"
            control={control}
            isDisabled={isFormDisabled}
            onFocusChange={createOnFocusChange('sellRate')}
            inputWrapperClassName={clsx(el`money-form-text-field`)}
            fieldWrapperClassName={el`money-form-field-wrapper`}
            prepend={
              <Text type="body-xs" className={el`margin-dollars-prepend`}>
                $
              </Text>
            }
          />
        </div>
      </div>
      <RateDetails
        el={el}
        metadata={newestCustomerRate?.metadata}
        name="sellRate"
        expanded={lineItemsExpanded}
        setExpanded={setLineItemsExpanded}
        onSubmit={p.onSubmit}
        quoteId={p.quote.id}
        compact={p.compact}
      />
    </div>
  );

  const separatorComponent = <Separator className={el`money-form-separator`} />;

  const includedFormComponents = intersperse(
    [
      ...(shouldShowBuyAndMargin(newestCarrierRate?.ratingMethod)
        ? [buyRateComponent, marginInputsComponent]
        : []),
      sellRateComponent,
    ],
    separatorComponent
  );

  return (
    <FormProvider {...methods}>
      <form id={FORM_ID} className="flex flex-col p-[16px]">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2">
            <RateLogo rateMethod={newestCarrierRate?.ratingMethod} />
            <LinkToRate
              metadata={newestCarrierRate?.metadata}
              ratingMethod={newestCarrierRate?.ratingMethod}
              quote={p.quote}
            />
          </div>
          {p.hideGetRates ? (
            <div />
          ) : (
            <div className="flex flex-row gap-1">
              <CollectRatesButton quoteId={p.quote.id} />
            </div>
          )}
        </div>
        <div className={el`money-form-fields-container`}>
          <div className={clsx('flex w-full flex-col gap-2')}>
            {includedFormComponents}
          </div>
        </div>
        {(isDATRateViewAutomationEnabled ||
          isDATLoadSearchAutomationEnabled) && (
          <div className={el`dat-rate-view-link`}>
            <LinkToDATRate
              quote={p.quote}
              ratingMethod={RatingMethod.DAT}
              optionalLabel={
                isDATRateViewAutomationEnabled
                  ? 'Open in DAT RateView'
                  : isDATLoadSearchAutomationEnabled
                  ? 'Open in DAT LoadSearch'
                  : undefined
              }
            />
          </div>
        )}
        <RatingWidgets
          quote={p.quote}
          setBuy={setBuy}
          setLineItemsExpanded={setLineItemsExpanded}
          onExpandAnyRateWidget={() => setLineItemsExpanded(false)}
        />
      </form>
    </FormProvider>
  );
}

function shouldShowBuyAndMargin(ratingMethod?: RatingMethod) {
  if (!ratingMethod) return true;
  switch (ratingMethod) {
    case RatingMethod.ARCBEST_TL_QUOTING:
    case RatingMethod.WERNER:
      return false;
    default:
      return true;
  }
}
