import { RatingMethod } from 'clerk_common/types/pricingStrategy';
import { CopyGenericRateId } from './CopyGenericRateId';
import { LinkToDATRate } from './LinkToDATRate';
import { LinkToGenericRate } from './LinkToGenericRate';
import { LinkToRateProps } from './types';

export function LinkToRate(p: LinkToRateProps) {
  switch (p.ratingMethod) {
    case RatingMethod.DAT:
      return <LinkToDATRate {...p} />;
    case RatingMethod.ARCBEST_TL_QUOTING:
      return <CopyGenericRateId {...p} />;
    default:
      return <LinkToGenericRate {...p} />;
  }
}
