import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { isNil } from 'lodash';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

export const ROOT = makeRootClassName('TimeEntity');
export const el = makeElementClassNameFactory(ROOT);

type TimeData = {
  startTimeOrOnlyTime: EditableJSONField;
  optionalEndTime: EditableJSONField;
};

export type TimeFieldsData = {
  _value: TimeData;
  _display: {
    name: string;
  };
};

type TimeRangeProps = {
  data: TimeFieldsData;
  prefix?: string;
};

export function TimeRange({ data, prefix }: TimeRangeProps) {
  const timeRange: TimeData = getObjectValue(data);
  const startTime = timeRange.startTimeOrOnlyTime;
  const endTime = timeRange.optionalEndTime;

  if (isNil(startTime) || isNil(endTime)) {
    return null;
  }

  return (
    <div className={el`oneline`}>
      <FormField
        data={startTime}
        prefix={`${prefix}_value.startTimeOrOnlyTime.`}
        className={el`element`}
      />
      <div className={el`separator`}>
        <Text className={el`element`} type="body-sm">
          to (optional)
        </Text>
      </div>
      <FormField
        data={endTime}
        prefix={`${prefix}_value.optionalEndTime.`}
        className={el`element`}
      />
    </div>
  );
}
