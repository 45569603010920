import { Address } from 'clerk_common/types/address';

export function formatAddressLine(
  addressOne?: string,
  addressTwo?: string,
  city?: string,
  state?: string,
  zip?: string,
  country?: string
): string {
  let text = '';
  if (addressOne) text += addressOne;
  if (addressTwo) text += needsSeparator(text) ? ` ${addressTwo}` : addressTwo;
  if (city) text += needsSeparator(text) ? `, ${city}` : city;
  if (state) text += needsSeparator(text) ? `, ${state}` : state;
  if (zip) text += needsSeparator(text) ? `${!state ? ',' : ''} ${zip}` : zip;
  if (country) text += needsSeparator(text) ? `, ${country}` : country;
  return text;
}

function needsSeparator(text: string) {
  return text.length > 0 && !text.endsWith(',');
}

export function formatAddress(address?: Address) {
  if (!address) return '';
  return formatAddressLine(
    address.addressOne,
    address.addressTwo,
    address.city,
    address.state,
    address.zip,
    address.country
  );
}

export function formatAddressLineOne(address?: Address) {
  if (!address) return '';
  return formatAddressLine(address.addressOne, address.addressTwo);
}

export function formatAddressLineTwo(address?: Address) {
  if (!address) return '';
  return formatAddressLine(
    undefined,
    undefined,
    address.city,
    address.state,
    address.zip,
    address.country
  );
}

export function formatDataTableCellAddress(address?: Address | string) {
  if (!address || typeof address === 'string') return;
  return formatAddressLine('', '', address.city, address.state);
}
