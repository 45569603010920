import { useState } from 'react';

export interface PaginationState {
  first?: number;
  last?: number;
  after?: string | null;
  before?: string | null;
}

export interface PaginationActions {
  handleNextPage: (cursor?: string | null) => void;
  handlePreviousPage: (cursor?: string | null) => void;
  handleLastPage: () => void;
  handleFirstPage: () => void;
}

export const usePagination = (
  pageSize: number
): PaginationState & PaginationActions => {
  const [first, setFirst] = useState<number | undefined>(pageSize);
  const [last, setLast] = useState<number | undefined>(undefined);
  const [after, setAfter] = useState<string | null>();
  const [before, setBefore] = useState<string | null>();

  const handleNextPage = (endCursor?: string | null) => {
    setBefore(undefined);
    setFirst(pageSize);
    setLast(undefined);
    setAfter(endCursor);
  };

  const handlePreviousPage = (startCursor?: string | null) => {
    setAfter(undefined);
    setLast(pageSize);
    setFirst(undefined);
    setBefore(startCursor);
  };

  const handleLastPage = () => {
    setAfter(undefined);
    setLast(pageSize);
    setFirst(undefined);
    setBefore(undefined);
  };

  const handleFirstPage = () => {
    setAfter(undefined);
    setLast(undefined);
    setFirst(pageSize);
    setBefore(undefined);
  };

  return {
    first,
    last,
    after,
    before,
    handleNextPage,
    handlePreviousPage,
    handleLastPage,
    handleFirstPage,
  };
};
