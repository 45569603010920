import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';
import {
  ElementComponentProps,
  makeDisplayData,
} from '../simple-array/SimpleArray';

const ROOT = makeRootClassName('HazmatClass');
export const el = makeElementClassNameFactory(ROOT);

type HazmatClassElement = {
  hazardClass: EditableJSONField;
  qualifier: EditableJSONField;
};

type HazmatClassProps = ElementComponentProps<HazmatClassElement>;

export function HazmatClass({
  idx,
  data,
  prefix,
  hideLabel,
  showIndexOnHover,
}: HazmatClassProps) {
  return (
    <div className={clsx(el`hazmat-class-element`, 'group')}>
      {showIndexOnHover ? (
        <Text type="custom" className={el`hazmat-class-index`}>
          {idx + 1}
        </Text>
      ) : null}
      <FormField
        data={{
          ...data.hazardClass,
          ...makeDisplayData(idx, 'Class'),
        }}
        prefix={`${prefix}hazardClass.`}
        hideLabel={hideLabel}
      />
      <FormField
        data={{
          ...data.qualifier,
          ...makeDisplayData(idx, 'Qualifier'),
        }}
        prefix={`${prefix}qualifier.`}
        hideLabel={hideLabel}
      />
    </div>
  );
}

export function createHazmatClassElement(): HazmatClassElement {
  const defaultFields = {
    _value: '',
    _display: {
      name: '',
    },
    _logProbs: [],
  };

  return {
    hazardClass: {
      ...defaultFields,
      _corrected: '',
    },
    qualifier: {
      ...defaultFields,
      _corrected: '',
    },
  };
}
