import { addCommodityFields } from './addCommodityFields';
import { removeFieldsFromCommodity } from './removeCommodityFields';
import { ItemReferenceSettings, TemplateWithCommodity } from './types';

export function addItemReference<T extends TemplateWithCommodity>(
  template: T,
  settings?: ItemReferenceSettings
): TemplateWithCommodity {
  const s: ItemReferenceSettings = settings ?? {
    placeholder: '<IDENTIFIER>',
  };
  const newFields = { itemReference: { _ai: s } };
  return addCommodityFields(template, newFields);
}

export function removeItemReference(template: TemplateWithCommodity) {
  return removeFieldsFromCommodity(template, ['itemReference']);
}
