import { FormField } from '../../fields/FormField';
import { el, LineItemElement } from './LineItemArray';

type LineItemProps = {
  data: LineItemElement;
  prefix?: string;
  hideLabel?: boolean;
};

export function LineItem({ data, prefix, hideLabel }: LineItemProps) {
  return (
    <div className={el`line-item-element`}>
      <FormField
        data={{
          ...data.description,
        }}
        prefix={`${prefix}description.`}
        hideLabel={hideLabel}
      />
      {data.lineItemType !== undefined && (
        <FormField
          data={{
            ...data.lineItemType,
          }}
          prefix={`${prefix}lineItemType.`}
          hideLabel={hideLabel}
        />
      )}
      <FormField
        data={{
          ...data.amount,
        }}
        prefix={`${prefix}amount.`}
        hideLabel={hideLabel}
      />
    </div>
  );
}
