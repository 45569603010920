import { ApolloQueryResult } from '@apollo/client';
import {
  GetQuoteRateFormQuery,
  RateForm,
  useGetQuoteRateFormQuery,
} from '@shared/generated/graphql';

export const useQuoteRateForm = (
  id: string
): {
  rateForm?: RateForm;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GetQuoteRateFormQuery>>;
} => {
  const { data, loading, refetch } = useGetQuoteRateFormQuery({
    variables: {
      id,
    },
  });

  return {
    rateForm: data?.quoteById?.rateForm,
    loading,
    refetch,
  };
};
