import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

import { Text, Tooltip } from '@shared/components';
import { MessageQuoteAssociation } from '@shared/generated/graphql';
import { useMessageQuoteAssociations } from '@shared/plugin/pages/Home/components/email-thread-activity/hooks/useMessageQuoteAssociations';
import { FREIGHT_ENTITY_TYPE_LABELS } from '@shared/plugin/pages/Home/components/email-thread-activity/types';
import {
  enumToString,
  FreightEntityType,
  QUOTE_RESPONSE_CLASSES,
} from 'clerk_common/enums';
import { capitalize } from 'lodash';
import { PiEnvelopeSimpleDuotone } from 'react-icons/pi';
import { MessageClassificationFeedItem } from '../../types';
import { FeedItemTimestamp } from '../feed-item/FeedItem';

const ROOT = makeRootClassName('MessageClassification');
const el = makeElementClassNameFactory(ROOT);

type MessageClassificationFeedItemProps = {
  item: MessageClassificationFeedItem;
};
export const MessageClassificationItem = ({
  item,
}: MessageClassificationFeedItemProps) => {
  const message = item.data;
  const associations = useMessageQuoteAssociations(message.id);
  const type = message.maxConfidenceClass?.type;
  const activityLabel = type ? getActivityLabel(type, associations) : null;

  const renderMessageClassification = () => {
    return (
      <div>
        <div className={el`classification-details`}>
          {activityLabel ? (
            <Tooltip
              content={`${activityLabel} from ${message.emailMetadata?.sender}`}
            >
              <div className={el`classification`}>
                <PiEnvelopeSimpleDuotone
                  size={14}
                  className={el`classification-icon`}
                />
                <Text type="body-xs">{activityLabel}</Text>
              </div>
            </Tooltip>
          ) : null}
          <FeedItemTimestamp
            timestamp={message.emailMetadata?.receivedDateTime}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={el`message-classification-root`}>
      {renderMessageClassification()}
    </div>
  );
};

function getActivityLabel(
  type: FreightEntityType,
  associations: MessageQuoteAssociation[]
): string {
  let label =
    FREIGHT_ENTITY_TYPE_LABELS[type] ||
    capitalize(enumToString(type).toLowerCase());

  if (isQuoteResponse(type)) {
    const quoteResponseTime = getQuoteResponseTime(associations);
    if (quoteResponseTime) {
      label += ` in ${formatTime(quoteResponseTime)}`;
    }
  }
  return label;
}

function isQuoteResponse(type: FreightEntityType): boolean {
  return QUOTE_RESPONSE_CLASSES.includes(type);
}

function getQuoteResponseTime(
  associations: MessageQuoteAssociation[]
): number | null {
  if (!associations) return null;
  return associations[0]?.quote.responseTime || null;
}

function formatTime(minutes: number): string {
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);

  if (days > 0) {
    if (hours > 0) {
      return `${days}d ${hours}h`;
    } else {
      return `${days}d`;
    }
  } else if (hours > 0) {
    if (mins > 0) {
      return `${hours}h ${mins}m`;
    } else {
      return `${hours}h`;
    }
  } else if (mins > 0) {
    if (secs > 0) {
      return `${mins}m ${secs}s`;
    } else {
      return `${mins}m`;
    }
  } else {
    return `${secs}s`;
  }
}
