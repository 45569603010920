import { DropdownMenu } from '@shared/components/dropdown-menu';
import { SearchFieldRow } from '@shared/components/search-field';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import { Place } from '@shared/types/place';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { formatAddress } from 'clerk_common/stringification/address';
import clsx from 'clsx';
import React from 'react';
import { Check } from 'react-feather';

export const ROOT = makeRootClassName('LocationSelect');
export const el = makeElementClassNameFactory(ROOT);

export type SelectablePlace = {
  id: string;
  name: string;
  address?: Place['address'];
  locationCode?: Place['locationCode'];
};
type LocationSelectProps = StyleProps & {
  places: SelectablePlace[];
  isLoading: boolean;
  setSearchTerm: (value: string) => void;
  buttonContent: React.ReactNode;
  buttonTooltip?: string;
  selectedId?: string;
  onSelect: (place: SelectablePlace) => void;
  align?: 'start' | 'end';
};
export function LocationSelect(p: LocationSelectProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <div className={p.className}>
          <Tooltip content={p.buttonTooltip}>
            <button>{p.buttonContent}</button>
          </Tooltip>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className={el`select-menu-content`}
        align={p.align ?? 'end'}
      >
        <div className={el`search-bar`}>
          <SearchFieldRow onValueChange={p.setSearchTerm} />
        </div>
        {p.places.length === 0 && (
          <div className={el`no-results`}>
            <Text type="body-xs">No results found</Text>
          </div>
        )}
        <div className={el`location-options`}>
          {p.places.map((place, idx) => {
            const isLast = idx + 1 === p.places.length;
            const isSelected = place.id === p.selectedId;
            return (
              <DropdownMenu.Item
                key={place.id}
                className={clsx(
                  el`location-option-dropdown-item`,
                  isLast && 'rounded-b-lg'
                )}
                onSelect={() => p.onSelect(place)}
              >
                <LocationOption
                  place={place}
                  isLast={isLast}
                  isSelected={isSelected}
                />
              </DropdownMenu.Item>
            );
          })}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
type LocationOptionProps = {
  place: SelectablePlace;
  isLast: boolean;
  isSelected?: boolean;
};
export function LocationOption({
  place,
  isLast,
  isSelected,
}: LocationOptionProps) {
  return (
    <div className={clsx(el`location-option`, isLast && 'rounded-b-lg')}>
      <div className={el`location-option-content`}>
        <Text type="body-xs" isHeavy>
          {place.name}
        </Text>
        <Text type="body-xs">{formatAddress(place.address)}</Text>
      </div>
      <Check
        size={16}
        className={isSelected ? el`selected-check` : 'opacity-0'}
      />
    </div>
  );
}
