import { svgTrash } from '@shared/assets';
import { Button, Text } from '@shared/components';
import { TextField } from '@shared/components/react-hook-form';
import { Control, FieldValues, Path } from 'react-hook-form';

type TransmitIdsFormSectionProps<T extends FieldValues> = {
  transmitIdFieldsName: Path<T>;
  transmitIds: string[];
  setValue: (name: Path<T>, value: any) => void;
  control: Control<T>;
};
export function TransmitIdsFormSection<T extends FieldValues>(
  p: TransmitIdsFormSectionProps<T>
) {
  const requiredProps = { rules: { required: true } };

  const addTransmitId = () => {
    p.setValue(p.transmitIdFieldsName, [...p.transmitIds, '']);
  };

  const removeTransmitId = (index: number) => {
    p.setValue(
      p.transmitIdFieldsName,
      p.transmitIds.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <div className="mb-2">
        <Text type="body-md">What are the customer codes?</Text>
        <Text type="body-xs" className="text-gray-500">
          These are used for integrations.
        </Text>
      </div>
      {p.transmitIds.map((_, i, arr) => {
        return (
          <div
            className="flex flex-row gap-2 w-full justify-start"
            key={`${i}-of-${arr.length}`}
          >
            <TextField
              name={`${p.transmitIdFieldsName}.${i}` as Path<T>}
              control={p.control}
              size="small"
              {...requiredProps}
              aria-label="Customer Code"
            />
            <Button
              variant="secondary"
              onPress={() => {
                removeTransmitId(i);
              }}
              icon={svgTrash}
              className="w-[33px] h-[33px]"
            />
          </div>
        );
      })}
      <Button variant="tertiary" onPress={addTransmitId}>
        {p.transmitIds.length === 0
          ? '+ Add a Customer Code'
          : '+ Add Another Customer Code'}
      </Button>
    </div>
  );
}
