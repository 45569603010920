import { Text } from '@shared/components/text';
import { OrderingPriorities } from 'clerk_common/templates/types';
import { AddButton, RemoveButton } from './arrayComponents/buttons';
import { getTextProps } from './formating';
import { FormFieldsForObject } from './FormFieldsForObject';
import { useInternalFormContext } from './internalFormContext/InternalFormContext';
import { el } from './JSONDefinedForm';
import { EditableJSONArray } from './types';
import { useArrayBlock } from './useArrayBlock';

type FormFieldsFromArrayProps = {
  data: EditableJSONArray;
  prefix: string;
  orderingPriorities?: OrderingPriorities;
};
export function FormFieldsFromArray({
  data,
  prefix,
  orderingPriorities,
}: FormFieldsFromArrayProps) {
  const { save } = useInternalFormContext();
  const { elementsToDisplay, addElement, removeElement, createElement } =
    useArrayBlock({
      data,
      prefix,
      save,
    });
  const textProps = getTextProps();
  const displayName = data._display?.name ?? 'element';
  if (elementsToDisplay.length === 0) {
    return (
      <div className={el`flat-object`}>
        <Text {...textProps}>{displayName}</Text>
        <Text type="body-xs">No items</Text>
        <AddButton
          elementDescription={displayName}
          addElement={() => addElement(createElement())}
        />
      </div>
    );
  }
  return (
    <div className={el`array-of-fields`}>
      <ul>
        {elementsToDisplay.map((item, idx) => (
          <li key={JSON.stringify(item)}>
            <FormFieldsForObject
              _display={{
                ...data._display,
                ...(elementsToDisplay.length > 1 && {
                  name: `${displayName} #${idx + 1}`,
                }),
              }}
              data={item.element}
              prefix={`${prefix}${item.provenance}.${item.idx}.`}
              actionButtons={
                elementsToDisplay.length > 1 ? (
                  <RemoveButton
                    elementDescription={displayName}
                    removeElement={() =>
                      removeElement(item.provenance, item.idx)
                    }
                  />
                ) : null
              }
              orderingPriorities={orderingPriorities}
            />
          </li>
        ))}
      </ul>
      <AddButton
        elementDescription={displayName}
        addElement={() => addElement(createElement())}
      />
    </div>
  );
}
