import { svgTrash } from '@shared/assets';
import { InteractableComponent, Text } from '@shared/components';
import { useFlowsViewContext } from '@shared/contexts/FlowsViewContext';
import { GetWorkflowsQuery } from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { Scalar, ScalarMap, TransformationDef } from 'clerk_common';
import clsx from 'clsx';

type WorkflowTransformation =
  GetWorkflowsQuery['workflows']['edges'][0]['node']['transformations'][0];

const ROOT = makeRootClassName('TransformationCard');
const el = makeElementClassNameFactory(ROOT);

// TODO(jacob): This is a very temporary solution to make the stringified
// transformation a little more human readable. It should be replaced with more
// robust stringification immediately.

const getKeyPrecedence = (key: string) => {
  switch (key) {
    case 'path':
    case 'prefix':
      return 0;
    case 'value':
      return 1;
    case 'addressOne':
      return 2;
    case 'addressTwo':
      return 3;
    case 'city':
      return 4;
    case 'state':
      return 5;
    case 'zip':
      return 6;
    case 'country':
      return 7;
    default:
      return 100;
  }
};

const sortParamKeys = (a: [string, Scalar], b: [string, Scalar]) => {
  return getKeyPrecedence(a[0]) - getKeyPrecedence(b[0]);
};

export type TransformationCardProps = {
  workflowId: string;
  transformation: WorkflowTransformation;
};

export const TransformationCard = (p: TransformationCardProps) => {
  const { removeWorkflowTransformation } = useFlowsViewContext();
  const definition = p.transformation.transformationConfig
    .transformationDefinition.definition as TransformationDef;

  const paramText = Object.entries(
    p.transformation.transformationConfig.params as ScalarMap
  )
    .sort(sortParamKeys)
    .map((val) => {
      return `${val[0]}: ${val[1]}`;
    });

  return (
    <div className={clsx(ROOT)}>
      <div className="flex flex-col gap-1">
        <Text type="body-sm">{definition.description}</Text>
        <div className={el`params`}>
          <p>{paramText.join('\n')}</p>
        </div>
      </div>
      <InteractableComponent
        onPress={() => {
          removeWorkflowTransformation(
            p.workflowId,
            p.transformation.transformationConfig.id
          );
        }}
        className="text-red-500 cursor-pointer"
        asChild
      >
        {svgTrash}
      </InteractableComponent>
    </div>
  );
};
