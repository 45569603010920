import { useLocalStorage } from 'usehooks-ts';

const APP_CUES_STORAGE_KEY = 'vooma-app-cues';

export enum AppCueName {
  STORE_ALLOWED_SENDER = 'STORE_ALLOWED_SENDER',
  SAVE_ORIGINATOR_CONTACT = 'SAVE_ORIGINATOR_CONTACT',
}
export enum CueTrackEvents {
  VIEW = 'Viewed Cue',
  ACCEPT = 'Accepted Cue',
  DISMISS = 'Dismissed Cue',
  HIDE_FOREVER = 'Clicked Hide Cue Forever',
}

type AppCueValue = {
  viewed: boolean;
  hide: boolean;
};

type AppCuesActions = {
  viewCue: (cue: AppCueName) => void;
  hideCue: (cue: AppCueName) => void;
  getCue: (cue: AppCueName) => AppCueValue | undefined;
};

export const useInAppCues = (): AppCuesActions => {
  const [appCues, setAppCues] = useLocalStorage<
    Record<AppCueName, AppCueValue | undefined>
  >(APP_CUES_STORAGE_KEY, {} as Record<AppCueName, AppCueValue>);

  const viewCue = (cue: AppCueName) => {
    const current = appCues[cue];

    setAppCues({
      ...appCues,
      [cue]: { viewed: true, hide: current?.hide ?? false },
    });
  };

  const hideCue = (cue: AppCueName) => {
    const current = appCues[cue];

    setAppCues({
      ...appCues,
      [cue]: { hide: true, viewed: current?.viewed ?? false },
    });
  };

  const getCue = (cue: AppCueName): AppCueValue | undefined => {
    return appCues[cue];
  };

  return { viewCue, hideCue, getCue };
};
