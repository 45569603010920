import {
  TextArea,
  TextField,
  TimeSelect,
} from '@shared/components/react-hook-form';
import { StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { KeyboardEvent, ReactElement, ReactNode } from 'react';
import { Path } from 'react-hook-form';
import { el } from '../JSONDefinedForm';
import { EditableJSONField, FORM_STATE_FIELD } from '../types';

const determineFieldType = (data: EditableJSONField) => {
  if (data._type) {
    switch (data._type) {
      case 'TIME':
        return 'time';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }
  return 'text';
};

const determineComponentType = (p: BasicTextFieldProps) => {
  const isTimeField = determineFieldType(p.data) === 'time';
  if (isTimeField) {
    return TimeSelect;
  }

  const isMultiline = p.prefix?.toLowerCase().includes('notes');
  if (isMultiline) {
    return TextArea;
  }

  return TextField;
};

type BasicTextFieldProps = StyleProps & {
  fieldValue: string;
  data: EditableJSONField;
  prefix?: string;
  label?: ReactNode;
  hideLabel?: boolean;
  isRequired?: boolean;
  confidenceBucket: 'high' | 'low';
  wrapperChildren?: ReactElement | ReactElement[];
  wrapperChildrenPlacement?: 'inside' | 'outside';
  onTouchField: (e: KeyboardEvent) => void;
  onChange: (e: string) => void;
  onFocusChange: (e: boolean) => void;
  clear: () => void;
};

export function BasicTextField(p: BasicTextFieldProps) {
  const {
    fieldValue,
    data,
    prefix,
    label,
    hideLabel,
    isRequired,
    confidenceBucket,
    wrapperChildren,
    wrapperChildrenPlacement,
    onTouchField,
    onChange,
    onFocusChange,
    clear,
    className,
  } = p;

  const fieldType = determineFieldType(data);
  const TextFieldType = determineComponentType(p);
  const maxLength = prefix?.includes('commodity') ? 50 : undefined;

  return (
    <TextFieldType
      className={clsx(className, el`field`)}
      inputClassName={clsx(el`input`, `confidence-${confidenceBucket}`)}
      defaultValue={data[FORM_STATE_FIELD]}
      onKeyDown={onTouchField}
      onChange={onChange}
      onFocusChange={onFocusChange}
      onBlur={() => onFocusChange(false)}
      label={hideLabel ? undefined : label}
      aria-label={label as string}
      isRequired={isRequired}
      rules={{
        required: isRequired,
      }}
      maxLength={maxLength}
      value={fieldValue}
      name={`${prefix}${FORM_STATE_FIELD}` as Path<EditableJSONField>}
      size="small"
      type={fieldType}
      onClear={clear}
      wrapperChildren={wrapperChildren}
      wrapperChildrenPlacement={wrapperChildrenPlacement}
    />
  );
}
