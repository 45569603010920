import { CircularProgress } from '@shared/components/plugin-components';
import { useToast } from '@shared/components/toast';
import {
  useGetOriginatorsQuery,
  useOrderQuery,
} from '@shared/generated/graphql';
import { orderFromFragment } from '@shared/graphql/fromFragments/order';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { originatorsFromFragment } from '@shared/plugin/types/fromFragments/originator';
import { Originator } from '@shared/plugin/types/originator';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { ORDERS_ROUTE } from 'clerk_common/constants/urls/routes';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useReprocessOrder } from '../OrderSidePanel/hooks/useReprocessOrder';
import { OriginatorOnboardingWizard } from './components/OriginatorOnboardingWizard';
import { OriginatorOnboardingType } from './components/OriginatorOnboardingWizard/types';

const ROOT = makeRootClassName('OriginatorOnboarding');
export const el = makeElementClassNameFactory(ROOT);

export const getOnboardingRoute = (
  type: OriginatorOnboardingType,
  orderId: string,
  originatorId: string
) => {
  return `${ORDERS_ROUTE}/${orderId}/originator-onboarding/${originatorId}?onboardingType=${type}`;
};

interface OriginatorOnboardingProps {
  onboardingType: OriginatorOnboardingType;
  loading: boolean;
  originator?: Originator;
  onSubmit: () => void;
}

const OriginatorOnboarding = ({
  onboardingType,
  loading,
  originator,
  onSubmit,
}: OriginatorOnboardingProps) => {
  return (
    <div className={el`onboarding-container`}>
      <div className={el`onboarding-details`}>
        {isNil(originator) ? (
          <OriginatorNotFoundOrLoading loading={loading} />
        ) : (
          <OriginatorOnboardingWizard
            originator={originator}
            onboardingType={onboardingType}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

type OriginatorNotFoundOrLoadingProps = {
  loading: boolean;
};
const OriginatorNotFoundOrLoading = (p: OriginatorNotFoundOrLoadingProps) => {
  if (p.loading) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <CircularProgress />
      </div>
    );
  }
  return <div>Originator not found</div>;
};

const OriginatorOnboardingContainer = () => {
  const { page } = useAnalytics();
  const { orderId, originatorId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const { reprocess } = useReprocessOrder(orderId);
  const onboardingType = searchParams.get(
    'onboardingType'
  ) as unknown as OriginatorOnboardingType;
  const { data: originatorData, loading: originatorLoading } =
    useGetOriginatorsQuery({
      variables: {
        input: {
          id: originatorId,
        },
      },
      skip: !originatorId,
    });

  const { data } = useOrderQuery({
    variables: {
      id: orderId ?? '',
    },
    skip: !orderId,
  });

  const order = data?.orderById ? orderFromFragment(data?.orderById) : null;
  const originators = originatorsFromFragment(originatorData);
  const originator = originators.find((o) => o.id === originatorId);

  useEffect(() => {
    if (originator?.name) {
      page('Originator Onboarding', {
        orderId: order?.id,
        originatorId,
        originatorName: originator?.name,
      });
    }
  }, [originator?.name]);

  const handleSubmit = () => {
    sendToast(`${originator?.name || 'Customer'} configured successfully!`, {
      description: 'Now reprocessing the order with the new configurations...',
      variant: 'success',
    });
    reprocess();
    navigate(-1);
  };

  return (
    <OriginatorOnboarding
      originator={originator}
      loading={originatorLoading}
      onboardingType={onboardingType}
      onSubmit={handleSubmit}
    />
  );
};

export default OriginatorOnboardingContainer;
