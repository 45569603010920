import { Text } from '@shared/components';
import { QuoteTrailRoute } from '@shared/types/quote-event';
import React from 'react';
import { PiArrowRightBold } from 'react-icons/pi';

interface AuditTrailLaneSnippetProps {
  route: QuoteTrailRoute;
  originatorName: string;
  pickupDate: string;
}

const AuditTrailLaneSnippet: React.FC<AuditTrailLaneSnippetProps> = ({
  route,
  originatorName,
  pickupDate,
}: AuditTrailLaneSnippetProps) => {
  return (
    <div className="select-none border-b-2 border-gray-200 border-solid py-2">
      <Text type="body-xs" className="text-gray-700">
        {pickupDate}
      </Text>
      <Text isHeavy={true} type="body-xs" className="text-gray-700">
        {originatorName}
      </Text>
      <div className="flex items-center justify-items-start">
        <Text type="body-xs" isHeavy={true} className="text-gray-700">
          {`${route.pickup.city}, ${route.pickup.state}`}
        </Text>
        <div className="flex items-center justify-center w-6">
          <PiArrowRightBold size={12} />{' '}
        </div>
        <Text type="body-xs" isHeavy={true} className="text-gray-700">
          {`${route.destination.city}, ${route.destination.state}`}
        </Text>
      </div>
    </div>
  );
};

export default AuditTrailLaneSnippet;
