import {
  IntegrationCredentialsType,
  UsageIndicator,
  useHasIntegrationCredentialsQuery,
} from '@shared/generated/graphql';

type UseOrganizationHasCredentialProps = {
  organizationId: string;
  type: IntegrationCredentialsType;
  usageIndicator: UsageIndicator;
};

export function useOrganizationHasCredential({
  organizationId,
  type,
  usageIndicator,
}: UseOrganizationHasCredentialProps) {
  const { data, loading, refetch } = useHasIntegrationCredentialsQuery({
    variables: {
      input: { organizationId, type, usageIndicator },
    },
    fetchPolicy: 'network-only',
  });
  const credsExist = data?.hasIntegrationCredentials.hasIntegrationCredentials;
  return { hasCredential: credsExist, loading, refetch };
}
