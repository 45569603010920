import { Modal } from '@shared/components';
import { isNil } from 'lodash';

import { useToast } from '@shared/components/toast';
import { Wizard } from '@shared/components/wizard';
import { EveryStepProps } from '@shared/components/wizard/Wizard';
import {
  OriginatorEventType,
  TrackProperties,
  UpdateOriginatorInput,
  useCreateOriginatorEventMutation,
  useUpdateOriginatorMutation,
  useUpdateTemplateMutation,
} from '@shared/generated/graphql';
import useOrderTemplate from '@shared/graphql/hooks/templates/useOrderTemplate';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Originator } from '@shared/plugin/types/originator';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getTemplateJson } from '@shared/utils/getTemplateJson';
import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { useNavigate } from 'react-router-dom';
import { OriginatorOnboardingConclusion } from './components/Conclusion';
import {
  GetStartedData,
  OriginatorOnboardingGetStarted,
} from './components/GetStarted';
import { PrepareOriginatorForOnboarding } from './components/PrepareForOnboarding';
import {
  fromReferenceValues,
  ReferencesStep,
  toReferenceDefaultValues,
} from './components/ReferencesStep';
import { TransmitIdsData, TransmitIdsStep } from './components/TransmitIdsStep';
import { OriginatorOnboardingType } from './types';

const ROOT = makeRootClassName('OriginatorOnboardingWizard');
export const el = makeElementClassNameFactory(ROOT);

enum OriginatorOnboardingStepNames {
  GET_STARTED = 'Get Started',
  TRANSMIT_IDS = 'Customer Codes',
  REFERENCES = 'References',
  CONCLUSION = 'Conclusion',
}

interface OriginatorOnboardingWizardProps {
  onSubmit: () => void;
  originator: Originator;
  onboardingType: OriginatorOnboardingType;
}

export function OriginatorOnboardingWizard({
  onSubmit,
  originator,
  onboardingType,
}: OriginatorOnboardingWizardProps) {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { sendToast } = useToast();
  const [updateOriginator] = useUpdateOriginatorMutation();
  const { template } = useOrderTemplate({ originatorId: originator.id });
  const templateData = getTemplateJson(template);
  const [updateTemplate] = useUpdateTemplateMutation();
  const [createOriginatorEvent] = useCreateOriginatorEventMutation();

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const handleUpdateOriginator = (
    input: Pick<UpdateOriginatorInput, 'name' | 'transmitIds'>
  ) => {
    if (!originator.id) return;
    updateOriginator({
      variables: {
        input: {
          id: originator.id,
          ...input,
        },
      },
      refetchQueries: ['GetOriginators'],
    }).catch((e) => sendErrorToast(e));
  };

  const handleUpdateTemplate = async (
    newData: MaybeCompletedFreightOrderTemplate
  ) => {
    if (!template?.id) return;
    await updateTemplate({
      variables: {
        input: {
          id: template.id,
          specializedTemplate: JSON.stringify(newData, null, 2),
        },
      },
    }).catch((e) => sendErrorToast(e));
  };

  // TODO(parlato): Pass this down and track each step view
  const trackProperties: TrackProperties = {
    originatorId: originator.id,
    originatorName: originator.name,
  };

  const handleCloseOrCompletion = () => {
    if (!isNil(originator?.id)) {
      createOriginatorEvent({
        variables: {
          input: {
            originatorId: originator.id,
            name: OriginatorEventType.EXTRACTED_FIELDS_CONFIGURED,
          },
        },
      }).catch((e) => console.error(e));
    }
  };

  const handleClose = () => {
    track('Originator Onboarding Wizard Closed', trackProperties);
    handleCloseOrCompletion();

    navigate(-1);
  };

  const trackStepSubmitted = (stepName: OriginatorOnboardingStepNames) => {
    track(`${stepName} Step Submitted`, trackProperties);
  };

  const getPageNumber = (currentStep: number, maxStep: number) => {
    const adjustment =
      onboardingType === OriginatorOnboardingType.EXISTING_ORIGINATOR ? 1 : 0;
    return `${currentStep + adjustment}  / ${maxStep + adjustment}`;
  };

  return (
    <Modal.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) handleClose();
      }}
    >
      <Modal.Content size="takeover">
        {isNil(template) ? (
          <PrepareOriginatorForOnboarding
            originatorId={originator.id}
            onClose={() => navigate(-1)}
          />
        ) : (
          <Wizard
            className={ROOT}
            steps={[
              ...(onboardingType ===
              OriginatorOnboardingType.EXISTING_ORIGINATOR
                ? []
                : [
                    {
                      stepKey: 'getStarted',
                      title: OriginatorOnboardingStepNames.GET_STARTED,
                      hideTitle: true,
                      onStepSubmit: (data: GetStartedData) => {
                        handleUpdateOriginator({ name: data.originatorName });
                        trackStepSubmitted(
                          OriginatorOnboardingStepNames.GET_STARTED
                        );
                      },
                      renderForm: (p: EveryStepProps) => (
                        <OriginatorOnboardingGetStarted
                          {...p}
                          onClose={handleClose}
                          originator={originator}
                          defaultValues={{
                            originatorName: originator.name ?? '',
                          }}
                          onboardingType={onboardingType}
                        />
                      ),
                    },
                  ]),
              {
                stepKey: 'transmitIds',
                title: 'Customer Codes',
                hideTitle: true,
                renderForm: (p) => (
                  <TransmitIdsStep
                    {...p}
                    defaultValues={{
                      transmitIds: originator.transmitIds,
                    }}
                    onClose={handleClose}
                    originator={originator}
                    onboardingType={onboardingType}
                  />
                ),
                getPageNumber,
                onStepSubmit: (data: TransmitIdsData) => {
                  handleUpdateOriginator({
                    transmitIds: data.transmitIds,
                  });
                  trackStepSubmitted(
                    OriginatorOnboardingStepNames.TRANSMIT_IDS
                  );
                },
              },
              {
                stepKey: 'references',
                title: 'References',
                hideTitle: true,
                renderForm: (p) => (
                  <ReferencesStep
                    {...p}
                    defaultValues={toReferenceDefaultValues(templateData)}
                    onClose={handleClose}
                  />
                ),
                onStepSubmit: (data) => {
                  const updated = fromReferenceValues(templateData, data);
                  if (updated) {
                    handleUpdateTemplate(updated);
                  }
                  trackStepSubmitted(OriginatorOnboardingStepNames.REFERENCES);
                },
                getPageNumber,
              },
              {
                stepKey: 'conclusion',
                title: 'Conclusion',
                hideTitle: true,
                renderForm: (p) => (
                  <OriginatorOnboardingConclusion
                    {...p}
                    onClose={handleClose}
                    originator={originator}
                  />
                ),
              },
            ]}
            onSubmit={() => {
              track('Originator Onboarding Wizard Completed', trackProperties);
              handleCloseOrCompletion();
              onSubmit();
            }}
            shouldSquashPages={true}
          />
        )}
      </Modal.Content>
    </Modal.Root>
  );
}
