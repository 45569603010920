import {
  QuoteBidStatus,
  QuoteDocument,
  QuoteEventType,
  useCreateQuoteEventMutation,
} from '@shared/generated/graphql';

export const useUpdateQuoteStatus = () => {
  const [createQuoteEvent, { loading, error }] = useCreateQuoteEventMutation();

  const updateQuoteStatus = (quoteId: string, status: QuoteBidStatus) => {
    let type;
    // case when statement to set type based on status
    switch (status) {
      case QuoteBidStatus.WON:
        type = QuoteEventType.MARKED_AS_WON;
        break;
      case QuoteBidStatus.LOST:
        type = QuoteEventType.MARKED_AS_LOST;
        break;
      case QuoteBidStatus.QUOTED:
        type = QuoteEventType.MARKED_AS_QUOTED;
        break;
      default:
        type = QuoteEventType.MARKED_AS_NEW;
    }

    return createQuoteEvent({
      variables: {
        input: {
          quoteId,
          type,
        },
      },
      refetchQueries: [{ query: QuoteDocument, variables: { id: quoteId } }],
    });
  };

  return {
    updateQuoteStatus,
    loading,
    error,
  };
};
