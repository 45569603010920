import { Button } from '@shared/components/button';
import { CircularProgress } from '@shared/components/circular-progress';
import { useComputeRates } from '../hooks/useComputeRates';

type CollectRatesButtonProps = {
  quoteId: string;
};

export function CollectRatesButton(p: CollectRatesButtonProps) {
  const { compute, loading } = useComputeRates();

  const icon = loading ? (
    <svg>
      <CircularProgress />
    </svg>
  ) : undefined;

  return (
    <Button
      icon={icon}
      iconPosition="right"
      size="small"
      variant="secondary"
      onPress={() => compute(p.quoteId)}
    >
      Get rates
    </Button>
  );
}
