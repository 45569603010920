import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { AutocompleteBase } from '../autocomplete';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineAutocomplete');
const el = makeElementClassNameFactory(ROOT);

export type InlineAutocompleteOption = {
  id: string;
  label: string;
};

interface InlineAutocompleteProps<T> {
  label: string;
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
  options: InlineAutocompleteOption[];
  expandable?: boolean;
}

export const InlineAutocomplete = <T extends FieldValues>(
  p: InlineAutocompleteProps<T>
) => {
  const { watch, setValue } = useFormContext<T>();
  const value: string = watch(p.name);

  const handleSelect = (option: InlineAutocompleteOption) => {
    setValue(p.name, option.id as PathValue<T, Path<T>>);
    p.save(p.name);
  };

  const placeholder = `Add ${p.label.toLowerCase()}`;
  const selectedValue = p.options.find((o) => o.id === value)?.label || value;

  return (
    <div className={clsx(ROOT)}>
      <div className={el`table`}>
        <div className={el`label`}>
          <Text type="body-sm">{p.label}</Text>
        </div>
        <div className={el`field-and-icon-wrapper`}>
          <AutocompleteBase
            onChange={(_, value) => {
              handleSelect(value as InlineAutocompleteOption);
            }}
            options={p.options}
            inputProps={{
              required: p.required,
            }}
            value={selectedValue}
            selectOnFocus
            size="small"
            compact
            blurOnSelect
            transparentWithoutFocus
            inputWrapperClassName={clsx(
              el`input-wrapper`,
              selectedValue ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            placeholderClassName={clsx(
              el`placeholder`,
              selectedValue ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            placeholder={placeholder}
            expandable={p.expandable}
            className={el`field-wrapper`}
          />
          <FlowIcon fieldPath={p.name} />
        </div>
      </div>
    </div>
  );
};
