import {
  EditableJSONArray,
  EditableJSONObject,
  EditableUnit,
} from '@shared/components/json-defined-form/types';
import { DisplayConfigs } from 'clerk_common/templates/types';
import { startCase } from 'lodash';

const makeDisplayOptions = (
  ds?: DisplayConfigs['_display'],
  parentKey?: string
) => {
  return {
    ...ds,
    name: ds?.name ?? startCase(parentKey) ?? 'No Name',
    flattened: ds?.flattened ?? true,
    hidden: ds?.hidden ?? false,
  };
};
function addDisplaySettingsToObject(
  obj: EditableJSONObject
): EditableJSONObject {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, addDisplaySettings(value, key)];
    })
  );
}

const isEditableJSONArray = (obj: EditableUnit): obj is EditableJSONArray => {
  return Array.isArray(obj._value);
};

export function addDisplaySettings(
  obj: EditableUnit,
  parentKey?: string
): EditableUnit {
  if (typeof obj._value === 'string') {
    return {
      ...obj,
      _display: makeDisplayOptions(obj._display, parentKey),
    };
  }
  if (isEditableJSONArray(obj)) {
    return {
      ...obj,
      _value: obj._value.map((v) => addDisplaySettingsToObject(v)),
      ...(obj._added
        ? { _added: obj._added?.map((v) => addDisplaySettingsToObject(v)) }
        : {}),
      _display: makeDisplayOptions(obj._display, parentKey),
    };
  }
  return {
    ...obj,
    _value: addDisplaySettingsToObject(obj._value as EditableJSONObject),
    _display: makeDisplayOptions(obj._display, parentKey),
  };
}
