import { EditableJSONObject } from '@shared/components/json-defined-form/types';

interface MayHaveMetaData {
  _metadata?: EditableJSONObject;
}
export const multipleAreSuspected = (data: MayHaveMetaData) => {
  if (!data._metadata?.numOmitted?._value) return false;
  const numOmitted = parseInt(
    (data._metadata.numOmitted._value as string) || '0'
  );
  return numOmitted > 0;
};
