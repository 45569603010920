export const stateCodes = {
  USA: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
  CAN: [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon' },
  ],
  MEX: [
    { value: 'AGU', label: 'Aguascalientes' },
    { value: 'BCN', label: 'Baja California' },
    { value: 'BCS', label: 'Baja California Sur' },
    { value: 'CAM', label: 'Campeche' },
    { value: 'CHP', label: 'Chiapas' },
    { value: 'CHH', label: 'Chihuahua' },
    { value: 'COA', label: 'Coahuila' },
    { value: 'COL', label: 'Colima' },
    { value: 'DUR', label: 'Durango' },
    { value: 'GUA', label: 'Guanajuato' },
    { value: 'GRO', label: 'Guerrero' },
    { value: 'HID', label: 'Hidalgo' },
    { value: 'JAL', label: 'Jalisco' },
    { value: 'MEX', label: 'Mexico' },
    { value: 'MIC', label: 'Michoacan' },
    { value: 'MOR', label: 'Morelos' },
    { value: 'NAY', label: 'Nayarit' },
    { value: 'NLE', label: 'Nuevo Leon' },
    { value: 'OAX', label: 'Oaxaca' },
    { value: 'PUE', label: 'Puebla' },
    { value: 'QUE', label: 'Queretaro' },
    { value: 'ROO', label: 'Quintana Roo' },
    { value: 'SLP', label: 'San Luis Potosi' },
    { value: 'SIN', label: 'Sinaloa' },
    { value: 'SON', label: 'Sonora' },
    { value: 'TAB', label: 'Tabasco' },
    { value: 'TAM', label: 'Tamaulipas' },
    { value: 'TLA', label: 'Tlaxcala' },
    { value: 'VER', label: 'Veracruz' },
    { value: 'YUC', label: 'Yucatan' },
    { value: 'ZAC', label: 'Zacatecas' },
  ],
};

export const getStateLabelFromAbbreviation = (state: string): string | null => {
  for (const states of Object.values(stateCodes)) {
    const stateLabelValue = states.find(
      (stateLabelValue) => stateLabelValue.value === state
    );
    if (stateLabelValue) {
      return stateLabelValue.label;
    }
  }
  return null;
};

export const getStateAbbreviationFromLabel = (
  abbreviationOrLabel: string
): string | null => {
  for (const states of Object.values(stateCodes)) {
    const stateLabelValue = states.find(
      (stateLabelValue) =>
        stateLabelValue.label === abbreviationOrLabel ||
        stateLabelValue.value === abbreviationOrLabel
    );
    if (stateLabelValue) {
      return stateLabelValue.value;
    }
  }
  return null;
};

export function getStateOptions(
  country?: string
): { label: string; value: string }[] {
  if (country && Object.keys(stateCodes).includes(country)) {
    return stateCodes[country as keyof typeof stateCodes];
  }
  return Object.values(stateCodes).flat();
}

export function getCountryFromState(state: string): string | null {
  const findStatePredicate = (stateLabelValue: {
    value: string;
    label: string;
  }) => {
    const options = [stateLabelValue.value, stateLabelValue.label].map(
      (value) => value.toLowerCase()
    );
    return options.includes(state.toLowerCase());
  };

  for (const [country, states] of Object.entries(stateCodes)) {
    if (states.find(findStatePredicate)) {
      return country;
    }
  }

  return null;
}
