import {
  GetTemplatesQuery,
  TemplateFragment,
  TemplatePreviewFragment,
} from '@shared/generated/graphql';
import { Template } from '@shared/types/template';

export function templateFromPreviewFragment(
  fragment: TemplatePreviewFragment
): Template {
  return {
    id: fragment.id,
    type: fragment.type,
    specializedTemplate: fragment.currentRevision?.jsonData ?? '{}',
  };
}

export const templatesFromFragment = (query: GetTemplatesQuery): Template[] => {
  return query.templates.edges.map(({ node }) =>
    templateFromPreviewFragment(node)
  );
};

export function templateFromFragment(fragment: TemplateFragment): Template {
  return {
    id: fragment.id,
    type: fragment.type,
    specializedTemplate: fragment.currentRevision?.jsonData ?? '{}',
    orderBuildPrompt: fragment.orderBuildPrompt ?? '',
    multiOrderPrompt: fragment.multiOrderPrompt ?? '',
    modelConfig: fragment.modelConfig ?? undefined,
  };
}
