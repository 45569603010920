import { LocationSelect, Text } from '@shared/components';
import { SelectablePlace } from '@shared/components/location-select/LocationSelect';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useSelectedOriginator } from '@shared/contexts/OriginatorContext';
import usePlaceOptions from '@shared/hooks/usePlaceOptions';
import { StyleProps } from '@shared/utils';
import { getNonNullableOrganizationId } from '@shared/utils/organizationId';
import { el } from './LocationEntityCard';

type OriginatorLocationSelectProps = StyleProps & {
  buttonText: string;
  selectedId?: string;
  onSelect: (id: string) => void;
};
export function OriginatorLocationSelect(p: OriginatorLocationSelectProps) {
  const { defaultOrgId } = useMeContext();
  const originator = useSelectedOriginator();
  const { places, isLoading, setSearchTerm } = usePlaceOptions({
    organizationId: getNonNullableOrganizationId(defaultOrgId),
    originatorIds: originator?.id ? [originator.id] : [],
  });
  const onSelect = (place: SelectablePlace) => {
    p.onSelect(place.id);
  };

  return (
    <LocationSelect
      places={places}
      isLoading={isLoading}
      setSearchTerm={setSearchTerm}
      buttonContent={
        <Text type="body-xs" isHeavy>
          {p.buttonText}
        </Text>
      }
      buttonTooltip="Select from this originator's saved locations, replacing the data shown here"
      selectedId={p.selectedId}
      onSelect={onSelect}
      className={el`select-button`}
    />
  );
}
