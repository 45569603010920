const colours = [
  '#FFBD70',
  '#FF8C42',
  '#C65D11',
  '#003B41',
  '#00666C',
  '#44949A',
];

export function getUserColorGroup(userUUID: string) {
  const index = +('0x' + userUUID.slice(-6)) % colours.length;
  return index;
}

export function getUserColour(colorGroup: number) {
  return colours[colorGroup];
}
