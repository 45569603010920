import { Button } from '@shared/components/button';
import { PopoverMultiSelect } from '@shared/components/popover-multiselect/PopoverMultiSelect';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import {
  addMissingValueFields,
  getAddOrRemoveCommodityFieldOptions,
} from 'clerk_common/templates/freight_order/configuration/';
import { convertToMultiCommodity } from 'clerk_common/templates/freight_order/configuration/commodity/convertToMultiCommodity';
import {
  addFreightClass,
  removeFreightClass,
} from 'clerk_common/templates/freight_order/configuration/commodity/freightClass';
import {
  addHandlingQuantitySection,
  removeHandlingQuantitySection,
} from 'clerk_common/templates/freight_order/configuration/commodity/handlingQuantity';
import {
  addItemReference,
  removeItemReference,
} from 'clerk_common/templates/freight_order/configuration/commodity/itemReference';
import {
  addNmfcCode,
  removeNmfcCode,
} from 'clerk_common/templates/freight_order/configuration/commodity/nmfcCode';
import {
  addPieceQuantitySection,
  removePieceQuantitySection,
} from 'clerk_common/templates/freight_order/configuration/commodity/pieceQuantity';
import { TemplateWithCommodity } from 'clerk_common/templates/freight_order/configuration/commodity/types';
import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { useEffect } from 'react';
import { PiPlus, PiPlusMinusBold } from 'react-icons/pi';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableUnit } from '../../types';

type ConfigureCommodityProps = {
  isMultiCommodity: boolean;
};

export function ConfigureCommodity(props: ConfigureCommodityProps) {
  const {
    handleUpdateTemplate,
    handleUpdateData,
    extractedData,
    templateData,
    setPendingAddElementPrefix,
  } = useInternalFormContext();
  const { track } = useAnalytics();

  useEffect(() => {
    track('Viewed Configure Commodity');
  }, []);

  const mutateOrderAndTemplate = async (
    configurationHelper: (
      template: MaybeCompletedFreightOrderTemplate
    ) => TemplateWithCommodity
  ) => {
    track('Mutate Order and Template', { name: configurationHelper.name });
    if (extractedData && handleUpdateData) {
      const newExtractedData = addMissingValueFields(
        configurationHelper(extractedData as MaybeCompletedFreightOrderTemplate)
      );
      await handleUpdateData(newExtractedData as EditableUnit);
    }

    if (templateData && handleUpdateTemplate) {
      const newTemplate = configurationHelper(templateData);

      await handleUpdateTemplate(
        newTemplate as MaybeCompletedFreightOrderTemplate
      );
    }
  };

  const onConvertToMultiCommodity = async () => {
    await mutateOrderAndTemplate(convertToMultiCommodity);
    setPendingAddElementPrefix('_value.commodities.');
  };

  const onAddHandlingUnits = async () => {
    await mutateOrderAndTemplate(addHandlingQuantitySection);
  };

  const onAddPieceUnits = async () => {
    await mutateOrderAndTemplate(addPieceQuantitySection);
  };

  const onAddFreightClass = async () => {
    await mutateOrderAndTemplate(addFreightClass);
  };

  const onAddNmfcCode = async () => {
    await mutateOrderAndTemplate(addNmfcCode);
  };

  const onAddItemReference = async () => {
    await mutateOrderAndTemplate(addItemReference);
  };

  const onRemoveHandlingUnits = async () => {
    await mutateOrderAndTemplate(removeHandlingQuantitySection);
  };

  const onRemovePieceUnits = async () => {
    await mutateOrderAndTemplate(removePieceQuantitySection);
  };

  const onRemoveFreightClass = async () => {
    await mutateOrderAndTemplate(removeFreightClass);
  };

  const onRemoveNmfcCode = async () => {
    await mutateOrderAndTemplate(removeNmfcCode);
  };

  const onRemoveItemReference = async () => {
    await mutateOrderAndTemplate(removeItemReference);
  };

  const handleAddOrRemoveOptionSelected = (id: string, shouldAdd: boolean) => {
    if (shouldAdd) {
      handleAddOptionSelected(id);
    } else {
      handleRemoveOptionSelected(id);
    }
  };

  const handleAddOptionSelected = (id: string) => {
    switch (id) {
      case 'handlingUnits':
        onAddHandlingUnits();
        break;
      case 'pieceUnits':
        onAddPieceUnits();
        break;
      case 'freightClass':
        onAddFreightClass();
        break;
      case 'nmfcCode':
        onAddNmfcCode();
        break;
      case 'itemReference':
        onAddItemReference();
        break;
    }
  };

  const handleRemoveOptionSelected = (id: string) => {
    switch (id) {
      case 'handlingUnits':
        onRemoveHandlingUnits();
        break;
      case 'pieceUnits':
        onRemovePieceUnits();
        break;
      case 'freightClass':
        onRemoveFreightClass();
        break;
      case 'nmfcCode':
        onRemoveNmfcCode();
        break;
      case 'itemReference':
        onRemoveItemReference();
        break;
    }
  };

  const options = templateData
    ? getAddOrRemoveCommodityFieldOptions(templateData)
    : [];

  return (
    <div>
      {!props.isMultiCommodity ? (
        <div className="flex flex-row justify-end w-full">
          <Button
            onPress={onConvertToMultiCommodity}
            variant="tertiary"
            size="small"
            icon={<PiPlus />}
          >
            Add Commodity Item
          </Button>
        </div>
      ) : null}
      {options.length ? (
        <div className="flex flex-row w-full">
          <PopoverMultiSelect
            options={options.map((option) => ({
              id: option.id,
              label: option.label,
              selected: option.hasOption,
            }))}
            onOptionChange={handleAddOrRemoveOptionSelected}
            buttonLabel="Add / Remove Commodity Field"
            buttonProps={{
              variant: 'tertiary',
              size: 'small',
              icon: <PiPlusMinusBold />,
            }}
            onOpen={() => track('Open Configure Commodity Popover')}
          />
        </div>
      ) : null}
    </div>
  );
}
