import { reverse } from 'lodash';
import { FeedItem } from '../types';

const getItemPriority = (item: FeedItem): number => {
  return item.priority || Number.MAX_SAFE_INTEGER;
};

const getItemEpoch = (item: FeedItem): number => {
  if (item.createdAt) {
    return new Date(item.createdAt).getTime();
  }

  return new Date(0).getTime();
};

export const itemComparator = (a: FeedItem, b: FeedItem): number => {
  if (a.priority !== b.priority) {
    return getItemPriority(a) - getItemPriority(b);
  }

  return getItemEpoch(b) - getItemEpoch(a);
};

export const sortItems = (...items: FeedItem[]): FeedItem[] => {
  return items.sort(itemComparator);
};

export const combineItems = (...items: FeedItem[]): FeedItem[] => {
  // NOTE(parlato): We show items bottom to top, so we reverse them here
  return reverse(items);
};
