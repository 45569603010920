import { QuoteValidationPayload } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { useEffect, useState } from 'react';
import { useComputeRates } from './useComputeRates';

export const useAutoFetchRates = ({
  validation,
  quote,
}: {
  validation?: QuoteValidationPayload;
  quote: Quote;
}) => {
  const { compute, loading } = useComputeRates();
  const [shouldAutoFetchRates, setShouldAutoFetchRates] = useState(false);

  useEffect(() => {
    // NOTE(parlato): we only auto fetch if we have an invalid quote
    // and we haven't fetched yet
    if (!shouldAutoFetchRates && validation?.valid === false) {
      setShouldAutoFetchRates(true);
    }
  }, [validation?.valid]);

  useEffect(() => {
    const quoteCarrierRateId = quote.carrierRate?.id;
    const quoteCustomerRateId = quote.customerRate?.id;
    const hasNoRates = !quoteCarrierRateId && !quoteCustomerRateId;

    if (!loading && validation?.valid && hasNoRates && shouldAutoFetchRates) {
      console.log('Auto fetching rates');
      compute(quote.id);
      setShouldAutoFetchRates(false);
    }
  }, [validation?.valid, shouldAutoFetchRates]);
};
