import { CircularProgress } from '@shared/components';
import {
  CustomerRateType,
  GetQuoteResponseMessageDocument,
  GetQuotesDocument,
  QuoteDocument,
  RatingMethod,
  useCreateCarrierRateMutation,
  useCreateCustomerRateMutation,
  useUpdateCarrierRateMutation,
  useUpdateCustomerRateMutation,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import {
  adjustCarrierRateComponents,
  adjustCustomerRateComponents,
  computeMarginDollars,
  computeMarginPercent,
  sumCarrierRate,
  toRateComponents,
} from '@shared/utils/rates/rates';
import { Rate, sumCustomerRate } from 'clerk_common/types';
import { Dictionary } from 'lodash';
import { useState } from 'react';
import { MoneyFormFields } from './components/MoneyFormFields';
import { RatingIntegrationsNotice } from './components/rating-integrations-notice/RatingIntegrationsNotice';
import { MoneyFormRateLineItem, MoneyFormValues } from './components/types';
import { RateErrorsContext } from './contexts/RateErrorContext';
import { useQuoteRateForm } from './hooks/useQuoteRateForm';
import { useUpdateQuoteRateMutations } from './hooks/useUpdateQuoteRateMutations';

type MoneyFormProps = {
  quote: Quote;
  compact?: boolean;
  hideGetRates?: boolean;
  webBaseUrl: string;
};
export function MoneyForm(p: MoneyFormProps) {
  const {
    rateForm,
    loading: loadingRateForm,
    refetch: refetchRateForm,
  } = useQuoteRateForm(p.quote.id);
  const [createCarrierRate] = useCreateCarrierRateMutation();
  const [createCustomerRate] = useCreateCustomerRateMutation();
  const [updateCarrierRate, { loading: loadingUpdateCarrierRate }] =
    useUpdateCarrierRateMutation();
  const [updateCustomerRate, { loading: loadingUpdateCustomerRate }] =
    useUpdateCustomerRateMutation();
  const { handleSetQuoteCustomerRate, handleSetQuoteCarrierRate } =
    useUpdateQuoteRateMutations();
  const [rateErrors, setRateErrors] = useState<Dictionary<string>>({});

  const setRateError = (ratingMethod: RatingMethod, error: string) => {
    setRateErrors((prev) => ({ ...prev, [ratingMethod]: error }));
  };

  const clearRateErrors = () => {
    setRateErrors({});
  };

  const refetchQueries = [
    { query: QuoteDocument, variables: { id: p.quote.id } },
    GetQuotesDocument,
    GetQuoteResponseMessageDocument,
  ];

  const saveBuyRate = async (
    lineItems: MoneyFormRateLineItem[],
    createNewRate?: boolean
  ) => {
    const carrierRate = p.quote.carrierRate;
    if (!createNewRate && carrierRate) {
      const adjustedCarrierRate = adjustCarrierRateComponents(
        carrierRate,
        lineItems
      );

      if (!adjustedCarrierRate) return;

      await updateCarrierRate({
        variables: {
          input: {
            id: carrierRate.id,
            loadData: p.quote.extractedData,
            computedRate: adjustedCarrierRate,
          },
        },
        refetchQueries,
      });
    } else {
      const buyRate: Rate = {
        rateComponents: toRateComponents(lineItems, 'buy'),
      };

      const res = await createCarrierRate({
        variables: {
          input: {
            quoteId: p.quote.id,
            loadData: p.quote.extractedData,
            ratingMethod: RatingMethod.HUMAN_INPUT,
            computedRate: buyRate,
          },
        },
        refetchQueries,
      });
      const carrierRateId = res.data?.createCarrierRate.carrierRate.id;
      if (carrierRateId)
        await handleSetQuoteCarrierRate(p.quote.id, carrierRateId);
    }
  };

  const saveSellRate = async (lineItems: MoneyFormRateLineItem[]) => {
    const customerRate = p.quote.customerRate;

    if (customerRate) {
      const adjustedCustomerRate = adjustCustomerRateComponents(
        customerRate,
        lineItems
      );

      if (!adjustedCustomerRate) return;

      await updateCustomerRate({
        variables: {
          input: {
            id: customerRate.id,
            loadData: p.quote.extractedData,
            rate: adjustedCustomerRate,
          },
        },
        refetchQueries,
      });
    } else {
      const sellRate: Rate = {
        rateComponents: toRateComponents(lineItems, 'sell'),
      };

      const res = await createCustomerRate({
        variables: {
          input: {
            quoteId: p.quote.id,
            loadData: p.quote.extractedData,
            type: CustomerRateType.QUOTED,
            rate: sellRate,
          },
        },
        refetchQueries,
      });
      const customerRateId = res.data?.createCustomerRate.customerRate.id;
      if (customerRateId)
        await handleSetQuoteCustomerRate(p.quote.id, customerRateId);
    }
  };

  const onSubmit = async (data: MoneyFormValues, createNewRate?: boolean) => {
    await Promise.all([
      saveBuyRate(data.lineItems, createNewRate),
      saveSellRate(data.lineItems),
    ]);
  };

  const buyRate = sumCarrierRate(p.quote.carrierRate);
  const sellRate = sumCustomerRate(p.quote.customerRate);
  const marginPercent = computeMarginPercent(buyRate, sellRate);
  const marginDollars = computeMarginDollars(buyRate, sellRate);
  const useSellRate = Boolean(p.quote.customerRate);
  const initialRates = {
    buyRate: buyRate.toFixed(2),
    sellRate: useSellRate ? sellRate?.toFixed(2) : undefined,
    marginPercent: useSellRate ? marginPercent?.toFixed(2) : undefined,
    marginDollars: useSellRate ? marginDollars?.toFixed(2) : undefined,
    lineItems: rateForm?.lineItems ?? [],
  };

  return (
    <div>
      <RateErrorsContext.Provider
        value={{ rateErrors, setRateError, clearRateErrors }}
      >
        {loadingRateForm ? (
          <CircularProgress />
        ) : (
          <MoneyFormFields
            quote={p.quote}
            onSubmit={onSubmit}
            initialRates={initialRates}
            refetchRateForm={refetchRateForm}
            compact={p.compact}
            isSubmitting={loadingUpdateCarrierRate || loadingUpdateCustomerRate}
            hideGetRates={p.hideGetRates}
            sellRateExplanation={
              p.quote.customerRate?.metadata?.pricingStrategyReasoning
            }
          />
        )}
        <RatingIntegrationsNotice webBaseUrl={p.webBaseUrl} quote={p.quote} />
      </RateErrorsContext.Provider>
    </div>
  );
}
