import { useTextField } from '@react-aria/textfield';
import { useOptionalRef } from '@shared/hooks';
import { ForwardedRef, forwardRef, ReactElement } from 'react';
import { TextFieldBase, TextFieldProps } from './TextFieldBase';
// config

const DEFAULT_PROPS = {
  size: 'medium',
  required: false,
} as const;

// component

function TextFieldComponent(
  props: TextFieldProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);
  const inputRef = useOptionalRef<HTMLInputElement>(p.inputRef);

  // behavior
  // --------

  const behaviorProps = p;
  const { labelProps, inputProps } = useTextField(
    {
      ...p,
      type: p.type === 'button' ? 'text' : p.type, // fix tooltip
    },
    inputRef
  );

  return (
    <TextFieldBase
      ref={domRef}
      {...behaviorProps}
      labelProps={labelProps}
      inputProps={inputProps}
      inputRef={inputRef}
      inputClassName={p.inputClassName}
      inputWrapperClassName={p.inputWrapperClassName}
    />
  );
}

/**
 * A text input.
 */
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  TextFieldComponent
);

export default TextField;
