import { merge, omit } from 'lodash';
import { extractOneCommodity, toComplexCommodityBlock } from './common';
import {
  TemplateWithCommodity,
  TemplateWithComplexCommodity,
  TemplateWithSimpleCommodity,
} from './types';

export function convertToComplexCommodity(
  template: TemplateWithCommodity
): TemplateWithComplexCommodity {
  if (template._value.commodity) {
    return simpleToComplexCommodity(template);
  }
  if (template._value.commodityDetails) {
    return template;
  }
  if (template._value.commodities) {
    return multiToComplexCommodity(template);
  }
  throw new Error('No commodity found');
}

function simpleToComplexCommodity<T extends TemplateWithSimpleCommodity>(
  template: T
): TemplateWithComplexCommodity {
  const valueWithoutCommodity = omit(template._value, 'commodity');
  return {
    ...template,
    _value: merge({}, valueWithoutCommodity, {
      commodityDetails: toComplexCommodityBlock(template._value.commodity),
    }),
  };
}

function multiToComplexCommodity(
  template: TemplateWithCommodity
): TemplateWithComplexCommodity {
  const valueWithoutCommodities = omit(template._value, 'commodities');
  return {
    ...template,
    _value: merge({}, valueWithoutCommodities, {
      commodityDetails: extractOneCommodity(template._value.commodities),
    }),
  };
}
