import { Text } from '@shared/components';
import { el } from '../JSONDefinedForm';

type FieldLabelProps = {
  label?: string;
};
export function FieldLabel(p: FieldLabelProps) {
  return (
    <div className={el`field-label`}>
      <Text type="body-xs" isHeavy>
        {p.label}
      </Text>
    </div>
  );
}
