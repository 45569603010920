import {
  DefaultType,
  FlowsViewContext,
} from '@shared/contexts/FlowsViewContext';
import { useMe } from '@shared/contexts/hooks/useMe';
import {
  FlowTriggerType,
  JobType,
  OrderEventType,
  QuoteEventType,
} from '@shared/generated/graphql';
import { useDeepCompareEffect } from '@shared/hooks/useDeepCompareEffect';
import { useManageFlows } from '@shared/hooks/useManageFlows';
import { FLOW_MANAGEMENT_ENABLED } from '@shared/plugin/features/flags';
import { FlowsSidePanel } from '@shared/plugin/pages/FlowsSidePanel';
import {
  FieldType,
  getDefaultTfDef,
  getDefaultTfName,
} from '@shared/utils/flows/definitions';
import {
  extractDefaults,
  FieldTransformations,
  getTransformedFields,
} from '@shared/utils/flows/transformationStatus';
import { KnownType } from 'clerk_common';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { Address } from 'clerk_common/types/address';
import { isEqual, isNil } from 'lodash';
import { useState } from 'react';

export const FlowsViewProvider = ({ children }: { children: JSX.Element }) => {
  const [show, setShow] = useState(false);
  const [originatorId, setOriginatorId] = useState<string | undefined>(
    undefined
  );

  const { me } = useMe();
  const myOrg = me?.organizationsDetails[0].id;

  const [organizationId, setOrganizationId] = useState<string | undefined>(
    myOrg
  );
  const [extractedData, setExtractedData] = useState<
    CompletedFreightOrderTemplate | undefined
  >(undefined);

  const [transformedFields, setTransformedFields] =
    useState<FieldTransformations>({});

  const [jobType, setJobType] = useState<JobType | undefined>(undefined);

  const {
    orgWorkflows,
    origWorkflows,
    definitions,
    createWorkflow,
    updateWorkflow,
    createTransformationConfig,
    createTransformationDefinition,
  } = useManageFlows(organizationId, originatorId);

  const defaultedFields = [
    ...orgWorkflows.flatMap(extractDefaults),
    ...origWorkflows.flatMap(extractDefaults),
  ];

  const fetchTransformedFields = async () => {
    if (isNil(extractedData)) return;
    const fields = await getTransformedFields(extractedData, defaultedFields);
    setTransformedFields(fields);
  };

  useDeepCompareEffect(() => {
    fetchTransformedFields();
  }, [extractedData, defaultedFields]);

  const getFieldTransformationInfo = (fieldPath: string) => {
    const info = transformedFields[fieldPath];
    if (info?.status !== 'CAN_CREATE' || FLOW_MANAGEMENT_ENABLED) {
      return info;
    }
  };

  const getWorkflowToEdit = async () => {
    if (!jobType) {
      console.error('Unable to create a workflow without specifying a trigger');
      return;
    }

    const orderTrigger = {
      type: FlowTriggerType.ORDER_EVENT,
      config: {
        eventType: OrderEventType.AI_INFERENCE_COMPLETED,
      },
    };

    const quoteTrigger = {
      type: FlowTriggerType.QUOTE_EVENT,
      config: {
        eventType: QuoteEventType.AI_INFERENCE_COMPLETED,
      },
    };

    const trigger = jobType === 'ORDERS' ? orderTrigger : quoteTrigger;

    const existingWorkflow = origWorkflows.find((wf) =>
      isEqual(wf.trigger, trigger)
    );

    if (existingWorkflow) {
      return existingWorkflow;
    } else {
      return await createWorkflow(trigger);
    }
  };

  const findOrCreateDefaultTransformation = async (
    fieldType: FieldType,
    defaultType: DefaultType
  ): Promise<string | undefined> => {
    const existing = definitions.find(
      (def) => getDefaultTfName(fieldType, defaultType) == def.definition.name
    );
    if (!isNil(existing)) {
      return existing.id;
    }
    const newDef = await createTransformationDefinition(
      getDefaultTfDef(fieldType, defaultType)
    );
    if (!newDef) {
      console.error('Could not create transformation definition');
      return undefined;
    }
    return newDef.id;
  };

  const makeAddressParams = (fieldPath: string, value: Address) => ({
    prefix: fieldPath,
    addressOne: value.addressOne ?? '',
    addressTwo: value.addressTwo ?? '',
    city: value.city ?? '',
    state: value.state ?? '',
    zip: value.zip ?? '',
    country: value.country ?? '',
  });

  const makeStringParams = (fieldPath: string, value: string) => ({
    path: fieldPath,
    value,
  });

  const getParamsByType = (
    fieldPath: string,
    value: Address | string,
    fieldType: FieldType
  ) => {
    if (fieldType === 'ADDRESS') {
      return makeAddressParams(fieldPath, value as Address);
    } else {
      return makeStringParams(fieldPath, value as string);
    }
  };

  const addDefaultByType = async (
    fieldPath: string,
    value: Address | string,
    defaultType: DefaultType,
    fieldType: FieldType = 'STRING'
  ): Promise<string[]> => {
    const transformationDefnId = await findOrCreateDefaultTransformation(
      fieldType,
      defaultType
    );

    if (!transformationDefnId) {
      console.error('Could not find or create transformation definition');
      return [];
    }

    const params = getParamsByType(fieldPath, value, fieldType);

    const config = await createTransformationConfig(
      transformationDefnId,
      params
    );

    if (!config) {
      console.error('Could not create transformation config');
      return [];
    }

    return [config.id];
  };

  const addDefault = async (
    fieldPath: string,
    value: Address | string,
    defaultType: DefaultType,
    fieldType?: KnownType
  ) => {
    const workflow = await getWorkflowToEdit();
    if (!workflow) {
      console.error('Could not find or create workflow');
      return;
    }

    const newIds = await addDefaultByType(
      fieldPath,
      value,
      defaultType,
      fieldType
    );

    const transformationIds: string[] = [
      ...workflow.transformations.map((tf) => tf.transformationConfig.id),
      ...newIds,
    ];

    updateWorkflow(workflow.id, transformationIds);
    fetchTransformedFields();
  };

  const removeWorkflowTransformation = (workflowId: string, tfId: string) => {
    const workflow = [...orgWorkflows, ...origWorkflows].find(
      (wf) => wf.id === workflowId
    );
    if (!workflow) {
      console.error('Could not find workflow to modify');
      return;
    }
    const newTfIds = workflow.transformations
      .map((tf) => tf.transformationConfig.id)
      .filter((id) => id !== tfId);
    updateWorkflow(workflow.id, newTfIds);
    fetchTransformedFields();
  };

  return (
    <FlowsViewContext.Provider
      value={{
        show,
        setShow,
        originatorId,
        setOriginatorId,
        organizationId,
        setOrganizationId,
        jobType,
        setJobType,
        extractedData,
        setExtractedData,
        getFieldTransformationInfo,
        addDefault,
        removeWorkflowTransformation,
      }}
    >
      {children}
      {FLOW_MANAGEMENT_ENABLED && (
        <FlowsSidePanel
          originatorId={originatorId}
          organizationId={organizationId}
          showSidepanel={show}
          setShowSidepanel={setShow}
        />
      )}
    </FlowsViewContext.Provider>
  );
};
