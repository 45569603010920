import { TextType } from '@shared/components/text';

export function getTextProps(): {
  type: TextType;
  isHeavy?: boolean;
} {
  return { type: 'body-md', isHeavy: true };
}

export function getBackgroundColor() {
  return 'bg-white';
}
