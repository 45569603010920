import { Slot } from '@radix-ui/react-slot';
import { FocusRingProps, useFocusRing } from '@react-aria/focus';
import {
  HoverProps,
  PressHookProps,
  useHover,
  usePress,
} from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, useRef } from 'react';

type CommonProps = StyleProps &
  PressHookProps &
  HoverProps &
  FocusRingProps & {
    /**
     * The child elements to render.
     */
    children: ReactElement;

    onDragOver?: React.DragEventHandler;
    onDragLeave?: React.DragEventHandler;
    onDrop?: React.DragEventHandler;
  };

type ConditionalProps =
  | {
      /**
       * If true, merges this DOM node with the only child of this component.
       * This will merge the original component props with the props
       * of the supplied element/component and change the underlying DOM node
       * to that of the only child.
       *
       * If false, this component will be a `div`.
       *
       * @default false
       */
      asChild: true;
    }
  | ({
      asChild?: false;
    } & React.HTMLAttributes<HTMLDivElement>);

export type InteractableComponentProps = CommonProps & ConditionalProps;

const DEFAULT_PROPS = {
  asChild: false,
} as const;

function InteractableComp(
  props: InteractableComponentProps,
  ref: ForwardedRef<Element>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerRef = useRef<any>(ref);
  const { pressProps, isPressed } = usePress({
    ref: innerRef,
    isPressed: p.isPressed,
    isDisabled: p.isDisabled,
    preventFocusOnPress: p.preventFocusOnPress,
    shouldCancelOnPointerExit: p.shouldCancelOnPointerExit,
    allowTextSelectionOnPress: p.allowTextSelectionOnPress,
    onPress: p.onPress,
    onPressStart: p.onPressStart,
    onPressEnd: p.onPressEnd,
    onPressChange: p.onPressChange,
    onPressUp: p.onPressUp,
  });
  const { hoverProps, isHovered } = useHover({
    isDisabled: p.isDisabled,
    onHoverStart: p.onHoverStart,
    onHoverEnd: p.onHoverEnd,
    onHoverChange: p.onHoverChange,
  });
  const { focusProps, isFocused, isFocusVisible } = useFocusRing({
    within: p.within,
    isTextInput: p.isTextInput,
    autoFocus: p.autoFocus,
  });
  const behaviorProps = mergeProps(pressProps, hoverProps, focusProps);

  const Comp = p.asChild ? Slot : 'div';

  return (
    <Comp
      ref={innerRef}
      className={clsx(
        {
          'is-disabled': p.isDisabled,
          'is-pressed': isPressed,
          'is-hovered': isHovered,
          'is-focused': isFocused,
          'is-focus-visible': isFocusVisible,
        },
        p.className
      )}
      onDragOver={p.onDragOver}
      onDragLeave={p.onDragLeave}
      onDrop={p.onDrop}
      {...behaviorProps}
    >
      {p.children}
    </Comp>
  );
}

const InteractableComponent = forwardRef<Element, InteractableComponentProps>(
  InteractableComp
);

export default InteractableComponent;
