import { RateComponentType } from './pricingStrategy';

export enum SunsetRateComponentDescription {
  LINE_HAUL = 'Line Haul',
  UNKNOWN_ACCESSORIAL = 'Unknown Accessorial',
  UNKNOWN_ACCESSORIAL_BASE = 'Unknown Accessorial Base',
}

export enum DATRateComponentDescription {
  EIGHT_DAY_AVG_TRIP_RATE = '8 Day Avg.Trip Rate',
  THIRTY_FIVE_DAY_AVG_TRIP_RATE = '35 Day Avg. Trip Rate',
  FIFTY_TWO_WEEK_AVG_TRIP_RATE = '52 Week Avg. Trip Rate',
  AVG_FORECASTED_RATE = 'Avg. Forecasted Rate',
  LINE_HAUL_RATE = 'Line Haul',
  FUEL_SURCHARGE = 'Fuel Surcharge',
}

export enum GreenscreensRateComponentDescription {
  LINE_HAUL_RATE = 'Line Haul',
  FUEL_SURCHARGE = 'Fuel Surcharge',
}

export type RateComponent = {
  value: number;
  description: string;
  type: RateComponentType;
  index?: number;
  componentId?: string;
  // NOTE(parlato) - hidden is deprecated
  hidden?: boolean;
};

export type Rate = {
  rateComponents: RateComponent[];
};

export const filterRateComponents = (
  rateComponents?: RateComponent[] | null
): RateComponent[] => {
  if (!rateComponents) return [];
  return rateComponents?.filter((rc) => !rc.hidden);
};

type HasRate = { rate?: Rate | null };
export function sumCustomerRate<T extends HasRate>(customerRate?: T) {
  if (!customerRate?.rate) return undefined;
  return filterRateComponents(customerRate.rate.rateComponents).reduce(
    (acc, curr) => acc + curr.value,
    0
  );
}
