import { cloneDeep, isArray, isNil, isObject, merge, reduce } from 'lodash';
import { ConstrainedKeysOf } from '../primitives';
import { CompletedTemplateChildConfigs, FormValue } from './types';

interface Gettable<T> {
  _value: T;
  _sanitized?: T;
  _entity?: T;
  _transformed?: T;
  _external?: T;
  _corrected?: T;
  _idxs?: string[];
  _added?: T;
}

interface GettableArray<T> {
  _value: T[];
  _idxs?: string[];
  _added?: T[];
}

const getBestValueField = <T>(f: Gettable<T>): keyof Gettable<T> => {
  // We use the value that was added latest in the processing chain.
  // The order of precedence is:
  // 1. User corrections (_corrected)
  // 2. Values resulting from load transformations (_transformed)
  // 3. Values resulting from external data (_external)
  // 4. Values resulting from entity recognition (_entity)
  // 5. Sanitized values (_sanitized)
  // 6. Raw LLM values (_value)
  if (!isNil(f._corrected)) {
    return '_corrected';
  }
  if (!isNil(f._external)) {
    return '_external';
  }
  if (!isNil(f._transformed)) {
    return '_transformed';
  }
  if (!isNil(f._entity)) {
    return '_entity';
  }
  if (!isNil(f._sanitized)) {
    return '_sanitized';
  }
  return '_value';
};

export function parseIdx(idxString: string): {
  provenance: '_value' | '_added';
  idx: number;
} {
  const [provenance, idx] = idxString.split('.');
  return { provenance: provenance as '_value' | '_added', idx: parseInt(idx) };
}

export function verifyIdxExists<T>(
  field: GettableArray<T>,
  provenance: '_value' | '_added',
  idx: number
) {
  if (!field[provenance]) {
    throw new Error(
      `Specified a field from ${provenance} but ${provenance} is not defined`
    );
  }
  if (idx >= field[provenance]!.length) {
    throw new Error(
      `Specified an idx from ${provenance} that is out of bounds: ${idx}`
    );
  }
}

export function getArrayValue<T>(field: GettableArray<T>): T[] {
  if (field._idxs) {
    return field._idxs.map((idx) => {
      const { provenance, idx: idxNum } = parseIdx(idx);
      verifyIdxExists(field, provenance, idxNum);
      if (provenance === '_value') {
        return field._value[idxNum];
      } else if (provenance === '_added') {
        if (!field._added) {
          throw new Error(
            'Specified a field from _added but _added is not defined'
          );
        }
        return field._added[idxNum];
      } else {
        throw new Error(`Invalid provenance: ${provenance}`);
      }
    });
  }
  const added = field._added ? field._added : [];
  return field._value.concat(added);
}

export function getValue<T>(field: Gettable<T> | GettableArray<T>): T | T[] {
  if (Array.isArray(field._value)) {
    return getArrayValue(field as GettableArray<T>);
  }
  return getObjectValue(field as Gettable<T>);
}

export function getObjectValue<T>(f: Gettable<T>): T {
  return f[getBestValueField(f)] as T;
}

// TODO(mike): Figure out if we can type this better
export function toValues(obj: any): any {
  if (Array.isArray(getObjectValue(obj))) {
    return toValuesForEach(getArrayValue(obj));
  }
  if (typeof obj._value === 'object') {
    return toValuesForEachKey(getObjectValue(obj));
  }
  return getObjectValue(obj);
}

function toValuesForEachKey(obj: { [key: string]: any }) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    return { ...acc, [key]: toValues(value) };
  }, {});
}

function toValuesForEach(arr: any[]) {
  return arr.map((value) => toValuesForEachKey(value));
}

export function flattenObject(obj: any, prefix = '') {
  return reduce(
    obj,
    (acc: { [key: string]: any }, value: any, key: string) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (isObject(value) && !isArray(value)) {
        merge(acc, flattenObject(value, newKey));
      } else if (isArray(value)) {
        if (value.length == 0) {
          return acc;
        }
        for (let i = 0; i < value.length; i++) {
          if (isObject(value[0])) {
            merge(acc, flattenObject(value[i], `${newKey}.${i}`));
          } else {
            merge(acc, { [`${newKey}.${i}`]: value[i] });
          }
        }
      } else {
        acc[newKey] = value;
      }
      return acc;
    },
    {}
  );
}

export function tryToGetValue<T>(
  value: Gettable<T> | GettableArray<T>,
  backup: T
): T | T[] {
  try {
    return getValue(value);
  } catch (e) {
    return backup;
  }
}

/**
 * Returns the field value if it exists, or `undefined` if the field is not
 * present.
 */
export const conditionallyGetValue = <ParentT extends FormValue>(
  parent: ParentT,
  key: ConstrainedKeysOf<ParentT, CompletedTemplateChildConfigs>
): string | undefined => {
  const child = parent[key] as CompletedTemplateChildConfigs;
  return !isNil(child) ? getObjectValue<string>(child) : undefined;
};

export const mutateBestValue = <T>(
  obj: Gettable<T>,
  mut: (v: T) => T
): Gettable<T> => {
  const bestValueField = getBestValueField(obj);
  const bestValue = obj[bestValueField] as T;
  const newObj = {
    ...cloneDeep(obj),
    [bestValueField]: mut(bestValue),
  };
  return newObj;
};
