import { OriginatorStatus, QuoteFragment } from '@shared/generated/graphql';
import { ConfigOverrides, IntegrationConfigs } from '@shared/types/order';
import { CustomerRate, Quote } from '@shared/types/quote';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { Rate } from 'clerk_common/types';

export const quoteFromFragment = (fragment: QuoteFragment): Quote => {
  return {
    ...fragment,
    originator: {
      name: fragment.originator?.name ?? undefined,
      id: fragment.originator?.id ?? undefined,
      transmitIds: fragment.originator?.transmitIds ?? [],
      status: fragment.originator?.status ?? OriginatorStatus.TEST,
      configOverrides:
        (fragment.originator?.configOverrides as ConfigOverrides) ?? undefined,
    },
    organization: {
      ...fragment.organization,
      id: fragment.organization.id,
      name: fragment.organization.name,
      integrationConfigs: (fragment.organization
        .integrationConfigs as IntegrationConfigs) ?? {
        integrations: [],
      },
      configurableOptions: fragment.organization.configurableOptions ?? {},
    },
    carrierRates: fragment.carrierRates.map((rate) => {
      return {
        ...rate,
        computedRate: (rate.computedRate as Rate) ?? undefined,
      };
    }),
    customerRates: customerRatesFromFragment(fragment.customerRates),
    carrierRate: fragment.carrierRate ?? undefined,
    customerRate: (fragment.customerRate as CustomerRate) ?? undefined,
    routeGeoCoordinates: fragment.routeGeoCoordinates ?? undefined,
    extractedData:
      (fragment.extractedData as CompletedFreightOrderTemplate) ?? undefined,
  };
};

export function customerRatesFromFragment(
  rates: QuoteFragment['customerRates']
) {
  return rates.map((rate) => {
    return {
      ...rate,
      rate: (rate.rate as Rate) ?? undefined,
    } as CustomerRate;
  });
}
