import { AccordionProps } from '@mui/material/Accordion';
import { makeRootClassName } from '@shared/utils';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

const ROOT = makeRootClassName('ExpandablePanel');

type ExpandablePanelProps = Omit<AccordionProps, 'children'> & {
  children: ReactNode;
  summary: ReactNode;
  handleChange?: (expanded: boolean) => void;
  collapsePanelComponent?: ReactNode;
};

export const ExpandablePanel = (props: ExpandablePanelProps) => {
  const [isExpanded, setIsExpanded] = useState(props.defaultExpanded);

  const onClick = () => {
    if (props.disabled) return;
    const expanded = !isExpanded;
    setIsExpanded(expanded);
    props.handleChange?.(expanded);
  };

  useEffect(() => {
    if (props.defaultExpanded) {
      setIsExpanded(true);
    }
  }, [props.defaultExpanded]);

  return (
    <div className={ROOT}>
      <div>
        <div onClick={onClick}>{props.summary}</div>
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isExpanded ? 'auto' : 0 }}
        transition={{ duration: 0.2 }}
      >
        {isExpanded && (
          <>
            {props.children}
            {props.collapsePanelComponent ? (
              <div onClick={onClick}>{props.collapsePanelComponent}</div>
            ) : null}
          </>
        )}
      </motion.div>
    </div>
  );
};
