import { Button, Text } from '@shared/components';
import { useHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import { AuthTokenType } from '@shared/generated/graphql';

type AuthorizeMicrosoftInstructionsProps = {
  webIntegrationsUrl: string;
};
export const AuthorizeMicrosoftInstructions = (
  p: AuthorizeMicrosoftInstructionsProps
) => {
  const { refetch, loading } = useHasAuthorizedIntegration({
    type: AuthTokenType.MICROSOFT_GRAPH_API,
  });
  return (
    <div className="flex flex-col">
      <Text type="body-sm" className="text-gray-600 text-left">
        <hr className="my-2"></hr>
        <ol className="text-left list-decimal pl-4">
          <li className="mt-2">
            Go to your Vooma integration settings:{' '}
            <a
              className="text-brand-500 inline font-semibold"
              href={p.webIntegrationsUrl}
              target="_blank"
              rel="noreferrer"
            >
              {p.webIntegrationsUrl}
            </a>
          </li>
          <li className="mt-2">
            Select the Microsoft Outlook integration.{' '}
            <div className="flex flex-row justify-center py-2">
              <div className="w-40 h-40 mr-4 object-cover rounded-full">
                <img
                  src="vooma_ms_integration_button.png"
                  alt="Vooma add-in for Outlook integration button"
                />
              </div>
            </div>
          </li>
          <li className="mt-2">
            {`Select your account, or otherwise select "Use another account" and
            log into your account.`}
          </li>
          <li className="mt-2">{`Click "Authorize".`}</li>
          <li className="mt-2">
            Wait for the authorization to complete. You should see this banner
            in the Vooma app.{' '}
            <div className="flex flex-row justify-center py-2">
              <img
                src="integration_complete.png"
                alt="Vooma integration complete"
              />
            </div>
          </li>
          <li className="mt-2">{`Click "Refresh" below.`}</li>
        </ol>
      </Text>
      <Button
        onPress={() => refetch()}
        variant="secondary"
        className="mt-2 w-4/5 self-center"
        isDisabled={loading}
      >
        Refresh
      </Button>
    </div>
  );
};
