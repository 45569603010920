import { ApolloError } from '@apollo/client';
import { useGetTemplatesQuery } from '@shared/generated/graphql';
import { templatesFromFragment } from '@shared/graphql/fromFragments/template';
import { Template } from '@shared/types/template';

type UseTemplatesOptions = {
  originatorId?: string;
  organizationId?: string;
  pollIntervalMs?: number;
};

type UseTemplatesState = {
  templates: Template[];
  isLoading: boolean;
  error?: ApolloError;
};

type UseTemplatesActions = {
  // add any actions the hook provides
};

export type UseTemplatesValue = UseTemplatesState & UseTemplatesActions;

const useTemplates = (props?: UseTemplatesOptions): UseTemplatesValue => {
  const { data, loading, error } = useGetTemplatesQuery({
    variables: {
      input: {
        originatorId: props?.originatorId,
        organizationId: props?.organizationId,
      },
    },
    pollInterval: props?.pollIntervalMs,
    fetchPolicy: 'cache-and-network',
  });

  const templates = data ? templatesFromFragment(data) : [];

  return {
    templates,
    isLoading: loading,
    error,
  };
};

export default useTemplates;
