import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { useInternalFormContext } from '../internalFormContext/InternalFormContext';
import { EditableJSONField } from '../types';
import { hasBeenCorrected } from './fieldLogic';

const ROOT = makeRootClassName('DebugTooltip');
export const el = makeElementClassNameFactory(ROOT);

const printDebugData = (data: EditableJSONField) => {
  let desc = `_value: ${JSON.stringify(data._value)}`;
  const sources = [
    '_sanitized',
    '_entity',
    '_transformed',
    '_corrected',
  ] as const;
  for (const source of sources) {
    if (source in data) {
      desc += `\n${source}: ${JSON.stringify(data[source])}`;
    }
  }
  if ('_correctionRationale' in data) {
    desc += `\n_correctionRationale: ${data._correctionRationale}`;
  }
  return desc;
};

type DebugTooltipProps = {
  children: React.ReactNode;
  data: EditableJSONField;
};

export function DebugTooltip({ children, data }: DebugTooltipProps) {
  const { showDebugInfo } = useInternalFormContext();
  if (!showDebugInfo) return <>{children}</>;
  return (
    <Tooltip content={printDebugData(data)}>
      <div
        className={clsx(
          el`wrapper-div`,
          hasBeenCorrected(data) && el`corrected`
        )}
      >
        {children}
      </div>
    </Tooltip>
  );
}
