import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { EditableJSONField } from '../../types';
import { SimpleArray } from '../simple-array/SimpleArray';
import { Accessorial } from './Accessorial';

const ROOT = makeRootClassName('AccessorialArray');
export const el = makeElementClassNameFactory(ROOT);

export type AccessorialElement = {
  accessorialDescription: EditableJSONField;
};

export type AccessorialArrayData = {
  _value: AccessorialElement[];
  _added?: AccessorialElement[];
  _idxs?: string[];
};

type AccessorialArrayProps = {
  data: AccessorialArrayData;
  prefix?: string;
};

export function AccessorialArray({ data, prefix }: AccessorialArrayProps) {
  return (
    <SimpleArray
      data={data}
      ElementComponent={Accessorial}
      createNewElement={createAccessorialElement}
      description="Accessorial Service"
      prefix={prefix}
    />
  );
}

function createAccessorialElement(): AccessorialElement {
  const defaultFields = {
    _value: '',
    _display: {
      name: '',
    },
    _logProbs: [],
  };

  return {
    accessorialDescription: {
      ...defaultFields,
      _corrected: '',
    },
  };
}
