import { createContext, useContext } from 'react';

export type IntercomSupportContextType = {
  isInitialized: boolean;
  displayLauncher: (show: boolean) => void;
};

export const IntercomSupportContext = createContext<IntercomSupportContextType>(
  buildEmptyContext()
);

export function useIntercomSupportContext() {
  const context = useContext(IntercomSupportContext);
  if (!context) {
    throw new Error(
      'useIntercomSupportContext must be used within a IntercomSupportContext.Provider'
    );
  }
  return context;
}

function buildEmptyContext(): IntercomSupportContextType {
  return { isInitialized: false, displayLauncher: () => {} };
}
