import { ApolloError } from '@apollo/client';
import {
  GetLaneHistoryQuery,
  LaneFilter,
  QuoteBidStatus,
  QuoteHistorySummaryFragment,
  ShipmentHistorySummaryFragment,
  useGetLaneHistoryQuery,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { getEquipmentTypesFromFreightOrder } from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { useGetShipments } from './useGetShipments';

type UseGetQuotesState = {
  quotes: QuoteHistorySummaryFragment[];
  shipments: ShipmentHistorySummaryFragment[];
  loading?: boolean;
  error?: ApolloError;
};

const getHistory = (quote: Quote, data?: GetLaneHistoryQuery) => {
  // NOTE(parlato): Do not show a route history if the quote has not
  // geocoordinates
  if (!quote.routeGeoCoordinates) return [];

  return (
    data?.quotes.edges
      .map((edge) => edge.node)
      .filter((q) => q.id !== quote.id && Boolean(q.customerRate))
      .slice(0, 5) ?? []
  );
};

const getLaneFilter = (quote: Quote): LaneFilter | undefined => {
  const route = quote.routeGeoCoordinates;
  if (!route || route?.length < 1) return undefined;
  const destinationIndex = route.length - 1;
  return {
    destination: {
      latitude: route[destinationIndex].latitude,
      longitude: route[destinationIndex].longitude,
      radiusMiles: 50,
    },
    origin: {
      latitude: route[0].latitude,
      longitude: route[0].longitude,
      radiusMiles: 50,
    },
  };
};

const getEquipmentTypes = (quote: Quote): string[] => {
  if (!quote?.extractedData) return [];
  return getEquipmentTypesFromFreightOrder(quote.extractedData);
};

export const useLaneHistory = (
  quote: Quote,
  originatorId?: string
): UseGetQuotesState => {
  const { data, loading, error } = useGetLaneHistoryQuery({
    variables: {
      input: {
        organizationIds: [quote.organization.id],
        originatorIds: originatorId ? [originatorId] : null,
        statuses: [
          QuoteBidStatus.WON,
          QuoteBidStatus.LOST,
          QuoteBidStatus.QUOTED,
        ],
        laneFilter: getLaneFilter(quote),
        equipmentTypes: getEquipmentTypes(quote),
      },
      first: 6,
    },
    fetchPolicy: 'cache-and-network',
  });

  const quotes = getHistory(quote, data);

  const { shipments } = useGetShipments({
    equipmentTypes: getEquipmentTypes(quote),
    laneFilter: getLaneFilter(quote),
  });

  return {
    quotes,
    shipments,
    loading,
    error,
  };
};
