import { QuoteValidationPayload } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';

export const shouldShowRates = (
  quote: Quote,
  validation: QuoteValidationPayload | undefined
) => {
  const quotingConfig =
    quote.organization.integrationConfigs?.integrations.find(
      (i) => i.orderType === 'QUOTE'
    );
  const missingQuoteIntegrationConfig = Boolean(!quotingConfig);

  // NOTE(parlato): If there is no config, we show the rates but hide the get rates button
  // This is because users without rating integrations can still update rates manually.
  const isQuoteValid = validation?.valid;
  return missingQuoteIntegrationConfig || isQuoteValid;
};
