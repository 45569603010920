import { useAuthContext } from '@shared/contexts/useAuthContext';
import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
  redirectTo: string;
}

const PrivateRoute = ({
  children,
  redirectTo,
}: PropsWithChildren<PrivateRouteProps>) => {
  const { loading, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      navigate(redirectTo);
    }
  }, [isAuthenticated, loading, navigate, redirectTo]);

  return <>{isAuthenticated ? children : null}</>;
};

export default PrivateRoute;
