import { EditableUnit } from '../types';
import { Commodity } from './commodity/Commodity';

export const createPrefixSpecializedBlock = (
  data: EditableUnit,
  prefix: string
): JSX.Element | null => {
  switch (prefix) {
    case '_value.commodity.':
      return <Commodity data={data as EditableUnit} prefix={prefix} />;
  }
  return null;
};
