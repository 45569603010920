import {
  JobEventFragment,
  JobSource,
  OrganizationType,
  ProcessingStatus,
} from '@shared/generated/graphql';
import { Document } from '@shared/types/document';
import { OrderPreview } from '@shared/types/order';
import { QuotePreview } from './quote';

export function jobIsReprocessing(job: Job): boolean {
  return jobIsProcessing(job) && jobHasEverCompleted(job);
}

function jobIsProcessing(job: Job): boolean {
  return job.processingStatus === ProcessingStatus.PROCESSING;
}

function jobHasEverCompleted(job: Job): boolean {
  return job.jobEvents.some(
    (event) => event.type === 'AI_PROCESSING_COMPLETED'
  );
}

export type JobEmailMetadata = {
  sender?: string;
  replyTo?: string;
  subject?: string;
  messageId?: string;
  itemId?: string;
  emailS3Key?: string;
  originatorAddress?: string;
};

export enum JobStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
}

export type Job = {
  id: string;
  createdAt: Date;
  // TODO(mike): Remove this in the next PR.
  status?: JobStatus;
  source?: JobSource;
  processingStatus: ProcessingStatus;
  notes?: string;
  emailMetadata?: JobEmailMetadata;
  jobEvents: JobEventFragment[];
  documents: Document[];
  orders: OrderPreview[];
  quotes: QuotePreview[];
  organization: {
    id: string;
    type?: OrganizationType;
  };
  originator?: {
    name: string;
  };
  user?: {
    id: string;
    email?: string;
  };
  isRedacted: boolean;
};
