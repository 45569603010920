import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import { ReactElement } from 'react';
import { CiDeliveryTruck } from 'react-icons/ci';

import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import { Text } from '../text';
import { Tooltip } from '../tooltip';

export type CarrierRateChipProps = StyleProps & {
  carrierRate: number;
  carrierName: string;
};

const ROOT = makeRootClassName('CarrierRateChip');
const el = makeElementClassNameFactory(ROOT);

function CarrierRateChip({
  carrierRate,
  carrierName,
}: CarrierRateChipProps): ReactElement {
  const rateStr = carrierRate
    ? usdFormatterNoCents.format(carrierRate)
    : undefined;
  const icon = <CiDeliveryTruck />;

  return (
    <Tooltip content={`${carrierName}`} isInstant className={el`tooltip`}>
      <div className={ROOT}>
        {icon && <div className={el`chip-icon`}>{icon}</div>}
        <Text className={el`chip-text`} type="body-xs">
          Bought - {carrierName}
        </Text>
        {rateStr && <Text type="body-xs">{'•'}</Text>}
        {rateStr && <Text type="body-xs">{rateStr}</Text>}
      </div>
    </Tooltip>
  );
}

export default CarrierRateChip;
