import { JobsState } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { JobSummaryFragment } from '@shared/generated/graphql';
import { min } from 'lodash';
import { FeedItemType, FeedItemUser, JobCardFeedItem } from '../types';

const getJobFeedItemCreatedAt = (job: JobSummaryFragment): string => {
  if (job.quotes.length > 0) {
    const minQuoteCreatedAt = min(
      job.quotes.map((quote) => quote.createdAtDisplay || quote.createdAt)
    );
    return minQuoteCreatedAt || job.createdAt;
  }

  if (job.orders.length > 0) {
    const minOrderCreatedAt = min(
      job.orders.map((order) => order.createdAtDisplay || order.createdAt)
    );
    return minOrderCreatedAt || job.createdAt;
  }

  return job.createdAt;
};

const getJobCreatedByDisplayName = (
  job: JobSummaryFragment
): string | undefined => {
  if (job.user?.firstName && job.user?.lastName) {
    return `${job.user.firstName} ${job.user.lastName}`;
  }

  if (job.user?.firstName) {
    return job.user.firstName;
  }

  if (job.user?.email) {
    return job.user.email;
  }
};

const getJobCreatedBy = (job: JobSummaryFragment): FeedItemUser | undefined => {
  if (job.user) {
    return {
      id: job.user.id,
      firstName: job.user.firstName || undefined,
      lastName: job.user.lastName || undefined,
      email: job.user.email || undefined,
    };
  }
};

export const transformThreadJobs = (
  threadJobs: JobsState
): JobCardFeedItem[] => {
  return threadJobs.jobs.map((job) => {
    const jobType = threadJobs.jobTypes[job.id];
    return {
      type: FeedItemType.JOB_CARD,
      createdAt: getJobFeedItemCreatedAt(job),
      createdBy: getJobCreatedBy(job),
      createdByDisplay: getJobCreatedByDisplayName(job),
      data: {
        job,
        initialJobType: jobType,
      },
    };
  });
};
