import { useGetOrganizationFeatureFlagsQuery } from '@shared/generated/graphql';
import { FeatureFlagName } from 'clerk_common/types/featureFlags';

type OrgFeatureFlagProps = {
  featureFlagName: FeatureFlagName;
  pollingInterval?: number;
};

const DEFAULT_POLL_INTERVAL = 1000 * 60;

export const useOrganizationFeatureFlag = ({
  featureFlagName,
  pollingInterval,
}: OrgFeatureFlagProps) => {
  const { data } = useGetOrganizationFeatureFlagsQuery({
    pollInterval: pollingInterval || DEFAULT_POLL_INTERVAL,
  });

  return Boolean(
    data?.me?.organizationsDetails?.find(
      (orgDetails) =>
        orgDetails.enabledFeatureFlags?.find(
          (flag) => flag.name === featureFlagName
        )
    )
  );
};
