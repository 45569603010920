import { RelatedExternalMessage } from '@shared/plugin/pages/Home/components/email-thread-activity/types';
import { ExternalMessageClassification } from 'clerk_common';
import { FeedItemType, MessageClassificationFeedItem } from '../types';

export const toMessageClassificationFeedItem = (
  message: RelatedExternalMessage & {
    maxConfidenceClass: ExternalMessageClassification | null;
  }
): MessageClassificationFeedItem => {
  return {
    type: FeedItemType.MESSAGE_CLASSIFICATION,
    data: message,
    createdAt: message?.emailMetadata?.receivedDateTime,
    createdByDisplay: message?.emailMetadata?.sender,
  };
};
