import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

const ROOT = makeRootClassName('TemperatureFields');
export const el = makeElementClassNameFactory(ROOT);

type TemperatureData = {
  min: EditableJSONField;
  max: EditableJSONField;
  units: EditableJSONField;
};

export type TemperatureFieldsData = {
  _value: TemperatureData;
};

type TemperatureFieldsProps = {
  data: TemperatureFieldsData;
  prefix?: string;
};

export function TemperatureFields({ data, prefix }: TemperatureFieldsProps) {
  const minTemp = getObjectValue(data).min;
  const maxTemp = getObjectValue(data).max;
  const units = getObjectValue(data).units;
  if (!minTemp || !maxTemp || !units) {
    console.warn(
      'Block is designated as a temperature block but does not have temperature or units data.'
    );
    return null;
  }
  return (
    <div className={ROOT}>
      <FormField data={minTemp} prefix={`${prefix}_value.min.`} />
      <FormField data={maxTemp} prefix={`${prefix}_value.max.`} />
      <FormField data={units} prefix={`${prefix}_value.units.`} />
    </div>
  );
}
