import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, ReactNode } from 'react';
import { LuMenu } from 'react-icons/lu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export type DropdownItem = {
  node: ReactNode;
  disabled?: boolean;
};

interface DropdownProps {
  items: DropdownItem[];
  icon?: ReactNode;
  position?: string;
}

export function Dropdown({ items, icon, position }: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-center">
        <Menu.Button>
          <div className="bg-white p-[5px] shadow-sm rounded-md border-[1px] border-gray-200 hover:bg-gray-100">
            {icon ?? <LuMenu size={12} />}
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            position ?? 'left-0',
            'absolute z-50 mt-1 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          <div className="py-1">
            {items.map((item, idx) => (
              <Menu.Item disabled={item.disabled} key={`dropdown-item-${idx}`}>
                {({ active }) => (
                  <div
                    className={classNames(
                      item.disabled
                        ? 'text-gray-400 cursor-default'
                        : 'cursor-pointer',
                      !item.disabled &&
                        (active
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700'),
                      'block px-4 text-sm'
                    )}
                  >
                    {item.node}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
