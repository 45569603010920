import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { JobType } from '@shared/generated/graphql';
import { createContext, useContext, useMemo, useState } from 'react';
import { FeedValue, useFeed } from '../features/Feed/hooks/useFeed';
import { FeedFilterOption, FeedFilterParams } from '../features/Feed/types';

type FeedContextType = {
  feed: FeedValue;
  filter: FeedFilterParams;
};

export const FeedContext = createContext<FeedContextType>({
  feed: {
    items: [],
    suggestedActions: [],
    refresh: () => Promise.resolve(),
    executeAction: () => Promise.resolve(),
    getJobSuggestedActions: () => [],
  },
  filter: {
    selectedOption: FeedFilterOption.ACTIVITY,
    setSelectedOption: () => {},
    filterOptions: [FeedFilterOption.ACTIVITY],
  },
});

export const FeedContextProvider = ({
  handleSendToVooma,
  disableTransactions,
  children,
}: {
  children: JSX.Element;
  disableTransactions: boolean;
  handleSendToVooma: (jobType: JobType) => Promise<void>;
}) => {
  const [selectedOption, setSelectedOption] = useState<FeedFilterOption>(
    FeedFilterOption.ACTIVITY
  );
  const { hasOrders, hasQuotes } = useMeContext();
  const feed = useFeed({
    handleSendToVooma,
    disableTransactions,
  });

  const options = useMemo(() => {
    const filterOptions = [FeedFilterOption.ACTIVITY];

    if (hasQuotes) {
      filterOptions.push(FeedFilterOption.QUOTES);
    }
    if (hasOrders) {
      filterOptions.push(FeedFilterOption.ORDERS);
    }
    return filterOptions;
  }, [hasOrders, hasQuotes]);

  return (
    <FeedContext.Provider
      value={{
        feed,
        filter: {
          selectedOption,
          setSelectedOption,
          filterOptions: options,
        },
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export function useFeedContext() {
  const context = useContext(FeedContext);
  if (!context) {
    throw new Error(
      'useFeedContext must be used within a FeedContext.Provider'
    );
  }
  return context;
}
