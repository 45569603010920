import * as RadixPopover from '@radix-ui/react-popover';
import { makeRootClassName, StyleProps } from '@shared/utils';
import {
  RadixTriggerToReactAriaAdaptor,
  RadixTriggerToReactAriaAdaptorProps,
} from '@shared/utils/radixTriggerToReactAriaAdaptor';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export type PopoverProps = StyleProps &
  RadixPopover.PopoverProps &
  RadixTriggerToReactAriaAdaptorProps & {
    /**
     * Which side of the trigger the popover should be placed.
     * @default "bottom"
     */
    side?: 'top' | 'bottom' | 'left' | 'right';

    /**
     * The preferred alignment against the trigger
     * @default "end"
     */
    align?: 'start' | 'center' | 'end';

    /** The target for the popover */
    children?: ReactNode;

    /** The content of the popover */
    content: ReactNode;

    // NOTE(parlato): Hacky, but I'm moving fast and can't investigate why
    // classNames aren't taking precedence as expected
    roundedLarge?: boolean;
  };

const ROOT = makeRootClassName('Popover');

const DEFAULT_PROPS = {
  side: 'bottom',
  align: 'end',
  hasReactAriaChildren: false,
} as const;

function Popover(props: PopoverProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <RadixPopover.Root open={p.open} onOpenChange={p.onOpenChange}>
      <RadixPopover.Trigger asChild>
        {p.hasReactAriaChildren ? (
          <RadixTriggerToReactAriaAdaptor>
            {p.children}
          </RadixTriggerToReactAriaAdaptor>
        ) : (
          p.children
        )}
      </RadixPopover.Trigger>
      <RadixPopover.Content
        className={clsx(ROOT, p.className, p.roundedLarge && 'rounded-large')}
        sideOffset={6}
        side={p.side}
        align={p.align}
      >
        {p.content}
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
}

export default Popover;
