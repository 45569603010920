import {
  GetQuoteRateFormDocument,
  QuoteDocument,
  useUpdateQuoteMutation,
} from '@shared/generated/graphql';

export const useUpdateQuoteRateMutations = () => {
  const [updateQuote, { loading }] = useUpdateQuoteMutation();

  const handleSetQuoteCustomerRate = async (
    quoteId: string,
    customerRateId: string
  ) => {
    return await updateQuote({
      variables: {
        input: {
          id: quoteId,
          customerRateId,
        },
      },
      refetchQueries: [QuoteDocument, GetQuoteRateFormDocument],
    });
  };

  const handleSetQuoteCarrierRate = async (
    quoteId: string,
    carrierRateId: string
  ) => {
    return await updateQuote({
      variables: {
        input: {
          id: quoteId,
          carrierRateId,
          // NOTE(parlato): This is intentional to avoid messy mappings
          // between different rate component collections.
          customerRateId: null,
        },
      },
      refetchQueries: [
        { query: QuoteDocument, variables: { id: quoteId } },
        GetQuoteRateFormDocument,
      ],
    });
  };

  return {
    handleSetQuoteCustomerRate,
    handleSetQuoteCarrierRate,
    loading,
  };
};
