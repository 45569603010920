import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistanceToNowStrict,
} from 'date-fns';

export const getDaysAgo = (days: number) => {
  return new Date(Date.now() - 1000 * 60 * 60 * 24 * days).toISOString();
};

export const formatFullDatetime = (datetime?: Date) =>
  datetime ? format(datetime, 'PPPPp') : 'Unknown date, probably 1453';

export const formatFeedItemTime = (time: Date) => {
  const now = new Date();
  if (differenceInMinutes(time, now) === 0) {
    return 'Just now';
  }
  if (differenceInHours(time, now) === 0) {
    return formatDistanceToNowStrict(time, {
      addSuffix: true,
      unit: 'minute',
    }).replace('minute', 'min');
  }

  return format(time, 'h:mm a');
};

export const formatTime = (time: Date) => {
  const now = new Date();
  if (differenceInMinutes(time, now) === 0) {
    return 'Just now';
  }
  if (differenceInHours(time, now) === 0) {
    return formatDistanceToNowStrict(time, {
      addSuffix: true,
      unit: 'minute',
    }).replace('minute', 'min');
  }

  const daysAgo = -differenceInCalendarDays(time, now);

  if (daysAgo === 0) {
    return format(time, 'h:mm a');
  }

  if (daysAgo < 7) {
    return formatDistanceToNowStrict(time, { addSuffix: true, unit: 'day' });
  }

  if (daysAgo < 60) {
    return format(time, 'MMM d');
  }

  if (daysAgo < 365 * 2) {
    return formatDistanceToNowStrict(time, { addSuffix: true, unit: 'month' });
  }

  return formatDistanceToNowStrict(time, { addSuffix: true, unit: 'year' });
};

export const formatDay = (time: Date) => {
  const now = new Date();
  if (differenceInMinutes(time, now) === 0) {
    return 'Just now';
  }

  const daysAgo = -differenceInCalendarDays(time, now);

  if (daysAgo === 0) {
    return 'Today';
  }

  if (daysAgo < 7) {
    return format(time, 'eeee');
  }

  return format(time, 'MMM d');
};
