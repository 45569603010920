import { Text } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

const ROOT = makeRootClassName('MileageFields');
export const el = makeElementClassNameFactory(ROOT);

type MileageData = {
  value: EditableJSONField;
  units: EditableJSONField;
};

export type MileageFieldsData = {
  _value: MileageData;
};

type MileageFieldsProps = {
  data: MileageFieldsData;
  prefix?: string;
};

export function MileageFields({ data, prefix }: MileageFieldsProps) {
  const mileageData = getObjectValue(data).value;
  const mileageUnitsData = getObjectValue(data).units;
  if (!mileageData || !mileageUnitsData) {
    console.warn(
      'Block is designated as a milage block but does not have milage or units data.'
    );
    return null;
  }
  return (
    <div className={ROOT}>
      <Text className={el`title`} isHeavy type={'body-md'}>
        Mileage
      </Text>
      <div className={el`fields`}>
        <FormField data={mileageData} prefix={`${prefix}_value.value.`} />
        <FormField data={mileageUnitsData} prefix={`${prefix}_value.units.`} />
      </div>
    </div>
  );
}
