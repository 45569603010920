import { addCommodityFields } from './addCommodityFields';
import { removeFieldsFromCommodity } from './removeCommodityFields';
import { NmfcCodeSettings, TemplateWithCommodity } from './types';

export function addNmfcCode<T extends TemplateWithCommodity>(
  template: T,
  settings?: NmfcCodeSettings
): TemplateWithCommodity {
  const s: NmfcCodeSettings = settings ?? {
    placeholder: '<NUMERIC CODE>',
  };
  const newFields = {
    nmfcCode: { _display: { name: 'NMFC Code' }, _ai: s },
  };
  return addCommodityFields(template, newFields);
}

export function removeNmfcCode(template: TemplateWithCommodity) {
  return removeFieldsFromCommodity(template, ['nmfcCode']);
}
