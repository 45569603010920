import { ApolloError } from '@apollo/client';
import { TemplateType } from '@shared/generated/graphql';
import { Template } from '@shared/types/template';
import { isNil } from 'lodash';
import useTemplates from './useTemplates';

type UseOrderTemplateOptions = {
  originatorId?: string;
};

type UseOrderTemplateState = {
  template: Template | null;
  isLoading: boolean;
  error?: ApolloError;
};

const useOrderTemplate = (
  props: UseOrderTemplateOptions
): UseOrderTemplateState => {
  const { templates, isLoading, error } = useTemplates({
    originatorId: props.originatorId,
  });

  if (isNil(props.originatorId)) {
    return {
      template: null,
      isLoading: false,
    };
  }

  const orderTemplate = templates.find(
    (template) => template.type === TemplateType.FREIGHT_ORDER
  );

  return {
    template: orderTemplate || null,
    isLoading,
    error,
  };
};

export default useOrderTemplate;
