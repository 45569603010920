import { formatDay } from '@shared/plugin/utils/datetime';
import { groupBy, isNil, reverse } from 'lodash';
import { FeedItem } from '../types';
import { itemComparator } from './combineItems';

type FeedItemGroup = {
  items: FeedItem[];
  header: string;
};

export const groupItemsByDate = (items: FeedItem[]): FeedItemGroup[] => {
  const groupedItems = groupBy(items, (item) => {
    if (!item.createdAt || !isNil(item.priority)) return '';

    const date = new Date(item.createdAt);

    return formatDay(date);
  });

  return reverse(
    Object.entries(groupedItems)
      .map(([header, items]) => ({
        header,
        items,
      }))
      .sort((a, b) => {
        if (a.header === '') return -1;
        const firstAItem = a.items[0];
        const firstBItem = b.items[0];

        return itemComparator(firstAItem, firstBItem);
      })
  );
};
