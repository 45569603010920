import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { ConstrainedKeysOf } from 'clerk_common';
import { isNil } from 'lodash';
import { buildFieldIfPresent } from '../../buildFieldIfPresent';
import { getTextProps } from '../../formating';
import { EditableJSONField } from '../../types';
import { Dimensions } from '../dimensions/Dimensions';
import { HazmatFields } from '../hazmat-fields/HazmatFields';
import { WeightFields } from '../weight-fields/WeightFields';
import { CommodityElement } from './CommodityArray';

const ROOT = makeRootClassName('CommodityFields');
export const el = makeElementClassNameFactory(ROOT);

type CommodityProps = {
  idx: number;
  data: CommodityElement;
  prefix?: string;
};

export function Commodity({ idx, data, prefix }: CommodityProps) {
  const buildCommodityField = (
    fieldName: ConstrainedKeysOf<CommodityElement, EditableJSONField>
  ) => {
    return buildFieldIfPresent(data, fieldName, prefix ?? '');
  };

  const textProps = getTextProps();
  const text = <Text {...textProps}>{`Commodity #${idx + 1}`}</Text>;

  const descriptionField = buildCommodityField('description');
  const freightClassField = buildCommodityField('freightClass');
  const nmfcCodeField = buildCommodityField('nmfcCode');
  const handlingQuantityField = buildCommodityField('handlingQuantity');
  const handlingUnitsField = buildCommodityField('handlingUnits');
  const pieceQuantityField = buildCommodityField('pieceQuantity');
  const pieceUnitsField = buildCommodityField('pieceUnits');
  const itemReferenceField = buildCommodityField('itemReference');

  if (
    isNil(descriptionField) &&
    isNil(handlingQuantityField) &&
    isNil(handlingUnitsField) &&
    isNil(pieceQuantityField) &&
    isNil(pieceUnitsField) &&
    isNil(freightClassField) &&
    isNil(nmfcCodeField)
  ) {
    console.warn('Commodity block created with no data populated.');
    return null;
  }

  return (
    <div className={ROOT}>
      {text}
      {descriptionField}
      {itemReferenceField}
      {(freightClassField || nmfcCodeField) && (
        <div className={`${ROOT}-oneline`}>
          {freightClassField}
          {nmfcCodeField}
        </div>
      )}
      {(handlingQuantityField || handlingUnitsField) && (
        <div className={`${ROOT}-oneline`}>
          {handlingQuantityField}
          {handlingUnitsField}
        </div>
      )}
      {(pieceQuantityField || pieceUnitsField) && (
        <div className={`${ROOT}-oneline`}>
          {pieceQuantityField}
          {pieceUnitsField}
        </div>
      )}
      {data.weight && (
        <WeightFields data={data.weight} prefix={`${prefix}.weight.`} />
      )}
      {data.dimensions && (
        <Dimensions data={data.dimensions} prefix={`${prefix}.dimensions.`} />
      )}
      {data.hazmat && (
        <HazmatFields data={data.hazmat} prefix={`${prefix}.hazmat.`} />
      )}
    </div>
  );
}
