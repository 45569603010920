import { EditableJSONField } from '@shared/components/json-defined-form/types';

function warnAboutSyncFailure<T>(propertyName: string, val: T, serverVal: T) {
  // alert('Failed to sync with server. Please try again.');
  console.warn(
    `${propertyName} failed to sync with server. Expected '${val}' but got '${serverVal}'`
  );
}
export function syncState<T>(
  val: T,
  data: EditableJSONField,
  fn: (data: EditableJSONField) => T,
  propertyName: string
) {
  const newVal = fn(data);
  if (val !== newVal) {
    warnAboutSyncFailure(propertyName, val, newVal);
  }
  return newVal;
}
