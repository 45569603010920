import { ReactElement } from 'react';
import { SearchField } from './SearchField';

type SearchFieldProps = {
  value?: string;
  onValueChange: (value: string) => void;
};

export function SearchFieldRow(props: SearchFieldProps): ReactElement {
  const p = { ...props };
  return (
    <div className={'p-[8px] w-full'}>
      <SearchField
        aria-label="Search"
        value={p.value}
        onChange={p.onValueChange}
        className={'w-full'}
        placeholder="Search"
        size="small"
        autoFocus
      />
    </div>
  );
}
