import { TextField, TextFieldProps } from '@shared/components';
import { FieldWrapper } from '@shared/components/react-hook-form/shared';
import { ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

/**
 * We extend the regular TextField props with UseControllerProps.
 * To make this truly generic (reusable in any react-hook-form, regarless of
 * the type of its FieldValues), we use generics to infer the type of the
 * FieldValues from the value passed into control prop.
 *
 * @see https://github.com/react-hook-form/react-hook-form/discussions/7851#discussioncomment-2219298
 */
export type ReactHookFormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps &
  UseControllerProps<TFieldValues, TName> & {
    fieldWrapperClassName?: string;
  };

function ReactHookFormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ReactHookFormTextFieldProps<TFieldValues, TName>): ReactElement {
  const {
    fieldWrapperClassName,
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    ...rest
  } = props;
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FieldWrapper error={error} fieldWrapperClassName={fieldWrapperClassName}>
      <TextField
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        name={fieldName}
        validationState={
          props.validationState || (error ? 'invalid' : undefined)
        }
        value={
          props.value !== undefined && props.value !== null
            ? props.value
            : value
        }
        onChange={props.onChange ? props.onChange : onChange}
        onBlur={(e) => {
          onBlur();
          props.onBlur?.(e); // TODO(parlato): Not satisfied with this solution
        }}
        inputRef={ref}
      />
    </FieldWrapper>
  );
}

export default ReactHookFormTextField;
