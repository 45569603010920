import { Transition } from '@headlessui/react';
import { Button, Text } from '@shared/components';
import { StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { Fragment } from 'react';
import { GoArrowLeft } from 'react-icons/go';

export type SlideOverProps = StyleProps & {
  title: string;
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  header?: React.ReactNode;
};

export const SlideOver = ({
  title,
  children,
  show,
  onClose,
  header,
  className,
}: SlideOverProps) => {
  return (
    <div
      className={clsx('relative z-10', className)}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Background backdrop */}
      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-500"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-500"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="fixed inset-0 bg-gray-500 -m-10 bg-opacity-75 transition-opacity"></div>
      </Transition>

      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            {/* Slide-over panel */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform transition ease-out duration-500 sm:duration-700"
              enterFrom="transform translate-x-full"
              enterTo="transform translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="transform translate-x-0"
              leaveTo="transform translate-x-full"
            >
              <div className="pointer-events-auto relative w-screen max-w-md bg-white">
                <div className="absolute inset-0 border flex flex-col bg-white shadow-xl overflow-hidden">
                  <div className="bg-gray-50 border-t-[1px] border-gray-200 flex justify-between items-center p-[8px]">
                    <Button
                      onPress={onClose}
                      variant="secondary"
                      size="xs"
                      icon={<GoArrowLeft size={12} />}
                    >
                      Back
                    </Button>

                    <div id="slide-over-title">
                      <Text type="body-sm" className="text-gray-700">
                        {title}
                      </Text>
                    </div>
                    <div className="flex gap-1 items-center justify-center">
                      {header}
                    </div>
                  </div>
                  <div className="flex flex-col flex-grow overflow-hidden">
                    {children}
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
};
