import { useToast } from '@shared/components/toast';
import {
  MarketConditionsPayload,
  useFindOrCreateMarketConditionsMutation,
} from '@shared/generated/graphql';
import { useMemo } from 'react';

export const useMarketConditionsMutation = (quoteId: string) => {
  const [findOrCreateMarketConditions, { data, loading }] =
    useFindOrCreateMarketConditionsMutation();
  const { sendToast } = useToast();

  const fetchMarketConditionsMutation = async () => {
    await findOrCreateMarketConditions({
      variables: {
        input: {
          id: quoteId,
        },
      },
    }).catch((err) => {
      sendToast(`Could not fetch market conditions. ${err.message}`, {
        variant: 'info',
      });
    });
  };

  const forceFetchMarketConditionsMutation = async () => {
    await findOrCreateMarketConditions({
      variables: {
        input: {
          id: quoteId,
        },
      },
      fetchPolicy: 'network-only',
    }).catch((err) => {
      sendToast(`Could not fetch market conditions. ${err.message}`, {
        variant: 'info',
      });
    });
  };

  const marketConditions = useMemo(() => {
    if (!data?.findOrCreateMarketConditions) return undefined;

    return data?.findOrCreateMarketConditions as MarketConditionsPayload[];
  }, [data]);

  return {
    fetchMarketConditionsMutation,
    forceFetchMarketConditionsMutation,
    marketConditions,
    loading,
  };
};
