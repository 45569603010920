import { ApolloError } from '@apollo/client';
import { PlaceTag, useGetPlacesQuery } from '@shared/generated/graphql';
import { placesFromFragment } from '@shared/graphql/fromFragments/place';
import { Place } from '@shared/types/place';
import { stringNullsToNulls } from '@shared/utils/nullableIds';

type UsePlacesOptions = {
  organizationId: string;
  originatorIds?: string[];
  tags?: PlaceTag[];
  searchTerm?: string;
  pollIntervalMs?: number;
  fetchOnlyOriginatorPlaces?: boolean;
};

type UsePlacesState = {
  places: Place[];
  isLoading: boolean;
  error?: ApolloError;
};

type UsePlacesActions = {
  // Add any actions the hook provides
};

export type UsePlacesValue = UsePlacesState & UsePlacesActions;

const usePlaces = (props: UsePlacesOptions): UsePlacesValue => {
  const { data, loading, error } = useGetPlacesQuery({
    variables: {
      input: {
        organizationId: props.organizationId,
        originatorIds: constructOriginatorIdsInput(
          props.originatorIds,
          props.fetchOnlyOriginatorPlaces
        ),
        tags: props.tags,
        searchTerm: props.searchTerm,
      },
    },
    pollInterval: props.pollIntervalMs,
  });

  const places = data ? placesFromFragment(data) : [];

  return {
    places,
    isLoading: loading,
    error,
  };
};

const constructOriginatorIdsInput = (
  originatorIds?: string[],
  fetchOnlyOriginatorPlaces?: boolean
) => {
  if (fetchOnlyOriginatorPlaces) {
    return stringNullsToNulls(originatorIds);
  }
  return [null, ...(stringNullsToNulls(originatorIds) ?? [])];
};

export default usePlaces;
