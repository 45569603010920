import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { AutocompleteBase } from '../autocomplete';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { InlineAutocompleteOption } from '../inline-autocomplete/InlineAutocomplete';
import { TextField } from '../react-hook-form';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineQuantifiedField');
const el = makeElementClassNameFactory(ROOT);

interface InlineQuantifiedFieldProps<T> {
  label: string;
  valueName: Path<T>;
  uomName: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
  uomOptions: InlineAutocompleteOption[];
}

export const InlineQuantifiedField = <T extends FieldValues>(
  p: InlineQuantifiedFieldProps<T>
) => {
  const { watch, setValue, control } = useFormContext<T>();
  const value = watch(p.valueName);
  const uom = watch(p.uomName);

  const onSaveValue = () => {
    p.save(p.valueName);
  };

  const handleValueKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSaveValue();
    }
  };

  const onSaveUoM = (value: InlineAutocompleteOption) => {
    setValue(p.uomName, value.id as PathValue<T, Path<T>>);
    p.save(p.uomName);
  };

  const valuePlaceholder = `Add ${p.label.toLowerCase()}`;
  const selectedUoMValue = p.uomOptions.find((o) => o.id === uom)?.label || uom;

  return (
    <div className={clsx(ROOT)}>
      <div className={el`table`}>
        <div className={el`label`}>
          <Text type="body-sm">{p.label}</Text>
        </div>
        <div className={'flex items-center'}>
          <TextField
            placeholder={valuePlaceholder}
            onKeyDown={handleValueKeyDown}
            control={control}
            name={p.valueName}
            onBlur={onSaveValue}
            type="number"
            size="medium"
            fieldWrapperClassName={clsx(
              el`input-wrapper`,
              value ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            transparentWithoutFocus
            placeholderClassName={clsx(
              el`placeholder`,
              value ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            aria-label={p.label}
          />
          <AutocompleteBase
            onChange={(_, value) => {
              onSaveUoM(value as InlineAutocompleteOption);
            }}
            value={selectedUoMValue}
            options={p.uomOptions}
            inputProps={{
              required: p.required,
            }}
            selectOnFocus
            size="small"
            compact
            blurOnSelect
            transparentWithoutFocus
            inputWrapperClassName={clsx(
              el`input-wrapper`,
              selectedUoMValue ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            placeholderClassName={clsx(
              el`placeholder`,
              selectedUoMValue ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            placeholder="Units"
          />
          <FlowIcon fieldPath={p.valueName} />
        </div>
        {/* <TextField
          placeholder={valuePlaceholder}
          onKeyDown={handleUoMKeyDown}
          control={control}
          name={p.uomName}
          onBlur={onSaveUoM}
          size="small"
          fieldWrapperClassName={clsx(
            el`input-wrapper`,
            uom ? 'VALID' : 'INVALID',
            p.required && 'REQUIRED'
          )}
          transparentWithoutFocus
          placeholderClassName={clsx(
            el`placeholder`,
            value ? 'VALID' : 'INVALID',
            p.required && 'REQUIRED'
          )}
        /> */}
      </div>
    </div>
  );
};
