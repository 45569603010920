import { Text } from '@shared/components/text';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { ConstrainedKeysOf } from 'clerk_common';

import { getObjectValue } from 'clerk_common/templates/getValue';
import { isNil } from 'lodash';
import { buildFieldIfPresent } from '../../buildFieldIfPresent';
import { getTextProps } from '../../formating';
import { EditableJSONField } from '../../types';
import { ConfigureCommodity } from '../commodity-array/ConfigureCommodity';
import { Dimensions, DimensionsData } from '../dimensions/Dimensions';
import { HazmatFields, HazmatFieldsData } from '../hazmat-fields/HazmatFields';

const ROOT = makeRootClassName('CommodityFields');
export const el = makeElementClassNameFactory(ROOT);

type CommodityData = {
  description: EditableJSONField;
  handlingQuantity: EditableJSONField;
  handlingUnits: EditableJSONField;
  pieceQuantity: EditableJSONField;
  pieceUnits: EditableJSONField;
  itemReference: EditableJSONField;
  freightClass: EditableJSONField;
  nmfcCode: EditableJSONField;
  dimensions?: DimensionsData;
  hazmat?: HazmatFieldsData;
};

export type CommodityFieldsData = {
  _value: CommodityData;
  _display: {
    name: string;
  };
};

type CommodityFieldsProps = {
  data: CommodityFieldsData;
  prefix?: string;
};

export function CommodityFields({ data, prefix }: CommodityFieldsProps) {
  const commodityValue = getObjectValue(data);

  const textProps = getTextProps();
  const text = data._display?.name && (
    <Text className={el`nested-object-title`} {...textProps}>
      {data._display.name}
    </Text>
  );

  const buildCommodityField = (
    fieldName: ConstrainedKeysOf<CommodityData, EditableJSONField>
  ) => {
    return buildFieldIfPresent(commodityValue, fieldName, prefix ?? '');
  };

  const descriptionField = buildCommodityField('description');
  const freightClassField = buildCommodityField('freightClass');
  const nmfcCodeField = buildCommodityField('nmfcCode');
  const handlingQuantityField = buildCommodityField('handlingQuantity');
  const handlingUnitsField = buildCommodityField('handlingUnits');
  const pieceQuantityField = buildCommodityField('pieceQuantity');
  const pieceUnitsField = buildCommodityField('pieceUnits');
  const itemReference = buildCommodityField('itemReference');

  if (
    isNil(descriptionField) &&
    isNil(handlingQuantityField) &&
    isNil(handlingUnitsField) &&
    isNil(pieceQuantityField) &&
    isNil(pieceUnitsField) &&
    isNil(freightClassField) &&
    isNil(nmfcCodeField) &&
    isNil(itemReference)
  ) {
    console.warn('Commodity block created with no data populated.');
    return null;
  }

  return (
    <div className={ROOT}>
      {text}
      {descriptionField}
      {(freightClassField || nmfcCodeField) && (
        <div className={`${ROOT}-oneline`}>
          {freightClassField}
          {nmfcCodeField}
        </div>
      )}
      {(handlingQuantityField || handlingUnitsField) && (
        <div className={`${ROOT}-oneline`}>
          {handlingQuantityField}
          {handlingUnitsField}
        </div>
      )}
      {(pieceQuantityField || pieceUnitsField) && (
        <div className={`${ROOT}-oneline`}>
          {pieceQuantityField}
          {pieceUnitsField}
        </div>
      )}
      {itemReference && (
        <div className={`${ROOT}-oneline`}>{itemReference}</div>
      )}
      {commodityValue.dimensions && (
        <Dimensions
          data={commodityValue.dimensions}
          prefix={`${prefix}.dimensions.`}
        />
      )}
      {commodityValue.hazmat && (
        <HazmatFields
          data={commodityValue.hazmat}
          prefix={`${prefix}_value.hazmat.`}
        />
      )}
      <ConfigureCommodity isMultiCommodity={false} />
    </div>
  );
}
