import { useAuthContext } from '@shared/contexts/useAuthContext';
import { MeQueryResult, useMeQuery } from '@shared/generated/graphql';
import { useEffect } from 'react';

export type Me = NonNullable<MeQueryResult['data']>['me'];

export const useMe = () => {
  const { setToken } = useAuthContext();
  const { data, loading, refetch, error } = useMeQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    pollInterval: 1000 * 60,
  });

  // TODO(parlato): Move this to the ApolloClient setup
  useEffect(() => {
    if (
      error?.message &&
      (error.message.includes('jwt expired') ||
        error.message.includes('jwt malformed'))
    ) {
      setToken(undefined);
    }
  }, [error, setToken]);

  return {
    me: data?.me,
    loading,
    refetch,
  };
};
