import { Address } from 'clerk_common/types/address';

export enum UserRelevantQuoteTrailEventType {
  AI_COMPLETED = 'AI_COMPLETED',
  AI_FAILED = 'AI_FAILED',
  MARKED_AS_LOST = 'MARK_AS_LOST',
  MARKED_AS_NEW = 'MARK_AS_NEW',
  MARKED_AS_WON = 'MARK_AS_WON',
  MARKED_AS_QUOTED = 'MARKED_AS_QUOTED',
  USER_NOTE = 'USER_NOTE',
  PRICING_CHANGED = 'PRICING_CHANGED',
  AUTO_REPLY = 'AUTO_REPLY',
}

export interface QuoteTrailUser {
  firstName: string;
  lastName: string;
  isVoomaUser: boolean;
  colourGroup: number;
}
export type QuoteTrailItem = {
  type: UserRelevantQuoteTrailEventType;
  timestamp: Date;
  value?: string | null;
  creator: QuoteTrailUser | null;
};

export type QuoteTrailRoute = {
  pickup: Address;
  destination: Address;
  numberOfStops: number;
};
