import { RatingMethod } from '@shared/generated/graphql';
import { DATRatingMethod, RateMetadata } from '@shared/types/quote';
import { usdFormatter } from 'clerk_common/stringification/numbers';

export const shouldShowRateValue = ({
  metadata,
}: {
  metadata?: RateMetadata;
}): boolean => {
  return Boolean(
    metadata && metadata?.ratingMethod !== DATRatingMethod.HISTORY
  );
};

export const makeRateDetailSubtitleComponents = ({
  ratingMethod,
  metadata,
}: {
  ratingMethod?: RatingMethod;
  metadata?: RateMetadata;
}) => {
  try {
    switch (ratingMethod) {
      case RatingMethod.DAT: {
        if (metadata?.ratingMethod === DATRatingMethod.RATE) {
          const { averageFuelSurchargePerTripUsd, mileage, perTrip } =
            metadata?.response?.rate || {};
          const { lowUsd, highUsd } = perTrip;
          const totalLowRate =
            (lowUsd + averageFuelSurchargePerTripUsd) / mileage;
          const totalHighRate =
            (highUsd + averageFuelSurchargePerTripUsd) / mileage;

          return [totalLowRate, totalHighRate].map(
            (r, i) => `${i === 0 ? 'Min' : 'Max'}: ${usdFormatter.format(r)}`
          );
        }
        return [];
      }

      case RatingMethod.GREENSCREENS: {
        const { lowBuyRate, highBuyRate } = metadata?.response || {};
        return [lowBuyRate, highBuyRate].map(
          (r, i) => `${i === 0 ? 'Min' : 'Max'}: ${usdFormatter.format(r)}`
        );
      }
      default:
        return [];
    }
  } catch (err) {
    console.error('Error making rate detail subtitle components', err);
    return [];
  }
};

export const getMileageRate = ({ metadata }: { metadata?: RateMetadata }) => {
  const mileageRate = metadata?.mileageRate;

  if (!mileageRate) return null;

  return `${usdFormatter.format(
    mileageRate.value
  )}/${mileageRate.units.toLowerCase()}`;
};
