import { useEffect, useState } from 'react';
import { SuggestionDataItem } from 'react-mentions';
import allEmojis from './emojis.json';

export type Emoji = {
  emoji: string;
  id: string;
};

type IdToEmoji = Record<string, Emoji>;

function toEmojiMap(emojis: { e: string; id: string }[]): IdToEmoji {
  return emojis.reduce((acc, e) => {
    acc[e.id] = { emoji: e.e, id: e.id };
    return acc;
  }, {} as IdToEmoji);
}

type UseEmojiState = {
  emojis: { id: string; display: string }[];
  idToEmoji: IdToEmoji;
  getEmojiById: (id: string) => Emoji | undefined;
  fetchEmojis: (
    query: string,
    callback: (options: SuggestionDataItem[]) => void
  ) => void;
};

export function useEmojis(): UseEmojiState {
  const [idToEmoji, setIdToEmoji] = useState<IdToEmoji>({});

  useEffect(() => {
    setIdToEmoji(toEmojiMap(allEmojis.emojis));
  }, []);

  const fetchEmojis = async (
    query: string,
    callback: (options: SuggestionDataItem[]) => void
  ) => {
    if (!query.length) return callback([]);
    const MAX_OPTIONS = 10;
    const filteredEmojis = Object.keys(idToEmoji)
      .filter((e) => e.toLowerCase().includes(query.toLowerCase()))
      .slice(0, MAX_OPTIONS);
    const options = filteredEmojis.map((e) => ({ id: e, display: e }));
    callback(options);
  };

  const getEmojiById = (id: string) => idToEmoji[id];

  return {
    emojis: Object.values(idToEmoji).map((e) => ({ id: e.id, display: e.id })),
    idToEmoji,
    getEmojiById,
    fetchEmojis,
  };
}
