import {
  ArrayProps,
  DisplayConfigs,
  EntityConfigs,
  ProvenanceConfigs,
  TypeConfigs,
} from 'clerk_common/templates/types';

export const FIELD_SOURCES = [
  '_value',
  '_sanitized',
  '_entity',
  '_transformed',
  '_corrected',
  '_form',
] as const;
type FieldSourceTuple = typeof FIELD_SOURCES;
export type FieldSource = FieldSourceTuple[number];

export const FORM_STATE_FIELD = '_form';

export type EditableJSON = {
  _value: EditableJSONObject;
  _metadata?: EditableJSONObject;
} & DisplayConfigs &
  TypeConfigs &
  ProvenanceConfigs &
  EntityConfigs;

export type EditableJSONArray = {
  _value: EditableJSONObject[];
} & ArrayProps<EditableJSONObject> &
  DisplayConfigs &
  ProvenanceConfigs &
  TypeConfigs;

export type EditableJSONField = {
  _value: string;
  _logProbs: number[];
  _sanitized?: string;
  _transformed?: string;
  _corrected?: string;
  _entity?: string;
  _form?: string;
  _correctionRationale?: string;
} & DisplayConfigs &
  ProvenanceConfigs &
  TypeConfigs;

export type EditableUnit = EditableJSON | EditableJSONArray | EditableJSONField;

export type EditableJSONObject = {
  [key: string]: EditableUnit;
};
