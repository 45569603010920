import { TimeSelect, TimeSelectProps } from '@shared/components';
import { FieldWrapper } from '@shared/components/react-hook-form/shared';
import { ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

/**
 * We extend the regular TimeSelect props with UseControllerProps.
 * To make this truly generic (reusable in any react-hook-form, regarless of
 * the type of its FieldValues), we use generics to infer the type of the
 * FieldValues from the value passed into control prop.
 *
 * @see https://github.com/react-hook-form/react-hook-form/discussions/7851#discussioncomment-2219298
 */
export type ReactHookFormTimeSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TimeSelectProps & UseControllerProps<TFieldValues, TName>;

function ReactHookFormTimeSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ReactHookFormTimeSelectProps<TFieldValues, TName>): ReactElement {
  const { control, name, rules, shouldUnregister, defaultValue, ...rest } =
    props;
  const {
    field: { onChange, name: fieldName, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FieldWrapper error={error}>
      <TimeSelect
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        name={fieldName}
        value={value}
        onChange={onChange}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        validationState={error ? 'invalid' : 'valid'}
        isRequired={props.isRequired}
      />
    </FieldWrapper>
  );
}

export default ReactHookFormTimeSelect;
