import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { DisplayConfigs } from 'clerk_common';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { FormField } from '../../fields/FormField';
import { EditableJSONField } from '../../types';

const ROOT = makeRootClassName('Dimensions');
export const el = makeElementClassNameFactory(ROOT);

type DimensionValue = {
  value: EditableJSONField;
  units: EditableJSONField;
};

export type DimensionData = {
  _value: DimensionValue;
} & DisplayConfigs;

type DimensionsValue = {
  length: DimensionData;
  width: DimensionData;
  height: DimensionData;
};

export type DimensionsData = {
  _value: DimensionsValue;
} & DisplayConfigs;

type DimensionProps = {
  data: DimensionData;
  prefix?: string;
};

type DimensionsProps = {
  data: DimensionsData;
  prefix?: string;
};

export function Dimension({ data, prefix }: DimensionProps) {
  const value = getObjectValue(data).value;
  const units = getObjectValue(data).units;
  if (!value && !units) {
    console.warn(
      'Block is designated as a dimension block but does not have a value or unit.'
    );
    return null;
  }
  return (
    <div className="flex flex-row gap-[8px]">
      <FormField data={value} prefix={`${prefix}_value.value.`} />
      <FormField data={units} prefix={`${prefix}_value.units.`} />
    </div>
  );
}

export function Dimensions({ data, prefix }: DimensionsProps) {
  const length = getObjectValue(data).length;
  const width = getObjectValue(data).width;
  const height = getObjectValue(data).height;
  if (!length || !width || !height) {
    console.warn(
      'Block is designated as a dimensions block but does not have any dimensions.'
    );
    return null;
  }
  return (
    <div className={ROOT}>
      <Dimension data={length} prefix={`${prefix}_value.length.`} />
      <Dimension data={width} prefix={`${prefix}_value.width.`} />
      <Dimension data={height} prefix={`${prefix}_value.height.`} />
    </div>
  );
}
