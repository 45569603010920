import {
  AgentActionType,
  ConversationFragment,
} from '@shared/generated/graphql';
import { Conversation } from '@shared/types/conversation';
import { basicUserFromFragment } from './user';

export function conversationFromFragment(
  fragment: ConversationFragment
): Conversation {
  return {
    id: fragment.id,
    createdAt: new Date(fragment.createdAt),
    type: fragment.type,
    status: fragment.status,
    purposeDescription: toPurposeDescription(fragment.purpose?.type),
    user: basicUserFromFragment(fragment.user),
    summary: fragment.summary ?? undefined,
    metadata: fragment.metadata ?? undefined,
  };
}

function toPurposeDescription(purposeType?: AgentActionType) {
  if (!purposeType) return undefined;
  switch (purposeType) {
    case AgentActionType.SCHEDULE_ORDER:
      return 'Schedule an appointment';
    default:
      return undefined;
  }
}
