import { optionsToPlaceholder } from '../placeholders';
import { addCommodityFields } from './addCommodityFields';
import { removeFieldsFromCommodity } from './removeCommodityFields';
import {
  ComplexCommodityFields,
  HandlingQuantitySectionSettings,
  HandlingUnitsSettings,
  TemplateWithCommodity,
} from './types';

export function addHandlingQuantitySection<T extends TemplateWithCommodity>(
  template: T,
  settings?: HandlingQuantitySectionSettings
): TemplateWithCommodity {
  const s = settings ?? {
    quantity: { placeholder: '<NUMBER WITHOUT UNITS>' },
    units: { allowedHandlingUnits: ['PALLETS', 'SKIDS', 'CASES'] },
  };
  const newFields = handlingQuantitySettingsToComplexCommodityFields(s);
  return addCommodityFields(template, newFields);
}

export function removeHandlingQuantitySection(template: TemplateWithCommodity) {
  return removeFieldsFromCommodity(template, [
    'handlingQuantity',
    'handlingUnits',
  ]);
}

function handlingQuantitySettingsToComplexCommodityFields(
  settings: HandlingQuantitySectionSettings
): ComplexCommodityFields {
  return {
    handlingQuantity: {
      _ai: settings.quantity,
    },
    handlingUnits: {
      _ai: handlingUnitsSettingsToAIBlock(settings.units),
      _type: 'SHIPPING_UNITS',
    },
  };
}

function handlingUnitsSettingsToAIBlock(settings: HandlingUnitsSettings) {
  const { allowedHandlingUnits, ...rest } = settings;
  return {
    placeholder: optionsToPlaceholder({
      options: allowedHandlingUnits,
      includeUnknown: true,
    }),
    ...rest,
  };
}
