import { PropertyName, merge, omit } from 'lodash';
import {
  ComplexCommodityFields,
  TemplateWithCommodity,
  TemplateWithComplexCommodity,
  TemplateWithMultiComplexCommodity,
} from './types';

export function removeFieldsFromCommodity(
  template: TemplateWithCommodity,
  fields: (keyof ComplexCommodityFields)[]
): TemplateWithCommodity {
  if (template._value.commodity) throw new Error('No commodity section found');
  if (template._value.commodityDetails) {
    return removeFieldsFromComplexCommodity(template, fields);
  }
  if (template._value.commodities) {
    return removeFieldsFromMultiCommodity(template, fields);
  }
  throw new Error('No commodity section found');
}
function removeFieldsFromComplexCommodity(
  template: TemplateWithComplexCommodity,
  fields: (keyof ComplexCommodityFields)[]
): TemplateWithComplexCommodity {
  const paths = fields.map(
    (f: PropertyName) => `_value.commodityDetails._value.${String(f)}`
  );
  // TODO(mike): Remove type assertion
  return omit(template, paths) as TemplateWithComplexCommodity;
}
function removeFieldsFromMultiCommodity(
  template: TemplateWithMultiComplexCommodity,
  fields: (keyof ComplexCommodityFields)[]
): TemplateWithMultiComplexCommodity {
  if (!template._value.commodities) throw new Error('No commodities found');
  const valueWithoutCommodities = omit(template._value, 'commodities');
  return {
    ...template,
    _value: merge({}, valueWithoutCommodities, {
      commodities: {
        ...template._value.commodities,
        _value: template._value.commodities._value.map((c) => omit(c, fields)),
        _added: template._value.commodities._added?.map((c) => omit(c, fields)),
        // TODO(mike): Remove type assertion
      } as TemplateWithCommodity['_value']['commodities'],
    }),
  };
}
