import { DefaultType } from '@shared/contexts/FlowsViewContext';
import {
  ActionDef,
  KnownType,
  ParamDeclDef,
  TransformationDef,
} from 'clerk_common';

export type FieldType = KnownType | 'STRING';

const ADDRESS_DEFAULT_TF_NAMES = {
  HARD: 'Hardcode address',
  SOFT: 'Set address if empty',
};

const STRING_DEFAULT_TF_NAMES = {
  HARD: 'Hardcode field',
  SOFT: 'Set field if empty',
};

export const ALL_DEFAULT_TF_NAMES = [
  ...Object.values(ADDRESS_DEFAULT_TF_NAMES),
  ...Object.values(STRING_DEFAULT_TF_NAMES),
];

export const getDefaultTfName = (
  fieldType: FieldType,
  defaultType: DefaultType
): string => {
  switch (fieldType) {
    case 'ADDRESS':
      return ADDRESS_DEFAULT_TF_NAMES[defaultType];
    case 'STRING':
    default:
      return STRING_DEFAULT_TF_NAMES[defaultType];
  }
};

export const hardDefault: TransformationDef = {
  name: STRING_DEFAULT_TF_NAMES.HARD,
  description: 'Always set a field to a value',
  params: [
    { id: 'path', name: 'path' },
    { id: 'value', name: 'value' },
  ],
  condition: { type: 'Always' },
  actions: [
    {
      type: 'Replace',
      path: { id: 'path', type: 'Param' },
      value: { id: 'value', type: 'Param' },
    },
  ],
};

export const softDefault: TransformationDef = {
  name: STRING_DEFAULT_TF_NAMES.SOFT,
  description: "Set a field to a value if it's empty",
  params: [
    { id: 'path', name: 'path' },
    { id: 'value', name: 'value' },
  ],
  condition: {
    type: 'Equality',
    strict: true,
    lhs: { path: { id: 'path', type: 'Param' }, type: 'FieldValue' },
    rhs: { type: 'Constant', value: '' },
  },
  actions: [
    {
      type: 'Replace',
      path: { id: 'path', type: 'Param' },
      value: { id: 'value', type: 'Param' },
    },
  ],
};

// NOTE(jacob): The default quote template populates "USA" as the country
// if not specified, so we don't include country in the "is address empty"
// check.
const ADDRESS_COMPONENTS_WITHOUT_COUNTRY = [
  'addressOne',
  'addressTwo',
  'city',
  'state',
  'zip',
];

const ALL_ADDRESS_COMPONENTS = [
  ...ADDRESS_COMPONENTS_WITHOUT_COUNTRY,
  'country',
];

const buildReplaceComponentAction = (component: string): ActionDef => {
  return {
    type: 'Replace',
    path: {
      type: 'Concatenate',
      separator: '.',
      scalars: {
        type: 'Consolidate',
        values: [
          {
            type: 'Param',
            id: 'prefix',
          },
          {
            type: 'Constant',
            value: component,
          },
        ],
      },
    },
    value: {
      type: 'Param',
      id: component,
    },
  };
};

const addressParams: ParamDeclDef[] = [
  {
    name: 'prefix',
    id: 'prefix',
  },
  ...ALL_ADDRESS_COMPONENTS.map((c) => {
    return { name: c, id: c };
  }),
];

export const hardDefaultAddress: TransformationDef = {
  name: ADDRESS_DEFAULT_TF_NAMES.HARD,
  description: 'Always set a particular address',
  params: addressParams,
  condition: {
    type: 'Always',
  },
  actions: [...ALL_ADDRESS_COMPONENTS.map(buildReplaceComponentAction)],
};

export const softDefaultAddress: TransformationDef = {
  name: ADDRESS_DEFAULT_TF_NAMES.SOFT,
  description: "Set an address if it's missing",
  params: addressParams,
  condition: {
    type: 'Equality',
    strict: true,
    lhs: {
      type: 'Reduce',
      array: {
        type: 'ArrayLiteral',
        values: ADDRESS_COMPONENTS_WITHOUT_COUNTRY,
      },
      arrayVariable: 'addressComponents',
      initialValue: {
        type: 'Constant',
        value: true,
      },
      expression: {
        type: 'And',
        conditions: [
          {
            type: 'Equality',
            strict: true,
            lhs: {
              type: 'Constant',
              value: '',
            },
            rhs: {
              type: 'Concatenate',
              separator: '.',
              scalars: {
                type: 'Consolidate',
                values: [
                  {
                    type: 'Param',
                    id: 'prefix',
                  },
                  {
                    type: 'Placeholder',
                    role: 'Value',
                    arrayVariable: 'addressComponents',
                  },
                ],
              },
            },
          },
          {
            type: 'Equality',
            strict: true,
            lhs: {
              type: 'Constant',
              value: true,
            },
            rhs: {
              type: 'Placeholder',
              role: 'Accumulator',
              arrayVariable: 'addressComponents',
            },
          },
        ],
      },
    },
    rhs: {
      type: 'Constant',
      value: true,
    },
  },
  actions: [...ALL_ADDRESS_COMPONENTS.map(buildReplaceComponentAction)],
};

const ADDRESS_DEFAULT_TF_DEFS = {
  HARD: hardDefaultAddress,
  SOFT: softDefaultAddress,
};

const STRING_DEFAULT_TF_DEFS = {
  HARD: hardDefault,
  SOFT: softDefault,
};

export const getDefaultTfDef = (
  fieldType: FieldType,
  defaultType: DefaultType
): TransformationDef => {
  switch (fieldType) {
    case 'ADDRESS':
      return ADDRESS_DEFAULT_TF_DEFS[defaultType];
    case 'STRING':
    default:
      return STRING_DEFAULT_TF_DEFS[defaultType];
  }
};
