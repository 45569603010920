import { addCommodityFields } from './addCommodityFields';
import { removeFieldsFromCommodity } from './removeCommodityFields';
import { FreightClassSettings, TemplateWithCommodity } from './types';

export function addFreightClass<T extends TemplateWithCommodity>(
  template: T,
  settings?: FreightClassSettings
): TemplateWithCommodity {
  const s: FreightClassSettings = settings ?? {
    placeholder: '<NUMERIC CODE>',
  };
  const newFields = { freightClass: { _ai: s } };
  return addCommodityFields(template, newFields);
}

export function removeFreightClass(template: TemplateWithCommodity) {
  return removeFieldsFromCommodity(template, ['freightClass']);
}
