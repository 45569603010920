import { Button } from '@shared/components/button';
import { PopoverWithTooltip } from '@shared/components/popover-with-tooltip';
import { Text } from '@shared/components/text';
import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { el } from './LocationEntityCard';

type LocationEntityCardOptionsProps = {
  onEditAddress: () => void;
  onEditLocationFile: () => void;
};

export const LocationEntityCardOptions = ({
  onEditAddress,
  onEditLocationFile,
}: LocationEntityCardOptionsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const getPopoverContent = () => {
    return (
      <div className={el`popover-content`}>
        <div className={el`popover-option`}>
          <Button
            size="small"
            variant="primary"
            onPress={() => {
              setIsPopoverOpen(false);
              onEditAddress();
            }}
            className={el`popover-button`}
          >
            Edit Address
          </Button>
          <Text type="body-xs" className={el`popover-text`}>
            Changes apply only to this order.
          </Text>
        </div>
        <div className={el`popover-option`}>
          <Button
            size="small"
            variant="primary"
            onPress={() => {
              setIsPopoverOpen(false);
              onEditLocationFile();
            }}
            className={el`popover-button`}
          >
            Edit Saved Location
          </Button>
          <Text type="body-xs" className={el`popover-text`}>
            Changes apply to this and any future orders with this location.
          </Text>
        </div>
        <div className={el`popover-option`}>
          <Button
            size="small"
            variant="tertiary"
            onPress={() => setIsPopoverOpen(false)}
            className={el`popover-button`}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={el`popover-container`}>
      <PopoverWithTooltip
        open={isPopoverOpen}
        popoverClassName={el`popover`}
        roundedLarge={true}
        popoverSide="left"
        popoverAlign="center"
        popoverContent={getPopoverContent()}
        tooltipContent={
          isPopoverOpen ? undefined : 'Edit address or location file'
        }
        tooltipSide={isPopoverOpen ? undefined : 'top'}
        isInstant
      >
        <Button
          size="xs"
          variant="default"
          onPress={() => setIsPopoverOpen((c) => !c)}
          icon={<MdEdit className={el`popover-icon`} />}
        />
      </PopoverWithTooltip>
    </div>
  );
};
