import { Checkbox } from '@shared/components';
import { CheckboxProps } from '@shared/components/checkbox/Checkbox';
import { FieldWrapper } from '@shared/components/react-hook-form/shared';
import { ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

/**
 * We extend the regular Checkbox props with UseControllerProps.
 * To make this truly generic (reusable in any react-hook-form, regardless of
 * the type of its FieldValues), we use generics to infer the type of the
 * FieldValues from the value passed into control prop.
 *
 * @see https://github.com/react-hook-form/react-hook-form/discussions/7851#discussioncomment-2219298
 */
export type ReactHookFormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CheckboxProps & UseControllerProps<TFieldValues, TName>;

function ReactHookFormCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ReactHookFormCheckboxProps<TFieldValues, TName>): ReactElement {
  const { control, name, rules, shouldUnregister, defaultValue, ...rest } =
    props;
  const {
    field: { onChange, name: fieldName, value },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FieldWrapper error={error}>
      <Checkbox
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        name={fieldName}
        checked={value}
        onCheckedChange={onChange}
      />
    </FieldWrapper>
  );
}

export default ReactHookFormCheckbox;
