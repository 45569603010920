import {
  ConversationStatus,
  ConversationType,
} from '@shared/generated/graphql';
import { capitalize } from 'lodash';

export const describeConversation = (
  status: ConversationStatus,
  type: ConversationType
) => {
  const t = stringifyConversationType(type);
  switch (status) {
    case ConversationStatus.PENDING:
      return `Vooma is about to start a ${t}`;
    case ConversationStatus.ACTIVE:
      return `Vooma is on a ${t}`;
    case ConversationStatus.COMPLETED:
      return `Vooma completed a ${t}`;
    default:
      return capitalize(t);
  }
};

const stringifyConversationType = (type: ConversationType) => {
  switch (type) {
    case ConversationType.PHONE_CALL:
      return 'phone call';
    default:
      return 'conversation';
  }
};

export const describeStatus = (status: ConversationStatus) => {
  switch (status) {
    case ConversationStatus.PENDING:
      return 'Calling';
    case ConversationStatus.ACTIVE:
      return 'In progress';
    case ConversationStatus.COMPLETED:
      return 'Completed';
  }
};
