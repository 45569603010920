import { JobEventType, JobFragment } from '@shared/generated/graphql';
import { orderFromPreviewFragment } from '@shared/graphql/fromFragments/order';
import { quoteFromPreviewFragment } from '@shared/graphql/fromFragments/quote';
import { Job, JobEmailMetadata, JobStatus } from '@shared/types/job';
import { isNil } from 'lodash';
import { processingStatusFromEvents, StatusString } from '../processingStatus';

export const jobEventsToStatusAdaptor = {
  isProcessingEvent: (type?: JobEventType | null) => {
    if (!type) return false;
    const kProcessingEvents = [
      JobEventType.AI_PROCESSING_TRIGGERED,
      JobEventType.AI_PROCESSING_COMPLETED,
      JobEventType.AI_PROCESSING_FAILED,
    ];
    return kProcessingEvents.includes(type);
  },
  isTriggeredEvent: (type?: JobEventType | null) => {
    return type === JobEventType.AI_PROCESSING_TRIGGERED;
  },
  isCompletedEvent: (type?: JobEventType | null) => {
    return type === JobEventType.AI_PROCESSING_COMPLETED;
  },
  isFailedEvent: (type?: JobEventType | null) => {
    return type === JobEventType.AI_PROCESSING_FAILED;
  },
  toStatus: (str: StatusString) => {
    return str as JobStatus;
  },
};

export function jobFromFragment(fragment: JobFragment): Job {
  return {
    id: fragment.id,
    source: fragment.source,
    createdAt: new Date(fragment.createdAt),
    status: processingStatusFromEvents(
      fragment.jobEvents,
      jobEventsToStatusAdaptor
    ),
    processingStatus: fragment.status,
    notes: fragment.notes ?? '',
    emailMetadata: fragment.emailMetadata as JobEmailMetadata,
    documents:
      fragment.documents &&
      fragment.documents.map((doc) => ({
        id: doc.id,
        name: doc.upload?.name ?? '',
        url: doc.upload?.url ?? '',
      })),
    jobEvents: fragment.jobEvents,
    orders: fragment.orders.map((o) => orderFromPreviewFragment(o)),
    quotes: fragment.quotes.map((o) => quoteFromPreviewFragment(o)),
    organization: {
      id: fragment.organization.id,
      type: fragment.organization.type ?? undefined,
    },
    originator: fragment.originator ?? undefined,
    user: fragment.user
      ? {
          id: fragment.user.id,
          email: fragment.user.email ?? undefined,
        }
      : undefined,
    isRedacted: !isNil(fragment.redactedAt),
  };
}
