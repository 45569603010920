import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { TextField } from '../react-hook-form';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineTextField');
const el = makeElementClassNameFactory(ROOT);

type InlineTextFieldProps<T> = {
  label: string;
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
};

export const InlineTextField = <T extends FieldValues>({
  name,
  label,
  save,
  required,
}: InlineTextFieldProps<T>) => {
  const { watch, control } = useFormContext<T>();
  const value: string = watch(name);

  const onSave = () => {
    save(name);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const placeholder = `Add ${label.toLowerCase()}`;

  return (
    <div className={clsx(ROOT)}>
      <div className={el`table`}>
        <div className={el`label`}>
          <Text type="body-sm">{label}</Text>
        </div>
        <div className={el`field-and-icon-wrapper`}>
          <TextField
            aria-label={label}
            onKeyDown={handleKeyDown}
            control={control}
            name={name}
            onBlur={onSave}
            size="medium"
            fieldWrapperClassName={clsx(
              el`input-wrapper`,
              value ? 'VALID' : 'INVALID',
              required && 'REQUIRED'
            )}
            transparentWithoutFocus
            placeholderClassName={clsx(
              el`placeholder`,
              value ? 'VALID' : 'INVALID',
              required && 'REQUIRED'
            )}
            placeholder={placeholder}
            className={el`field-wrapper`}
          />
          <FlowIcon fieldPath={name} />
        </div>
      </div>
    </div>
  );
};
