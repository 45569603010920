import { chain, mergeProps } from '@react-aria/utils';
import { mergeRefs } from '@shared/utils/mergeRefs';
import * as React from 'react';

export type RadixTriggerToReactAriaAdaptorProps = {
  /**
   * If the child of the Radix trigger is a `react-aria` component
   * (with an `onPress` prop), an adaptor will be used in order to
   * make `radix-ui` and `react-aria` work together.
   */
  hasReactAriaChildren?: boolean;
};

/**
 * Adapter for `@radix-ui/react-popover`'s `Trigger` to `react-aria`.
 *
 * Example:
 *
 * ```tsx
 * <RadixPopover.Trigger asChild>
 *      <RadixTriggerToReactAriaAdaptor>
 *          <Button>Open</Button>
 *      </RadixTriggerToReactAriaAdaptor>
 * </RadixPopover.Trigger>
 * ```
 *
 * When it receives the `asChild` prop, the `Trigger` component
 * from `@radix-ui/react-popover` clones its only child received
 * and modifies the cloned element's props by adding, among other
 * props, an `onClick` prop. This adaptor component essentially
 * passes the props from `<Trigger>` to `<Button>`, while renaming
 * `onClick` to `onPress` (so it is compatible with `react-aria`).
 *
 */
// eslint-disable-next-line react/display-name
export const RadixTriggerToReactAriaAdaptor = React.forwardRef<
  HTMLElement,
  React.PropsWithChildren<Record<string, unknown>>
>((props, forwardedRef) => {
  const onlyChild = React.Children.only(props.children);

  if (React.isValidElement(onlyChild)) {
    const mergedProps = mergeProps(props, onlyChild.props);
    mergedProps.onPress = chain(mergedProps.onClick, mergedProps.onPress);
    delete mergedProps.onClick;

    const onlyChildRef = (
      onlyChild as typeof onlyChild & { ref?: React.Ref<any> }
    ).ref;
    const mergedRefs = mergeRefs(
      onlyChildRef ? [forwardedRef, onlyChildRef] : [forwardedRef]
    );

    return React.cloneElement(
      onlyChild,
      {
        ...mergedProps,
        ref: mergedRefs,
      },
      onlyChild.props.children
    );
  }

  return null;
});
