import { Text } from '@shared/components/text';
import clsx from 'clsx';
import { FormField } from '../../fields/FormField';
import { makeDisplayData } from '../simple-array/SimpleArray';
import { el, ReferenceNumberElement } from './ReferenceNumberArray';

type ReferenceNumberProps = {
  idx: number;
  data: ReferenceNumberElement;
  prefix?: string;
  hideLabel?: boolean;
  showIndexOnHover?: boolean;
};

export function ReferenceNumber({
  idx,
  data,
  prefix,
  hideLabel,
  showIndexOnHover,
}: ReferenceNumberProps) {
  return (
    <div className={clsx(el`reference-number-element`, 'group')}>
      {showIndexOnHover ? (
        <Text type="custom" className={el`reference-number-index`}>
          {idx + 1}
        </Text>
      ) : null}
      {data.referenceType !== undefined && (
        <FormField
          data={{
            ...data.referenceType,
            ...makeDisplayData(idx, 'Reference Type'),
          }}
          prefix={`${prefix}referenceType.`}
          hideLabel={hideLabel}
        />
      )}
      <FormField
        data={{
          ...data.referenceNumber,
          ...makeDisplayData(
            idx,
            data.referenceType ? 'Value' : 'Reference Numbers'
          ),
        }}
        prefix={`${prefix}referenceNumber.`}
        hideLabel={hideLabel}
      />
    </div>
  );
}
