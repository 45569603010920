import { optionsToPlaceholder } from '../placeholders';
import { addCommodityFields } from './addCommodityFields';
import { removeFieldsFromCommodity } from './removeCommodityFields';
import {
  ComplexCommodityFields,
  PieceQuantitySectionSettings,
  PieceUnitsSettings,
  TemplateWithCommodity,
} from './types';

export function addPieceQuantitySection<T extends TemplateWithCommodity>(
  template: T,
  settings?: PieceQuantitySectionSettings
): TemplateWithCommodity {
  const s = settings ?? {
    quantity: { placeholder: '<NUMBER WITHOUT UNITS>' },
    units: {
      allowedPieceUnits: [
        'PIECES',
        'PACKAGES',
        'CARTONS',
        'CASES',
        'UNITS',
        'BUNDLES',
        'ROLLS',
        'BOXES',
      ],
      comment:
        "'PIECES' sometimes abbreviated as 'PCS'. CARTONS sometimes abbreviated as 'CTN'. CASES sometimes abbreviated as 'CAS'",
    },
  };
  const newFields = pieceQuantitySettingsToComplexCommodityFields(s);
  return addCommodityFields(template, newFields);
}

export function removePieceQuantitySection(template: TemplateWithCommodity) {
  return removeFieldsFromCommodity(template, ['pieceQuantity', 'pieceUnits']);
}

function pieceQuantitySettingsToComplexCommodityFields(
  settings: PieceQuantitySectionSettings
): ComplexCommodityFields {
  return {
    pieceQuantity: {
      _ai: settings.quantity,
    },
    pieceUnits: {
      _ai: pieceUnitsSettingsToAIBlock(settings.units),
      _type: 'SHIPPING_UNITS',
    },
  };
}

function pieceUnitsSettingsToAIBlock(settings: PieceUnitsSettings) {
  const { allowedPieceUnits, ...rest } = settings;
  return {
    placeholder: optionsToPlaceholder({
      options: allowedPieceUnits,
      includeUnknown: true,
    }),
    ...rest,
  };
}
