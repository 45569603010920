import { CircularProgress } from '@shared/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { ActionTimelineEventProps } from './components/action-timeline-event/ActionTimelineEvent';

export type EventsTimelineProps = StyleProps & {
  isLoading?: boolean;

  children?:
    | ReactElement<ActionTimelineEventProps>
    | ReactElement<ActionTimelineEventProps>[];
};
const ROOT = makeRootClassName('EventsTimeline');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function EventsTimeline(props: EventsTimelineProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`inner`}>
          {p.isLoading && (
            <div className="ml-5">
              <CircularProgress />
            </div>
          )}
          {!p.isLoading && p.children}
        </div>
      </div>
    </div>
  );
}

export default EventsTimeline;
