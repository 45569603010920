import { useUpdateQuoteRateMutations } from '@shared/components/money-form/hooks/useUpdateQuoteRateMutations';
import {
  GetQuotesDocument,
  QuoteDocument,
  useUpdateCarrierRateMutation,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { Quote } from '@shared/types/quote';
import {
  isManualAdjustment,
  selectNewestRatesOfEachMethod,
} from '@shared/utils/rates/rates';
import { filterRateComponents } from 'clerk_common/types';

type UseSetBuyRateIdProps = {
  quote: Quote;
};

export function useSetBuyRateId(p: UseSetBuyRateIdProps) {
  const { track } = useAnalytics();

  const { handleSetQuoteCarrierRate, loading: loadingSetRate } =
    useUpdateQuoteRateMutations();
  const [updateCarrierRate, { loading: loadingUpdateCarrierRate }] =
    useUpdateCarrierRateMutation();

  const setBuyRateId = async (rateId: string) => {
    track('Selected Carrier Rate', { carrierRateId: rateId });
    const rates = selectNewestRatesOfEachMethod(p.quote.carrierRates);
    const rate = rates.find((r) => r.id === rateId);
    await handleSetQuoteCarrierRate(p.quote.id, rateId);
    if (rate) {
      const refetchQueries = [
        { query: QuoteDocument, variables: { id: p.quote.id } },
        GetQuotesDocument,
      ];

      // NOTE(parlato): Reset the manual adjustments when selecting a rate
      await updateCarrierRate({
        variables: {
          input: {
            id: rate.id,
            computedRate: {
              ...rate.computedRate,
              rateComponents: filterRateComponents(
                rate.computedRate?.rateComponents
              ).filter((rc) => !isManualAdjustment(rc)),
            },
          },
        },
        refetchQueries,
      });
    }
  };

  const loading = loadingSetRate || loadingUpdateCarrierRate;

  return {
    setBuyRateId,
    loading,
  };
}
