import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export function useDeepCompareEffect(
  callback: () => void,
  dependencies: any[]
) {
  const previousDeps = useRef(dependencies);

  useEffect(() => {
    if (!isEqual(previousDeps.current, dependencies)) {
      callback();
      previousDeps.current = dependencies;
    }
  }, dependencies);
}
