import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { JobSummaryFragment, JobType } from '@shared/generated/graphql';
import { useRelatedExternalMessages } from '@shared/plugin/pages/Home/components/email-thread-activity/hooks/useRelatedExternalMessages';
import { useMemo } from 'react';
import { FeedItem, SuggestedAction, SuggestedActionsFeedItem } from '../types';
import { combineItems } from '../utils/combineItems';
import { getEmptyStateItem } from '../utils/getEmptyStateItem';
import { transformRelatedExternalMessages } from '../utils/transformRelatedExternalMessages';
import { transformThreadJobs } from '../utils/transformThreadJobs';
import { useFeedConversations } from './useFeedConversations';
import { useFeedQuoteEvents } from './useFeedQuoteEvents';
import { useSuggestedActions } from './useSuggestedActions';

type FeedOptions = {
  handleSendToVooma: (jobType: JobType) => Promise<void>;
  disableTransactions: boolean;
};

type FeedState = {
  items: FeedItem[];
  suggestedActions: SuggestedActionsFeedItem[];
};

type FeedActions = {
  refresh: () => Promise<void>;
  executeAction: (action: SuggestedAction) => Promise<void>;
  getJobSuggestedActions: (job: JobSummaryFragment) => SuggestedAction[];
};

export type FeedValue = FeedState & FeedActions;

export const useFeed = (options: FeedOptions): FeedValue => {
  const {
    items: suggestedActionsItems,
    executeAction,
    getJobSuggestedActions,
  } = useSuggestedActions({
    handleSendToVooma: options.handleSendToVooma,
    disableTransactions: options.disableTransactions,
  });

  const { messages } = useRelatedExternalMessages();
  const messageClassificationFeedItems =
    transformRelatedExternalMessages(messages);

  const { threadJobs } = useEmailThreadContext();
  const jobCardFeedItems = transformThreadJobs(threadJobs);
  const { events: feedQuoteEvents } = useFeedQuoteEvents();
  const { conversations } = useFeedConversations();

  const items = useMemo(() => {
    return combineItems(
      ...messageClassificationFeedItems,
      ...jobCardFeedItems,
      ...feedQuoteEvents,
      ...conversations
    );
  }, [messageClassificationFeedItems, jobCardFeedItems, feedQuoteEvents]);

  return {
    items: items?.length ? items : [getEmptyStateItem()],
    suggestedActions: suggestedActionsItems,
    getJobSuggestedActions,
    executeAction,
    refresh: () => {
      throw new Error('refresh() not implemented');
    },
  };
};
