import { Text } from '@shared/components';
import { TextType } from '@shared/components/text';
import clsx from 'clsx';
import { ReactNode } from 'react';

type GetElementSeparatorProps = {
  className?: string;
  key?: string | number;
};
export const getElementSeparator = (p?: GetElementSeparatorProps) => (
  <div
    key={p?.key}
    className={clsx('text-gray-400', p?.className)}
    style={{ fontSize: 2 }}
  >
    {'\u2B24'}
  </div>
);

export function intersperseElements(
  arr: (string | string[] | undefined)[],
  textType?: TextType
): ReactNode[] {
  const interspersedElements: ReactNode[] = [];
  arr.forEach((element, index) => {
    interspersedElements.push(
      <Text
        key={index}
        type={textType || 'body-xs'}
        className="text-gray-500 break-words line-clamp-1"
      >
        {element}
      </Text>
    );
    if (index < arr.length - 1) {
      interspersedElements.push(
        getElementSeparator({ key: `separator-${index}-${index + 1}` })
      );
    }
  });
  return interspersedElements;
}
