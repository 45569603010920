import { Template } from '@shared/types/template';
import { MaybeCompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { isNil } from 'lodash';

export function getTemplateJson(
  template?: Template | null
): MaybeCompletedFreightOrderTemplate | null {
  if (isNil(template)) return null;
  if (!template?.specializedTemplate) return null;
  try {
    return JSON.parse(template.specializedTemplate);
  } catch (e) {
    return null;
  }
}
