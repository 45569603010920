import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactElement, ReactNode } from 'react';

type DropdownMenuRootProps = RadixDropdownMenu.DropdownMenuProps;

function Root(props: DropdownMenuRootProps): ReactElement {
  const { children, ...otherProps } = props;

  return (
    <RadixDropdownMenu.Root {...otherProps}>{children}</RadixDropdownMenu.Root>
  );
}

type DropdownMenuTriggerProps = {
  children: ReactNode;
};

function Trigger({ children }: DropdownMenuTriggerProps): ReactElement {
  return (
    <RadixDropdownMenu.Trigger asChild>{children}</RadixDropdownMenu.Trigger>
  );
}

type DropdownMenuContentProps = RadixDropdownMenu.MenuContentProps;

function Content(props: DropdownMenuContentProps): ReactElement {
  const { children, ...otherProps } = props;

  return (
    <RadixDropdownMenu.Content {...otherProps}>
      {children}
    </RadixDropdownMenu.Content>
  );
}

type DropdownMenuItemProps = RadixDropdownMenu.MenuItemProps;

function Item(props: DropdownMenuItemProps): ReactElement {
  const { children, ...otherProps } = props;

  return (
    <RadixDropdownMenu.Item {...otherProps}>{children}</RadixDropdownMenu.Item>
  );
}

type DropdownMenuSubProps = RadixDropdownMenu.DropdownMenuSubProps;

function Sub(props: DropdownMenuSubProps): ReactElement {
  const { children, ...otherProps } = props;

  return (
    <RadixDropdownMenu.Sub {...otherProps}>{children}</RadixDropdownMenu.Sub>
  );
}

type DropdownMenuSubContentProps = RadixDropdownMenu.MenuSubContentProps;

function SubContent(props: DropdownMenuSubContentProps): ReactElement {
  const { children, ...otherProps } = props;

  return (
    <RadixDropdownMenu.SubContent {...otherProps}>
      {children}
    </RadixDropdownMenu.SubContent>
  );
}

function SubTrigger({ children }: DropdownMenuTriggerProps): ReactElement {
  return (
    <RadixDropdownMenu.SubTrigger asChild>
      {children}
    </RadixDropdownMenu.SubTrigger>
  );
}

const DropdownMenu = {
  Root,
  Trigger,
  Content,
  Item,
  Sub,
  SubTrigger,
  SubContent,
};

export default DropdownMenu;
