import { StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactChild, ReactElement } from 'react';
import StopPoint, { StopPointProps } from '../stop-point/StopPoint';

export type BaseTimelineEventProps = StyleProps &
  Pick<StopPointProps, 'type'> & {
    children: ReactChild | ReactChild[];
    eventMarker?: ReactChild;
  };

const DEFAULT_PROPS = {} as const;

function BaseTimelineEvent(props: BaseTimelineEventProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <>
      <div className={clsx('flex w-full items-start')}>
        {p.eventMarker ? p.eventMarker : <StopPoint type={p.type} />}
        <div className="w-full">{p.children}</div>
      </div>
    </>
  );
}

export default BaseTimelineEvent;
