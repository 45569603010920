import { FeatureFlagName, RatingMethod } from '@shared/generated/graphql';
import { useOrganizationFeatureFlag } from '@shared/hooks/useOrganizationFeatureFlag';
import {
  CarrierRate,
  DATRatingMethod,
  GreenscreensRatingMethod,
} from '@shared/types/quote';
import {
  getRateMapKey,
  isManualAdjustment,
  rateToLabel,
} from '@shared/utils/rates/rates';
import { filterRateComponents } from 'clerk_common/types';
import { useMemo } from 'react';
import { getGroupedRatesUrl } from '../../utils/getGroupedRatesUrl';
import { GroupedRates } from './grouped-rates/GroupedRates';
import { GroupedRate } from './grouped-rates/types';
import { RatingWidgetProps } from './types';

const isExcludedIntegration = (rc: CarrierRate) => {
  return rc.ratingMethod === RatingMethod.ARCBEST_TL_QUOTING;
};

export const selectDefaultRate = ({
  rates,
  ratingMethod,
}: {
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
}): GroupedRate | undefined => {
  switch (ratingMethod) {
    case RatingMethod.DAT:
      return (
        rates.find((r) => r.metadata?.ratingMethod === DATRatingMethod.RATE) ||
        rates[0]
      );

    case RatingMethod.GREENSCREENS:
      return (
        rates.find(
          (r) => r.metadata?.ratingMethod === GreenscreensRatingMethod.DEFAULT
        ) || rates[0]
      );

    default:
      return rates[0];
  }
};

function toGroupedRate(r: CarrierRate): GroupedRate {
  return {
    id: r.id,
    label: rateToLabel(r),
    key: getRateMapKey(r),
    ratingMethod: r.ratingMethod,
    metadata: r.metadata,
    rate:
      filterRateComponents(r.computedRate?.rateComponents)
        .filter((rc) => !isManualAdjustment(rc))
        .reduce((acc, curr) => acc + curr.value, 0) ?? 0,
  };
}

export function LegacyRatingWidget(p: RatingWidgetProps) {
  const rates: GroupedRate[] = p.carrierRates
    .filter((r) => !isExcludedIntegration(r))
    .map((r) => toGroupedRate(r));

  const defaultRate = selectDefaultRate({
    rates,
    ratingMethod: p.ratingMethod,
  });

  const isDATRateViewAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_RATE_VIEW_AUTOMATION,
  });
  const isDATLoadSearchAutomationEnabled = useOrganizationFeatureFlag({
    featureFlagName: FeatureFlagName.DAT_LOAD_SEARCH_AUTOMATION,
  });
  const isTabi = p.ratingMethod === RatingMethod.TABI;

  const groupUrl = useMemo(
    () =>
      getGroupedRatesUrl({
        ratingMethod: p.ratingMethod,
        rates,
        quote: p.quote,
        isDATRateViewAutomationEnabled,
        isDATLoadSearchAutomationEnabled,
      }),
    [p]
  );

  return (
    <GroupedRates
      rates={rates}
      ratingMethod={p.ratingMethod}
      quote={p.quote}
      onExpandAnyRateWidget={p.onExpandAnyRateWidget}
      rateSourceUrl={groupUrl}
      defaultExpanded={isTabi}
      defaultRate={defaultRate}
    />
  );
}
