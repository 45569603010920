import { Button } from '@shared/components';
import { StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';

type SeeMoreProps = {
  children: ReactElement;
} & StyleProps;
export function SeeMore(props: SeeMoreProps) {
  const { children, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={clsx(className, 'flex flex-col gap-2 items-start pt-2')}>
      <Button
        size="medium"
        variant="tertiary"
        onPress={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'See less' : 'See more...'}
      </Button>
      {isOpen && children}
    </div>
  );
}
