import { merge } from 'lodash';
import { convertToComplexCommodity } from './convertToComplexCommodity';
import { ComplexCommodityFields, TemplateWithCommodity } from './types';

export function addCommodityFields<T extends TemplateWithCommodity>(
  template: T,
  fields: ComplexCommodityFields
): T {
  if (template._value.commodity) {
    const upgraded = convertToComplexCommodity(template);
    return addCommodityFields(upgraded, fields) as T;
  }
  if (template._value.commodityDetails) {
    return addFieldsToComplexCommodity(template, fields) as T;
  }
  if (template._value.commodities) {
    return addFieldsToMultiCommodity(template, fields) as T;
  }
  throw new Error('No commodity section found');
}
function addFieldsToComplexCommodity(
  template: TemplateWithCommodity,
  fields: ComplexCommodityFields
) {
  const toAdd = merge({}, template._value.commodityDetails, {
    _value: fields,
  });
  return merge({}, template, {
    _value: {
      commodityDetails: {
        _type: 'COMMODITY',
        _display: {
          name: 'Commodity',
          flattened: true,
        },
        ...toAdd,
      },
    },
  });
}
function addFieldsToMultiCommodity(
  template: TemplateWithCommodity,
  fields: ComplexCommodityFields
) {
  if (!template._value.commodities) throw new Error('No commodities found');
  return merge({}, template, {
    _value: {
      commodities: {
        _value: template._value.commodities._value.map((commodity) =>
          merge({}, commodity, { ...fields })
        ),
        _added: template._value.commodities._added?.map((commodity) =>
          merge({}, commodity, { ...fields })
        ),
      },
    },
  });
}
