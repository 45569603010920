import { Button } from '@shared/components/';
import { Text } from '@shared/components/text';
import { Tooltip } from '@shared/components/tooltip';
import {
  CachedRequestType,
  IntegrationType,
  MarketConditionsPayload,
} from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { StopType } from 'clerk_common/enums';
import clsx from 'clsx';
import { mean } from 'lodash';
import {
  PiArrowClockwiseFill,
  PiArrowRightBold,
  PiTrendDownBold,
  PiTrendUpBold,
} from 'react-icons/pi';
import { RateError } from '../rating-widgets/components/RateError/RateError';
import {
  DATLoadTruckRatio,
  DATLoadTruckRatioChart,
} from './DATLoadTruckRatioChart';
import { GSMarketIndexData } from './types';

const ROOT = makeRootClassName('MarketConditions');
const el = makeElementClassNameFactory(ROOT);

export type DATLoadTruckRatioResponseItem = {
  geoCode: string;
  loadTruckRatios: DATLoadTruckRatio[];
};

const extractLocation = (stop: any) => {
  const { city, state, zip } = stop.location;

  if (city && state) return `${city}, ${state}`;

  return `Postal Code: ${zip}`;
};

interface GreenscreensMarketIndicatorProps {
  data: GSMarketIndexData;
  label: string;
  title: string;
}

export const GreenscreensMarketIndicator = (
  p: GreenscreensMarketIndicatorProps
) => {
  const getIcon = () => {
    switch (p.data.trend) {
      case 'UP':
        return <PiTrendUpBold size={12} />;
      case 'DOWN':
        return <PiTrendDownBold size={12} />;
      default:
        return <PiArrowRightBold size={12} />;
    }
  };

  return (
    <div className={el`load-flow`}>
      <Text type="body-xs">{p.label}</Text>
      <div className={clsx(el`icon`, p.data.trendOutcome)}>
        <Tooltip
          isInstant
          content={`${p.title}\nis ${p.data.instant} with ${p.data.derivative} derivative.`}
        >
          <div className={el`trend-line`}>{getIcon()}</div>
        </Tooltip>
      </div>
    </div>
  );
};

interface GreenscreensMarketConditionProps {
  marketCondition: MarketConditionsPayload;
}

export const GreenscreensMarketCondition = (
  p: GreenscreensMarketConditionProps
) => {
  if (
    p.marketCondition.errorMessage &&
    p.marketCondition.integrationType === IntegrationType.GREENSCREENS
  )
    return (
      <RateError
        errorMessage={p.marketCondition.errorMessage}
        integrationName="Greenscreens"
        fetchedEntity="market conditions"
      />
    );

  const location = extractLocation(p.marketCondition.stop);
  const stopType = p.marketCondition.stop.stopType as StopType;
  const { inboundLoadFlow, outboundLoadFlow } =
    p.marketCondition.marketConditions[0].apiResponse;

  return (
    <div className={el`market-condition`}>
      <Text type="body-xs" isHeavy className={el`location-name`}>
        {location}
      </Text>
      <div className={el`load-flows`}>
        {stopType === 'PICKUP' && (
          <GreenscreensMarketIndicator
            data={outboundLoadFlow}
            label="OLF"
            title={`Outbound load flow in ${location}`}
          />
        )}
        {stopType === 'DELIVERY' && (
          <GreenscreensMarketIndicator
            data={inboundLoadFlow}
            label="ILF"
            title={`Inbound load flow in ${location}`}
          />
        )}
      </div>
    </div>
  );
};

interface DATMarketConditionProps {
  marketCondition: MarketConditionsPayload;
}

// TODO(parlato): Would be great to type this and all children
// components in more detail; currently moving very fast and I
// doubt the types will change given the objects come from DAT
export const DATMarketCondition = (p: DATMarketConditionProps) => {
  if (
    p.marketCondition.errorMessage &&
    p.marketCondition.integrationType === IntegrationType.DAT
  )
    return (
      <RateError
        errorMessage={p.marketCondition.errorMessage}
        integrationName="DAT"
        fetchedEntity="market conditions"
      />
    );

  const marketCondition = p.marketCondition.marketConditions[0];

  // TODO(parlato): Handle market indices as well
  if (marketCondition.requestType !== CachedRequestType.LOAD_TO_TRUCK_RATIOS)
    return null;

  const response = marketCondition?.apiResponse?.[0];

  if (!response) return null;

  const { geoCode, loadTruckRatios } =
    response as DATLoadTruckRatioResponseItem;
  const ratios = loadTruckRatios.map((ltr: { ratio: string }) =>
    parseFloat(ltr.ratio)
  );
  const averageLTR = Math.round(mean(ratios) * 100) / 100;

  return (
    <div className={el`dat-market-condition`}>
      <div className={el`market-condition`}>
        <Text type="body-xs" isHeavy className={el`location-name`}>
          {geoCode}
        </Text>
        <Text type="body-xs" className={el`average-ltr`}>
          Load-to-truck ratio: <b>{averageLTR}</b>
        </Text>
      </div>
      <Text type="body-xs">8 day load-to-truck trend</Text>
      <div className={el`dat-ltr-chart`}>
        <DATLoadTruckRatioChart data={loadTruckRatios} />
      </div>
    </div>
  );
};

interface MarketConditionProps {
  marketCondition: MarketConditionsPayload;
}

export const MarketCondition = (p: MarketConditionProps) => {
  return (
    <div>
      {p.marketCondition.integrationType === IntegrationType.GREENSCREENS && (
        <GreenscreensMarketCondition marketCondition={p.marketCondition} />
      )}
      {p.marketCondition.integrationType === IntegrationType.DAT && (
        <DATMarketCondition marketCondition={p.marketCondition} />
      )}
    </div>
  );
};

interface MarketConditionsProps {
  marketConditions: MarketConditionsPayload[];
  handleRefresh: () => void;
}

export const MarketConditions = (p: MarketConditionsProps) => {
  return (
    <div className={ROOT}>
      <div className={el`header`}>
        <Text type="body-xs">Market conditions</Text>
        <Button
          size="xs"
          variant="light"
          icon={<PiArrowClockwiseFill className="text-gray-700" />}
          onPress={p.handleRefresh}
          tooltip="Refresh market conditions"
        />
      </div>
      <div className={el`market-conditions`}>
        {p.marketConditions.map((mc, index) => (
          <MarketCondition
            key={`${mc.integrationType}-market-condition-${index}`}
            marketCondition={mc}
          />
        ))}
      </div>
    </div>
  );
};
