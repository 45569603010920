import { makeRootClassName, StyleProps } from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
} from 'react-calendar';

export type CalendarProps = StyleProps & ReactCalendarProps;

const ROOT = makeRootClassName('Calendar');

const DEFAULT_PROPS = {} as const;

export function Calendar(props: CalendarProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <ReactCalendar
      {...props}
      className={clsx(
        ROOT,
        { 'is-double-view': p.showDoubleView },
        p.className
      )}
    />
  );
}

// @TODO: a better approach might be use a more headless approach like
// https://github.com/rehookify/datepicker
export default Calendar;
