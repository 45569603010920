import { isNil } from 'lodash';
import { EditableJSONField } from '../types';

const computeConfidence = (logProbs?: number[]) => {
  if (!logProbs) return 0;
  const min = Math.min(...logProbs);
  return Math.exp(min);
};

const classifyConfidence = (confidence: number) => {
  if (confidence > 0.9) return 'high';
  return 'low';
};

const isMarkedCorrect = (data: EditableJSONField) => {
  return data._corrected !== undefined;
};

export const determineBestValueField = (data: EditableJSONField) => {
  if (data._corrected !== null && data._corrected !== undefined)
    return '_corrected';
  if (data._transformed !== null && data._transformed !== undefined)
    return '_transformed';
  if (data._entity !== null && data._entity !== undefined) return '_entity';
  if (data._sanitized !== null && data._sanitized !== undefined)
    return '_sanitized';
  return '_value';
};

export const determineConfidenceBucket = (data: EditableJSONField) => {
  // return 'low';
  if (isMarkedCorrect(data)) return 'high';
  const confidence = computeConfidence(data._logProbs);
  return classifyConfidence(confidence);
};

const generalizePrefix = (prefix: string): string => {
  // Get rid of _value. and trailing . if it exists.
  const simplified = prefix
    .replace(/_value./g, '')
    .replace(/_added./g, '')
    .replace(/_sanitized./g, '')
    .replace(/_entity./g, '')
    .replace(/_transformed./g, '')
    .replace(/_corrected./g, '')
    .replace(/_form./g, '')
    .replace(/\.$/, '');
  // Replace all array indices with <n>
  return simplified.replace(/\.\d+\./g, '.<n>.');
};

export const isRequiredField = (requiredFields: string[], prefix?: string) => {
  if (!prefix) return false;
  const generalizedPrefix = generalizePrefix(prefix);
  return requiredFields.includes(generalizedPrefix);
};

export const isParentOfRequiredField = (
  requiredFields: string[],
  prefix?: string
) => {
  if (!prefix) return false;
  const generalizedPrefix = generalizePrefix(prefix);
  return requiredFields.some((field) =>
    field.startsWith(`${generalizedPrefix}.`)
  );
};

const isSame = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const hasBeenCorrected = (data: EditableJSONField) => {
  if (!('_corrected' in data) || isNil(data._corrected)) return false;
  const fields = ['_transformed', '_entity', '_sanitized', '_value'] as const;
  for (const key of fields) {
    if (key in data) return !isSame(data[key], data._corrected);
  }
  return true;
};
