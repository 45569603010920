import { Text, Tooltip } from '@shared/components';
import { CarrierRateChip } from '@shared/components/carrier-rate-chip';
import QuoteStatusDropdownChip from '@shared/components/quote-status-dropdown-chip/QuoteStatusDropdownChip';
import {
  QuoteBidStatus,
  QuoteHistorySummaryFragment,
  ShipmentCarrierMetadata,
  ShipmentHistorySummaryFragment,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { formatTime } from '@shared/plugin/utils/datetime';
import { getElementSeparator } from '@shared/plugin/utils/intersperseElements';
import { makeRootClassName } from '@shared/utils';
import { sumCustomerRate } from 'clerk_common/types';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';

interface QuoteHistoryRowProps {
  quote: QuoteHistorySummaryFragment;
  navigateToQuote: (quoteId: string) => void;
}

const ROOT = makeRootClassName('QuoteHistoryRow');

const QuoteHistoryRow = (p: QuoteHistoryRowProps) => {
  const sellRate = p.quote.customerRate;
  const createdAt = new Date(p.quote.createdAt);
  const rate = sellRate ? sumCustomerRate(sellRate) : null;
  const { track } = useAnalytics();

  if (!rate) return null;

  const statusChip = (status: QuoteBidStatus) => {
    return (
      <div className={clsx(ROOT)}>
        <QuoteStatusDropdownChip status={status} sellRate={rate} />
      </div>
    );
  };

  return (
    <div
      className="flex cursor-pointer flex-col items-start gap-[4px]"
      onClick={() => {
        track('Clicked Lane History Quote', { quoteId: p.quote.id });
        p.navigateToQuote(p.quote.id);
      }}
    >
      <div className="flex flex-row items-center gap-[4px] text-gray-600">
        {statusChip(p.quote.status)}
        {getElementSeparator({ className: 'text-gray-600' })}
        <Text type="body-sm">{formatTime(createdAt)}</Text>
      </div>
    </div>
  );
};

interface CarrierRateHistoryRowProps {
  carrierRate: CompletedShipmentCarrierMetadata;
}

const CarrierRateHistoryRow = (p: CarrierRateHistoryRowProps) => {
  if (!p.carrierRate.pickupAt) return null;
  const pickupAt = new Date(p.carrierRate.pickupAt);
  const carrierName = p.carrierRate.name;
  const rateAmount = p.carrierRate.rateAmount;
  return (
    <div className="flex flex-row items-center gap-[4px] text-gray-600">
      <CarrierRateChip carrierRate={rateAmount} carrierName={carrierName} />
      {getElementSeparator({ className: 'text-gray-600' })}
      <Text type="body-sm">{formatTime(pickupAt)}</Text>
    </div>
  );
};

interface QuoteLaneHistoryProps {
  quotes: QuoteHistorySummaryFragment[];
  shipments: ShipmentHistorySummaryFragment[];
  quoteId: string;
  navigateToQuote: (quoteId: string) => void;
}

type CompletedShipmentCarrierMetadata = ShipmentCarrierMetadata & {
  pickupAt: string;
  rateAmount: number;
};

type QuoteLaneHistoryRowData = {
  type: 'quote' | 'carrierRate';
  data: QuoteHistorySummaryFragment | CompletedShipmentCarrierMetadata;
};

const getQuoteLaneHistoryRowDatas = (
  quotes: QuoteHistorySummaryFragment[],
  shipments: ShipmentHistorySummaryFragment[]
): QuoteLaneHistoryRowData[] => {
  const allRows: QuoteLaneHistoryRowData[] = [];
  allRows.push(
    ...quotes.map((q: QuoteHistorySummaryFragment) => ({
      type: 'quote' as const,
      data: q,
    }))
  );
  for (const s of shipments) {
    allRows.push(
      ...(s.carrierRates
        ?.filter(
          (carrierRate): carrierRate is CompletedShipmentCarrierMetadata =>
            !isNil(carrierRate.pickupAt) && !isNil(carrierRate.rateAmount)
        )
        .map((carrierRate) => ({
          type: 'carrierRate' as const,
          data: carrierRate,
        })) ?? [])
    );
  }

  const getDate = (row: QuoteLaneHistoryRowData) => {
    if (row.type === 'quote') {
      const quoteData = row.data as QuoteHistorySummaryFragment;
      return new Date(quoteData.createdAt);
    }
    if (row.type === 'carrierRate') {
      const carrierRateData = row.data as CompletedShipmentCarrierMetadata;
      return new Date(carrierRateData.pickupAt);
    }
    throw new Error('Invalid row type');
  };

  const sortedRows = allRows.sort((a, b) => {
    return getDate(b).getTime() - getDate(a).getTime();
  });
  return sortedRows;
};

export const QuoteLaneHistory = (p: QuoteLaneHistoryProps) => {
  const [expanded, setExpanded] = useState(true);
  const { track } = useAnalytics();

  const handleToggleHistory = () => {
    const event = expanded ? 'Collapse Lane History' : 'Expand Lane History';
    setExpanded(!expanded);
    track(event, {
      quoteId: p.quoteId,
    });
  };

  const historyRows = getQuoteLaneHistoryRowDatas(p.quotes, p.shipments);

  return !isEmpty(historyRows) ? (
    <div className="flex flex-col p-4">
      <>
        <div
          onClick={handleToggleHistory}
          className="flex cursor-pointer flex-row items-center justify-between text-gray-600"
        >
          <Text type="body-md">Lane history</Text>
          <Tooltip
            isInstant
            content={expanded ? 'Collapse lane history' : 'Expand lane history'}
          >
            {expanded ? <LuMinus size={16} /> : <LuPlus size={16} />}
          </Tooltip>
        </div>
        {expanded && (
          <div className="mt-2 flex cursor-pointer flex-col gap-2">
            <Text type="body-xs" className="text-gray-500">
              Recent quotes for this customer with the same equipment type and
              similar origin-destination pair (50 mile radius)
            </Text>
            {historyRows.map((row, index) => {
              if (row.type === 'quote') {
                const quote = row.data as QuoteHistorySummaryFragment;
                return (
                  <QuoteHistoryRow
                    key={`history-${index}`}
                    quote={quote}
                    navigateToQuote={p.navigateToQuote}
                  />
                );
              }
              if (row.type === 'carrierRate') {
                const carrierRate =
                  row.data as CompletedShipmentCarrierMetadata;
                return (
                  <CarrierRateHistoryRow
                    key={`history-${index}`}
                    carrierRate={carrierRate}
                  />
                );
              }
            })}
          </div>
        )}
      </>
    </div>
  ) : null;
};
