import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  getInitialEmailScanningFormData,
  getUpdatedFeatures,
} from '@shared/features/user-features-form/utils/featuresForm';
import {
  JobType,
  useUpdateUserFeaturesMutation,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { AppCueName, CueTrackEvents } from '@shared/hooks/useInAppCues';
import useSendInAppCue from '@shared/hooks/useSendInAppCue';

type EmailScanningCueActions = {
  handleSendCue: (email: string, jobType: JobType) => void;
  handleAddAllowedSender: (email: string, shouldToast: boolean) => void;
};

export const useEmailScanningCue = (): EmailScanningCueActions => {
  const { sendCue } = useSendInAppCue();
  const { defaultOrgId, me } = useMeContext();
  const { sendToast } = useToast();
  const [updateUserFeatures] = useUpdateUserFeaturesMutation();
  const { track } = useAnalytics();

  const handleDismiss = async () => {
    track(CueTrackEvents.DISMISS, {
      organizationId: defaultOrgId,
      name: AppCueName.STORE_ALLOWED_SENDER,
    });
  };

  const handleAddAllowedSender = async (
    email: string,
    shouldToast: boolean
  ) => {
    if (!defaultOrgId || !me?.features) return;
    const data = getInitialEmailScanningFormData(defaultOrgId, me);
    data.enabled = true;
    data.allowList.push({ value: email });
    const updatedFeatures = getUpdatedFeatures(data, defaultOrgId, me);

    track(CueTrackEvents.ACCEPT, {
      organizationId: defaultOrgId,
      name: AppCueName.STORE_ALLOWED_SENDER,
    });

    const res = await updateUserFeatures({
      variables: {
        input: {
          id: me?.id,
          features: {
            features: updatedFeatures,
          },
        },
      },
      onError: shouldToast
        ? () => {
            sendToast('Failed to add email.', { variant: 'error' });
          }
        : undefined,
    });

    if (shouldToast && res.data?.updateUserFeatures.user) {
      sendToast('Email added!', { variant: 'success' });
    }
  };

  const handleSendCue = (email: string, jobType: string) => {
    const entities = jobType === JobType.QUOTES ? 'Quotes' : 'Orders';
    const entityCta =
      jobType === JobType.QUOTES ? 'Create quote' : 'Build order';
    track(CueTrackEvents.VIEW, {
      organizationId: defaultOrgId,
      name: AppCueName.STORE_ALLOWED_SENDER,
    });
    sendCue(
      `Save time building ${entities.toLowerCase()}`,
      AppCueName.STORE_ALLOWED_SENDER,
      {
        description: `Would you like Vooma to automatically identify and build ${entities.toLowerCase()} from ${email}? \n\n${entities} will be available instantly when you click "${entityCta}."`,
        autoHideDuration: 7000,
        buttonActions: [
          {
            variant: 'default',
            size: 'small',
            label: 'Not now',
            isDismissAction: true,
            onPress: () => handleDismiss(),
          },
          {
            variant: 'primary',
            size: 'small',
            label: 'Yes, please!',
            isDismissAction: true,
            onPress: () => handleAddAllowedSender(email, true),
          },
        ],
      }
    );
  };

  return { handleSendCue, handleAddAllowedSender };
};
