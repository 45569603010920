import * as RadixPopover from '@radix-ui/react-popover';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import {
  RadixTriggerToReactAriaAdaptor,
  RadixTriggerToReactAriaAdaptorProps,
} from '@shared/utils/radixTriggerToReactAriaAdaptor';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { Text } from '../text';

type PopoverProps = RadixPopover.PopoverProps & {
  popoverClassName?: string;
  /**
   * Which side of the trigger the popover should be placed.
   * @default "bottom"
   */
  popoverSide?: 'top' | 'bottom' | 'left' | 'right';

  /**
   * The preferred alignment against the trigger
   * @default "end"
   */
  popoverAlign?: 'start' | 'center' | 'end';

  // NOTE(parlato): Hacky, but I'm moving fast and can't investigate why
  // classNames aren't taking precedence as expected
  roundedLarge?: boolean;

  popoverContent: ReactNode;
};

type TooltipProps = {
  tooltipClassName?: string;
  tooltipSide?: 'top' | 'bottom' | 'left' | 'right';
  tooltipContent: ReactNode;
  isInstant?: boolean;
};

export type PopoverWithTooltipProps = StyleProps &
  PopoverProps &
  TooltipProps &
  RadixTriggerToReactAriaAdaptorProps & {
    children: ReactNode;
  };

const ROOT = makeRootClassName('PopoverWithTooltip');
const el = makeElementClassNameFactory(ROOT);

const DELAY = 600;

const DEFAULT_PROPS = {
  popoverSide: 'bottom',
  popoverAlign: 'end',
  tooltipSide: 'top',
  isInstant: false,
  hasReactAriaChildren: false,
} as const;

// NOTE(Martin): I think we should replace this with the standard Popover component and add a title for tooltips
function PopoverWithTooltip(props: PopoverWithTooltipProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={p.isInstant ? 0 : DELAY}>
        <RadixPopover.Root open={p.open} onOpenChange={p.onOpenChange}>
          <RadixTooltip.Trigger asChild>
            <RadixPopover.Trigger>
              {p.hasReactAriaChildren ? (
                <RadixTriggerToReactAriaAdaptor>
                  {p.children}
                </RadixTriggerToReactAriaAdaptor>
              ) : (
                p.children
              )}
            </RadixPopover.Trigger>
          </RadixTooltip.Trigger>
          <RadixPopover.Content
            className={clsx(
              el`popover`,
              p.popoverClassName,
              p.roundedLarge && 'rounded-large'
            )}
            sideOffset={6}
            side={p.popoverSide}
            align={p.popoverAlign}
          >
            {p.popoverContent}
          </RadixPopover.Content>
          <RadixTooltip.Content
            side={p.tooltipSide}
            align="center"
            sideOffset={4}
            className={clsx(el`tooltip`, p.tooltipClassName)}
          >
            <Text type="body-xs">{p.tooltipContent}</Text>
          </RadixTooltip.Content>
        </RadixPopover.Root>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

export default PopoverWithTooltip;
