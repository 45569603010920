import { MoreMenu, Text } from '@shared/components';
import { CircularProgress } from '@shared/components/plugin-components';
import {
  JobSummaryFragment,
  JobType,
  ProcessingStatus,
  useDeleteJobMutation,
} from '@shared/generated/graphql';
import { JobIconWithPadding } from '@shared/plugin/components/JobIcon';
import { useActionRegistryContext } from '@shared/plugin/contexts/ActionRegistryContext';
import { JobEmailMetadata } from '@shared/types/job';
import { handlePlural } from 'clerk_common/stringification/handlePlural';
import clsx from 'clsx';
import { LuMoreVertical } from 'react-icons/lu';

function jobIsProcessing(status: ProcessingStatus) {
  return (
    status === ProcessingStatus.NEW || status === ProcessingStatus.PROCESSING
  );
}

function jobIsComplete(status: ProcessingStatus) {
  return status === ProcessingStatus.COMPLETED;
}

function containsMultipleQuotes(job: JobSummaryFragment) {
  return job.quotes.length > 1;
}

function inferJobType(job: JobSummaryFragment) {
  if (job.quotes.length > 0) return JobType.QUOTES;
  if (job.orders.length > 0) return JobType.ORDERS;
  return null;
}

function getOriginator(job: JobSummaryFragment) {
  if (job.originator) return job.originator;
  if (job.orders.length > 0) return job.orders[0].originator;
  if (job.quotes.length > 0) return job.quotes[0].originator;
  return null;
}

function getTitle(job: JobSummaryFragment, emailMetadata: JobEmailMetadata) {
  const originator = getOriginator(job);
  if (originator) return originator.name;
  return emailMetadata?.originatorAddress ?? 'Unknown sender';
}

function describeChildren(job: JobSummaryFragment) {
  const orders = job.orders.length;
  const quotes = job.quotes.length;
  const quoteStr = handlePlural(quotes, 'quote', 'quotes');
  const orderStr = handlePlural(orders, 'order', 'orders');
  if (orders > 0 && quotes > 0) {
    return `${orderStr}, ${quoteStr}`;
  }
  if (orders > 0) return orderStr;
  if (quotes > 0) return quoteStr;
  return '';
}

interface JobCardHeaderProps {
  job: JobSummaryFragment;
  initialJobType: JobType;
  toggleExpanded: () => void;
}

export const JobCardHeader = ({
  job,
  initialJobType,
  toggleExpanded,
}: JobCardHeaderProps) => {
  const [deleteJob] = useDeleteJobMutation();
  const { setBatchReplyJobId, batchReplyJobId: currentBatchReplyJobId } =
    useActionRegistryContext();
  const hoverClass = 'hover:shadow-sm cursor-pointer hover:bg-brand-50';
  const isProcessing = jobIsProcessing(job.status);
  const emailMetadata = job.emailMetadata as JobEmailMetadata;
  const title = getTitle(job, emailMetadata);
  const subtitle = emailMetadata?.subject ?? 'No subject';
  const jobType = inferJobType(job) ?? initialJobType;
  const hasChildren = job.orders.length > 0 || job.quotes.length > 0;

  const handleDelete = async () => {
    await deleteJob({
      variables: { input: { id: job.id } },
      refetchQueries: ['GetJobs'],
    });
  };

  const moreMenuOptions = [
    ...(containsMultipleQuotes(job)
      ? [
          {
            label: currentBatchReplyJobId ? 'Cancel reply' : 'Batch reply',
            onPress: () =>
              setBatchReplyJobId(currentBatchReplyJobId ? undefined : job.id),
          },
        ]
      : []),
    { label: 'Delete', onPress: handleDelete, isDestructive: true },
  ];

  if (jobIsComplete(job.status) && !hasChildren) return null;

  return (
    <div
      onClick={toggleExpanded}
      className={clsx(
        'flex flex-row items-start justify-start gap-2 p-2',
        hoverClass
      )}
    >
      <JobIconWithPadding jobType={jobType} />
      <div className="flex flex-1 flex-col items-start">
        <div className="flex w-full flex-row items-center justify-between">
          <Text type="body-xs" isHeavy className="line-clamp-1 text-gray-700">
            {title}
          </Text>
          <div className="flex flex-row items-center gap-1">
            <MoreMenu
              moreIcon={<LuMoreVertical size={12} />}
              options={moreMenuOptions}
            />
          </div>
        </div>
        <Text type="body-xs" className="tet-black line-clamp-1">
          {subtitle}
        </Text>
        {hasChildren && (
          <div className="flex w-full flex-row items-center justify-between">
            <Text type="body-xs" className="line-clamp-1 text-gray-500">
              {describeChildren(job)}
            </Text>
          </div>
        )}
        <div className="mt-1 flex flex-row items-center gap-1">
          <div className="w-1/10">
            {isProcessing ? <CircularProgress size="xs" /> : null}
          </div>
          <Text type="body-xs" className="text-gray-500">
            {isProcessing ? 'Generating...' : ''}
          </Text>
        </div>
      </div>
    </div>
  );
};
