export const VOOMA_AI_EMAIL = 'vooma@vooma.ai';

export enum DeepLinkLocation {
  SUBMITTED_ORDER_TOAST = 'SUBMITTED_ORDER_TOAST',
  ORDER_REVIEW_FORM = 'ORDER_REVIEW_FORM',
}

export enum CreateCustomerLocation {
  ORDER_ORIGINATOR_DROPDOWN = 'ORDER_ORIGINATOR_DROPDOWN',
  QUOTE_ORIGINATOR_DROPDOWN = 'QUOTE_ORIGINATOR_DROPDOWN',
}

export enum RevokeAccessLocation {
  ORGANIZATION_SETTINGS_PAGE = 'ORGANIZATION_SETTINGS_PAGE',
  ORGANIZATION_SIDE_PANEL = 'ORGANIZATION_SIDE_PANEL',
}
