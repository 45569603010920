import Avatar from '@mui/material/Avatar';
import { svgLogoCollapsed } from '@shared/assets/icons/svgs';
import { Text } from '@shared/components';
import {
  QuoteTrailUser,
  UserRelevantQuoteTrailEventType,
} from '@shared/types/quote-event';
import { getUserColour } from '@shared/utils/userColour';
import {
  parseToSequence,
  TextOrMentionElement,
} from 'clerk_common/collaboration/mentions/parse';
import React from 'react';

interface Props {
  time: string;
  message: string;
  creator: QuoteTrailUser | null;
  type: UserRelevantQuoteTrailEventType;
}

type RendererProps = Omit<Props, 'type'>;

function AuditTrailEvent({ type, ...rest }: Props) {
  return type === UserRelevantQuoteTrailEventType.USER_NOTE ? (
    <AuditTrailUserNote {...rest} />
  ) : (
    <AuditTrailAutomatedEvent {...rest} />
  );
}

function AuditTrailUserNote({ time, message, creator }: RendererProps) {
  // NOTE(martin): we don't currently support quotenotes created by Vooma user, but I want to leave
  // this code as I believe this could be something we follow-up on soon
  const creatorDisplayProps =
    creator && !creator.isVoomaUser
      ? {
          bg: getUserColour(creator.colourGroup),
          visual:
            creator.firstName.substring(0, 1) +
            creator.lastName.substring(0, 1),
          name: `${creator?.firstName} ${creator?.lastName}`,
        }
      : {
          bg: 'white',
          visual: svgLogoCollapsed,
          name:
            creator?.firstName || creator?.lastName
              ? `${creator?.firstName} ${creator?.lastName}`
              : 'Vooma Agent',
        };

  return (
    <div className="mt-1 cursor-default">
      <div className="flex w-full items-center gap-2">
        <Avatar
          sx={{
            width: 24,
            height: 24,
            fontSize: '12px',
            bgcolor: creatorDisplayProps.bg,
          }}
        >
          {creatorDisplayProps.visual}
        </Avatar>
        <Text
          className="max-w-40 overflow-hidden text-ellipsis text-nowrap font-semibold"
          type="body-xs"
        >
          {creatorDisplayProps.name}
        </Text>
        <Text className="text-gray-500" type="body-xs">
          {time}
        </Text>
      </div>
      <div className="mt-1">
        <TextWithMentions text={message} />
      </div>
    </div>
  );
}

type TextWithMentionsProps = {
  text: string;
};
function TextWithMentions({ text }: TextWithMentionsProps) {
  const sequence = parseToSequence(text);
  return (
    <Text
      className="ml-8 mr-2 mt-1 overflow-hidden text-ellipsis rounded-md bg-gray-100 p-2 text-gray-700"
      type="body-xs"
    >
      {sequence.map((e: TextOrMentionElement, idx: number) => (
        <React.Fragment key={idx}>
          {e.elementType === 'mention' ? (
            <strong>@{e.display || e.id}</strong>
          ) : (
            e.text
          )}
        </React.Fragment>
      ))}
    </Text>
  );
}

function AuditTrailAutomatedEvent({ time, message }: RendererProps) {
  return (
    <div className="flex">
      <Text
        className="mt-1 overflow-hidden text-ellipsis whitespace-pre-line text-gray-500"
        type="body-xs"
      >
        {`${message} at ${time}`}
      </Text>
    </div>
  );
}

export default AuditTrailEvent;
