import { SnackbarKey, useSnackbar } from 'notistack';
import type {
  ToastActionProps,
  ToastButtonActionProps,
  ToastProps,
} from './Toast';
import Toast from './Toast';

export type ToastMessage = string;
export type ToastOptions = {
  variant?: ToastProps['variant'];
  description?: ToastProps['description'];
  isDismissible?: ToastProps['isDismissible'];
  actions?: ToastActionProps[];
  buttonActions?: ToastButtonActionProps[];
  autoHideDuration?: number;
  compact?: boolean;
  children?: React.ReactNode;
};
export type ToastKey = SnackbarKey;
type UseToastActions = {
  sendToast: (message: ToastMessage, options?: ToastOptions) => ToastKey;
  dismissToast: (key?: ToastKey) => void;
};

export const DEFAULT_TOAST_WITH_ACTION_DURATION = 8000;

export default function useToast(): UseToastActions {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function sendToast(message: ToastMessage, options: ToastOptions = {}) {
    const getToastProps = (key: ToastKey): ToastProps => ({
      title: message,
      compact: options.compact,
      description: options.description,
      variant: options.variant,
      isDismissible: options.isDismissible,
      actions: options?.actions?.map((action) => {
        if (action.isDismissAction)
          return {
            ...action,
            onPress: (event, ...args) => {
              action?.onPress?.(event, ...args);
              closeSnackbar(key);
            },
          };
        return action;
      }),
      buttonActions: options?.buttonActions?.map((action) => {
        if (action.isDismissAction)
          return {
            ...action,
            onPress: (event, ...args) => {
              action?.onPress?.(event, ...args);
              closeSnackbar(key);
            },
          };
        return action;
      }),
    });
    return enqueueSnackbar(message, {
      autoHideDuration: options.autoHideDuration,
      content: (key) => (
        <Toast {...getToastProps(key)} onDismiss={() => closeSnackbar(key)}>
          {options.children}
        </Toast>
      ),
    });
  }

  return { sendToast, dismissToast: closeSnackbar };
}
