export enum PricingStrategyType {
  SIMPLE_MARGIN = 'SIMPLE_MARGIN',
}

export enum RateComponentType {
  LINE_HAUL = 'LINE_HAUL',
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  MARGIN = 'MARGIN',
  TOTAL = 'TOTAL',
  OTHER = 'OTHER',
}

export enum RatingMethod {
  'ARCBEST_TL_QUOTING' = 'ARCBEST_TL_QUOTING',
  'DAT' = 'DAT',
  'ECHO' = 'ECHO',
  'GREENSCREENS' = 'GREENSCREENS',
  'HUMAN_INPUT' = 'HUMAN_INPUT',
  'CUSTOM' = 'CUSTOM',
  'SUNSET' = 'SUNSET',
  'NFI' = 'NFI',
  'TABI' = 'TABI',
  'WERNER' = 'WERNER',
}

export type PricingStrategyConfigurationBase = {
  // base config goes here
};

export type SimpleLLMPricingCalculatorConfig =
  PricingStrategyConfigurationBase & {
    llmLineItemPrompt?: string;
  };

export type SimpleMarginConfig = SimpleLLMPricingCalculatorConfig & {
  ratingMethod: RatingMethod;
  marginPercentage?: number;
  markupDollars?: number;
  minimumMargin?: number;
};

export type PricingStrategyConfiguration = SimpleMarginConfig;

import { z } from 'zod';

export const SimpleMarginConfigSchema = z
  .object({
    ratingMethod: z.nativeEnum(RatingMethod),
    marginPercentage: z.number().optional(),
    markupDollars: z.number().optional(),
    minimumMargin: z.number().optional(),
    llmLineItemPrompt: z.string().optional(),
  })
  .strict();
