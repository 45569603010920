import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  Job,
  JobType,
  useCreateContactMutation,
  useGetOriginatorsLazyQuery,
} from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { AppCueName, CueTrackEvents } from '@shared/hooks/useInAppCues';
import useSendInAppCue from '@shared/hooks/useSendInAppCue';

type SaveContactCueActions = {
  handleSendCue: (params: SendCueParams) => Promise<void>;
};

type SendCueParams = {
  job?: Pick<Job, 'emailMetadata'>;
  originatorId: string;
  jobType: JobType;
};

export const useSaveContactCue = (): SaveContactCueActions => {
  const { sendCue, hideCueForever } = useSendInAppCue();
  const { defaultOrgId } = useMeContext();
  const { sendToast } = useToast();
  const { track } = useAnalytics();
  const [getOriginators] = useGetOriginatorsLazyQuery();
  const [createContact] = useCreateContactMutation();

  const handleDismiss = async (originatorId: string) => {
    track(CueTrackEvents.DISMISS, {
      organizationId: defaultOrgId,
      originatorId,
      name: AppCueName.SAVE_ORIGINATOR_CONTACT,
    });
  };

  const handleAddContact = async (email: string, originatorId: string) => {
    if (!defaultOrgId) return;

    track(CueTrackEvents.ACCEPT, {
      organizationId: defaultOrgId,
      originatorId,
      name: AppCueName.SAVE_ORIGINATOR_CONTACT,
    });

    const res = await createContact({
      variables: {
        input: {
          email,
          name: '',
          organizationId: defaultOrgId,
          originatorId,
        },
      },
      refetchQueries: ['GetOriginators'],
      onError: (error) => {
        const isDuplicate = error.message.includes('duplicate');
        if (isDuplicate) {
          sendToast('Contact already exists.', { variant: 'info' });
        } else {
          sendToast('Failed to save contact.', { variant: 'error' });
        }
      },
    });

    if (res.data?.createContact?.success) {
      sendToast('Contact saved!', { variant: 'success' });
    }
  };

  const handleHideCueForever = async (originatorId: string) => {
    hideCueForever(AppCueName.SAVE_ORIGINATOR_CONTACT);
    track(CueTrackEvents.HIDE_FOREVER, {
      organizationId: defaultOrgId,
      originatorId,
      name: AppCueName.SAVE_ORIGINATOR_CONTACT,
    });
  };

  const handleSendCue = async ({
    job,
    originatorId,
    jobType,
  }: SendCueParams) => {
    const entities = jobType === JobType.QUOTES ? 'Quotes' : 'Orders';

    // NOTE(parlato): Currently this functionality will only work for
    // quotes generated in the Outlook add-in
    const email = job?.emailMetadata?.originatorAddress;
    if (!email) return;

    const res = await getOriginators({
      variables: { input: { id: originatorId } },
    });

    const originatorName = res.data?.originators.edges[0]?.node?.name;
    if (!originatorName) return;

    track(CueTrackEvents.VIEW, {
      organizationId: defaultOrgId,
      originatorId: originatorId,
      name: AppCueName.SAVE_ORIGINATOR_CONTACT,
    });
    sendCue(
      `Save a contact for ${originatorName}?`,
      AppCueName.SAVE_ORIGINATOR_CONTACT,
      {
        description: `Vooma will associate ${email} with ${originatorName} to improve accuracy on future ${entities.toLowerCase()}.`,
        autoHideDuration: 7000,
        actions: [
          {
            label: "Don't ask again",
            size: 'small',
            isDismissAction: true,
            onPress: () => handleHideCueForever(originatorId),
          },
        ],
        buttonActions: [
          {
            variant: 'default',
            size: 'small',
            label: 'Not now',
            isDismissAction: true,
            onPress: () => handleDismiss(originatorId),
          },
          {
            variant: 'primary',
            size: 'small',
            label: 'Yes, please!',
            isDismissAction: true,
            onPress: () => handleAddContact(email, originatorId),
          },
        ],
      }
    );
  };

  return { handleSendCue };
};
