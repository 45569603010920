import { Button, CircularProgress, Text } from '@shared/components';
import useInitializeTemplate from '@shared/graphql/hooks/templates/useInitializeTemplate';
import { useEffect } from 'react';

type PrepareOriginatorForOnboardingProps = {
  originatorId: string;
  onClose: () => void;
};

export function PrepareOriginatorForOnboarding({
  originatorId,
  onClose,
}: PrepareOriginatorForOnboardingProps) {
  const { initializeTemplate, originatorHasTemplate } = useInitializeTemplate({
    originatorId,
  });

  useEffect(() => {
    if (!originatorHasTemplate) {
      initializeTemplate();
    }
  }, [initializeTemplate, originatorHasTemplate]);

  return (
    <div className="flex flex-col w-full h-full gap-4 items-center justify-center p-4">
      {originatorHasTemplate ? (
        <Text className="text-gray-500 text-center" type="body-sm">
          This customer is in an unsupported state. Please contact support.
        </Text>
      ) : (
        <CircularProgress />
      )}
      <Button variant="secondary" onPress={onClose}>
        Go back
      </Button>
    </div>
  );
}
