import { AuthorizeFrontNotice } from '@/components/AuthorizeFrontNotice';
import { AuthorizeUnderlyingInboxNotice } from '@/components/AuthorizeUnderlyingInboxNotice';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  AuthorizedIntegrationContext,
  AuthorizedIntegrationContextType,
} from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import {
  AuthTokenType,
  IntegrationCredentialsType,
  UsageIndicator,
} from '@shared/generated/graphql';
import { useOrganizationHasCredential } from '@shared/graphql/hooks/useOrganizationHasCredential';

type FrontAuthorizatedIntegrationProviderProps = {
  children: JSX.Element;
};

export const FrontAuthorizedIntegrationProvider = ({
  children,
}: FrontAuthorizatedIntegrationProviderProps) => {
  const { defaultOrgId } = useMeContext();
  const microsoftAuthorization = useHasAuthorizedIntegration({
    type: AuthTokenType.MICROSOFT_GRAPH_API,
  });
  const frontAuthorization = useOrganizationHasCredential({
    organizationId: defaultOrgId ?? '',
    type: IntegrationCredentialsType.FRONT,
    usageIndicator: UsageIndicator.PRODUCTION,
  });

  const refetchBoth = async () => {
    await microsoftAuthorization.refetch();
    await frontAuthorization.refetch();
  };

  const value: AuthorizedIntegrationContextType = {
    authorizedEmail: microsoftAuthorization.authorizedEmail,
    authorized:
      microsoftAuthorization.authorized &&
      (frontAuthorization.hasCredential ?? false),
    loading: microsoftAuthorization.loading || frontAuthorization.loading,
    refetch: refetchBoth,
    integrationName: IntegrationCredentialsType.FRONT,
    renderAuthInstructions: () => (
      <AuthorizeFrontIntegrationsNotice
        frontIsAuthorized={frontAuthorization.hasCredential ?? false}
        microsoftIsAuthorized={microsoftAuthorization.authorized}
        refetch={refetchBoth}
      />
    ),
  };

  return (
    <AuthorizedIntegrationContext.Provider value={value}>
      {children}
    </AuthorizedIntegrationContext.Provider>
  );
};

type AuthorizeFrontIntegrationsNoticeProps = {
  frontIsAuthorized: boolean;
  microsoftIsAuthorized: boolean;
  refetch: () => void;
};
export const AuthorizeFrontIntegrationsNotice = (
  p: AuthorizeFrontIntegrationsNoticeProps
) => {
  if (!p.frontIsAuthorized) return <AuthorizeFrontNotice />;
  if (!p.microsoftIsAuthorized) return <AuthorizeUnderlyingInboxNotice />;
  return null;
};
