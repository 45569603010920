import { useCreateJobMutation } from '@/hooks/useCreateJobMutation';
import { useFrontJobs } from '@/hooks/useFrontJobs';
import { useReplyAll } from '@/hooks/useReplyAll';
import { ApplicationMessage } from '@frontapp/plugin-sdk';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { EmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { parseNotifications } from '@shared/features/automatic-navigator/AutomaticNavigator';
import { JobType } from '@shared/generated/graphql';
import { JobEmailMetadata } from '@shared/types/job';
import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFrontContext } from './useFrontPluginContext';

function getMostRecentMessage(msgs: readonly ApplicationMessage[]) {
  return msgs.reduce((acc, curr) => {
    return acc.date > curr.date ? acc : curr;
  }, msgs[0]);
}

type FrontThreadContextProviderProps = {
  children: JSX.Element;
};
export const FrontThreadContextProvider = ({
  children,
}: FrontThreadContextProviderProps) => {
  const { me } = useMeContext();
  const ctx = useFrontContext();
  const createOrderJob = useCreateJobMutation();
  const createQuoteJob = useCreateJobMutation();
  const threadJobs = useFrontJobs({
    conversationId: ctx.conversationId,
  });
  const reply = useReplyAll();

  const [lastMsg, setLastMsg] = useState<ApplicationMessage | null>(null);

  const fetchMessages = useCallback(async () => {
    const msgs = await ctx.listMessages().then((res) => res.results);
    const mostRecentMessage = getMostRecentMessage(msgs);
    setLastMsg(mostRecentMessage);
  }, [ctx]);

  useEffect(() => {
    void fetchMessages();
  }, [fetchMessages]);

  const createEmailMetadata = (): JobEmailMetadata => {
    return {
      itemId: ctx.conversationId,
      messageId: lastMsg?.id,
      subject: lastMsg?.subject,
      sender: me?.email ?? '',
      replyTo: me?.email ?? '',
      originatorAddress: lastMsg?.from?.handle ?? '',
    };
  };

  const createOrderFromThread = async () => {
    const ret = await createOrderJob.createJob({
      emailMetadata: createEmailMetadata(),
      jobType: JobType.ORDERS,
      conversationId: ctx.conversationId,
    });
    return ret.data?.createFrontJob.job;
  };

  const createQuoteFromThread = async () => {
    const ret = await createQuoteJob.createJob({
      emailMetadata: createEmailMetadata(),
      jobType: JobType.QUOTES,
      conversationId: ctx.conversationId,
    });
    return ret.data?.createFrontJob.job;
  };

  const createOrder = {
    call: createOrderFromThread,
    error: createOrderJob.error,
    loading: createOrderJob.loading,
  };

  const createQuote = {
    call: createQuoteFromThread,
    error: createQuoteJob.error,
    loading: createQuoteJob.loading,
  };

  const sharedInbox = {
    isSharedInbox: false,
    candidates: [],
    selectCandidate: () => {},
  };

  const thisItem = lastMsg
    ? {
        id: lastMsg.id,
        sender: {
          emailAddress: lastMsg.from.name ?? '',
        },
      }
    : undefined;

  const itemBody = stringifyMsg(lastMsg);

  const openThread = async (emailMetadata: JobEmailMetadata) => {
    if (!ctx.openThread || isNil(emailMetadata.itemId)) return;
    ctx.openThread(emailMetadata.itemId);
  };

  return (
    <EmailThreadContext.Provider
      value={{
        thisItem,
        notifications: parseNotifications(itemBody),
        threadJobs,
        createOrder,
        createQuote,
        sharedInbox,
        reply,
        openThread,
      }}
    >
      {children}
    </EmailThreadContext.Provider>
  );
};

function stringifyMsg(msg?: ApplicationMessage | null) {
  if (isNil(msg)) return '';
  const componentsToInclude = [msg.subject, msg.content?.body ?? ''];
  return componentsToInclude.join('\n');
}
