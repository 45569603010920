import { EventsTimeline } from '@shared/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import { Stop } from '@shared/utils/forms/types';
import { StopType } from 'clerk_common/types/stop';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { RouteSegment } from './components/RouteSegment';

export type RouteFormProps = StyleProps & {
  stops: Stop[];
  pickups: Stop[];
  removeStop: (type: StopType, idx: number) => void;
  isLoading?: boolean;
  allowCustomAddress?: boolean;
};

const ROOT = makeRootClassName('RouteForm');
const el = makeElementClassNameFactory(ROOT);

export function RouteForm(p: RouteFormProps): ReactElement {
  if (p.isLoading) return <div>Loading...</div>;

  return (
    <div className={clsx(ROOT)}>
      <div className={el`routes-wrapper`}>
        <EventsTimeline>
          <>
            <RouteSegment
              stops={p.pickups}
              stopType="PICKUP"
              el={el}
              removeStop={(idx) => p.removeStop('PICKUP', idx)}
              allowCustomAddress={p.allowCustomAddress}
            />
            <RouteSegment
              stops={p.stops}
              stopType="DELIVERY"
              el={el}
              removeStop={(idx) => p.removeStop('DELIVERY', idx)}
              allowCustomAddress={p.allowCustomAddress}
            />
          </>
        </EventsTimeline>
      </div>
    </div>
  );
}
