import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { EditableJSONField } from '../../types';
import { SimpleArray } from '../simple-array/SimpleArray';
import { Attribute } from './Attribute';

const ROOT = makeRootClassName('AttributeArray');
export const el = makeElementClassNameFactory(ROOT);

export type AttributeElement = {
  attributeName: EditableJSONField;
  attributeValue: EditableJSONField;
};

export type AttributeArrayData = {
  _value: AttributeElement[];
  _added?: AttributeElement[];
  _idxs?: string[];
};

type AttributeArrayProps = {
  data: AttributeArrayData;
  prefix?: string;
};

export function AttributeArray({ data, prefix }: AttributeArrayProps) {
  return (
    <SimpleArray
      data={data}
      ElementComponent={Attribute}
      createNewElement={createAttributeElement}
      description="Attributes"
      prefix={prefix}
    />
  );
}

function createAttributeElement(): AttributeElement {
  const defaultFields = {
    _value: '',
    _display: {
      name: '',
    },
    _logProbs: [],
  };

  return {
    attributeName: {
      ...defaultFields,
      _corrected: '',
    },
    attributeValue: {
      ...defaultFields,
      _corrected: '',
    },
  };
}
