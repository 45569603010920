import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { ReactNode } from 'react';

const NOTISTACK_OPTIONS: Omit<SnackbarProviderProps, 'children'> = {
  maxSnack: 2,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  autoHideDuration: 3000,
};

export function ToastProvider({
  children,
  rootRef = undefined,
}: {
  children?: ReactNode;
  rootRef?: HTMLDivElement | null;
}) {
  const options = {
    ...NOTISTACK_OPTIONS,
    ...(rootRef && {
      domRoot: rootRef,
      classes: { containerRoot: 'bottom-0 absolute z-10' },
    }),
  };
  return <SnackbarProvider {...options}>{children}</SnackbarProvider>;
}
