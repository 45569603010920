import { InlineAddressField } from '@shared/components/inline-address-field/InlineAddressField';
import { SearchInputOption } from '@shared/components/search-input/SearchInput';
import {
  PlaceSuggestionResults,
  usePlacesSuggestions,
} from '@shared/hooks/usePlacesSuggestions';
import { formatAddressComponents } from 'clerk_common/google/address';
import { Address } from 'clerk_common/types/address';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { HiOutlineLocationMarker } from 'react-icons/hi';

const mapSuggestions = (
  suggestions: PlaceSuggestionResults
): SearchInputOption[] => {
  return [...suggestions].map((s) => ({
    label: s.structured_formatting.main_text,
    id: s.place_id,
    details: s.structured_formatting.secondary_text,
    icon: <HiOutlineLocationMarker />,
  }));
};
interface AddressSearchProps<T> {
  name: Path<T>;
  required: boolean;
  save: (p: Path<T> | Path<T>[]) => void;
  validateAddress: (address: Address) => boolean;
  allowCustomAddress?: boolean;
}

export const AddressSearch = <T extends FieldValues>(
  p: AddressSearchProps<T>
) => {
  const { setValue: setFormValue, getValues } = useFormContext<T>();

  const addressPath = `${p.name}.address` as Path<T>;
  const namePath = `${p.name}.name` as Path<T>;
  const nameValue = getValues(namePath);

  const { results, setSearchTerm, geocodeByPlaceId, loading, onSelectionMade } =
    usePlacesSuggestions();
  const mappedSuggestions = mapSuggestions(results);

  const handleSelect = async (id: string) => {
    const res = await geocodeByPlaceId(id);
    if (res) {
      const place = res[0];
      if (!place?.address_components?.length) return;

      onSelectionMade();

      const address = formatAddressComponents(place.address_components);

      setFormValue(addressPath, address as PathValue<T, Path<T>>);

      // NOTE(jacob): We don't currently need the ability to set the
      // location name from the form; we just want to display it if it
      // exists. If the user selects an address and there was a name in
      // the template, we clear it.
      if (nameValue) {
        setFormValue(namePath, '' as PathValue<T, Path<T>>);
        p.save([namePath, addressPath]);
      } else {
        p.save(addressPath);
      }
    }
  };

  return (
    <InlineAddressField<T>
      save={p.save}
      name={p.name}
      required={p.required}
      validateAddress={p.validateAddress}
      handleSearch={(query) => {
        if (query) {
          setSearchTerm(query);
        }
      }}
      handleSelect={handleSelect}
      options={mappedSuggestions}
      loadingSearchResults={loading}
      placeholder="Enter city or zipcode"
      allowCustomAddress={p.allowCustomAddress}
    />
  );
};
