import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { formatTimeString } from '@shared/utils/stringification/timestamp';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { MdCheck } from 'react-icons/md';
import { Button } from '../button';
import { Popover } from '../popover';
import { TimeSelect } from '../react-hook-form';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineTimeField');
const el = makeElementClassNameFactory(ROOT);

interface InlineTimeFieldProps<T> {
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
}

export const InlineTimeField = <T extends FieldValues>(
  p: InlineTimeFieldProps<T>
) => {
  const { watch, setValue } = useFormContext<T>();
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const value: string = watch(p.name);
  const timeString = value ? formatTimeString(value) : '';

  const initialValue = useMemo(() => {
    return watch(p.name);
  }, [timePickerOpen, p.name]);

  const onChange = (time: string) => {
    setValue(p.name, time as PathValue<T, Path<T>>);
  };

  const onSave = () => {
    p.save(p.name);
    setTimePickerOpen(false);
  };

  const onCancel = () => {
    setValue(p.name, initialValue as PathValue<T, Path<T>>);
    setTimePickerOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onCancel();
    } else if (e.key === 'Enter') {
      console.log('TEST TEST');
      onSave();
    }
  };

  const handleSetTimePickerOpen = (open: boolean) => {
    if (!open) {
      onSave();
    }
    setTimePickerOpen(open);
  };

  return (
    <Popover
      className={clsx(ROOT)}
      side="bottom"
      align="start"
      open={timePickerOpen}
      onOpenChange={handleSetTimePickerOpen}
      content={
        <div className={el`time-input-container`}>
          <TimeSelect
            className="w-full"
            step={900}
            value={value}
            name={p.name}
            onChange={onChange}
            onKeyDown={handleKeyDown}
          />
          <div className={el`actions`}>
            <Button
              variant="secondary"
              tooltip="Save"
              icon={<MdCheck size={16} />}
              onPress={onSave}
            />
          </div>
        </div>
      }
    >
      <div className={clsx(ROOT)}>
        <div
          className={clsx(
            el`time-wrapper`,
            p.required && 'REQUIRED',
            timeString ? 'VALID' : 'INVALID'
          )}
        >
          <Text type="body-xs">{timeString || 'Add time'}</Text>
        </div>
      </div>
    </Popover>
  );
};
