import { InteractableComponent } from '@shared/components/interactable-component';
import { TextField } from '@shared/components/react-hook-form';
import { Tooltip } from '@shared/components/tooltip';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { formatAddress } from 'clerk_common/stringification/address';
import clsx from 'clsx';
import { useState } from 'react';
import { Check, Menu } from 'react-feather';
import { Path, useFormContext } from 'react-hook-form';
import { DebugTooltip } from '../../fields/DebugTooltip';
import { FieldLabel } from '../../fields/FieldLabel';
import {
  determineBestValueField,
  isRequiredField,
} from '../../fields/fieldLogic';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { EditableJSONField, FORM_STATE_FIELD } from '../../types';
import { AddressComponentsForm } from './AddressComponents';

const ROOT = makeRootClassName('Address');
export const el = makeElementClassNameFactory(ROOT);

export type AddressComponents = {
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export type AddressData = {
  _value: string;
  _sanitized?: string | AddressComponents;
  _transformed?: string | AddressComponents;
  _corrected?: string | AddressComponents;
  _form?: string | AddressComponents;
  _entity?: string | AddressComponents;
  _display?: {
    name: string;
  };
  _logProbs: number[];
};

type AddressProps = {
  data: AddressData;
  prefix?: string;
};

export function Address({ data, prefix }: AddressProps) {
  const { setValue } = useFormContext<AddressData>();
  const { requiredFields, save } = useInternalFormContext();
  const [isEditing, setIsEditing] = useState(false);
  const isRequired = isRequiredField(requiredFields, prefix);
  const bestValueField = determineBestValueField(data as EditableJSONField);
  const displayValue = getDisplayAddress(data[bestValueField]);
  const clear = () => {
    setValue(
      `${prefix}${FORM_STATE_FIELD}` as Path<AddressData>,
      copyComponents('')
    );
    save();
  };

  const createCorrection = () => {
    if (data._corrected) return;
    setValue(
      `${prefix}${FORM_STATE_FIELD}` as Path<AddressData>,
      copyComponents(data[bestValueField])
    );
  };

  const label = <FieldLabel label={data._display?.name ?? ''} />;

  return (
    <div className={ROOT}>
      <div className={el`header`}>
        <DebugTooltip data={data as EditableJSONField}>
          <TextField
            defaultValue={displayValue}
            label={label}
            isRequired={isRequired}
            rules={{
              required: isRequired,
            }}
            value={displayValue}
            name={`${prefix}${FORM_STATE_FIELD}` as '_value'}
            size="small"
            type={'text'}
            isDisabled
            onClear={clear}
          />
        </DebugTooltip>
        <InteractableComponent
          className={el`edit-save-button`}
          onPress={() => {
            if (isEditing) {
              setIsEditing(false);
              save();
            } else {
              createCorrection();
              setIsEditing(true);
            }
          }}
        >
          <Tooltip
            content={`${isEditing ? 'Hide' : 'Show'} address components`}
          >
            {isEditing ? <Check size={18} /> : <Menu size={18} />}
          </Tooltip>
        </InteractableComponent>
      </div>
      <div className={clsx(`${ROOT} is-collapsed-${!isEditing}`)}>
        <AddressComponentsForm
          data={data[bestValueField] as AddressComponents}
          prefix={prefix}
        />
      </div>
    </div>
  );
}

function getDisplayAddress(data?: string | AddressComponents) {
  if (!data) {
    return '';
  }
  if (typeof data === 'string') {
    return data;
  }
  return formatAddress(data);
}

function copyComponents(data?: string | AddressComponents) {
  if (!data) {
    return {
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };
  }
  if (typeof data === 'string') {
    return {
      addressOne: data,
      addressTwo: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };
  }
  return { ...data };
}
