import { useToast } from '@shared/components/toast';
import { useGetQuoteResponseMessageQuery } from '@shared/generated/graphql';

type UseDraftQuoteResponseOptions = {
  quoteIds: string[];
  originatorId?: string;
};

const useDraftQuoteResponse = ({
  quoteIds,
  originatorId,
}: UseDraftQuoteResponseOptions) => {
  const { sendToast } = useToast();
  const { data, loading } = useGetQuoteResponseMessageQuery({
    variables: {
      input: {
        quoteIds,
        originatorId,
      },
    },
    onError: () => {
      sendToast('Error generating draft response', {
        variant: 'error',
        isDismissible: true,
      });
    },
  });

  return {
    message: data?.getQuoteResponseMessage?.message ?? undefined,
    isLoading: loading,
  };
};

export default useDraftQuoteResponse;
