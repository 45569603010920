import { Option, Select, TextField } from '@shared/components/react-hook-form';
import { Text } from '@shared/components/text';
import { getStateOptions, stateCodes } from 'clerk_common/geography';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isRequiredField } from '../../fields/fieldLogic';
import { useInternalFormContext } from '../../internalFormContext/InternalFormContext';
import { FORM_STATE_FIELD } from '../../types';
import { AddressComponents, el } from './Address';

type AddressProps = {
  data: AddressComponents;
  prefix?: string;
};

type AddressFormField =
  | 'addressOne'
  | 'addressTwo'
  | 'city'
  | 'state'
  | 'zip'
  | 'country';

export function AddressComponentsForm({ data, prefix }: AddressProps) {
  const stateOptions = getStateOptions(data.country);
  const countryOptions = Object.keys(stateCodes);
  return (
    <div className={el`form`}>
      <AddressComponentField
        value={data.addressOne ?? ''}
        fieldPath={`${prefix}${FORM_STATE_FIELD}.addressOne`}
        label={'Address Line 1'}
      />
      <AddressComponentField
        value={data.addressTwo ?? ''}
        fieldPath={`${prefix}${FORM_STATE_FIELD}.addressTwo`}
        label={'Address Line 2'}
      />
      <div className={el`side-by-side`}>
        <AddressComponentField
          value={data.city ?? ''}
          fieldPath={`${prefix}${FORM_STATE_FIELD}.city`}
          label={'City'}
        />
        <SelectComponent
          value={data.state ?? ''}
          options={stateOptions}
          fieldPath={`${prefix}${FORM_STATE_FIELD}.state`}
          label={'State'}
        />
      </div>
      <div className={el`side-by-side`}>
        <AddressComponentField
          value={data.zip ?? ''}
          fieldPath={`${prefix}${FORM_STATE_FIELD}.zip`}
          label={'Zip'}
        />
        <SelectComponent
          value={data.country ?? ''}
          options={countryOptions.map((c) => ({ label: c, value: c }))}
          fieldPath={`${prefix}${FORM_STATE_FIELD}.country`}
          label={'Country'}
        />
      </div>
    </div>
  );
}

function AddressComponentField({
  value,
  fieldPath,
  label,
}: {
  value: string;
  fieldPath: string;
  label: string;
}) {
  const { requiredFields, save } = useInternalFormContext();
  const { setValue } = useFormContext<AddressComponents>();
  const [fieldValue, setFieldValue] = useState(value);
  const isRequired = isRequiredField(requiredFields, fieldPath);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const clear = () => {
    setFieldValue('');
    setValue(fieldPath as AddressFormField, '');
    save();
  };
  return (
    <TextField
      value={fieldValue}
      defaultValue={fieldValue}
      label={label}
      name={fieldPath}
      size="small"
      type={'text'}
      onClear={clear}
      onChange={(e) => {
        setFieldValue(e);
        setValue(fieldPath as AddressFormField, e);
      }}
      isRequired={isRequired}
      rules={{
        required: isRequired,
      }}
      onFocusChange={(e) => {
        if (!e) save();
      }}
    />
  );
}

function SelectComponent({
  value,
  options,
  label,
  fieldPath,
}: {
  value: string;
  options: { label: string; value: string }[];
  label: string;
  fieldPath: string;
}) {
  const { requiredFields, save } = useInternalFormContext();
  const { setValue } = useFormContext<AddressComponents>();
  const isRequired = isRequiredField(requiredFields, fieldPath);
  return (
    <Select
      label={label}
      onChange={(e) => {
        setValue(fieldPath as AddressFormField, e);
        save();
      }}
      defaultValue={value}
      size="small"
      value={value}
      name={fieldPath}
      renderValue={(val) => {
        return <Text type="body-xs">{val}</Text>;
      }}
      rules={{
        required: isRequired,
      }}
      isRequired={isRequired}
    >
      {options.map((o) => (
        <Option key={o.value} value={o.value}>
          <Text type="body-xs" className={el`dropdown-option`}>
            {o.label}
          </Text>
        </Option>
      ))}
    </Select>
  );
}
