import { ApolloError } from '@apollo/client';
import { JobSummaryFragment, JobType, Maybe } from '@shared/generated/graphql';
import { JobEmailMetadata } from '@shared/types/job';
import { createContext, useContext } from 'react';

type ApiCallback<ArgsT, ReturnT> = {
  call: (args: ArgsT) => Promise<ReturnT>;
  error?: ApolloError;
  loading: boolean;
};

type Sender = {
  emailAddress: string;
};

type ThreadItem = {
  id: string;
  sender: Sender;
};

type SharedInboxState = {
  isSharedInbox: boolean;
  owner?: string;
  candidates: string[];
  selectCandidate: (email?: string) => void;
};

export type JobsState = {
  jobs: JobSummaryFragment[];
  jobTypes: Record<string, JobType>;
  storeJobType: (jobType: JobType, jobId?: string) => void;
  loading: boolean;
};

type ReplyState = {
  draftReplyAll: (itemId: string, content?: string) => Promise<void>;
  loadingDraftReplyAll: boolean;
};

export type ParsedNotifications = {
  quoteId?: string;
  orderId?: string;
};

export type EmailThreadContextType = {
  thisItem?: ThreadItem;
  notifications?: ParsedNotifications;
  threadJobs: JobsState;
  createOrder: ApiCallback<void, Maybe<JobSummaryFragment> | undefined>;
  createQuote: ApiCallback<void, Maybe<JobSummaryFragment> | undefined>;
  sharedInbox: SharedInboxState;
  reply: ReplyState;
  openThread: (emailMetadata: JobEmailMetadata) => void;
};

export const EmailThreadContext = createContext<EmailThreadContextType>(
  buildEmptyContext()
);

export function useEmailThreadContext() {
  const context = useContext(EmailThreadContext);
  if (!context) {
    throw new Error(
      'EmailThreadContext must be used within a EmailThreadContextProvider'
    );
  }
  return context;
}

function buildEmptyContext(): EmailThreadContextType {
  return {
    threadJobs: {
      jobs: [],
      jobTypes: {},
      storeJobType: () => {},
      loading: false,
    },
    createOrder: {
      call: async () => null,
      error: undefined,
      loading: false,
    },
    createQuote: {
      call: async () => null,
      error: undefined,
      loading: false,
    },
    sharedInbox: {
      isSharedInbox: false,
      candidates: [],
      selectCandidate: () => {},
    },
    reply: {
      draftReplyAll: async () => {},
      loadingDraftReplyAll: false,
    },
    openThread: () => {},
  };
}
