import { differenceInMinutes } from 'date-fns';

interface ProcessingEvent<EventTypeT> {
  type?: EventTypeT | null;
  createdAt: string;
}

export type StatusString =
  | 'NEW'
  | 'PROCESSING'
  | 'COMPLETED'
  | 'FAILED'
  | 'TIMED_OUT';

interface EventTypeAdaptor<EventTypeT, StatusT> {
  isProcessingEvent: (type?: EventTypeT | null) => boolean;
  isTriggeredEvent: (type?: EventTypeT | null) => boolean;
  isCompletedEvent: (type?: EventTypeT | null) => boolean;
  isFailedEvent: (type?: EventTypeT | null) => boolean;
  toStatus: (str: StatusString) => StatusT;
}

export function processingStatusFromEvents<EventTypeT, StatusT>(
  events: ProcessingEvent<EventTypeT>[],
  adaptors: EventTypeAdaptor<EventTypeT, StatusT>,
  timeNow: Date = new Date(),
  timeoutInMinutes = 5
): StatusT {
  const es = [...events].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
  const e = es.filter((e) => adaptors.isProcessingEvent(e.type));
  if (!e.length) return adaptors.toStatus('NEW');
  const lastEvent = e[e.length - 1];
  if (adaptors.isCompletedEvent(lastEvent.type)) {
    return adaptors.toStatus('COMPLETED');
  }
  if (adaptors.isFailedEvent(lastEvent.type)) {
    return adaptors.toStatus('FAILED');
  }
  if (
    differenceInMinutes(timeNow, new Date(lastEvent.createdAt)) >=
    timeoutInMinutes
  ) {
    return adaptors.toStatus('TIMED_OUT');
  }
  if (adaptors.isTriggeredEvent(lastEvent.type)) {
    return adaptors.toStatus('PROCESSING');
  }
  throw new Error(`Could not determine status from events ${e}`);
}
