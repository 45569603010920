import {
  JobEventType,
  JobSource,
  JobSummaryFragment,
  ProcessingStatus,
} from '@shared/generated/graphql';

export const filterOptimisticJobs = (jobs: JobSummaryFragment[]) => {
  return jobs.filter(
    (job) =>
      job?.source !== JobSource.OUTLOOK_EMAIL_MONITORING ||
      (job?.jobEvents?.length > 0 &&
        job?.jobEvents?.some(
          (jobEvent) => jobEvent?.type === JobEventType.USER_CONFIRMED
        ))
  );
};

export const filterJobsWithNoEntities = (jobs: JobSummaryFragment[]) => {
  return jobs.filter(
    (job) =>
      job?.quotes.length > 0 ||
      job?.orders.length > 0 ||
      job?.status !== ProcessingStatus.COMPLETED
  );
};

export const filterJobsToDisplay = (jobs: JobSummaryFragment[]) => {
  return filterOptimisticJobs(filterJobsWithNoEntities(jobs));
};
