import { CircularProgress, Tooltip } from '@shared/components';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { useRelatedExternalMessages } from './hooks/useRelatedExternalMessages';

const ROOT = makeRootClassName('EmailThreadActivity');
const el = makeElementClassNameFactory(ROOT);

const ProcessThreadActivity = ({ isProcessing }: { isProcessing: boolean }) => {
  return isProcessing ? (
    <div className={el`processing-activity`}>
      <Tooltip
        isInstant
        content="Vooma is processing messages on this thread"
        side="bottom"
      >
        <div>
          <CircularProgress size="xs" />
        </div>
      </Tooltip>
    </div>
  ) : null;
};

export const EmailThreadActivity = () => {
  const { isProcessing } = useRelatedExternalMessages();

  return <ProcessThreadActivity isProcessing={isProcessing} />;
};
