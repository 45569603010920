import useToast, {
  ToastKey,
  ToastMessage,
  ToastOptions,
} from '@shared/components/toast/useToast';
import { AppCueName, useInAppCues } from './useInAppCues';

type UseToastActions = {
  sendCue: (
    message: ToastMessage,
    cueName: AppCueName,
    options?: ToastOptions
  ) => ToastKey | undefined;
  dismissCue: (cueName: AppCueName, key?: ToastKey) => void;
  hideCueForever: (cueName: AppCueName) => void;
};

export default function useSendInAppCue(): UseToastActions {
  const { sendToast, dismissToast } = useToast();
  const { viewCue, getCue, hideCue } = useInAppCues();

  const sendCue = (
    message: ToastMessage,
    appCueName: AppCueName,
    options?: ToastOptions
  ) => {
    const cue = getCue(appCueName);
    if (!cue?.hide) {
      viewCue(appCueName);
      return sendToast(message, options);
    }
  };

  const hideCueForever = (appCueName: AppCueName) => {
    hideCue(appCueName);
  };

  return { sendCue, dismissCue: dismissToast, hideCueForever };
}
