import clsx from 'clsx';
import { FormField } from '../../fields/FormField';
import { makeDisplayData } from '../simple-array/SimpleArray';
import { TagElement, el } from './TagArray';

type TagProps = {
  idx: number;
  data: TagElement;
  prefix?: string;
};

export function Tag({ idx, data, prefix }: TagProps) {
  return (
    <div className={clsx(el`tag-element`, 'group')}>
      <FormField
        data={{
          ...data.name,
          ...makeDisplayData(idx, 'Tags'),
        }}
        prefix={`${prefix}name.`}
      />
    </div>
  );
}
