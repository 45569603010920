import { useToast } from '@shared/components/toast';
import {
  GetTemplatesDocument,
  TemplateType,
  useCreateTemplateMutation,
} from '@shared/generated/graphql';
import { isNil } from 'lodash';
import useOrderTemplate from './useOrderTemplate';

type UseInitializeTemplateOptions = {
  originatorId?: string;
  errorMessage?: string;
};

type UseTemplateState = {
  originatorHasTemplate: boolean;
};

type UseTemplateActions = {
  initializeTemplate: () => void;
};

type UseInitializeTemplateValue = UseTemplateState & UseTemplateActions;

const useInitializeTemplate = (
  props: UseInitializeTemplateOptions
): UseInitializeTemplateValue => {
  const [createTemplate] = useCreateTemplateMutation();
  const { sendToast } = useToast();
  const { template } = useOrderTemplate({
    originatorId: props.originatorId,
  });

  const sendErrorToast = (e: Error) => {
    sendToast('Something went wrong', {
      description: e.message,
      variant: 'error',
      isDismissible: true,
    });
  };

  const originatorHasTemplate = !isNil(template);

  const initializeTemplate = async () => {
    if (template) {
      return;
    }

    try {
      await createTemplate({
        variables: {
          input: {
            type: TemplateType.FREIGHT_ORDER,
            originatorId: props.originatorId,
            initializeFromDefaultTemplate: true,
          },
        },
        refetchQueries: [GetTemplatesDocument],
      });
    } catch (e) {
      sendErrorToast(
        new Error(
          props.errorMessage ?? 'Failed to initialize customer configuration.'
        )
      );
      return;
    }
  };

  return {
    originatorHasTemplate,
    initializeTemplate,
  };
};

export default useInitializeTemplate;
