import { User } from '@shared/types/user';
import { UserFeature } from 'clerk_common';
import {
  checkIsValidEmail,
  checkIsValidEmailDomain,
  getUserEmailScanningFeature,
} from 'clerk_common/features/emailScanning';

export type EmailScanningFeatureFormValues = {
  enabled: boolean;
  allowList: { value: string }[];
  blockList: { value: string }[];
};

export const getInitialEmailScanningFormData = (
  organizationId?: string,
  user?: Pick<User, 'features'>
): EmailScanningFeatureFormValues => {
  if (!user || !organizationId) {
    return {
      enabled: false,
      allowList: [],
      blockList: [],
    };
  }

  const features = user.features?.features || [];
  const feature = getUserEmailScanningFeature(organizationId, { features });

  return {
    enabled: Boolean(feature?.enabled),
    allowList:
      feature?.config.allowList.map((address) => ({ value: address })) || [],
    blockList:
      feature?.config.blockList.map((address) => ({ value: address })) || [],
  };
};

const isValidEmailOrDomain = (email: string) => {
  return checkIsValidEmail(email) || checkIsValidEmailDomain(email);
};

export const mapFormValuesToUserFeatures = (
  data: EmailScanningFeatureFormValues,
  organizationId: string
): UserFeature => {
  return {
    name: 'EmailScanning',
    enabled: Boolean(data.enabled),
    config: {
      allowList: data.allowList
        .map((address) => address.value)
        .filter(isValidEmailOrDomain),
      blockList: data.blockList
        .map((address) => address.value)
        .filter(isValidEmailOrDomain),
    },
    organizationId,
  };
};

export const getUpdatedFeatures = (
  data: EmailScanningFeatureFormValues,
  organizationId: string,
  user: Pick<User, 'features'>
) => {
  const userFeature = mapFormValuesToUserFeatures(data, organizationId);
  const features = user.features?.features || [];
  const otherFeatures = features.filter(
    (f) => f.name !== 'EmailScanning'
  ) as UserFeature[];
  return [...otherFeatures, userFeature];
};
