import { LogosURLsContext } from '@shared/contexts/pluginContexts/LogosURLsContext';

type StandardLogosContextProviderProps = {
  children: JSX.Element;
};
export const StandardLogosContextProvider = ({
  children,
}: StandardLogosContextProviderProps) => {
  const logos = {
    GREENSCREENS: '/greenscreens.png',
    DAT: '/dat.png',
    SUNSET: '/sunset.png',
    TABI: '/tabi.png',
    WERNER: '/werner.png',
    ARCBEST: '/arcbest.png',
    ECHO: '/echo.png',
  };

  return (
    <LogosURLsContext.Provider
      value={{
        logos,
      }}
    >
      {children}
    </LogosURLsContext.Provider>
  );
};
