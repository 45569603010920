import { ApolloError } from '@apollo/client';
import {
  OriginatorStatus,
  useGetOriginatorsQuery,
} from '@shared/generated/graphql';
import { originatorsFromFragment } from '@shared/plugin/types/fromFragments/originator';
import { Originator } from '@shared/plugin/types/originator';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

type UseOriginatorsOptions = {
  organizationId?: string;
  statuses?: OriginatorStatus[];
  searchTerm?: string;
  pollIntervalMs?: number;
  id?: string;
};

type UseOriginatorsState = {
  originators: Originator[];
  isLoading: boolean;
  error?: ApolloError;

  searchTerm?: string;
  setSearchTerm: (searchTerm?: string) => void;
};

type UseOriginatorsActions = {
  // add any actions the hook provides
};

export type UseOriginatorsValue = UseOriginatorsState & UseOriginatorsActions;

const useOriginators = (props?: UseOriginatorsOptions): UseOriginatorsValue => {
  const [storedSearchTerm, setStoredSearchTerm] = useState<string>();
  const searchTerm = props?.searchTerm ?? storedSearchTerm;

  const { data, loading, error } = useGetOriginatorsQuery({
    variables: {
      input: {
        organizationIds: props?.organizationId ? [props.organizationId] : [], // TODO(parlato): Verify this returns null with []
        statuses: props?.statuses,
        searchTerm: props?.searchTerm,
        id: props?.id,
      },
    },
    pollInterval: props?.pollIntervalMs,
  });

  const originators = data ? originatorsFromFragment(data) : [];

  const debouncedHandleSearch = useCallback(
    debounce((query?: string) => setStoredSearchTerm(query), 300),
    [setStoredSearchTerm]
  );

  return {
    originators,
    isLoading: loading,
    error,
    searchTerm,
    setSearchTerm: debouncedHandleSearch,
  };
};

export default useOriginators;
