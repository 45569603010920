import { Text } from '@shared/components/text';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export type FormDisplayTableRowProps = StyleProps & {
  /** The text to display on the left side of the row */
  displayText: string;
  /** The value to display on the right side of the row */
  value?: string | ReactElement;
  /** Whether or not the row should be highlighted */
  isHighlighted?: boolean;
  /** Whether or not there is a space above the row */
  isSpaced?: boolean;
  statusChip?: ReactNode;
};

const ROOT = makeRootClassName('FormDisplayTableRow');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function FormDisplayTableRow(
  props: FormDisplayTableRowProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div
      className={clsx(ROOT, p.className, el`list`, {
        'is-highlighted': p.isHighlighted,
        'is-spaced': p.isSpaced,
      })}
    >
      <div className={el`key-row`}>
        <Text type="body-sm">{p.displayText}</Text>
      </div>
      <div className={clsx(el`value-row`)}>
        {p.statusChip ? p.statusChip : <Text type="body-sm">{p.value}</Text>}
      </div>
    </div>
  );
}
export default FormDisplayTableRow;
