import { Button, CircularProgress, Text } from '@shared/components';
import { Pagination } from '@shared/components/plugin-components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { QuoteSummaryFragment } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { usePagination } from '@shared/hooks/usePagination';
import { useEffect } from 'react';

import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { QuoteRow } from './components/QuoteRow';
import useGetQuoteSummaries from './hooks/useGetQuoteSummaries';

interface QuotesProps {
  quotes: QuoteSummaryFragment[];
}

const PAGE_SIZE = 10;

const Quotes = (p: QuotesProps) => {
  return (
    <div className="flex flex-col overflow-y-scroll">
      {p.quotes.map((quote) => (
        <QuoteRow quote={quote} key={quote.id} />
      ))}
    </div>
  );
};

type QuotesContainerProps = {
  webBaseUrl: string;
};
const QuotesContainer = (props: QuotesContainerProps) => {
  const { page } = useAnalytics();
  const { defaultOrgId, me } = useMeContext();
  const { first, last, before, after, handleNextPage, handlePreviousPage } =
    usePagination(PAGE_SIZE);
  const {
    quotes,
    isLoading,
    totalCount,
    hasNextPage,
    hasPreviousPage,
    endCursor,
    startCursor,
  } = useGetQuoteSummaries({
    first,
    last,
    fetchAll: true,
    organizationIds: defaultOrgId ? [defaultOrgId] : [],
    pollIntervalMs: 1000 * 15,
    userIds: me?.id ? [me.id] : [],
    after,
    before,
  });

  useEffect(() => {
    if (defaultOrgId) {
      page('Quotes', { organizationId: defaultOrgId });
    }
  }, [defaultOrgId]);

  return isLoading ? (
    <div className="flex w-full h-1/4 items-center justify-center">
      <CircularProgress size="small" />
    </div>
  ) : (
    <div className="flex flex-col flex-1 py-2 overflow-hidden">
      <div className="p-2 text-gray-500">
        <Text
          type="body-xs"
          className="flex flex-row gap-1 items-center justify-center"
        >
          {`Search and filter all quotes in the`}
          <a
            href={`${props.webBaseUrl}${QUOTES_ROUTE}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="xs"
              icon={<MdOutlineOpenInNew className="text-brand-500" />}
              variant="tertiary"
              iconPosition="right"
            >
              Vooma web app
            </Button>
          </a>
        </Text>
      </div>
      <Pagination
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        totalCount={totalCount}
        onNextPage={() => handleNextPage(endCursor)}
        onPreviousPage={() => handlePreviousPage(startCursor)}
        startCursor={startCursor}
        endCursor={endCursor}
      />
      <Quotes quotes={quotes} />
    </div>
  );
};

export default QuotesContainer;
