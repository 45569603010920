import clsx from 'clsx';
import { FormField } from '../../fields/FormField';
import { makeDisplayData } from '../simple-array/SimpleArray';
import { AttributeElement, el } from './AttributeArray';

type AttributeProps = {
  idx: number;
  data: AttributeElement;
  prefix?: string;
};

export function Attribute({ idx, data, prefix }: AttributeProps) {
  return (
    <div className={clsx(el`attribute-element`, 'group')}>
      <FormField
        data={{
          ...data.attributeName,
          ...makeDisplayData(idx, 'Attribute Name'),
        }}
        prefix={`${prefix}attributeName.`}
      />
      <FormField
        data={{
          ...data.attributeValue,
          ...makeDisplayData(idx, 'Value'),
        }}
        prefix={`${prefix}attributeValue.`}
      />
    </div>
  );
}
