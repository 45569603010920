import { svgSend } from '@shared/assets';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useFetchUsers } from '@shared/graphql/hooks/users/useGetUsers';
import { User } from '@shared/types/user';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { Button } from '../button';
import { CircularProgress } from '../circular-progress';
import { useEmojis } from './useEmojis';

const ROOT = makeRootClassName('MentionInput');
const el = makeElementClassNameFactory(ROOT);

const getNameField = (user: User) => {
  if (user.name && user.name.length > 0) return user.name;
  if (user.userName && user.userName.length > 0) return user.userName;
};

const stringifyUser = (user: User) => {
  const fields = [];
  const nameField = getNameField(user);
  if (nameField && nameField.length > 0) fields.push(nameField);
  if (user.email && user.email.length > 0) fields.push(user.email);
  return fields.join(' - ');
};

const getBestDisplayName = (user: User) => {
  if (user.name && user.name.length > 0) return user.name;
  if (user.userName && user.userName.length > 0) return user.userName;
  if (user.email && user.email.length > 0) return user.email;
  return 'Unnamed User';
};

const toMention = (user: User) => ({
  id: user.id,
  display: getBestDisplayName(user),
});

type SubmitProps = {
  loading: boolean;
  isDisabled: boolean;
  onSubmit: () => void;
};

type MentionInputProps = {
  value: string;
  onChange: (val: string) => void;
  submit: SubmitProps;
};

export function MentionInput({ value, onChange, submit }: MentionInputProps) {
  const { me } = useMeContext();
  const [users, setUsers] = useState<User[]>([]);
  const { fetchEmojis, getEmojiById } = useEmojis();

  const { fetchUsers } = useFetchUsers({
    organizationIds: me?.organizationsDetails.map((org) => org.id) ?? [],
  });

  const replaceEmojis = (text: string) => {
    const emojiRegex = /\\emoji\{([^}]+)\}\\/g;
    const out = text.replace(emojiRegex, (match, id) => {
      return getEmojiById(id)?.emoji ?? match;
    });
    return out;
  };

  const setAndReturnUsers = async (newUsers: User[]) => {
    setUsers(newUsers);
    return newUsers;
  };

  const fetchMentionOptions = async (
    query: string,
    callback: (options: SuggestionDataItem[]) => void
  ) => {
    const MAX_RESULTS = 10;
    await fetchUsers(query)
      .then(setAndReturnUsers)
      .then((res) => res.map(toMention).slice(0, MAX_RESULTS))
      .then(callback);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const shiftIsPressed = e.shiftKey;
    const isEnter = e.key === 'Enter';
    if (isEnter && !shiftIsPressed && !submit.isDisabled && value) {
      submit.onSubmit();
    }
  };

  const UserSuggestion = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode
    // index: number,
    // focused: boolean
  ) => {
    const user = users.find((u) => u.id === suggestion.id);
    if (user) {
      const userString = stringifyUser(user);
      return <HighlightMatchingSubStrings text={userString} search={search} />;
    }
    return highlightedDisplay;
  };

  const EmojiSuggestion = (
    suggestion: SuggestionDataItem,
    search: string
    // highlightedDisplay: React.ReactNode,
    // index: number
    // focused: boolean
  ) => {
    const e = getEmojiById(`${suggestion.id}`);
    if (!e) return null;
    const { id, emoji } = e;
    return (
      <div className="flex flex-row gap-3">
        <span>{emoji}</span>
        <HighlightMatchingSubStrings text={id} search={search} />
      </div>
    );
  };

  const findEmoji = (id: string) => getEmojiById(id)?.emoji ?? '?';

  const isDisabled = submit.loading;

  return (
    <div className={clsx(ROOT, { 'is-disabled': isDisabled })}>
      <MentionsInput
        style={styles}
        value={value}
        onChange={(e) => onChange(replaceEmojis(e.target.value))}
        onKeyDown={onKeyDown}
        placeholder='Start typing, using "@" to mention someone...'
        customSuggestionsContainer={(children) => (
          <div className={el`suggestions-container`}>{children}</div>
        )}
        forceSuggestionsAboveCursor
        allowSpaceInQuery
        disabled={isDisabled}
      >
        <Mention
          style={mentionStyles}
          trigger="@"
          data={fetchMentionOptions}
          markup={'<@__id__:user:__display__>'}
          displayTransform={(_, display) => `@${display}`}
          renderSuggestion={UserSuggestion}
        />
        <Mention
          trigger=":"
          markup="\emoji{__id__}\"
          data={fetchEmojis}
          displayTransform={(id, _) => findEmoji(id)}
          renderSuggestion={EmojiSuggestion}
        />
      </MentionsInput>
      <SendButton
        onPress={submit.onSubmit}
        isDisabled={submit.isDisabled}
        loading={submit.loading}
      />
    </div>
  );
}

type SendButtonProps = {
  onPress: () => void;
  isDisabled: boolean;
  loading: boolean;
};
function SendButton(p: SendButtonProps) {
  return (
    <div className={el`send-button`}>
      {p.loading ? (
        <CircularProgress size="xs" />
      ) : (
        <Button
          icon={svgSend}
          onPress={p.onPress}
          isDisabled={p.isDisabled}
          size="xs"
        ></Button>
      )}
    </div>
  );
}

type HighlightMatchingSubStringsProps = {
  text: string;
  search: string;
};
function HighlightMatchingSubStrings({
  text,
  search,
}: HighlightMatchingSubStringsProps) {
  if (!search) return <span>{text}</span>;
  const parts = text.split(new RegExp(`(${search})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => {
        if (part.toLowerCase() === search.toLowerCase()) {
          return (
            <span key={i}>
              <strong>{part}</strong>
            </span>
          );
        }
        return part;
      })}
    </span>
  );
}

// TODO(mike) Figure out how to style in a way that is more consistent with the rest of the app
const styles = {
  control: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'black',
    backgroundColor: 'transparent',
  },
  input: {
    outline: 'none',
    padding: '5px',
  },
  highlighter: {
    border: 0,
    padding: '5px',
  },

  '&multiLine': {
    control: {
      minHeight: 63,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '0px solid rgba(0,0,0,0.15)',
      fontSize: 12,
    },
    item: {
      padding: '5px 15px',
      '&focused': {
        backgroundColor: '#eeeeee',
      },
      borderRadius: 3,
    },
  },
};

// NOTE(mike): The styling here is a bit limited because react-mentions styles
// by overlaying the rendered mention over the text input. I don't recommend
// trying to introduce any spacing-related styles (padding, margin, etc) here.
const mentionStyles = {
  backgroundColor: '#e5eaed',
  borderRadius: 3,
  outline: '2px solid #e5eaed',
  outlineOffset: '-1px',
};
