import { MaybeCompletedFreightOrderTemplate } from '../../types';

export function hasCommoditySection(
  prefix: string,
  template?: MaybeCompletedFreightOrderTemplate
): boolean {
  if (!template) return false;

  if (!template._value.commodityDetails && !template._value.commodities) {
    return false;
  }
  if (template._value.commodityDetails) {
    // TODO(parlato): Refactor to use better typing here
    return Boolean((template._value.commodityDetails._value as any)[prefix]);
  }
  if (template._value.commodities) {
    // TODO(parlato): Refactor to use better typing here
    return Boolean(
      template._value.commodities?._value.some((c) => (c as any)[prefix]) ||
        template._value.commodities?._added?.some((c) => (c as any)[prefix])
    );
  }

  return false;
}
