type Address = {
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

function findComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string
) {
  return components.find((c) => c.types.includes(type));
}

function useComponentName(
  preferredName: string,
  component?: google.maps.GeocoderAddressComponent
) {
  if (!component) {
    return '';
  }
  if (preferredName === 'long_name' && component.long_name) {
    return component.long_name;
  }
  if (preferredName === 'short_name' && component.short_name) {
    return component.short_name;
  }
  return component.long_name || component.short_name;
}

function combineAddress(streetNumber: string, streetName: string) {
  if (streetNumber.length === 0) {
    return streetName;
  }
  if (streetName.length === 0) {
    return streetNumber;
  }
  return `${streetNumber} ${streetName}`;
}

export const formatAddressComponents = (
  components: google.maps.GeocoderAddressComponent[]
): Address => {
  const streetNumber = useComponentName(
    'long_name',
    findComponent(components, 'street_number')
  );
  const streetName = useComponentName(
    'long_name',
    findComponent(components, 'route')
  );
  const addressOne = combineAddress(streetNumber, streetName);
  const addressTwo = useComponentName(
    'long_name',
    findComponent(components, 'subpremise')
  );
  const city = useComponentName(
    'long_name',
    findComponent(components, 'locality')
  );
  const state = useComponentName(
    'short_name',
    findComponent(components, 'administrative_area_level_1')
  );
  const zip = useComponentName(
    'long_name',
    findComponent(components, 'postal_code')
  );
  const country = useComponentName(
    'short_name',
    findComponent(components, 'country')
  );
  return {
    addressOne,
    addressTwo,
    city,
    state,
    zip,
    country,
  };
};

export const formatCityState = (
  components: google.maps.GeocoderAddressComponent[]
): string | undefined => {
  const city = useComponentName(
    'long_name',
    findComponent(components, 'locality')
  );
  const state = useComponentName(
    'short_name',
    findComponent(components, 'administrative_area_level_1')
  );

  if (city && state) {
    return `${city}, ${state}`;
  }
};

export const formatStateCountry = (
  components: google.maps.GeocoderAddressComponent[]
): string | undefined => {
  const country = useComponentName(
    'short_name',
    findComponent(components, 'country')
  );
  const state = useComponentName(
    'long_name',
    findComponent(components, 'administrative_area_level_1')
  );

  if (country && state) {
    return `${state}, ${country}`;
  }
};

/**
 * Format the place label for use in filter chips, e.g.
 */
export const formatBestPlaceLabel = (
  place: google.maps.GeocoderResult
): string => {
  const cityState = formatCityState(place.address_components);
  if (cityState) {
    return cityState;
  }

  const stateCountry = formatStateCountry(place.address_components);
  if (stateCountry) {
    return stateCountry;
  }

  return place.formatted_address;
};
