import { Accordion } from '@shared/components';
import { Text } from '@shared/components/text';
import {
  DisplayConfigs,
  OrderingPriorities,
} from 'clerk_common/templates/types';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { getBackgroundColor, getTextProps } from './formating';
import { el } from './JSONDefinedForm';
import { JSONFormFields } from './JSONFormFields';
import { EditableJSONObject } from './types';

function orderDataKeys(
  data: EditableJSONObject,
  orderingPriorities?: OrderingPriorities
): string[] {
  if (!orderingPriorities || orderingPriorities.length === 0) {
    return Object.keys(data);
  }

  const orderedDataKeys: string[] = [];

  orderingPriorities.forEach(({ key }) => {
    if (key in data && !orderedDataKeys.includes(key)) {
      orderedDataKeys.push(key);
    }
  });

  Object.keys(data).forEach((key) => {
    if (!orderedDataKeys.includes(key)) {
      orderedDataKeys.push(key);
    }
  });

  return orderedDataKeys;
}

type FormFieldsForObjectProps = {
  data: EditableJSONObject;
  prefix: string;
  dereferenceValueField?: boolean;
  actionButtons?: React.ReactNode;
  orderingPriorities?: OrderingPriorities;
} & DisplayConfigs;
export function FormFieldsForObject({
  _display,
  data,
  prefix,
  dereferenceValueField = false,
  actionButtons,
  orderingPriorities,
}: FormFieldsForObjectProps) {
  const textProps = getTextProps();
  const bgColor = getBackgroundColor();

  const trigger = (_display?.name || actionButtons) && (
    <div className={el`trigger`}>
      {_display?.name && (
        <Text className={el`nested-object-title`} {...textProps}>
          {_display.name}
        </Text>
      )}
      {actionButtons}
    </div>
  );

  const pref = dereferenceValueField ? `${prefix}_value.` : prefix;
  const orderedKeys = orderDataKeys(data, orderingPriorities);
  const children = orderedKeys.map((k, idx) => {
    const subData = data[k];
    const subPriorities = !isNil(orderingPriorities)
      ? orderingPriorities.find((p) => p.key === k)?.subPriorities
      : undefined;
    return (
      <JSONFormFields
        key={idx}
        data={subData}
        prefix={`${pref}${k}.`}
        orderingPriorities={subPriorities}
      />
    );
  });

  if (_display?.flattened) {
    return (
      <div className={trigger ? el`flat-object` : undefined}>
        {trigger}
        {children}
      </div>
    );
  }

  return (
    <div className={clsx(el`nested-object`, bgColor)}>
      <Accordion sections={[{ trigger, children, startOpen: true }]} />
    </div>
  );
}
