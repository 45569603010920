import {
  JobSource,
  JobType,
  useCreateJobFromFrontMutation,
} from '@/generated/graphql';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { JobEmailMetadata } from '@shared/types/job';

interface CreateJobPops {
  jobType: JobType;
  emailMetadata: JobEmailMetadata;
  conversationId: string;
}

export const useCreateJobMutation = () => {
  const { me, defaultOrgId } = useMeContext();
  const [createJobFromFront, { loading, error }] =
    useCreateJobFromFrontMutation();

  const createJob = ({
    emailMetadata,
    jobType,
    conversationId,
  }: CreateJobPops) => {
    if (!defaultOrgId) throw new Error('No default org id');

    return createJobFromFront({
      variables: {
        input: {
          organizationId: defaultOrgId,
          userId: me?.id,
          emailMetadata,
          frontMessageData: { conversationId },
          jobType,
          jobSource: JobSource.FRONT,
        },
      },
      refetchQueries: ['GetJobs'],
    });
  };

  return {
    createJob,
    loading,
    error,
  };
};
