import { Popover, Text } from '@shared/components';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { uniq } from 'lodash';
import { useState } from 'react';

export const SharedInboxNotice = () => {
  const { sharedInbox } = useEmailThreadContext();
  const { isSharedInbox, owner, candidates, selectCandidate } = sharedInbox;
  const [popoverOpen, setPopoverOpen] = useState(false);

  const inboxOwnerOptions = uniq([...(owner ? [owner] : []), ...candidates]);

  const shouldAllowSelectSharedInbox = inboxOwnerOptions?.length >= 1;

  const getOptions = () => {
    return (
      <div className="flex flex-col py-2">
        <div
          onClick={() => {
            selectCandidate();
            setPopoverOpen(false);
          }}
          className="flex p-2 hover:bg-gray-100 cursor-pointer"
        >
          <Text type="body-sm">Not a shared inbox</Text>
        </div>
        {inboxOwnerOptions?.map((email, idx) => (
          <div
            key={`${email}-${idx}`}
            onClick={() => {
              selectCandidate(email);
              setPopoverOpen(false);
            }}
            className="flex p-2 hover:bg-gray-100 cursor-pointer"
          >
            <Text type="body-sm">{email}</Text>
          </div>
        ))}
      </div>
    );
  };

  return isSharedInbox ? (
    <div className="flex justify-center mt-2 px-8">
      <Text type="body-xs" className="text-gray-400 truncate text-ellipsis">
        {!shouldAllowSelectSharedInbox && (
          <>Using Vooma in shared inbox {owner}</>
        )}
        {shouldAllowSelectSharedInbox && (
          <Popover
            content={getOptions()}
            side="bottom"
            align="start"
            open={popoverOpen}
            onOpenChange={setPopoverOpen}
          >
            <div className="flex gap-1">
              Using Vooma in shared inbox
              <div
                className="cursor-pointer"
                onClick={() => setPopoverOpen(true)}
              >
                <Text type="body-xs" className="text-brand-500">
                  {owner}
                </Text>
              </div>
            </div>
          </Popover>
        )}
      </Text>
    </div>
  ) : null;
};
