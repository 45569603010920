import { svgSparkles } from '@shared/assets/icons';
import { CircularProgress, Icon, Text } from '@shared/components';
import usePlace from '@shared/graphql/hooks/places/usePlace';
import { Place } from '@shared/types/place';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  formatAddressLineOne,
  formatAddressLineTwo,
} from 'clerk_common/stringification/address';
import { LocationEntityCardOptions } from './LocationEntityCardOptions';

export const ROOT = makeRootClassName('LocationEntity');
export const el = makeElementClassNameFactory(ROOT);

type LocationEntityCardContentsProps = {
  place: Place;
  setEntityId: (id: string) => void;
  openLocation: (id: string) => void;
};
function LocationEntityCardContents({
  place,
  setEntityId,
  openLocation,
}: LocationEntityCardContentsProps) {
  return (
    <div className={el`content`}>
      <Icon
        content={svgSparkles}
        size="xs"
        className={el`sparkles-icon`}
        tooltipContent="We matched a known facility for this address"
      />
      <div className={el`place-details`}>
        <div className={el`header`}>
          <Text type="body-xs" isHeavy>
            {place.name}
          </Text>
          <LocationEntityCardOptions
            onEditAddress={() => setEntityId('')}
            onEditLocationFile={() => openLocation(place.id)}
          />
        </div>
        <Text type="body-xs">{formatAddressLineOne(place.address)}</Text>
        <Text type="body-xs">{formatAddressLineTwo(place.address)}</Text>
      </div>
    </div>
  );
}

type LocationEntityCardProps = {
  id: string;
  setEntityId: (id: string) => void;
  openLocation: (id: string) => void;
};
export function LocationEntityCard({
  id,
  setEntityId,
  openLocation,
}: LocationEntityCardProps) {
  const { place, isLoading } = usePlace({ id: id });

  if (isLoading) {
    return (
      <div className={ROOT}>
        <div className={el`card`}>
          <CircularProgress size="xs" />
        </div>
      </div>
    );
  }
  if (!place) {
    return (
      <div className={ROOT}>
        <Text type="body-xs">{`Place with id ${id} not found`}</Text>
      </div>
    );
  }
  return (
    <div className={ROOT}>
      <div className={el`card`}>
        <LocationEntityCardContents
          place={place}
          setEntityId={setEntityId}
          openLocation={openLocation}
        />
      </div>
    </div>
  );
}
