import { OrderingPriorities } from 'clerk_common/templates/types';
import { FormField } from './fields/FormField';
import { FormFieldsForObject } from './FormFieldsForObject';
import { FormFieldsFromArray } from './FormFieldsFromArray';
import { useInternalFormContext } from './internalFormContext/InternalFormContext';
import { createPrefixSpecializedBlock } from './specializedBlocks/createPrefixSpecializedBlock';
import { createBuiltinSpecializedBlock } from './specializedBlocks/createSpecializedBlock';
import {
  EditableJSONArray,
  EditableJSONField,
  EditableJSONObject,
  EditableUnit,
} from './types';

type JSONFormFieldsProps = {
  data: EditableUnit;
  prefix: string;
  orderingPriorities?: OrderingPriorities;
};
export function JSONFormFields({
  data,
  prefix,
  orderingPriorities,
}: JSONFormFieldsProps) {
  const { createCustomBlock } = useInternalFormContext();

  if (data._display?.hidden) {
    return null;
  }

  const customSpecializedBlock = createCustomBlock(data, prefix);
  if (customSpecializedBlock) return customSpecializedBlock;

  const prefixSpecializedBlock = createPrefixSpecializedBlock(data, prefix);
  if (prefixSpecializedBlock) return prefixSpecializedBlock;

  if (data._type) {
    return createBuiltinSpecializedBlock(data, prefix);
  }
  if (typeof data._value === 'string') {
    return <FormField data={data as EditableJSONField} prefix={prefix} />;
  }
  if (Array.isArray(data._value)) {
    return (
      <FormFieldsFromArray
        data={data as EditableJSONArray}
        prefix={prefix}
        orderingPriorities={orderingPriorities}
      />
    );
  }
  if (typeof data._value === 'object') {
    return (
      <FormFieldsForObject
        dereferenceValueField
        data={data._value as EditableJSONObject}
        _display={data._display}
        prefix={prefix}
        orderingPriorities={orderingPriorities}
      />
    );
  }
  if (!data._value) {
    console.warn(
      "Can't render JSON block because it's missing a '_value' field",
      data
    );
    return null;
  }
  throw new Error(`Invalid JSON block: ${data._value}`);
}
