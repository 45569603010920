import { UserRole } from '@shared/generated/graphql';
import { UserFeatures } from 'clerk_common';

export type User = {
  id: string;
  userName: string;
  name?: string;
  email?: string;
  roles: UserRole[];
  features?: UserFeatures;
};

type GetNameArgs = {
  firstName?: string | null;
  lastName?: string | null;
};
export function toName(user: GetNameArgs) {
  const els = [user.firstName, user.lastName].filter((el) => el);
  return els.join(' ');
}
