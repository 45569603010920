const NO_ORGANIZATION_ID = 'NO_ORGANIZATION_ID';

export const getNonNullableOrganizationId = (
  organizationId?: string | null
) => {
  if (organizationId) {
    return organizationId;
  }
  return NO_ORGANIZATION_ID;
};
