export const createWaveform =
  'M10.5 2.75a.75.75 0 0 0-1.5 0v14.5a.75.75 0 0 0 1.5 0V2.75zM3.75 5.5a.75.75 0 0 1 .75.75v7.5a.75.75 0 0 1-1.5 0v-7.5a.75.75 0 0 1 .75-.75zm3 1.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 6.75 7zm6-2.5a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-1.5 0v-9.5a.75.75 0 0 1 .75-.75zm3.75 3.25a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0v-4.5z';

export const createVideoOff =
  'M2.28 1.22a.75.75 0 0 0-1.06 1.06l1.112 1.113A2.749 2.749 0 0 0 1 5.75v8.5A2.75 2.75 0 0 0 3.75 17h8.5c.999 0 1.873-.533 2.355-1.33l3.115 3.116a.75.75 0 1 0 1.06-1.06L2.28 1.22zm11.188 13.313L3.47 4.531A1.25 1.25 0 0 0 2.5 5.75v8.5c0 .69.56 1.25 1.25 1.25h8.5a1.25 1.25 0 0 0 1.218-.967zM7 3.75A.75.75 0 0 1 7.75 3h4.5A2.75 2.75 0 0 1 15 5.75v1.088l2.43-1.683a1 1 0 0 1 1.57.822v8.046a1 1 0 0 1-1.57.822l-3.607-2.498a.75.75 0 0 1-.323-.616V5.75c0-.69-.56-1.25-1.25-1.25h-4.5A.75.75 0 0 1 7 3.75zm8 7.328V8.662l2.5-1.73v6.137L15 11.338v-.26z';

export const createVideo =
  'M3.75 3A2.75 2.75 0 0 0 1 5.75v8.5A2.75 2.75 0 0 0 3.75 17h8.5A2.75 2.75 0 0 0 15 14.25v-1.088l2.43 1.683a1 1 0 0 0 1.57-.822V5.977a1 1 0 0 0-1.57-.822L15 6.838V5.75A2.75 2.75 0 0 0 12.25 3h-8.5zM15 8.662v2.676l2.5 1.73V6.932L15 8.662zm-1.5-.393V5.75c0-.69-.56-1.25-1.25-1.25h-8.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25V8.27z';

export const createVariable =
  'M4.219 3.46C4.099 3.557 4 3.708 4 4v4c0 .328-.105.634-.228.884-.125.257-.292.5-.468.718a5.146 5.146 0 0 1-.36.398c.127.127.248.26.36.398.176.217.343.461.468.718.123.25.228.556.228.884v4c0 .303.32.75 1 .75a.75.75 0 0 1 0 1.5c-1.32 0-2.5-.94-2.5-2.25v-4a.614.614 0 0 0-.075-.224 2.359 2.359 0 0 0-.286-.433c-.257-.317-.566-.578-.765-.694a.75.75 0 0 1 0-1.298c.2-.116.508-.377.765-.694.125-.155.223-.303.286-.433A.614.614 0 0 0 2.5 8V4c0-.707.276-1.306.781-1.71.484-.387 1.105-.54 1.719-.54a.75.75 0 0 1 0 1.5c-.386 0-.64.097-.781.21zM15.781 3.46c.12.096.219.247.219.54v4c0 .328.105.634.228.884.125.257.292.5.468.718.112.137.233.272.36.398a5.15 5.15 0 0 0-.36.398 3.844 3.844 0 0 0-.468.718c-.123.25-.228.556-.228.884v4c0 .303-.32.75-1 .75a.75.75 0 0 0 0 1.5c1.32 0 2.5-.94 2.5-2.25v-4c0-.016.008-.088.075-.224.063-.13.16-.278.286-.433.257-.317.566-.578.765-.694a.75.75 0 0 0 0-1.298c-.2-.116-.508-.377-.765-.694a2.363 2.363 0 0 1-.286-.433A.614.614 0 0 1 17.5 8V4c0-.707-.276-1.306-.782-1.71-.483-.387-1.104-.54-1.718-.54a.75.75 0 0 0 0 1.5c.386 0 .64.097.781.21zM10.75 6.75a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5z';

export const createUnlock =
  'M6.5 6a3.5 3.5 0 0 1 5.98-2.47.75.75 0 1 0 1.062-1.06A5 5 0 0 0 5 6v2h-.25A2.75 2.75 0 0 0 2 10.75v5.5A2.75 2.75 0 0 0 4.75 19h10.5A2.75 2.75 0 0 0 18 16.25v-5.5A2.75 2.75 0 0 0 15.25 8H6.5V6zm-3 4.75c0-.69.56-1.25 1.25-1.25h10.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-5.5z';

export const createTrash =
  'M8.12492 8.95833V13.5417M11.0416 8.95833V13.5417M7.29159 6.45833V5.625C7.29159 4.70452 8.03778 3.95833 8.95825 3.95833H10.2083C11.1287 3.95833 11.8749 4.70452 11.8749 5.625V6.45833M3.95825 6.45833H15.2083M5.49255 14.5194L4.79159 6.45833H14.3749L13.674 14.5194C13.5991 15.3806 12.8781 16.0417 12.0136 16.0417H7.15295C6.28843 16.0417 5.56744 15.3806 5.49255 14.5194Z';

export const createSun =
  'M10.75 1.75a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zM10.75 16.25a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2z M5.5 10a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0zM10 7a3 3 0 1 0 0 6 3 3 0 0 0 0-6z M1 10a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2A.75.75 0 0 1 1 10zM15.5 10a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75zM4.696 3.636a.75.75 0 0 0-1.06 1.06L5.05 6.112a.75.75 0 0 0 1.06-1.06L4.697 3.635zM14.95 13.89a.75.75 0 0 0-1.061 1.06l1.414 1.414a.75.75 0 1 0 1.06-1.06l-1.413-1.415zM16.364 3.636a.75.75 0 0 1 0 1.06L14.95 6.112a.75.75 0 1 1-1.061-1.06l1.414-1.415a.75.75 0 0 1 1.06 0zM6.11 13.89a.75.75 0 0 1 0 1.06l-1.413 1.414a.75.75 0 1 1-1.061-1.06l1.414-1.415a.75.75 0 0 1 1.06 0z';

export const createStarFilled =
  'M10 1.19a.75.75 0 0 1 .713.518l1.72 5.294H18a.75.75 0 0 1 .44 1.357l-4.503 3.272 1.72 5.294a.75.75 0 0 1-1.154.839L10 14.492l-4.503 3.272a.75.75 0 0 1-1.155-.839l1.72-5.294L1.56 8.359A.75.75 0 0 1 2 7.002h5.567l1.72-5.294A.75.75 0 0 1 10 1.19z';

export const createStar =
  'M10 1.19a.75.75 0 0 1 .713.518l1.72 5.294H18a.75.75 0 0 1 .44 1.357l-4.503 3.272 1.72 5.294a.75.75 0 0 1-1.154.839L10 14.492l-4.503 3.272a.75.75 0 0 1-1.155-.839l1.72-5.294L1.56 8.359A.75.75 0 0 1 2 7.002h5.567l1.72-5.294A.75.75 0 0 1 10 1.19zm0 3.177L8.825 7.984a.75.75 0 0 1-.714.518H4.308l3.077 2.236a.75.75 0 0 1 .273.838l-1.176 3.617 3.077-2.235a.75.75 0 0 1 .882 0l3.077 2.235-1.176-3.617a.75.75 0 0 1 .273-.838l3.077-2.236h-3.803a.75.75 0 0 1-.714-.518L10 4.367z';

export const createStages =
  'M5.78 2.47a.75.75 0 0 1 0 1.06L3.53 5.78a.75.75 0 0 1-1.06 0L1.22 4.53a.75.75 0 0 1 1.06-1.06l.72.72 1.72-1.72a.75.75 0 0 1 1.06 0zM17.25 4a.75.75 0 0 1 .102 1.493l-.102.007h-9.5a.75.75 0 0 1-.102-1.493L7.75 4h9.5zm.75 6a.75.75 0 0 0-.75-.75h-9.5l-.102.007a.75.75 0 0 0 .102 1.493h9.5l.102-.007A.75.75 0 0 0 18 10zm0 5.25a.75.75 0 0 0-.75-.75h-9.5l-.102.007A.75.75 0 0 0 7.75 16h9.5l.102-.007A.75.75 0 0 0 18 15.25zm-15.2-1.9a.5.5 0 0 0-.8.4v3a.5.5 0 0 0 .8.4l2-1.5a.5.5 0 0 0 0-.8l-2-1.5zm2.98-4.32a.75.75 0 0 0-1.06-1.06L3 9.69l-.72-.72a.75.75 0 0 0-1.06 1.06l1.25 1.25a.75.75 0 0 0 1.06 0l2.25-2.25z';

export const createStageReject =
  'M15.068 5.673a.773.773 0 0 0-.517-.186h-.996V10.8h.995a.773.773 0 0 0 .776-.636V6.123a.773.773 0 0 0-.258-.45zm-2.694 5.592-2.13 4.792a1.181 1.181 0 0 1-.822-1.125v-2.361a.59.59 0 0 0-.59-.59H5.484a.589.589 0 0 1-.59-.679l.814-5.313a.59.59 0 0 1 .59-.502h6.076v5.778zm2.162-6.959A1.954 1.954 0 0 1 16.5 5.998a.582.582 0 0 1 .005.08v4.132a.58.58 0 0 1-.005.079 1.954 1.954 0 0 1-1.965 1.692h-1.188l-2.206 4.962a.59.59 0 0 1-.54.35 2.361 2.361 0 0 1-2.36-2.36V13.16H5.494a1.772 1.772 0 0 1-1.768-2.036l.815-5.313a1.772 1.772 0 0 1 1.767-1.506h8.228z';

export const createStageHire =
  'M8.858 3.057a.59.59 0 0 1 .54-.35 2.361 2.361 0 0 1 2.36 2.36V6.84h2.748a1.77 1.77 0 0 1 1.768 2.036l-.815 5.313a1.772 1.772 0 0 1-1.767 1.506H5.265a1.771 1.771 0 0 1-1.771-1.771V9.79a1.771 1.771 0 0 1 1.77-1.77h1.388l2.206-4.963zM7.626 8.735l2.13-4.792a1.18 1.18 0 0 1 .822 1.125v2.361c0 .326.264.59.59.59H14.516a.59.59 0 0 1 .59.679l-.814 5.313a.59.59 0 0 1-.59.502H7.625V8.735zm-1.18 5.778V9.2H5.264a.59.59 0 0 0-.59.59v4.133a.59.59 0 0 0 .59.59h1.18z';

export const createStageGreenhouse =
  'M12.241 7.565c0 .58-.256 1.092-.676 1.493-.468.446-1.145.557-1.145.936 0 .513.864.356 1.693 1.148.549.524.887 1.215.887 2.017C13 14.74 11.67 16 10 16s-3-1.258-3-2.84c0-.803.339-1.494.887-2.017.829-.792 1.693-.635 1.693-1.148 0-.379-.677-.49-1.144-.936-.42-.401-.677-.914-.677-1.516 0-1.158.992-2.094 2.206-2.094.234 0 .444.033.619.033.315 0 .479-.133.479-.345 0-.123-.06-.279-.06-.446 0-.379.34-.691.748-.691.409 0 .736.323.736.713 0 .412-.339.602-.596.691-.21.067-.373.156-.373.357 0 .377.723.745.723 1.804zm-.232 5.595A1.971 1.971 0 0 0 10 11.165a1.971 1.971 0 0 0-2.008 1.995c0 1.092.852 1.995 2.008 1.995a1.979 1.979 0 0 0 2.008-1.995zm-.69-5.618c0-.701-.595-1.281-1.319-1.281-.723 0-1.319.58-1.319 1.281 0 .702.596 1.282 1.319 1.282.724 0 1.32-.58 1.32-1.282z';

export const createStage =
  'M2.25 2A.75.75 0 0 1 3 1.25h14a.75.75 0 0 1 .75.75v16a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75V2zm1.5.75v14.5h12.5V2.75H3.75z M13 8.75H7v-1.5h6v1.5zm-6 2.5h6v1.5H7v-1.5z';

export const createShare =
  'M4.47 8.28a.75.75 0 0 0 1.06 0l3.72-3.72v7.69a.75.75 0 0 0 1.5 0V4.56l3.72 3.72a.75.75 0 1 0 1.06-1.06l-5-5a.75.75 0 0 0-1.06 0l-5 5a.75.75 0 0 0 0 1.06z M2.75 12a.75.75 0 0 1 .75.75v2.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-2.5a.75.75 0 0 1 1.5 0v2.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-2.5a.75.75 0 0 1 .75-.75z';

export const createSend =
  'M18.78 1.22a.75.75 0 0 1 .178.777l-5.75 16.5a.75.75 0 0 1-1.426-.03L9.4 10.602 1.533 8.218a.75.75 0 0 1-.03-1.426l16.5-5.75a.75.75 0 0 1 .777.178zm-7.932 8.993 1.702 5.615 3.914-11.23-5.616 5.615zm4.555-6.677L4.172 7.45l5.615 1.702 5.616-5.616z';

export const createSearch =
  'M14.5 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-1.254 5.807a7.5 7.5 0 1 1 1.06-1.06l3.474 3.473a.75.75 0 1 1-1.06 1.06l-3.473-3.473z';

export const createRotateCw =
  'M3.895 5.645a7.5 7.5 0 0 1 12.74.855H13.75a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-1.5 0v2.272a9 9 0 1 0 .884 8.254.75.75 0 0 0-1.397-.547A7.5 7.5 0 1 1 3.895 5.645z';

export const createRotateCcw =
  'M14.355 3.895A7.5 7.5 0 0 0 3.012 7.273l-1.397-.546a9 9 0 1 1 0 6.542.75.75 0 1 1 1.397-.544 7.5 7.5 0 1 0 11.343-8.83z M1.75 2a.75.75 0 0 1 .75.75V6.5h3.75a.75.75 0 1 1 0 1.5h-4.5A.75.75 0 0 1 1 7.25v-4.5A.75.75 0 0 1 1.75 2z';

export const createRingWarning =
  'M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-1.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0-5.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-6a.75.75 0 0 0-.75.75v3.5a.75.75 0 0 0 1.5 0v-3.5A.75.75 0 0 0 10 6z';

export const createWarning =
  'M8.924 4.045a1.25 1.25 0 0 1 2.152 0l6.245 10.57a1.25 1.25 0 0 1-1.076 1.885H3.755a1.25 1.25 0 0 1-1.076-1.886L8.924 4.045zm3.444-.763c-1.065-1.801-3.671-1.801-4.736 0L1.387 13.851C.304 15.684 1.626 18 3.755 18h12.49c2.13 0 3.45-2.316 2.368-4.149L12.368 3.282zM9.25 7.75a.75.75 0 1 1 1.5 0v3.5a.75.75 0 0 1-1.5 0v-3.5zM9 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0z';

export const createRingStop =
  'M7.75 6A1.75 1.75 0 0 0 6 7.75v4.5c0 .966.784 1.75 1.75 1.75h4.5A1.75 1.75 0 0 0 14 12.25v-4.5A1.75 1.75 0 0 0 12.25 6h-4.5zM7.5 7.75a.25.25 0 0 1 .25-.25h4.5a.25.25 0 0 1 .25.25v4.5a.25.25 0 0 1-.25.25h-4.5a.25.25 0 0 1-.25-.25v-4.5z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createRingPlus =
  'M10 6a.75.75 0 0 1 .75.75v2.5h2.5a.75.75 0 0 1 0 1.5h-2.5v2.5a.75.75 0 0 1-1.5 0v-2.5h-2.5a.75.75 0 0 1 0-1.5h2.5v-2.5A.75.75 0 0 1 10 6z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createRingPlay =
  'M8.128 5.852A.75.75 0 0 0 7 6.5v7a.75.75 0 0 0 1.128.648l6-3.5a.75.75 0 0 0 0-1.296l-6-3.5zM12.26 10 8.5 12.194V7.806L12.261 10z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createRingPerson =
  'M15.014 15.578A7.472 7.472 0 0 1 10 17.5a7.472 7.472 0 0 1-5.014-1.922c.19-.156.445-.306.779-.446.964-.404 2.413-.632 4.235-.632 1.822 0 3.27.228 4.235.632.334.14.589.29.779.446zm1.009-1.108a4.584 4.584 0 0 0-1.208-.722C13.587 13.234 11.91 13 10 13c-1.91 0-3.587.234-4.815.748-.45.189-.864.428-1.208.722a7.5 7.5 0 1 1 12.046 0zM19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-9-6a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM7.5 8a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z';

export const createRingPause =
  'M12.25 6a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5a.75.75 0 0 1 .75-.75zM8.5 6.75a.75.75 0 0 0-1.5 0v6.5a.75.75 0 0 0 1.5 0v-6.5z M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-1.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z';

export const createRingMinus =
  'M13.25 10.75a.75.75 0 0 0 0-1.5h-6.5a.75.75 0 0 0 0 1.5h6.5z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createRingInfo =
  'M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-1.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zM10 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a.75.75 0 0 0-.75.75v3.5a.75.75 0 0 0 1.5 0v-3.5A.75.75 0 0 0 10 9z';

export const createRingFilled =
  'M17.5 10a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zm1.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-9 5a5 5 0 1 0 0-10 5 5 0 0 0 0 10z';

export const createRingCross =
  'M12.828 7.172a.75.75 0 0 1 0 1.06L11.061 10l1.767 1.768a.75.75 0 1 1-1.06 1.06L10 11.061l-1.768 1.767a.75.75 0 0 1-1.06-1.06L8.939 10 7.172 8.232a.75.75 0 0 1 1.06-1.06L10 8.939l1.768-1.767a.75.75 0 0 1 1.06 0z M16.364 16.364A9 9 0 1 1 3.636 3.636a9 9 0 0 1 12.728 12.728zm-1.06-1.06A7.5 7.5 0 1 1 4.696 4.696a7.5 7.5 0 0 1 10.606 10.606z';

export const createRingCheck =
  'M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-1.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm4.103-9.804a.75.75 0 1 0-1.206-.892l-3.732 5.05L7.03 9.72a.75.75 0 0 0-1.06 1.06l2.75 2.75a.75.75 0 0 0 1.133-.084l4.25-5.75z';

export const createRingArrowUp =
  'M7.53 10.28a.75.75 0 1 1-1.06-1.06l3-3a.75.75 0 0 1 1.06 0l3 3a.75.75 0 1 1-1.06 1.06l-1.72-1.72v4.69a.75.75 0 0 1-1.5 0V8.56l-1.72 1.72z M1 10a9 9 0 1 1 18 0 9 9 0 0 1-18 0zm1.5 0a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0z';

export const createRingArrowRight =
  'M9.72 7.53a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H6.75a.75.75 0 0 1 0-1.5h4.69L9.72 7.53z M10 1a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1.5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15z';

export const createRingArrowLeft =
  'M10.28 12.47a.75.75 0 1 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 1 1 1.06 1.06L8.56 9.25h4.69a.75.75 0 0 1 0 1.5H8.56l1.72 1.72z M10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18zm0-1.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15z';

export const createRingArrowDown =
  'M12.47 9.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72V6.75a.75.75 0 0 1 1.5 0v4.69l1.72-1.72z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createRing =
  'M10 17.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0 1.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18z';

export const createRefreshCw =
  'M14.355 3.895A7.5 7.5 0 0 0 3.012 7.273a.75.75 0 0 1-1.397-.546A9 9 0 0 1 17.5 5.023V2.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1 0-1.5h2.884a7.465 7.465 0 0 0-2.28-2.605zm3.605 8.407a.75.75 0 0 1 .425.971A9 9 0 0 1 2.5 14.978v2.272a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5H3.365a7.5 7.5 0 0 0 13.623-.773.75.75 0 0 1 .972-.425z';

export const createRefreshCcw =
  'M5.645 3.895a7.5 7.5 0 0 1 11.343 3.378.75.75 0 0 0 1.397-.546A9 9 0 0 0 2.5 5.023V2.75a.75.75 0 0 0-1.5 0v4.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5H3.366a7.465 7.465 0 0 1 2.28-2.605zM2.04 12.302a.75.75 0 0 0-.425.971A9 9 0 0 0 17.5 14.978v2.272a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0 0 1.5h2.885a7.5 7.5 0 0 1-13.623-.773.75.75 0 0 0-.972-.425z';

export const createPuzzle =
  'M15.714 9.643h-1.071V6.786c0-.786-.643-1.429-1.429-1.429h-2.857V4.286a1.786 1.786 0 0 0-3.571 0v1.071H3.929c-.786 0-1.422.643-1.422 1.429V9.5h1.064a1.93 1.93 0 0 1 0 3.857H2.5v2.714c0 .786.643 1.429 1.429 1.429h2.714v-1.071a1.93 1.93 0 0 1 3.857 0V17.5h2.714c.786 0 1.429-.643 1.429-1.429v-2.857h1.071a1.786 1.786 0 0 0 0-3.571z';

export const createPreview =
  'M17 2.5H3a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3zm1 17.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1-.75-.75zM10 10c.83 0 1.5-.67 1.5-1.5S10.83 7 10 7s-1.5.67-1.5 1.5S9.17 10 10 10zM4 8.5c.94-2.34 3.27-4 6-4s5.06 1.66 6 4c-.94 2.34-3.27 4-6 4s-5.06-1.66-6-4zm3.5 0a2.5 2.5 0 0 0 5 0 2.5 2.5 0 0 0-5 0z';

export const createPresent =
  'M17 2.5H3a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3zm1 17.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75a.75.75 0 0 1-.75-.75zm9.376-9.334a.5.5 0 0 0 0-.832L8.777 5.018A.5.5 0 0 0 8 5.434v6.132a.5.5 0 0 0 .777.416l4.599-3.066z';

export const createPlus =
  'M10 3a.75.75 0 0 1 .75.75v5.5h5.5a.75.75 0 0 1 0 1.5h-5.5v5.5a.75.75 0 0 1-1.5 0v-5.5h-5.5a.75.75 0 0 1 0-1.5h5.5v-5.5A.75.75 0 0 1 10 3z';

export const createPlay =
  'M5.135 2.621a.25.25 0 0 0-.385.21v14.337a.25.25 0 0 0 .385.21l11.15-7.168a.25.25 0 0 0 0-.42L5.136 2.62zm-1.885.21c0-1.384 1.532-2.22 2.696-1.471l11.151 7.168a1.75 1.75 0 0 1 0 2.944L5.947 18.64c-1.165.75-2.697-.087-2.697-1.472V2.832z';

export const createPlaceholder =
  'M1 4.75A3.75 3.75 0 0 1 4.75 1h3.5a.75.75 0 0 1 0 1.5h-3.5A2.25 2.25 0 0 0 2.5 4.75v3.5a.75.75 0 0 1-1.5 0v-3.5zM11.75 17.5a.75.75 0 0 0 0 1.5h3.5A3.75 3.75 0 0 0 19 15.25v-3.5a.75.75 0 0 0-1.5 0v3.5a2.25 2.25 0 0 1-2.25 2.25h-3.5zM2.5 11.75a.75.75 0 0 0-1.5 0v3.5A3.75 3.75 0 0 0 4.75 19h3.5a.75.75 0 0 0 0-1.5h-3.5a2.25 2.25 0 0 1-2.25-2.25v-3.5zM17.5 8.25a.75.75 0 0 0 1.5 0v-3.5A3.75 3.75 0 0 0 15.25 1h-3.5a.75.75 0 0 0 0 1.5h3.5a2.25 2.25 0 0 1 2.25 2.25v3.5z';

export const createPhone =
  'M16.56 19h-.21a17.26 17.26 0 0 1-7.49-2.66 16.91 16.91 0 0 1-5.19-5.19A17.13 17.13 0 0 1 1 3.63a2.37 2.37 0 0 1 .12-1 2.26 2.26 0 0 1 .51-.85 2.29 2.29 0 0 1 .8-.57A2.35 2.35 0 0 1 3.4 1h2.49a2.35 2.35 0 0 1 1.58.58c.445.381.736.91.82 1.49a9.58 9.58 0 0 0 .54 2.17 2.4 2.4 0 0 1-.54 2.53l-.65.65a12.55 12.55 0 0 0 3.91 3.9l.64-.63a2.39 2.39 0 0 1 2.54-.55c.697.26 1.422.442 2.16.54A2.41 2.41 0 0 1 19 14.11v2.48a2.51 2.51 0 0 1-.2 1 2.461 2.461 0 0 1-.58.81 2.4 2.4 0 0 1-.86.5 2.27 2.27 0 0 1-.8.1zM5.9 2.5H3.4a.94.94 0 0 0-.4.08.81.81 0 0 0-.3.21 1 1 0 0 0-.24.7 15.77 15.77 0 0 0 2.47 6.84 15.53 15.53 0 0 0 4.74 4.74 15.69 15.69 0 0 0 6.82 2.43.922.922 0 0 0 .68-.24.93.93 0 0 0 .22-.3 1 1 0 0 0 .08-.37V14.1a.9.9 0 0 0-.22-.61.88.88 0 0 0-.56-.31 12.598 12.598 0 0 1-2.49-.62.79.79 0 0 0-.5 0 .86.86 0 0 0-.45.24l-1.05 1a.74.74 0 0 1-.9.12 14 14 0 0 1-5.25-5.25.74.74 0 0 1 .12-.9l1-1a.94.94 0 0 0 .24-.45.89.89 0 0 0 0-.51 11.21 11.21 0 0 1-.61-2.54.91.91 0 0 0-.3-.55.94.94 0 0 0-.6-.22z';

export const createPerson =
  'M10 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM7.5 6a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm2.5 5c-1.91 0-3.587.234-4.815.748C3.957 12.263 3 13.152 3 14.5c0 1.348.957 2.237 2.185 2.752C6.413 17.766 8.09 18 10 18c1.91 0 3.587-.234 4.815-.748C16.043 16.737 17 15.848 17 14.5c0-1.348-.957-2.237-2.185-2.752C13.587 11.234 11.91 11 10 11zm-5.5 3.5c0-.479.301-.965 1.265-1.368.964-.404 2.413-.632 4.235-.632 1.822 0 3.27.228 4.235.632.964.403 1.265.889 1.265 1.368 0 .479-.301.965-1.265 1.368-.964.404-2.413.632-4.235.632-1.822 0-3.27-.228-4.235-.632C4.801 15.465 4.5 14.98 4.5 14.5z';

export const createPeople =
  'M4 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0zm4-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM8 11c-1.91 0-3.587.234-4.815.748C1.957 12.263 1 13.152 1 14.5c0 1.348.957 2.237 2.185 2.752C4.413 17.766 6.09 18 8 18c1.91 0 3.587-.234 4.815-.748C14.043 16.737 15 15.848 15 14.5c0-1.348-.957-2.237-2.185-2.752C11.587 11.234 9.91 11 8 11zm-5.5 3.5c0-.479.301-.965 1.265-1.368C4.73 12.728 6.178 12.5 8 12.5c1.822 0 3.27.228 4.235.632.964.403 1.265.889 1.265 1.368 0 .479-.301.965-1.265 1.368-.964.404-2.413.632-4.235.632-1.822 0-3.27-.228-4.235-.632C2.801 15.465 2.5 14.98 2.5 14.5zm13.727-2.966a.75.75 0 0 0-.454 1.43c1.324.419 1.727.994 1.727 1.536s-.403 1.117-1.727 1.537a.75.75 0 0 0 .454 1.43C17.737 16.986 19 16.06 19 14.5s-1.262-2.488-2.773-2.966zm-2.997-9.34a.75.75 0 1 0-.46 1.426 2.501 2.501 0 0 1 0 4.76.75.75 0 1 0 .46 1.427 4.001 4.001 0 0 0 0-7.614z';

export const createPaperclip =
  'M15.078 3.24a2.25 2.25 0 0 0-3.182 0l-7.778 7.778A3.75 3.75 0 1 0 9.42 16.32L17.2 8.543a.75.75 0 1 1 1.061 1.06l-7.778 7.779a5.25 5.25 0 0 1-7.425-7.425l7.778-7.778a3.75 3.75 0 1 1 5.304 5.303L8.36 15.261a2.25 2.25 0 0 1-3.182-3.182l6.364-6.364a.75.75 0 0 1 1.06 1.06L6.24 13.14A.75.75 0 0 0 7.3 14.2l7.779-7.777a2.25 2.25 0 0 0 0-3.182z';

export const createMoon =
  'M8.432 1.564a.75.75 0 0 1 .08.817 6.75 6.75 0 0 0 9.107 9.107.75.75 0 0 1 1.072.86A9 9 0 1 1 7.652 1.31a.75.75 0 0 1 .78.254zM6.534 3.347a7.5 7.5 0 1 0 10.119 10.119A8.25 8.25 0 0 1 6.534 3.347z';

export const createMinus =
  'M4 10c0-.414.288-.75.643-.75h10.714c.355 0 .643.336.643.75s-.288.75-.643.75H4.643C4.288 10.75 4 10.414 4 10z';

export const createMenu =
  'M2 4.75c0-.414.384-.75.857-.75h14.286c.473 0 .857.336.857.75s-.384.75-.857.75H2.857C2.384 5.5 2 5.164 2 4.75zm0 10.5c0-.414.384-.75.857-.75h14.286c.473 0 .857.336.857.75s-.384.75-.857.75H2.857C2.384 16 2 15.664 2 15.25zm.857-6C2.384 9.25 2 9.586 2 10s.384.75.857.75h14.286c.473 0 .857-.336.857-.75s-.384-.75-.857-.75H2.857z';

export const createMapPin =
  'M5.941 4.229A5.664 5.664 0 0 1 10 2.5c1.518 0 2.979.62 4.059 1.729a6.018 6.018 0 0 1 1.691 4.197c0 2.291-1.47 4.62-3.235 6.532A23.39 23.39 0 0 1 10 17.298a23.57 23.57 0 0 1-2.515-2.34C5.72 13.045 4.25 10.718 4.25 8.426c0-1.578.61-3.088 1.691-4.197zM10 1c-1.93 0-3.776.787-5.134 2.182A7.519 7.519 0 0 0 2.75 8.426c0 2.88 1.805 5.568 3.632 7.55a24.894 24.894 0 0 0 3.165 2.871.75.75 0 0 0 .906 0 24.903 24.903 0 0 0 3.164-2.872c1.828-1.98 3.633-4.67 3.633-7.55 0-1.962-.76-3.849-2.116-5.243A7.164 7.164 0 0 0 10 1zm1.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM13 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z';

export const createMail =
  'M3.75 3A2.75 2.75 0 0 0 1 5.75v8.5A2.75 2.75 0 0 0 3.75 17h12.5A2.75 2.75 0 0 0 19 14.25v-8.5A2.75 2.75 0 0 0 16.25 3H3.75zm-.079 1.502L3.75 4.5h12.5c.026 0 .052 0 .078.002L10 10.238 3.67 4.502zm-1.144.988c-.018.084-.027.17-.027.26v8.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25v-8.5c0-.09-.01-.176-.027-.26l-6.97 6.316a.75.75 0 0 1-1.007 0L2.527 5.49z';

export const createLock =
  'M6.5 6a3.5 3.5 0 1 1 7 0v2h-7V6zM5 8V6a5 5 0 0 1 10 0v2h.25A2.75 2.75 0 0 1 18 10.75v5.5A2.75 2.75 0 0 1 15.25 19H4.75A2.75 2.75 0 0 1 2 16.25v-5.5A2.75 2.75 0 0 1 4.75 8H5zm-1.5 2.75c0-.69.56-1.25 1.25-1.25h10.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-5.5z';

export const createListUnordered =
  'M3 5.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM17.25 4a.75.75 0 0 1 .102 1.493l-.102.007H6.75a.75.75 0 0 1-.102-1.493L6.75 4h10.5zm.75 6a.75.75 0 0 0-.75-.75H6.75l-.102.007a.75.75 0 0 0 .102 1.493h10.5l.102-.007A.75.75 0 0 0 18 10zm0 5.25a.75.75 0 0 0-.75-.75H6.75l-.102.007A.75.75 0 0 0 6.75 16h10.5l.102-.007A.75.75 0 0 0 18 15.25zM4 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 6.25a1 1 0 1 0 0-2 1 1 0 0 0 0 2z';

export const createListOrdered =
  'M3.115 2.65h.91v3.636h-.988V3.56h-.021l-.796.476v-.838l.895-.547zM17.25 4a.75.75 0 0 1 .102 1.493l-.102.007H6.75a.75.75 0 0 1-.102-1.493L6.75 4h10.5zm.75 6a.75.75 0 0 0-.75-.75H6.75l-.102.007a.75.75 0 0 0 .102 1.493h10.5l.102-.007A.75.75 0 0 0 18 10zm0 5.25a.75.75 0 0 0-.75-.75H6.75l-.102.007A.75.75 0 0 0 6.75 16h10.5l.102-.007A.75.75 0 0 0 18 15.25zM2.071 11.686h2.791v-.788h-1.42v-.022l.34-.284c.83-.69 1.041-1.049 1.041-1.47C4.823 8.444 4.267 8 3.396 8 2.549 8 1.998 8.47 2 9.236h.938c-.002-.306.184-.476.458-.476.271 0 .465.169.465.447 0 .258-.165.425-.433.647L2.07 10.976v.71zm2.92 4.456c.003.641-.624 1.094-1.514 1.094-.86 0-1.473-.48-1.477-1.158h.994c.004.211.206.362.49.362.274 0 .466-.158.462-.383.004-.22-.22-.377-.54-.377h-.37V15h.37c.295 0 .501-.157.497-.377.004-.211-.17-.362-.419-.362-.271 0-.465.156-.468.376H2.07c.004-.665.59-1.136 1.413-1.136.806 0 1.375.446 1.371 1.058.004.407-.312.686-.746.732v.028c.586.064.885.378.881.824z';

export const createList =
  'M3 4.75A.75.75 0 0 1 3.75 4h12.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 4.75zm0 10.5a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75zm.75-6a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75z';

export const createLinkExternal =
  'M2 4.75A2.75 2.75 0 0 1 4.75 2h2.5a.75.75 0 0 1 0 1.5h-2.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-2.5a.75.75 0 0 1 1.5 0v2.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25V4.75zm8.75-1.25a.75.75 0 0 1 0-1.5h6.5a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0V4.56l-5.22 5.22a.75.75 0 1 1-1.06-1.06l5.22-5.22h-4.69z';

export const createLink =
  'M17.071 2.929a5 5 0 0 0-7.071 0l-.884.884a.75.75 0 1 0 1.06 1.06l.884-.883a3.5 3.5 0 0 1 4.95 4.95l-2.121 2.12a3.5 3.5 0 0 1-5.577-.85.75.75 0 0 0-1.328.696 5 5 0 0 0 7.966 1.216L17.07 10a5 5 0 0 0 0-7.071z M2.929 17.071a5 5 0 0 0 7.07 0l.885-.884a.75.75 0 0 0-1.06-1.06l-.885.883a3.5 3.5 0 1 1-4.95-4.95l2.122-2.12a3.5 3.5 0 0 1 5.577.85.75.75 0 0 0 1.328-.696A5 5 0 0 0 5.05 7.878L2.93 10a5 5 0 0 0 0 7.071z';

export const createLayout =
  'M6.75 18V7.5h1.5V18h-1.5z M1.75 3.5c0-.966.784-1.75 1.75-1.75h13c.966 0 1.75.784 1.75 1.75v13a1.75 1.75 0 0 1-1.75 1.75h-13a1.75 1.75 0 0 1-1.75-1.75v-13zm1.75-.25a.25.25 0 0 0-.25.25v3.25h13.5V3.5a.25.25 0 0 0-.25-.25h-13zm13.25 5H3.25v8.25c0 .138.112.25.25.25h13a.25.25 0 0 0 .25-.25V8.25z';

export const createLayers2 =
  'M5.43 9.393 1.36 11.86a.75.75 0 0 0 0 1.282l8.25 5a.75.75 0 0 0 .778 0l8.25-5a.75.75 0 0 0 0-1.282L14.57 9.393l-1.425.89 3.657 2.217L10 16.623 3.197 12.5l3.657-2.216-1.425-.89z M9.611 1.859a.75.75 0 0 1 .778 0l8.25 5a.75.75 0 0 1 0 1.282l-8.25 5a.75.75 0 0 1-.778 0l-8.25-5a.75.75 0 0 1 0-1.282l8.25-5zM3.197 7.5 10 11.623 16.803 7.5 10 3.377 3.197 7.5z';

export const createLayers =
  'M1.109 12.861a.75.75 0 0 1 1.03-.252L10 17.373l7.861-4.764a.75.75 0 1 1 .778 1.282l-8.25 5a.75.75 0 0 1-.778 0l-8.25-5a.75.75 0 0 1-.252-1.03z M1.109 9.611a.75.75 0 0 1 1.03-.252L10 14.123l7.861-4.764a.75.75 0 1 1 .778 1.282l-8.25 5a.75.75 0 0 1-.778 0l-8.25-5a.75.75 0 0 1-.252-1.03z M9.611 1.109a.75.75 0 0 1 .778 0l8.25 5a.75.75 0 0 1 0 1.282l-8.25 5a.75.75 0 0 1-.778 0l-8.25-5a.75.75 0 0 1 0-1.282l8.25-5zM3.197 6.75 10 10.873l6.803-4.123L10 2.627 3.197 6.75z';

export const createImport =
  'M14.47 7.72a.75.75 0 1 1 1.06 1.06l-5 5a.75.75 0 0 1-1.06 0l-5-5a.75.75 0 0 1 1.06-1.06l3.72 3.72V2.75a.75.75 0 0 1 1.5 0v8.69l3.72-3.72zM3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z';

export const createGrid =
  'M4.75 2A2.75 2.75 0 0 0 2 4.75v1.5A2.75 2.75 0 0 0 4.75 9h1.5A2.75 2.75 0 0 0 9 6.25v-1.5A2.75 2.75 0 0 0 6.25 2h-1.5zM3.5 4.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5zM4.75 11A2.75 2.75 0 0 0 2 13.75v1.5A2.75 2.75 0 0 0 4.75 18h1.5A2.75 2.75 0 0 0 9 15.25v-1.5A2.75 2.75 0 0 0 6.25 11h-1.5zM3.5 13.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5zm7.5-9A2.75 2.75 0 0 1 13.75 2h1.5A2.75 2.75 0 0 1 18 4.75v1.5A2.75 2.75 0 0 1 15.25 9h-1.5A2.75 2.75 0 0 1 11 6.25v-1.5zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v1.5c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25v-1.5c0-.69-.56-1.25-1.25-1.25h-1.5zm0 7.5A2.75 2.75 0 0 0 11 13.75v1.5A2.75 2.75 0 0 0 13.75 18h1.5A2.75 2.75 0 0 0 18 15.25v-1.5A2.75 2.75 0 0 0 15.25 11h-1.5zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5z';

export const createGlobe =
  'M10.599 17.113c-.28.326-.486.387-.599.387-.113 0-.318-.06-.599-.387-.279-.324-.563-.837-.817-1.536-.449-1.232-.757-2.92-.821-4.827h4.474c-.064 1.908-.373 3.595-.82 4.827-.255.7-.54 1.212-.818 1.536zm1.638-7.863H7.763c.064-1.908.372-3.595.82-4.827.255-.7.54-1.212.818-1.536.28-.326.486-.387.599-.387.113 0 .318.06.599.387.279.324.563.837.817 1.536.448 1.232.757 2.92.821 4.827zm1.501 1.5c-.065 2.05-.394 3.917-.912 5.34-.133.365-.28.71-.445 1.024a7.507 7.507 0 0 0 5.082-6.364h-3.725zm3.725-1.5h-3.725c-.065-2.05-.394-3.917-.912-5.34a8.581 8.581 0 0 0-.445-1.024 7.507 7.507 0 0 1 5.082 6.364zm-11.201 0c.065-2.05.394-3.917.912-5.34.133-.365.28-.71.445-1.024A7.507 7.507 0 0 0 2.537 9.25h3.725zm-3.725 1.5a7.507 7.507 0 0 0 5.082 6.364 8.556 8.556 0 0 1-.445-1.024c-.518-1.423-.847-3.29-.912-5.34H2.537zM10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18z';

export const createGift =
  'M5.022 4.932C4.642 3.409 5.814 2 7.5 2c1.241 0 2.03.746 2.5 1.578C10.47 2.746 11.259 2 12.5 2c1.685 0 2.858 1.409 2.477 2.932-.097.39-.284.75-.558 1.068h2.831a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75h-.75v6.25a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75V11h-.75a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 2.75 6h2.83a2.598 2.598 0 0 1-.558-1.068zM9.25 7.5H3.5v2h5.75v-2zm1.5 2v-2h5.75v2h-5.75zM9.195 5.967a5.208 5.208 0 0 0-.379-1.41C8.501 3.858 8.08 3.5 7.5 3.5c-.814 0-1.142.591-1.022 1.068.108.433.727 1.23 2.717 1.4zM10.75 11H15v5.5h-4.25V11zm-1.5 0v5.5H5V11h4.25zm1.934-6.442a5.204 5.204 0 0 0-.379 1.41c1.99-.17 2.61-.967 2.717-1.4.12-.477-.208-1.068-1.022-1.068-.58 0-1 .357-1.316 1.058z';

export const createGear =
  'M7.149 2.54A1.75 1.75 0 0 1 8.886 1h2.228c.885 0 1.63.66 1.737 1.54l.102.837c.463.206.9.46 1.305.755l.777-.331a1.75 1.75 0 0 1 2.202.734l1.114 1.93a1.75 1.75 0 0 1-.464 2.274l-.676.507a7.311 7.311 0 0 1 0 1.508l.676.507a1.75 1.75 0 0 1 .464 2.274l-1.114 1.93a1.75 1.75 0 0 1-2.202.735l-.777-.332a7.25 7.25 0 0 1-1.305.755l-.102.838-.745-.09.745.09A1.75 1.75 0 0 1 11.114 19H8.886a1.75 1.75 0 0 1-1.737-1.54l-.102-.837c-.463-.207-.9-.46-1.305-.755l-.778.332a1.75 1.75 0 0 1-2.201-.735l-1.114-1.93a1.75 1.75 0 0 1 .464-2.274l.676-.507a7.325 7.325 0 0 1 0-1.508l-.676-.507a1.75 1.75 0 0 1-.464-2.274l1.114-1.93a1.75 1.75 0 0 1 2.201-.734l.778.33a7.243 7.243 0 0 1 1.305-.754l.102-.838zm1.737-.04a.25.25 0 0 0-.248.22l-.155 1.274A.75.75 0 0 1 8 4.607a5.743 5.743 0 0 0-1.668.965.75.75 0 0 1-.773.112L4.376 5.18a.25.25 0 0 0-.314.105l-1.114 1.93a.25.25 0 0 0 .066.325l1.027.771a.75.75 0 0 1 .29.724 5.794 5.794 0 0 0 0 1.93.75.75 0 0 1-.29.724l-1.027.771a.25.25 0 0 0-.066.325l1.114 1.93a.25.25 0 0 0 .314.105l1.183-.505a.75.75 0 0 1 .773.113c.493.41 1.056.738 1.668.965a.75.75 0 0 1 .483.613l.155 1.274a.25.25 0 0 0 .248.22h2.228a.25.25 0 0 0 .248-.22l.154-1.274a.75.75 0 0 1 .484-.613 5.742 5.742 0 0 0 1.668-.965.75.75 0 0 1 .773-.113l1.183.505a.25.25 0 0 0 .314-.105l1.114-1.93a.25.25 0 0 0-.066-.325l-1.027-.771a.75.75 0 0 1-.29-.725 5.797 5.797 0 0 0 0-1.929.75.75 0 0 1 .29-.724l1.027-.771a.25.25 0 0 0 .066-.325l-1.114-1.93a.25.25 0 0 0-.315-.105l-1.182.504a.75.75 0 0 1-.773-.112A5.744 5.744 0 0 0 12 4.607a.75.75 0 0 1-.484-.613l-.154-1.274a.25.25 0 0 0-.248-.22H8.886zM10 7.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5zM6.25 10a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0z';

export const createFormatUnderline =
  'M6.5 3v6a3.5 3.5 0 1 0 7 0V3H15v6A5 5 0 0 1 5 9V3h1.5zM16 18H4v-1.5h12V18z';

export const createFormatText2 =
  'M6.684 3v3h4.737v12h2.842V6H19V3H6.684zM1 11h2.842v7h2.842v-7h2.842V8H1v3z';

export const createFormatText =
  'M4.007 14 9.183 2h1.634l5.176 12H14.36l-1.295-3h-6.13L5.64 14H4.007zM10 3.893 12.418 9.5H7.581L10 3.893zM4 18h12v-1.5H4V18z';

export const createFormatStrikethrough =
  'M13.821 6.09h1.951C15.682 3.75 13.366 2 10.146 2c-3.187 0-5.69 1.727-5.69 4.333 0 1.257.587 2.207 1.62 2.917H2.75a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5h-5.902l-.03-.008-1.692-.424c-1.236-.303-3.22-.909-3.22-2.576 0-1.484 1.464-2.575 3.675-2.575 2.017 0 3.545.894 3.74 2.424zm.228 7.395c0-.506-.167-.907-.453-1.235h2.189c.137.374.215.794.215 1.265C16 16.03 13.821 18 9.984 18 6.407 18 4.195 16.273 4 13.727h2.081c.163 1.758 1.919 2.576 3.903 2.576 2.309 0 4.065-1.121 4.065-2.818z';

export const createFormatListNumber =
  'M3.115 2.65h.91v3.636h-.988V3.56h-.021l-.796.476v-.838l.895-.547zM17.25 4a.75.75 0 0 1 .102 1.493l-.102.007H6.75a.75.75 0 0 1-.102-1.493L6.75 4h10.5zm.75 6a.75.75 0 0 0-.75-.75H6.75l-.102.007a.75.75 0 0 0 .102 1.493h10.5l.102-.007A.75.75 0 0 0 18 10zm0 5.25a.75.75 0 0 0-.75-.75H6.75l-.102.007A.75.75 0 0 0 6.75 16h10.5l.102-.007A.75.75 0 0 0 18 15.25zM2.071 11.686h2.791v-.788h-1.42v-.022l.34-.284c.83-.69 1.041-1.049 1.041-1.47C4.823 8.444 4.267 8 3.396 8 2.549 8 1.998 8.47 2 9.236h.938c-.002-.306.184-.476.458-.476.271 0 .465.169.465.447 0 .258-.165.425-.433.647L2.07 10.976v.71zm2.92 4.456c.003.641-.624 1.094-1.514 1.094-.86 0-1.473-.48-1.477-1.158h.994c.004.211.206.362.49.362.274 0 .466-.158.462-.383.004-.22-.22-.377-.54-.377h-.37V15h.37c.295 0 .501-.157.497-.377.004-.211-.17-.362-.419-.362-.271 0-.465.156-.468.376H2.07c.004-.665.59-1.136 1.413-1.136.806 0 1.375.446 1.371 1.058.004.407-.312.686-.746.732v.028c.586.064.885.378.881.824z';

export const createFormatListBullet =
  'M3 5.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM17.25 4a.75.75 0 0 1 .102 1.493l-.102.007H6.75a.75.75 0 0 1-.102-1.493L6.75 4h10.5zm.75 6a.75.75 0 0 0-.75-.75H6.75l-.102.007a.75.75 0 0 0 .102 1.493h10.5l.102-.007A.75.75 0 0 0 18 10zm0 5.25a.75.75 0 0 0-.75-.75H6.75l-.102.007A.75.75 0 0 0 6.75 16h10.5l.102-.007A.75.75 0 0 0 18 15.25zM4 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 6.25a1 1 0 1 0 0-2 1 1 0 0 0 0 2z';

export const createFormatItalic =
  'M10.14 3.5H6V2h10v1.5h-4.346l-1.793 13H14V18H4v-1.5h4.346l1.793-13z';

export const createFormatHeading =
  'M4 18V2h1.5v16H4z M15.25 10.75H4.75v-1.5h10.5v1.5z M14.5 18V2H16v16h-1.5z';

export const createFormatBold =
  'M10.375 2H4v16h7.375a4.625 4.625 0 0 0 2.59-8.458A4.625 4.625 0 0 0 10.374 2zm0 6.75a2.125 2.125 0 0 0 0-4.25H6.5v4.25h3.875zM6.5 11.25v4.25h4.875a2.125 2.125 0 0 0 0-4.25H6.5z';

export const createFlagHidden =
  'M2.28 1.22a.75.75 0 0 0-1.06 1.06l16.5 16.506a.75.75 0 1 0 1.06-1.06L2.28 1.22zM3 18.25V4.061l1.5 1.501v5.856l1.943-.2a7.677 7.677 0 0 1 4.327.822c.13.067.26.13.393.187l1.993 1.995a7.675 7.675 0 0 1-3.077-.85 6.177 6.177 0 0 0-3.482-.662l-2.097.216v5.324a.75.75 0 0 1-1.5 0zM15.5 4v6.444l1.5 1.5V3a.75.75 0 0 0-.965-.718l-.644.193a6.176 6.176 0 0 1-4.621-.435 7.676 7.676 0 0 0-4.327-.822l-.15.015 1.465 1.466a6.176 6.176 0 0 1 2.32.673A7.676 7.676 0 0 0 15.5 4z';

export const createFlag =
  'M10.77 2.04a7.676 7.676 0 0 0-4.327-.823l-2.77.287A.75.75 0 0 0 3 2.25v16a.75.75 0 0 0 1.5 0v-5.324l2.097-.216c1.2-.125 2.41.105 3.482.662a7.676 7.676 0 0 0 5.743.54l.643-.194A.75.75 0 0 0 17 13V3a.75.75 0 0 0-.965-.718l-.644.193a6.176 6.176 0 0 1-4.621-.435zM4.5 11.418l1.943-.2a7.676 7.676 0 0 1 4.327.822 6.176 6.176 0 0 0 4.62.435l.11-.033V4c-1.82.461-3.749.24-5.421-.628a6.176 6.176 0 0 0-3.482-.663L4.5 2.926v8.492z';

export const createFilter =
  'M18 5.75a.75.75 0 0 0-.75-.75H2.75l-.102.007A.75.75 0 0 0 2.75 6.5h14.5l.102-.007A.75.75 0 0 0 18 5.75zm-2.75 3.5a.75.75 0 0 1 .102 1.493l-.102.007H4.75a.75.75 0 0 1-.102-1.493l.102-.007h10.5zm-2 4.25a.75.75 0 0 1 .102 1.493L13.25 15h-6.5a.75.75 0 0 1-.102-1.493l.102-.007h6.5z';

export const createEyeOff =
  'M2.28 1.22a.75.75 0 0 0-1.06 1.06l2.782 2.784A9.765 9.765 0 0 0 .714 9.77a.75.75 0 0 0 0 .458C1.975 14.156 5.655 17 10.002 17c1.71 0 3.32-.44 4.718-1.216l3 3.002a.75.75 0 1 0 1.061-1.06L2.28 1.22zM2.22 10a8.272 8.272 0 0 1 2.852-3.866l1.799 1.8a3.75 3.75 0 0 0 5.195 5.197l1.541 1.541A8.254 8.254 0 0 1 2.22 10zm8.748 2.032-3-3a2.25 2.25 0 0 0 3 3zM8.166 4.705A8.254 8.254 0 0 1 17.782 10a8.236 8.236 0 0 1-1.365 2.436.75.75 0 1 0 1.166.944 9.73 9.73 0 0 0 1.704-3.152.75.75 0 0 0 0-.457C18.028 5.844 14.347 3 10.001 3a9.78 9.78 0 0 0-2.167.242.75.75 0 0 0 .332 1.463z';

export const createEye =
  'M10 15.5A8.254 8.254 0 0 1 2.22 10a8.254 8.254 0 0 1 15.562 0A8.254 8.254 0 0 1 10 15.5zM10 3C5.655 3 1.974 5.844.715 9.771a.75.75 0 0 0 0 .458C1.975 14.156 5.655 17 10.002 17s8.028-2.844 9.286-6.771a.749.749 0 0 0 0-.458C18.028 5.844 14.347 3 10.001 3zm-2.25 7a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0zM10 6.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5z';

export const createExport =
  'M5.53 8.28a.75.75 0 0 1-1.06-1.06l5-5a.75.75 0 0 1 1.06 0l5 5a.75.75 0 0 1-1.06 1.06l-3.72-3.72v7.69a.75.75 0 0 1-1.5 0V4.56L5.53 8.28zM3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z';

export const createExpandRight =
  'M9.28 3.47a.75.75 0 0 1 0 1.06L4.56 9.25h9.69a.75.75 0 0 1 0 1.5H4.56l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0zM17.25 3a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-1.5 0V3.75a.75.75 0 0 1 .75-.75z';

export const createExpandLeft =
  'M10.72 3.47a.75.75 0 0 0 0 1.06l4.72 4.72H5.75a.75.75 0 0 0 0 1.5h9.69l-4.72 4.72a.75.75 0 1 0 1.06 1.06l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.06 0zM2.75 3a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-1.5 0V3.75A.75.75 0 0 1 2.75 3z';

export const createExit =
  'M17 4.286v11.428c0 .606-.197 1.188-.547 1.617-.35.428-.825.669-1.32.669H10.25a.25.25 0 0 1-.25-.25V2.25a.25.25 0 0 1 .25-.25h4.883c.495 0 .97.24 1.32.67.35.428.547 1.01.547 1.616zM6 4.25A.25.25 0 0 1 6.25 4h2a.25.25 0 0 1 .25.25v11.5a.25.25 0 0 1-.25.25h-2a.25.25 0 0 1-.25-.25V4.25zM3 6.25A.25.25 0 0 1 3.25 6h1a.25.25 0 0 1 .25.25v7.5a.25.25 0 0 1-.25.25h-1a.25.25 0 0 1-.25-.25v-7.5z';

export const createEmojiAdd =
  'M16.5 0a.75.75 0 0 1 .75.75v2h2a.75.75 0 0 1 0 1.5h-2v2a.75.75 0 0 1-1.5 0v-2h-2a.75.75 0 0 1 0-1.5h2v-2A.75.75 0 0 1 16.5 0zM10 2.5a7.5 7.5 0 1 0 7.445 6.59.75.75 0 1 1 1.49-.18 9 9 0 1 1-7.845-7.845.75.75 0 1 1-.18 1.49A7.583 7.583 0 0 0 10 2.5zM8.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2.32 4c-.36 0-.598.37-.405.674A4.996 4.996 0 0 0 10 15a4.996 4.996 0 0 0 4.226-2.326c.193-.304-.046-.674-.406-.674H6.18zM13 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z';

export const createEmoji =
  'M17.5 10a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zm1.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0zM5.775 12.674C5.582 12.37 5.82 12 6.18 12h7.64c.36 0 .599.37.406.674A4.996 4.996 0 0 1 10 15a4.996 4.996 0 0 1-4.225-2.326zM8.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM13 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z';

export const createEllipsisVertical =
  'M10 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM11.5 16a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z';

export const createEllipsisHorizontal =
  'M4 11.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM17.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z';

export const createEdit3 =
  'M10.72 2.22a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-8.5 8.5a.75.75 0 0 1-.53.22h-3a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 .22-.53l8.5-8.5zM3.5 11.56v1.94h1.94l6-6L9.5 5.56l-6 6zm7.06-7.06 1.94 1.94.69-.69-1.94-1.94-.69.69zM2 18.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75z';

export const createEdit2 =
  'M14.78 1.22a.75.75 0 0 0-1.06 0l-11.5 11.5a.75.75 0 0 0-.22.53v4c0 .414.336.75.75.75h4a.75.75 0 0 0 .53-.22l11.5-11.5a.75.75 0 0 0 0-1.06l-4-4zM3.5 16.5v-2.94l7.5-7.5L13.94 9l-7.5 7.5H3.5zM15 7.94l2.19-2.19-2.94-2.94L12.06 5 15 7.94z';

export const createEdit =
  'M14.72 1.22a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-.53.22h-3a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 .22-.53l7.5-7.5zM8.5 9.56v1.94h1.94l5-5-1.94-1.94-5 5zm6.06-6.06 1.94 1.94.69-.69-1.94-1.94-.69.69zM2 4.75A2.75 2.75 0 0 1 4.75 2h2.5a.75.75 0 0 1 0 1.5h-2.5c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-2.5a.75.75 0 0 1 1.5 0v2.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25V4.75z';

export const createDragVertical =
  'M12.5 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-5-13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 4.333a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM6 12.167a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zM6 16.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm5-4.333a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm1.5-2.834a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12.5 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z';

export const createDragHorizontal =
  'M18 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-13 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm4.333 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm2.833 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4.334 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-4.334-5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM9.333 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM5 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z';

export const createDownload =
  'M14.47 7.72a.75.75 0 1 1 1.06 1.06l-5 5a.75.75 0 0 1-1.06 0l-5-5a.75.75 0 0 1 1.06-1.06l3.72 3.72V2.75a.75.75 0 0 1 1.5 0v8.69l3.72-3.72zM2.75 16.5a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5H2.75z';

export const createDescription =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM3 8.25a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm0 3.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5z';

export const createCustomize =
  'm11.498 15.388.007.007c.335.384.52.863.52 1.355A2.026 2.026 0 0 1 10 18.775c-4.835 0-8.775-3.94-8.775-8.775 0-4.835 3.94-8.775 8.775-8.775 4.858 0 8.775 3.557 8.775 7.875a5.18 5.18 0 0 1-5.175 5.175h-1.593a.67.67 0 0 0-.509 1.113zm-1.003.9-.003-.003a2.01 2.01 0 0 1-.51-1.335c0-1.118.907-2.025 2.025-2.025H13.6A3.824 3.824 0 0 0 17.425 9.1c0-3.626-3.36-6.525-7.425-6.525-4.093 0-7.425 3.332-7.425 7.425S5.907 17.425 10 17.425a.67.67 0 0 0 .675-.675c0-.221-.11-.38-.18-.462z M5.05 10.9a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7zM7.75 7.3a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7zM12.25 7.3a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7zM14.95 10.9a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7z';

export const createCopy =
  'M3.75.95A2.75 2.75 0 0 0 1 3.7v7.5a2.75 2.75 0 0 0 2.75 2.75h.5a.75.75 0 0 0 0-1.5h-.5c-.69 0-1.25-.56-1.25-1.25V3.7c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v.55a.75.75 0 0 0 1.5 0V3.7A2.75 2.75 0 0 0 11.25.95h-7.5zm5 5A2.75 2.75 0 0 0 6 8.7v7.5a2.75 2.75 0 0 0 2.75 2.75h7.5A2.75 2.75 0 0 0 19 16.2V8.7a2.75 2.75 0 0 0-2.75-2.75h-7.5zM7.5 8.7c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25h-7.5c-.69 0-1.25-.56-1.25-1.25V8.7z';

export const createDuplicate =
  'M1 3.7A2.75 2.75 0 0 1 3.75.95h7.5A2.75 2.75 0 0 1 14 3.7v.55a.75.75 0 0 1-1.5 0V3.7c0-.69-.56-1.25-1.25-1.25h-7.5c-.69 0-1.25.56-1.25 1.25v7.5c0 .69.56 1.25 1.25 1.25h.5a.75.75 0 0 1 0 1.5h-.5A2.75 2.75 0 0 1 1 11.2V3.7zm5 5a2.75 2.75 0 0 1 2.75-2.75h7.5A2.75 2.75 0 0 1 19 8.7v7.5a2.75 2.75 0 0 1-2.75 2.75h-7.5A2.75 2.75 0 0 1 6 16.2V8.7zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v7.5c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V8.7c0-.69-.56-1.25-1.25-1.25h-7.5zM12.5 9.5a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1 0-1.5h1.5v-1.5a.75.75 0 0 1 .75-.75z';

export const createCollapseRight =
  'M7.72 3.47a.75.75 0 0 0 0 1.06l4.72 4.72H2.75a.75.75 0 0 0 0 1.5h9.69l-4.72 4.72a.75.75 0 1 0 1.06 1.06l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.06 0zM17.25 3a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-1.5 0V3.75a.75.75 0 0 1 .75-.75z';

export const createCollapseLeft =
  'M12.28 3.47a.75.75 0 0 1 0 1.06L7.56 9.25h9.69a.75.75 0 0 1 0 1.5H7.56l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0zM2.75 3a.75.75 0 0 1 .75.75v12.5a.75.75 0 0 1-1.5 0V3.75A.75.75 0 0 1 2.75 3z';

export const createCloseSmall =
  'M5.79 4.73a.75.75 0 1 0-1.06 1.06L8.94 10l-4.21 4.21a.75.75 0 1 0 1.06 1.06L10 11.06l4.21 4.21a.75.75 0 1 0 1.06-1.06L11.06 10l4.21-4.21a.75.75 0 1 0-1.06-1.06L10 8.94 5.79 4.73z';

export const createClose =
  'M3.22 3.22a.75.75 0 0 1 1.06 0L10 8.94l5.72-5.72a.75.75 0 1 1 1.06 1.06L11.06 10l5.72 5.72a.75.75 0 1 1-1.06 1.06L10 11.06l-5.72 5.72a.75.75 0 0 1-1.06-1.06L8.94 10 3.22 4.28a.75.75 0 0 1 0-1.06z';

export const createClockReverse =
  'M10 5a.75.75 0 0 1 .743.648l.007.102v3.939l2.03 2.03a.75.75 0 0 1 .073.977l-.073.084a.75.75 0 0 1-.976.073l-.084-.073-2.25-2.25a.75.75 0 0 1-.21-.412L9.25 10V5.75A.75.75 0 0 1 10 5z M14.355 3.895A7.5 7.5 0 0 0 3.012 7.273l-1.397-.546a9 9 0 1 1 0 6.542.75.75 0 1 1 1.397-.544 7.5 7.5 0 1 0 11.343-8.83z M1.75 2a.75.75 0 0 1 .75.75V6.5h3.75a.75.75 0 1 1 0 1.5h-4.5A.75.75 0 0 1 1 7.25v-4.5A.75.75 0 0 1 1.75 2z';

export const createClock =
  'M10.743 5.648a.75.75 0 0 0-1.493.102V10l.01.118a.75.75 0 0 0 .21.412l2.25 2.25.084.073a.75.75 0 0 0 .976-.073l.073-.084a.75.75 0 0 0-.073-.976l-2.03-2.031V5.75l-.007-.102z M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-1.5 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z';

export const createCircleStar =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M9.525 5.464a.5.5 0 0 1 .95 0l.872 2.682h2.82a.5.5 0 0 1 .295.904l-2.282 1.658.872 2.683a.5.5 0 0 1-.77.559L10 12.292 7.72 13.95a.5.5 0 0 1-.77-.56l.872-2.682L5.539 9.05a.5.5 0 0 1 .294-.904h2.82l.872-2.682z';

export const createCircleQuestion =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M10.85 11.5H9.16v-.14c.005-1.436.424-1.875 1.172-2.31.548-.32.971-.677.971-1.215 0-.57-.485-.94-1.086-.94-.584 0-1.123.357-1.15 1.026H7.25C7.29 6.28 8.609 5.5 10.226 5.5c1.768 0 3.024.837 3.024 2.274 0 .964-.543 1.567-1.376 2.023-.704.39-1.016.763-1.025 1.563v.14zM10 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2z';

export const createCirclePlus =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M10.75 6.75a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5z';

export const createCircleMinus =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M14 10a.75.75 0 0 1-.75.75h-6.5a.75.75 0 0 1 0-1.5h6.5A.75.75 0 0 1 14 10z';

export const createCircleInfo =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M10 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a.75.75 0 0 0-.75.75v3.5a.75.75 0 0 0 1.5 0v-3.5A.75.75 0 0 0 10 9z';

export const createCircleEllipsis =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z';

export const createCircleDot =
  'M.833 10a9.167 9.167 0 1 1 18.334 0A9.167 9.167 0 0 1 .833 10z M6.666 10a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0z';

export const createCircleCross =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M13.28 7.78a.75.75 0 0 0-1.06-1.06L10 8.94 7.78 6.72a.75.75 0 0 0-1.06 1.06L8.94 10l-2.22 2.22a.75.75 0 1 0 1.06 1.06L10 11.06l2.22 2.22a.75.75 0 1 0 1.06-1.06L11.06 10l2.22-2.22z';

export const createCircleChecked =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M13.946 6.647a.75.75 0 0 1 .157 1.049l-4.25 5.75a.75.75 0 0 1-1.133.084l-2.75-2.75a.75.75 0 1 1 1.06-1.06l2.135 2.134 3.732-5.05a.75.75 0 0 1 1.049-.157z';

export const createCircleBolt =
  'M.833 10a9.167 9.167 0 1 1 18.333 0A9.167 9.167 0 0 1 .833 10z M10.974 4.553a.5.5 0 0 1 .27.53L10.59 9h3.91a.5.5 0 0 1 .362.845l-5.25 5.5a.5.5 0 0 1-.855-.427L9.41 11H5.5a.5.5 0 0 1-.362-.845l5.25-5.5a.5.5 0 0 1 .586-.102z';

export const createChevronUp =
  'M14.53 12.28a.75.75 0 0 1-1.06 0L10 8.81l-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06z';

export const createChevronRight =
  'M7.72 5.47a.75.75 0 0 0 0 1.06L11.19 10l-3.47 3.47a.75.75 0 1 0 1.06 1.06l4-4a.75.75 0 0 0 0-1.06l-4-4a.75.75 0 0 0-1.06 0z';

export const createChevronLeft =
  'M12.28 5.47a.75.75 0 0 1 0 1.06L8.81 10l3.47 3.47a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0z';

export const createChevronDown =
  'M14.53 7.72a.75.75 0 0 0-1.06 0L10 11.19 6.53 7.72a.75.75 0 0 0-1.06 1.06l4 4a.75.75 0 0 0 1.06 0l4-4a.75.75 0 0 0 0-1.06z';

export const createCheckboxChecked =
  'M16 3.5H4a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5zM4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4z M13.78 7.22a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06L9 10.94l3.72-3.72a.75.75 0 0 1 1.06 0z';

export const createCheckbox =
  'M16 3.5H4a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5zM4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4z';

export const createCheck =
  'M16.948 4.648a.75.75 0 0 1 .154 1.05l-7.25 9.75a.75.75 0 0 1-1.132.082l-4.75-4.75a.75.75 0 1 1 1.06-1.06l4.136 4.136 6.732-9.054a.75.75 0 0 1 1.05-.154z';

export const createCaretUp =
  'm10.64 7.748 3.076 3.418a.3.3 0 0 1-.223.5H7.34a.3.3 0 0 1-.223-.5l3.077-3.418a.3.3 0 0 1 .445 0z';

export const createCaretRight =
  'M12.252 10.194 8.834 7.117a.3.3 0 0 0-.5.223v6.153a.3.3 0 0 0 .5.223l3.418-3.076a.3.3 0 0 0 0-.446z';

export const createCaretLeft =
  'm7.748 10.194 3.418-3.077a.3.3 0 0 1 .5.223v6.153a.3.3 0 0 1-.5.223L7.748 10.64a.3.3 0 0 1 0-.446z';

export const createCaretDown =
  'M10.194 12.252 7.117 8.834a.3.3 0 0 1 .223-.5h6.153a.3.3 0 0 1 .223.5l-3.076 3.418a.3.3 0 0 1-.447 0z';

export const createCalendarAlt =
  'M16 3.5H4a.5.5 0 0 0-.5.5v1.5h13V4a.5.5 0 0 0-.5-.5zm-14 2V16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v1.5zM16.5 16V7h-13v9a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5zM15 12h-3v3h3v-3z';

export const createCalendar =
  'M6 .75a.75.75 0 0 1 1.5 0V2h4.8V.75a.75.75 0 0 1 1.5 0V2H16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h2V.75zm6.3 2.75v1.25a.75.75 0 0 0 1.5 0V3.5H16a.5.5 0 0 1 .5.5v2.5h-13V4a.5.5 0 0 1 .5-.5h2v1.25a.75.75 0 0 0 1.5 0V3.5h4.8zM16.5 8v8a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V8h13z';

export const createBuilding =
  'M7.5 5.083a.75.75 0 0 0 0 1.5h.833a.75.75 0 1 0 0-1.5H7.5zM11.667 5.083a.75.75 0 0 0 0 1.5h.833a.75.75 0 0 0 0-1.5h-.833zM7.5 8.417a.75.75 0 1 0 0 1.5h.833a.75.75 0 1 0 0-1.5H7.5zM11.667 8.417a.75.75 0 0 0 0 1.5h.833a.75.75 0 0 0 0-1.5h-.833z M5.833 1.75a2.417 2.417 0 0 0-2.416 2.417V16.75H2.5a.75.75 0 0 0 0 1.5h15a.75.75 0 0 0 0-1.5h-.917V4.167a2.417 2.417 0 0 0-2.416-2.417H5.833zm3.334 10c-.875 0-1.584.709-1.584 1.583v3.417H4.917V4.167c0-.507.41-.917.916-.917h8.334c.506 0 .916.41.916.917V16.75h-2.666v-3.417c0-.874-.71-1.583-1.584-1.583H9.167zm1.666 1.5c.046 0 .084.037.084.083v3.417H9.083v-3.417c0-.046.038-.083.084-.083h1.666z';

export const createBubbleEmpty =
  'M10 1a9 9 0 0 0-7.992 13.142l-.406 2.842a1.25 1.25 0 0 0 1.414 1.414l2.842-.406A9 9 0 1 0 10 1zm-7.5 9a7.5 7.5 0 1 1 3.86 6.56.75.75 0 0 0-.47-.088l-2.756.394.394-2.756a.75.75 0 0 0-.087-.47A7.463 7.463 0 0 1 2.5 10z';

export const createBubble =
  'M6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM11 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z M10 1a9 9 0 0 0-7.992 13.142l-.406 2.842a1.25 1.25 0 0 0 1.414 1.414l2.842-.406A9 9 0 1 0 10 1zm-7.5 9a7.5 7.5 0 1 1 3.86 6.56.75.75 0 0 0-.47-.088l-2.756.394.394-2.756a.75.75 0 0 0-.087-.47A7.463 7.463 0 0 1 2.5 10z';

export const createBriefcase =
  'M8.333 1.667a2.5 2.5 0 0 0-2.5 2.5V5h-2.5a2.5 2.5 0 0 0-2.5 2.5v8.333a2.5 2.5 0 0 0 2.5 2.5h13.333a2.5 2.5 0 0 0 2.5-2.5V7.5a2.5 2.5 0 0 0-2.5-2.5h-2.5v-.833a2.5 2.5 0 0 0-2.5-2.5H8.333zm-.59 1.91a.833.833 0 0 0-.243.59V5h5v-.833a.833.833 0 0 0-.834-.834H8.333a.833.833 0 0 0-.59.244zM2.5 15.833c0 .46.373.834.833.834h2.5v-10h-2.5A.833.833 0 0 0 2.5 7.5v8.333zm15 0c0 .46-.373.834-.834.834h-2.5v-10h2.5c.46 0 .834.373.834.833v8.333zm-10-9.166h5v10h-5v-10z';

export const createBookmark =
  'M3 5.75A3.75 3.75 0 0 1 6.75 2h6.5A3.75 3.75 0 0 1 17 5.75v11.5a.75.75 0 0 1-1.232.574l-5.607-4.71a.25.25 0 0 0-.322 0l-5.607 4.71A.75.75 0 0 1 3 17.25V5.75zM6.75 3.5A2.25 2.25 0 0 0 4.5 5.75v9.89l4.374-3.674a1.75 1.75 0 0 1 2.252 0l4.374 3.675V5.75a2.25 2.25 0 0 0-2.25-2.25h-6.5z';

export const createBolt =
  'M11.576 1.075a.75.75 0 0 1 .412.812L10.902 7.75h6.348a.75.75 0 0 1 .565 1.243l-8.5 9.75a.75.75 0 0 1-1.302-.63l1.085-5.863H2.75a.75.75 0 0 1-.565-1.243l8.5-9.75a.75.75 0 0 1 .891-.182zM4.4 10.75H10a.75.75 0 0 1 .738.886l-.751 4.054L15.6 9.25H10a.75.75 0 0 1-.737-.887l.75-4.053L4.4 10.75z';

export const createBlockVideo =
  'm11.488 12.572.936.95-.85.837c-.37.364-.574.85-.574 1.368 0 .52.204 1.006.573 1.37a2.011 2.011 0 0 0 2.812 0l.852-.836.934.951-.85.835a3.324 3.324 0 0 1-2.34.953 3.33 3.33 0 0 1-2.343-.953 3.231 3.231 0 0 1-.971-2.32c0-.878.344-1.7.972-2.318l.85-.837zM9 1c4.187 0 7.633 3.233 7.973 7.333h-1.34c-.333-3.36-3.186-6-6.633-6-3.673 0-6.667 2.994-6.667 6.667 0 3.447 2.64 6.3 6 6.633v1.34C4.233 16.633 1 13.186 1 9c0-4.413 3.587-8 8-8zm4.346 9.619a3.353 3.353 0 0 1 4.683 0c.626.617.971 1.44.971 2.32 0 .878-.345 1.701-.972 2.319l-.85.836-.935-.95.85-.837c.37-.364.574-.85.574-1.369 0-.518-.204-1.004-.574-1.368a2.01 2.01 0 0 0-2.812 0l-.851.836-.935-.952.851-.835zm1.89 1.953.935.95-2.581 2.542-.936-.95 2.582-2.542zm-7.57-6.239 3.943 2.628a.047.047 0 0 1 0 .077l-3.942 2.628V6.333z';

export const createBlockText =
  'M17.5 2c.827 0 1.5.652 1.5 1.455v13.09c0 .803-.673 1.455-1.5 1.455h-15c-.827 0-1.5-.652-1.5-1.454V3.455C1 2.652 1.673 2 2.5 2h15zm0 1.455h-15v13.09h15.002L17.5 3.456zm-3 8.727v1.454h-9v-1.454h9zm-3-2.91v1.455h-6V9.273h6zm3-2.908v1.454h-9V6.364h9z';

export const createBlockSocial =
  'M15.025 1.5C17.217 1.5 19 3.24 19 5.38c0 .46-.083.913-.246 1.345-.677 1.77-2.137 3.42-4.431 5.018.001.073.01.145.01.218 0 3.606-2.99 6.539-6.666 6.539S1 15.567 1 11.961c0-2.932 1.98-5.42 4.697-6.246-.01-.112-.03-.223-.03-.335 0-2.14 1.783-3.88 3.975-3.88 1.003 0 1.964.373 2.691 1.026A4.038 4.038 0 0 1 15.025 1.5zM7.667 6.73c-.097 0-.192.01-.287.015-.468.025-.92.105-1.35.24-2.142.68-3.697 2.652-3.697 4.976 0 2.885 2.393 5.231 5.334 5.231 2.712 0 4.952-1.997 5.286-4.569.028-.217.047-.436.047-.662 0-.293-.03-.58-.079-.86-.42-2.476-2.613-4.37-5.254-4.37zm2.667 5.885c0 1.401-1.123 2.544-2.534 2.613l-.133.003C6.194 15.23 5 14.06 5 12.615h5.334zM5 9.837c.46 0 .833.366.833.817A.826.826 0 0 1 5 11.47a.826.826 0 0 1-.833-.817c0-.451.373-.817.833-.817zm5.334 0c.46 0 .833.366.833.817a.826.826 0 0 1-.833.817.826.826 0 0 1-.834-.817c0-.451.373-.817.834-.817zm4.69-7.03c-.85 0-1.653.404-2.15 1.08-.25.34-.83.34-1.081 0a2.675 2.675 0 0 0-2.151-1.08C8.185 2.808 7 3.963 7 5.38c0 .025.006.05.007.076.217-.021.437-.033.66-.033 3.082 0 5.676 2.066 6.435 4.858 1.737-1.31 2.884-2.654 3.403-4.013a2.51 2.51 0 0 0 .162-.888c0-1.418-1.185-2.572-2.642-2.572z';

export const createBlockShortAnswer =
  'M3.75 8a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm0 3.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H3.75z';

export const createBlockQuestion =
  'M14.275 3.221c.532.406.96.907 1.264 1.488.306.583.461 1.242.461 1.958 0 .93-.24 1.741-.711 2.409-.468.655-1.136 1.255-1.731 1.755-.507.45-.924.813-1.224 1.066a2.327 2.327 0 0 0-.513.568 1.994 1.994 0 0 0-.175.446c-.022.084-.029.148-.043.237a37.98 37.98 0 0 0-.106.853H8.499c.02-.482.066-.888.134-1.218.103-.498.297-.976.575-1.418.276-.42.608-.809.989-1.158.372-.343.829-.734 1.36-1.16.357-.294.809-.691 1.079-1.103.232-.359.35-.767.35-1.213 0-.336-.072-.644-.213-.918a2.23 2.23 0 0 0-.582-.712 2.984 2.984 0 0 0-.886-.472c-.837-.284-1.861-.2-2.527.071a2.66 2.66 0 0 0-.973.655c-.274.302-.48.65-.603 1.024a3.823 3.823 0 0 0-.199 1.346l.018.682H4l.003-.67a6.125 6.125 0 0 1 .425-2.301 5.12 5.12 0 0 1 1.231-1.83 5.594 5.594 0 0 1 2.003-1.19c1.396-.496 3.289-.57 4.795-.107.676.202 1.288.51 1.818.912zM8.498 18h3v-2.667h-3V18z';

export const createBlockParagraph =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM3 8.25c0-.414.392-.75.875-.75h12.25c.483 0 .875.336.875.75s-.392.75-.875.75H3.875C3.392 9 3 8.664 3 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm0 3.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H3.75z';

export const createBlockNotion =
  'M4.373 4.203c.557.453.766.418 1.812.349l9.86-.593c.21 0 .036-.208-.034-.243l-1.638-1.184c-.313-.243-.731-.522-1.533-.452l-9.547.696c-.349.034-.418.209-.28.348l1.36 1.08zm.592 2.298v10.375c0 .557.279.766.906.732l10.836-.628c.628-.034.698-.417.698-.87V5.805c0-.453-.174-.696-.558-.662l-11.324.662c-.418.035-.558.244-.558.696zm10.698.557c.07.313 0 .627-.314.662l-.522.104v7.66c-.454.243-.872.382-1.22.382-.558 0-.697-.174-1.115-.696L9.077 9.81v5.187l1.08.244s0 .626-.872.626l-2.403.14c-.07-.14 0-.488.244-.557l.627-.174V8.416l-.871-.07c-.07-.313.104-.766.592-.8l2.579-.174 3.554 5.43V7.999l-.906-.104c-.07-.383.209-.662.557-.696l2.405-.14zM2.491 1.836l9.931-.731c1.22-.105 1.533-.035 2.3.522l3.17 2.228c.523.383.698.487.698.905v12.22c0 .766-.28 1.22-1.255 1.289l-11.533.696c-.732.035-1.08-.07-1.464-.557l-2.335-3.029c-.418-.557-.592-.975-.592-1.463V3.054c0-.626.28-1.149 1.08-1.218z';

export const createBlockMap =
  'M6.438 2.084c.186-.101.409-.111.604-.029l6.152 2.612 4.782-2.583a.698.698 0 0 1 .685.012c.21.124.339.348.339.59V14.57a.685.685 0 0 1-.36.602l-5.078 2.743a.698.698 0 0 1-.604.029l-6.152-2.612-4.782 2.583a.698.698 0 0 1-.685-.012.684.684 0 0 1-.339-.59V5.43c0-.251.138-.482.36-.602l5.078-2.743zm.368 1.363L2.385 5.836v10.323l4.053-2.19c.186-.1.409-.11.604-.028l6.152 2.612 4.421-2.389V3.841l-4.053 2.19a.698.698 0 0 1-.604.028L6.806 3.447z M6 14.375V3h1.412v11.375H6zM12.588 17V5.625H14V17h-1.412z';

export const createBlockLinearScale = 'M18 10.75H2v-1.5h16v1.5z';

export const createBlockImage =
  'M17.364 1C18.266 1 19 1.734 19 2.636v14.728c0 .902-.734 1.636-1.636 1.636H2.636A1.638 1.638 0 0 1 1 17.364V2.636C1 1.734 1.734 1 2.636 1h14.728zm0 1.636H2.636v14.728h14.728V2.636zM8.772 10l2.59 3.107 1.224-1.461 2.732 3.263H4.682l4.09-4.91zm4.5-4.91c.904 0 1.637.732 1.637 1.633 0 .903-.733 1.632-1.636 1.632-.903 0-1.637-.73-1.637-1.632 0-.9.734-1.632 1.637-1.632z';

export const createBlockHtmlIframe =
  'm11.166 3 1.245.32L8.834 17l-1.244-.319L11.166 3zm-6.22 2.545.941.87L2.522 10l3.365 3.585-.94.87-3.774-4.02a.634.634 0 0 1 0-.87l3.774-4.02zm10.088 0 3.792 4.019a.634.634 0 0 1 0 .872l-3.792 4.018-.938-.872L17.476 10l-3.38-3.582.938-.873z';

export const createBlockGrid =
  'M4.75 2A2.75 2.75 0 0 0 2 4.75v1.5A2.75 2.75 0 0 0 4.75 9h1.5A2.75 2.75 0 0 0 9 6.25v-1.5A2.75 2.75 0 0 0 6.25 2h-1.5zM3.5 4.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5zM4.75 11A2.75 2.75 0 0 0 2 13.75v1.5A2.75 2.75 0 0 0 4.75 18h1.5A2.75 2.75 0 0 0 9 15.25v-1.5A2.75 2.75 0 0 0 6.25 11h-1.5zM3.5 13.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5zm7.5-9A2.75 2.75 0 0 1 13.75 2h1.5A2.75 2.75 0 0 1 18 4.75v1.5A2.75 2.75 0 0 1 15.25 9h-1.5A2.75 2.75 0 0 1 11 6.25v-1.5zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v1.5c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25v-1.5c0-.69-.56-1.25-1.25-1.25h-1.5zm0 7.5A2.75 2.75 0 0 0 11 13.75v1.5A2.75 2.75 0 0 0 13.75 18h1.5A2.75 2.75 0 0 0 18 15.25v-1.5A2.75 2.75 0 0 0 15.25 11h-1.5zm-1.25 2.75c0-.69.56-1.25 1.25-1.25h1.5c.69 0 1.25.56 1.25 1.25v1.5c0 .69-.56 1.25-1.25 1.25h-1.5c-.69 0-1.25-.56-1.25-1.25v-1.5z';

export const createBlockGoogle =
  'm4 17.794 3-5.198h12l-3 5.198H4z M13 12.596h6L13 2.202H7l6 10.394z m1 12.596 3 5.198 6-10.395-3-5.197-6 10.394z a M0 0h18v15.595H0z';

export const createBlockGallery =
  'M15.714 2.5c.788 0 1.429.673 1.429 1.5h1.428C19.36 4 20 4.673 20 5.5v9c0 .827-.64 1.5-1.429 1.5h-1.428c0 .827-.64 1.5-1.429 1.5H4.286c-.788 0-1.429-.673-1.429-1.5H1.43C.64 16 0 15.327 0 14.5v-9C0 4.673.64 4 1.429 4h1.428c0-.827.64-1.5 1.429-1.5h11.428zm0 1.5H4.286v12h11.428V4zm2.857 1.5h-1.428v9h1.428v-9zm-15.714 0H1.43v9h1.428v-9zm6.25 4.5 1.884 2.374.89-1.117 1.988 2.493H6.13L9.107 10zM12.5 7c.591 0 1.071.503 1.071 1.122 0 .62-.48 1.123-1.071 1.123-.592 0-1.071-.503-1.071-1.123S11.908 7 12.5 7z';

export const createBlockFigma =
  'M10.5 10c0-.796.29-1.559.806-2.121A2.639 2.639 0 0 1 13.25 7c.73 0 1.429.316 1.944.879C15.71 8.44 16 9.204 16 10s-.29 1.559-.806 2.121A2.64 2.64 0 0 1 13.25 13a2.64 2.64 0 0 1-1.944-.879A3.144 3.144 0 0 1 10.5 10z M5 16c0-.796.29-1.559.805-2.121A2.64 2.64 0 0 1 7.75 13h2.75v3c0 .796-.29 1.559-.805 2.121A2.64 2.64 0 0 1 7.75 19a2.64 2.64 0 0 1-1.945-.879A3.144 3.144 0 0 1 5 16z M10.5 1v6h2.75c.73 0 1.429-.316 1.944-.879A3.144 3.144 0 0 0 16 4c0-.796-.29-1.559-.806-2.121A2.639 2.639 0 0 0 13.25 1H10.5z M5 4c0 .796.29 1.559.805 2.121A2.64 2.64 0 0 0 7.75 7h2.75V1H7.75a2.64 2.64 0 0 0-1.945.879A3.144 3.144 0 0 0 5 4z M5 10c0 .796.29 1.559.805 2.121A2.64 2.64 0 0 0 7.75 13h2.75V7H7.75a2.64 2.64 0 0 0-1.945.879A3.144 3.144 0 0 0 5 10z a M0 0h11v18H0z';

export const createBlockCheckboxGrid =
  'M1 2h4v4H1zM8 2h4v4H8zM15 2h4v4h-4zM1 8h4v4H1zM8 8h4v4H8zM15 8h4v4h-4zM1 14h4v4H1zM8 14h4v4H8zM15 14h4v4h-4z';

export const createBellOff =
  'M2.28 1.22a.75.75 0 0 0-1.06 1.06L3.76 4.823A6.973 6.973 0 0 0 3 8v3.25A2.25 2.25 0 0 1 .75 13.5a.75.75 0 0 0 0 1.5h13.185l3.785 3.786a.75.75 0 1 0 1.06-1.06L2.28 1.22zM12.435 13.5 4.893 5.955A5.483 5.483 0 0 0 4.5 8v3.25c0 .844-.279 1.623-.75 2.25h8.685z M6.7 3.6A5.5 5.5 0 0 1 15.5 8v3.25A3.75 3.75 0 0 0 19.25 15a.75.75 0 0 0 0-1.5A2.25 2.25 0 0 1 17 11.25V8A7 7 0 0 0 5.799 2.4a.75.75 0 0 0 .9 1.2zM8.029 16.538a.75.75 0 1 0-1.182.924A3.994 3.994 0 0 0 10 19a3.994 3.994 0 0 0 3.153-1.538.75.75 0 1 0-1.182-.924A2.495 2.495 0 0 1 10 17.5c-.8 0-1.512-.375-1.971-.962z';

export const createBell =
  'M10 2.5A5.5 5.5 0 0 0 4.5 8v3.25c0 .844-.279 1.623-.75 2.25h12.5a3.734 3.734 0 0 1-.75-2.25V8A5.5 5.5 0 0 0 10 2.5zm9.25 11A2.25 2.25 0 0 1 17 11.25V8A7 7 0 1 0 3 8v3.25A2.25 2.25 0 0 1 .75 13.5a.75.75 0 0 0 0 1.5h18.5a.75.75 0 0 0 0-1.5zM6.976 16.41a.75.75 0 0 1 1.053.128c.459.587 1.17.962 1.971.962.8 0 1.512-.375 1.971-.962a.75.75 0 1 1 1.182.924A3.994 3.994 0 0 1 10 19a3.994 3.994 0 0 1-3.153-1.538.75.75 0 0 1 .13-1.053z';

export const createAward =
  'M15 7.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0zm-2.638 6.058A6.484 6.484 0 0 1 10 14c-.833 0-1.63-.157-2.362-.442l-.75 3.153 2.732-1.607a.75.75 0 0 1 .76 0l2.733 1.607-.751-3.153zm1.363-.73a6.5 6.5 0 1 0-7.45 0l-.005-.002-1.25 5.25a.75.75 0 0 0 1.11.82L10 16.62l3.87 2.276a.75.75 0 0 0 1.11-.82l-1.25-5.25-.005.001z';

export const createArrowUp =
  'M9.47 2.22a.75.75 0 0 1 1.06 0l6 6a.75.75 0 0 1-1.06 1.06l-4.72-4.72v12.69a.75.75 0 0 1-1.5 0V4.56L4.53 9.28a.75.75 0 0 1-1.06-1.06l6-6z';

export const createArrowRight =
  'M10.72 3.47a.75.75 0 0 0 0 1.06l4.72 4.72H2.75a.75.75 0 0 0 0 1.5h12.69l-4.72 4.72a.75.75 0 1 0 1.06 1.06l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.06 0z';

export const createArrowLeft =
  'M9.28 3.47a.75.75 0 0 1 0 1.06L4.56 9.25h12.69a.75.75 0 0 1 0 1.5H4.56l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0z';

export const createArrowDown =
  'M16.53 10.72a.75.75 0 0 0-1.06 0l-4.72 4.72V2.75a.75.75 0 0 0-1.5 0v12.69l-4.72-4.72a.75.75 0 0 0-1.06 1.06l6 6a.75.75 0 0 0 1.06 0l6-6a.75.75 0 0 0 0-1.06z';

export const createAlignRight =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM5 8.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H5.75A.75.75 0 0 1 5 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm2 3.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H5.75z';

export const createAlignLeft =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM3 8.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm0 3.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5H3.75z';

export const createAlignJustify =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM3 8.25a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm0 3.5a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75z';

export const createAlignCenter =
  'M3.75 4a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zM5 8.25a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5A.75.75 0 0 1 5 8.25zM3.75 11a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H3.75zm2 3.5a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5z';

export const createLinkAlt =
  'M2.5 9.875A3.375 3.375 0 0 1 5.875 6.5H8.25a.75.75 0 0 0 0-1.5H5.875A4.875 4.875 0 0 0 1 9.875v.25A4.875 4.875 0 0 0 5.875 15H8.25a.75.75 0 0 0 0-1.5H5.875A3.375 3.375 0 0 1 2.5 10.125v-.25zM11.75 5a.75.75 0 0 0 0 1.5h2.375A3.375 3.375 0 0 1 17.5 9.875v.25a3.375 3.375 0 0 1-3.375 3.375H11.75a.75.75 0 0 0 0 1.5h2.375A4.875 4.875 0 0 0 19 10.125v-.25A4.875 4.875 0 0 0 14.125 5H11.75zm-5 4.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5z';

export const createHome =
  'M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6';
