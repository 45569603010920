import { FormFieldsForObject } from '@shared/components/json-defined-form/FormFieldsForObject';
import { useInternalFormContext } from '@shared/components/json-defined-form/internalFormContext/InternalFormContext';
import { FormTemplateChildConfigs } from '@shared/components/json-defined-form/stateManagement/form';
import {
  EditableJSON,
  EditableJSONField,
} from '@shared/components/json-defined-form/types';
import { getObjectValue } from 'clerk_common/templates/getValue';
import { mutateByFieldTypeSync } from 'clerk_common/templates/mutate/mutateSync';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { LocationEntityCard } from './LocationEntityCard';
import { OriginatorLocationSelect } from './OriginatorLocationSelect';

type LocationEntityProps = {
  data: EditableJSON;
  prefix: string;
  openLocation: (id: string) => void;
};

export function LocationEntity({
  data,
  prefix,
  openLocation,
}: LocationEntityProps) {
  const { save } = useInternalFormContext();
  const { setValue } = useFormContext<EditableJSONField>();
  const setEntityId = (id: string) => {
    setValue(`${prefix}_entityId._corrected` as '_corrected', id);
  };
  const entityId = data._entityId ? getObjectValue(data._entityId) : undefined;
  const selectButtonText = entityId ? 'Change Location' : '+ Select Location';

  const resetToEntity = () => {
    const cleared = resetProvenanceToEntity(data);
    // TODO(mike): Properly type this.
    setValue(`${prefix}_value` as '_value', cleared._value as string);
    save();
  };

  const onSelect = (id: string) => {
    setEntityId(id);
    resetToEntity();
    // TODO(mike): Refresh entities here.
  };

  if (entityId) {
    return (
      <>
        <LocationEntityCard
          id={entityId}
          setEntityId={onSelect}
          openLocation={openLocation}
        />
        <OriginatorLocationSelect
          buttonText={selectButtonText}
          selectedId={entityId}
          onSelect={onSelect}
        />
      </>
    );
  }

  return (
    <>
      <OriginatorLocationSelect
        buttonText={selectButtonText}
        onSelect={onSelect}
      />
      <FormFieldsForObject
        dereferenceValueField
        data={data._value}
        _display={data._display}
        prefix={prefix}
      />
    </>
  );
}

export function resetProvenanceToEntity(value: EditableJSON) {
  const mutations = {
    field: (child: FormTemplateChildConfigs) => {
      if (!isNil(child._form)) delete child._form;
      if (!isNil(child._corrected)) delete child._corrected;
      if (!isNil(child._transformed)) delete child._transformed;
      return child;
    },
  };
  return mutateByFieldTypeSync(value, mutations);
}
