import { ApolloError } from '@apollo/client';
import { useToast } from '@shared/components/toast';
import {
  GetQuoteRateFormDocument,
  GetQuoteResponseMessageDocument,
  GetQuotesDocument,
  QuoteDocument,
  RatingError,
  useComputeRatesMutation,
} from '@shared/generated/graphql';
import { useRateErrorsContext } from '../contexts/RateErrorContext';

const removeHtmlTags = (str: string) => str.replace(/<[^>]*>?/gm, '');

export const useComputeRates = () => {
  const [computeRates, { loading }] = useComputeRatesMutation();
  const { sendToast } = useToast();
  const { setRateError, clearRateErrors } = useRateErrorsContext();

  const sendErrorToast = (e: ApolloError) => {
    sendToast('Error fetching rates', {
      variant: 'error',
      isDismissible: true,
      description: removeHtmlTags(e.message),
    });
  };

  const handleRatingError = (ratingError: RatingError) => {
    const errorMessage = ratingError.errorMessage
      ? removeHtmlTags(ratingError.errorMessage)
      : 'Unknown error';

    setRateError(ratingError.ratingMethod, errorMessage);
  };

  const compute = async (id: string) => {
    const res = await computeRates({
      variables: {
        input: {
          id,
        },
      },
      onError: sendErrorToast,
      refetchQueries: [
        { query: QuoteDocument, variables: { id } },
        GetQuotesDocument, // TODO(parlato): This is causing an 'unknown document' error
        GetQuoteResponseMessageDocument,
        GetQuoteRateFormDocument,
      ],
    });

    const ratingErrors = res?.data?.computeRates.ratingErrors;

    clearRateErrors();
    if (ratingErrors?.length) {
      ratingErrors.forEach(handleRatingError);
    }
  };

  return {
    compute,
    loading,
  };
};
