import { Button, Text } from '@shared/components';
import { CircularProgress } from '@shared/components/circular-progress';
import { InteractableComponent } from '@shared/components/interactable-component';
import { Popover } from '@shared/components/popover';
import { PopoverProps } from '@shared/components/popover/Popover';
import { OptionalTooltip } from '@shared/components/tooltip/Tooltip';
import { useOptionalRef } from '@shared/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
  TooltipComponentProps,
  TooltipForwardProps,
  useTooltipComponentProps,
  useTooltipForwardProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, useState } from 'react';
import { MoreVertical } from 'react-feather';

export type OptionProps = Pick<TooltipComponentProps, 'tooltipSide'> &
  Omit<TooltipForwardProps, 'onKeyDown' | 'onBlur'> & {
    label: string;
    isDestructive?: boolean;
    onPress?: () => void;
    tooltip?: string;
    isDisabled?: boolean;
  };

export const OptionComponent = (
  p: OptionProps,
  ref: ForwardedRef<HTMLElement>
) => {
  const tooltipProps = useTooltipComponentProps(p);
  const tooltipForwardProps = useTooltipForwardProps(p);
  const domRef = useOptionalRef(ref);

  return (
    <OptionalTooltip {...tooltipProps} content={p.tooltip} isInstant>
      <div>
        <InteractableComponent
          ref={domRef}
          {...tooltipForwardProps}
          onPress={p.onPress}
          className={clsx(el`option`, { 'is-destructive': p.isDestructive })}
          isDisabled={p.isDisabled}
        >
          <button className={el`no-focus-outline`}>
            <Text type="body-sm">{p.label}</Text>
          </button>
        </InteractableComponent>
      </div>
    </OptionalTooltip>
  );
};

export const Option = forwardRef<HTMLElement, OptionProps>(OptionComponent);

export type MoreMenuProps = StyleProps &
  Omit<PopoverProps, 'children' | 'content'> & {
    onChooseRemove?: () => void | Promise<void>;
    onRemoveLabel?: string;
    isLoading?: boolean;
    options?: OptionProps[];
    moreIcon?: ReactElement;
  };

const ROOT = makeRootClassName('MoreMenu');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function MoreMenu(props: MoreMenuProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { onChooseRemove, ...popoverProps } = p;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      content={
        <>
          {p.options?.map((option, i) => (
            <Option
              key={i}
              label={option.label}
              onPress={async () => {
                setIsOpen(false);
                setIsLoading(true);
                await option.onPress?.();
                setIsLoading(false);
              }}
              isDestructive={option.isDestructive}
              tooltip={option.tooltip}
              isDisabled={option.isDisabled}
            />
          ))}
        </>
      }
      className={clsx(ROOT, p.className, el`content`)}
      {...popoverProps}
    >
      {p.isLoading || isLoading ? (
        <CircularProgress size="xs" />
      ) : (
        <Button
          icon={p.moreIcon ?? <MoreVertical size="16" />}
          size="xs"
          isGhost
          className={el`icon-button`}
        />
      )}
    </Popover>
  );
}

export default MoreMenu;
