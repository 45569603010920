import { createContext, useContext } from 'react';

export const OriginatorContext = createContext(null as { id?: string } | null);

export function useSelectedOriginator() {
  const context = useContext(OriginatorContext);
  if (!context) {
    throw new Error(
      'useSelectedOriginator must be used within a SelectedOriginatorProvider'
    );
  }
  return context;
}
