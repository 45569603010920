import { Text } from '@shared/components';
import { useLoadInitialValues } from '@shared/components/react-hook-form';
import {
  WizardFooter,
  WizardStepContainer,
  WizardStepProps,
} from '@shared/components/wizard';
import { Originator } from '@shared/plugin/types/originator';
import { useForm } from 'react-hook-form';
import { el } from '../OriginatorOnboardingWizard';

interface OriginatorOnboardingConclusionProps {
  originator: Originator;
}
export type ConclusionData = {
  // TODO(mike): Any additional data needed for the conclusion step
};
const CONCLUSION_DEFAULTS = {};
export function OriginatorOnboardingConclusion(
  p: WizardStepProps<ConclusionData> & OriginatorOnboardingConclusionProps
) {
  const { handleSubmit, reset, getValues } = useForm<ConclusionData>({
    defaultValues: p.defaultValues ?? CONCLUSION_DEFAULTS,
  });
  useLoadInitialValues(reset, p.defaultValues as ConclusionData);

  return (
    <WizardStepContainer
      handleSubmit={handleSubmit}
      {...p}
      className={el`wizard-step-container`}
    >
      <div className="flex flex-col gap-4">
        <div className={el`header`}>
          <Text type="custom" isHeavy className={el`title`}>
            Ready for action!
          </Text>
        </div>
        <Text type="body-sm" className={el`subtitle`}>
          {`${p.originator.name} is configured. Click done to finish set-up.`}
        </Text>
      </div>
      <WizardFooter
        handleSubmit={handleSubmit}
        getValues={getValues}
        {...p}
        className={el`footer`}
        submitLabel="Done"
      />
    </WizardStepContainer>
  );
}
