import {
  FormValueChild,
  ListValuedIntermediateTemplateField,
  ObjectValuedIntermediateTemplateField,
} from '../types';

export const updatePath = (key: string, path?: string) => {
  return path ? `${path}.${key}` : key;
};

export const isObjectValued = (
  template: FormValueChild
): template is ObjectValuedIntermediateTemplateField => {
  return typeof template._value === 'object' && !Array.isArray(template._value);
};

export const isListValued = (
  template: FormValueChild
): template is ListValuedIntermediateTemplateField => {
  return Array.isArray(template._value);
};
