import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { AriaTextFieldProps } from '@react-types/textfield';
import { InteractableComponent } from '@shared/components/interactable-component';
import { useOptionalRef } from '@shared/hooks';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactElement,
  Ref,
  TextareaHTMLAttributes,
} from 'react';
import { X } from 'react-feather';
import { createCheck, createRingWarning } from '../../assets/icons';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '../../utils';
import Icon from '../icon/Icon';

type InputRef<T extends HTMLInputElement | HTMLTextAreaElement> = {
  /**
   * Optional ref to use for the inner input element.
   * Useful for libraries that need a ref to the input for focus
   * management, events, and other use cases i.e. react-hook-form.
   *
   * The default forwarded `ref` prop is set on the outer div
   * (useful for positioning, scroll and other use cases)
   */
  inputRef?: T extends HTMLInputElement
    ? Ref<HTMLInputElement>
    : Ref<HTMLTextAreaElement>;
};

type SharedTextInputOrAreaPublicProps = StyleProps &
  AriaTextFieldProps & {
    /**
     * The size of the input.
     * @default "medium"
     */
    size?: 'large' | 'medium' | 'small' | 'custom';

    /**
     * The text field's icon (svg path).
     */
    iconPath?: string;

    /**
     * The text fields's visual appearance.
     * @default "default"
     */
    variant?: 'default' | 'light' | 'outlined';

    /**
     * Additional children to be included in the input wrapper.
     * Useful for customization (searchfield, etc.)
     */
    wrapperChildren?: ReactElement | ReactElement[];

    wrapperChildrenPlacement?: 'inside' | 'outside';

    /** The class name for the input element. */
    inputClassName?: string;

    /** The class name for the input wrapper element. */
    inputWrapperClassName?: string;

    placeholderClassName?: string;

    /** Text prepended to the input, inside the box. */
    prepend?: ReactElement;

    /** Text appended to the input, inside the box. */
    append?: ReactElement;

    /**
     * Set tabIndex to -1 to disable keyboard interaction.
     * Check MDN docs {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex} for more information.
     * @default 0
     */
    tabIndex?: number;

    /**
     * Handler for when the clear button is clicked.
     * @default undefined
     */
    onClear?: () => void;

    /**
     * Whether the text field is required.
     * @default false
     */
    isRequired?: boolean;

    isTransparent?: boolean;
    transparentWithoutFocus?: boolean;

    /**
     * @default 'valid'
     */
    validationState?: 'valid' | 'invalid';

    step?: number;
  };

export type TextFieldProps = SharedTextInputOrAreaPublicProps &
  InputRef<HTMLInputElement>;

export type TextAreaProps = SharedTextInputOrAreaPublicProps &
  InputRef<HTMLTextAreaElement> & {
    /**
     * Whether the textarea autoresizes based on content
     * or allows manual resizing.
     * @default false
     */
    autoresizes?: boolean;
  };

type TextAreaAndTextFieldPropsWithoutInputRef = Omit<
  TextAreaProps,
  'inputRef'
> &
  Omit<TextFieldProps, 'inputRef'> & {
    /**
     * The size of the input.
     * @default "medium"
     */
    size?: 'large' | 'medium' | 'small' | 'custom';

    /** The props for the label element. */
    labelProps?: Omit<LabelHTMLAttributes<HTMLLabelElement>, 'id'>;

    /**
     * The text field's icon (svg path).
     */
    iconPath?: string;
  };

export type TextFieldBaseProps = TextAreaAndTextFieldPropsWithoutInputRef &
  (
    | {
        /**
         * Whether the field element should be a `textarea` instead of an
         * `input`.
         * @default false
         */
        isMultiLine: true;
        /** The props for the input element. */
        inputProps: Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'id'>;

        /** The input element's ref. */
        inputRef?: Ref<HTMLTextAreaElement>;
      }
    | {
        /**
         * Whether the field element should be a `textarea` instead of an
         * `input`.
         * @default false
         */
        isMultiLine?: false;

        /** The props for the input element. */
        inputProps: Omit<InputHTMLAttributes<HTMLInputElement>, 'id'>;

        /** The input element's ref. */
        inputRef?: Ref<HTMLInputElement>;
      }
  );

// config

const ROOT = makeRootClassName('TextFieldBase');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  labelPosition: 'top',
  isRequired: false,
  isMultiLine: false,
  autoresizes: false,
  variant: 'default',
  tabIndex: 0,
};

const VALID_ICON_PATH = createCheck;
const INVALID_ICON_PATH = createRingWarning;

function TextFieldBaseComponent(
  props: TextFieldBaseProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { placeholder, ...inputProps } = p.inputProps;
  const domRef = useOptionalRef(ref);

  // behavior
  // --------

  const { focusProps, isFocusVisible, isFocused } = useFocusRing({
    isTextInput: true,
  });
  const { isHovered, hoverProps } = useHover({ isDisabled: p.isDisabled });
  const inputBehaviorProps = mergeProps(focusProps, hoverProps, inputProps, {
    placeholder: ' ', // this hack makes :placeholder-shown work
  });

  const validationIconPath =
    p.validationState === 'valid' ? VALID_ICON_PATH : INVALID_ICON_PATH;
  const inputElementProps = {
    ...inputBehaviorProps,
    ['aria-placeholder']: placeholder,
    className: clsx(el`input`, p.inputClassName),
    tabIndex: p.tabIndex,
  };

  return (
    <div
      ref={domRef}
      className={clsx(
        `${ROOT} size-${p.size} variant-${p.variant}`,
        {
          'is-required': p.isRequired,
          'is-multiline': p.isMultiLine,
          'has-autoresize': p.autoresizes,
          'has-icon': p.iconPath,
          'is-valid': p.validationState === 'valid',
          'is-invalid': p.validationState === 'invalid',
          'is-disabled': p.isDisabled,
          'is-hovered': isHovered,
          'is-focused': isFocused,
          'is-focus-visible': isFocusVisible,
          'is-transparent':
            p.isTransparent || (p.transparentWithoutFocus && !isFocused),
        },
        p.className
      )}
    >
      {p.label && (
        <label {...p.labelProps} className={el`label`}>
          {p.isRequired && <div className={el`label-required-indicator`} />}
          <span className={el`label-span`}>{p.label}</span>
        </label>
      )}
      <div className={el`wrapper-and-children`}>
        <div className={clsx(el`input-wrapper`, p.inputWrapperClassName)}>
          {p.prepend}
          {props.isMultiLine ? (
            <textarea ref={props.inputRef} {...inputElementProps} />
          ) : (
            <input
              autoFocus={props.autoFocus}
              step={props.step}
              ref={props.inputRef}
              {...inputElementProps}
            />
          )}
          {p.append}
          {p.iconPath && <Icon content={p.iconPath} className={el`icon`} />}
          <div className={el`placeholder-wrapper`}>
            {placeholder?.split('\n').map((line, i) => (
              <p
                key={i}
                className={clsx(
                  el`placeholder`,
                  !isFocused && p.placeholderClassName
                )}
              >
                {line}
              </p>
            ))}
          </div>
          <div className={el`validation-icon-wrapper`}>
            {p.validationState && !p.isDisabled && (
              <Icon
                size="custom"
                className={el`validation-icon`}
                content={validationIconPath}
              />
            )}
            {p.onClear && p.value && p.value.length > 0 && (
              <InteractableComponent
                className={el`clear-button`}
                onPress={p.onClear}
                tabIndex={-1}
              >
                <Icon
                  size="custom"
                  className={el`validation-icon`}
                  content={<X size={'100%'} />}
                />
              </InteractableComponent>
            )}
          </div>
          {p.wrapperChildrenPlacement !== 'outside' &&
            p.wrapperChildren &&
            p.wrapperChildren}
        </div>
        {p.wrapperChildrenPlacement === 'outside' &&
          p.wrapperChildren &&
          p.wrapperChildren}
      </div>
    </div>
  );
}

export const TextFieldBase = forwardRef<HTMLDivElement, TextFieldBaseProps>(
  TextFieldBaseComponent
);

export default TextFieldBase;
