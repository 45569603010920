import { merge, omit } from 'lodash';
import { toComplexCommodityBlock } from './common';
import {
  TemplateWithCommodity,
  TemplateWithMultiComplexCommodity,
} from './types';

export function convertToMultiCommodity(
  template: TemplateWithCommodity
): TemplateWithMultiComplexCommodity {
  if (template._value.commodity) {
    return simpleToMultiCommodity(template);
  }
  if (template._value.commodityDetails) {
    return complexToMultiCommodity(template);
  }
  if (template._value.commodities) {
    return template;
  }
  throw new Error('No commodity found');
}

function simpleToMultiCommodity(
  template: TemplateWithCommodity
): TemplateWithMultiComplexCommodity {
  const withoutCommodity = omit(template._value, 'commodity');
  const commodity = toComplexCommodityBlock(template._value.commodity);
  if (!commodity) throw new Error('No commodity found');
  return {
    ...template,
    _value: merge({}, withoutCommodity, {
      commodities: {
        _value: [commodity._value],
        _type: 'COMMODITY_ARRAY',
      },
    }),
  };
}

function complexToMultiCommodity(
  template: TemplateWithCommodity
): TemplateWithMultiComplexCommodity {
  const withoutCommodityDetails = omit(template._value, 'commodityDetails');
  const commodityDetails = template._value.commodityDetails?._value;
  if (!commodityDetails) throw new Error('No commodity found');
  return {
    ...template,
    _value: merge({}, withoutCommodityDetails, {
      commodities: {
        _value: [commodityDetails],
        _type: 'COMMODITY_ARRAY',
      },
    }),
  };
}
